import React, { useEffect, useState, createContext } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	Result,
	notification,
	Form,
	Steps,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XCardsTransparent,
	XForm,
	XModal,
	XSelect,
	XSteps,
} from '../../styles/GlobalStyle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';

import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
	lowerCaseInput,
	validateMobileNumber,
} from '../../utils/formFunctions';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

// actions
import {
	getPayerConfigurationByIdApi,
	getPayerConfigurationByIdReset,
} from '../../actions/getPayerConfigurationByIdAction';

import { checkModulePermissions } from '../../utils/helper';

import Header from '../../components/Header/Header';

const FormDataContext = createContext();

const PayerConfigurationForm = (props) => {
	const {
		getPayerConfigurationById,
		getPayerConfigurationByIdReset,
		getPayerConfigurationByIdState,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	let modulePermissions = checkModulePermissions('payer-configuration');

	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		hospital: null,
		insurance_company: null,
		'pre_service-rc-organization_determination-timeline': 0,
		'pre_service-rc-organization_determination-timeline_type': null,
		'pre_service-rc-organization_determination-in_process': 0,
		'pre_service-rc-organization_determination-at_risk': 0,
		'pre_service-rc-organization_determination-escalation': 0,
		'pre_service-rc-organization_determination-untimely': 0,
		'pre_service-rc-first_appeal-timeline': 0,
		'pre_service-rc-first_appeal-timeline_type': null,
		'pre_service-rc-first_appeal-in_process': 0,
		'pre_service-rc-first_appeal-at_risk': 0,
		'pre_service-rc-first_appeal-escalation': 0,
		'pre_service-rc-first_appeal-untimely': 0,
		'pre_service-rc-second_appeal-timeline': 0,
		'pre_service-rc-second_appeal-timeline_type': null,
		'pre_service-rc-second_appeal-in_process': 0,
		'pre_service-rc-second_appeal-at_risk': 0,
		'pre_service-rc-second_appeal-escalation': 0,
		'pre_service-rc-second_appeal-untimely': 0,
		'pre_service-rc-third_appeal-timeline': 0,
		'pre_service-rc-third_appeal-timeline_type': null,
		'pre_service-rc-third_appeal-in_process': 0,
		'pre_service-rc-third_appeal-at_risk': 0,
		'pre_service-rc-third_appeal-escalation': 0,
		'pre_service-rc-third_appeal-untimely': 0,
		'pre_service-rc-fourth_appeal-timeline': 0,
		'pre_service-rc-fourth_appeal-timeline_type': null,
		'pre_service-rc-fourth_appeal-in_process': 0,
		'pre_service-rc-fourth_appeal-at_risk': 0,
		'pre_service-rc-fourth_appeal-escalation': 0,
		'pre_service-rc-fourth_appeal-untimely': 0,
		'pre_service-rc-judicial_review-timeline': 0,
		'pre_service-rc-judicial_review-timeline_type': null,
		'pre_service-rc-judicial_review-in_process': 0,
		'pre_service-rc-judicial_review-at_risk': 0,
		'pre_service-rc-judicial_review-escalation': 0,
		'pre_service-rc-judicial_review-untimely': 0,
		'post_claim-rc-organization_determination-timeline': 0,
		'post_claim-rc-organization_determination-timeline_type': null,
		'post_claim-rc-organization_determination-in_process': 0,
		'post_claim-rc-organization_determination-at_risk': 0,
		'post_claim-rc-organization_determination-escalation': 0,
		'post_claim-rc-organization_determination-untimely': 0,
		'post_claim-rc-first_appeal-timeline': 0,
		'post_claim-rc-first_appeal-timeline_type': null,
		'post_claim-rc-first_appeal-in_process': 0,
		'post_claim-rc-first_appeal-at_risk': 0,
		'post_claim-rc-first_appeal-escalation': 0,
		'post_claim-rc-first_appeal-untimely': 0,
		'post_claim-rc-second_appeal-timeline': 0,
		'post_claim-rc-second_appeal-timeline_type': null,
		'post_claim-rc-second_appeal-in_process': 0,
		'post_claim-rc-second_appeal-at_risk': 0,
		'post_claim-rc-second_appeal-escalation': 0,
		'post_claim-rc-second_appeal-untimely': 0,
		'post_claim-rc-third_appeal-timeline': 0,
		'post_claim-rc-third_appeal-timeline_type': null,
		'post_claim-rc-third_appeal-in_process': 0,
		'post_claim-rc-third_appeal-at_risk': 0,
		'post_claim-rc-third_appeal-escalation': 0,
		'post_claim-rc-third_appeal-untimely': 0,
		'post_claim-rc-fourth_appeal-timeline': 0,
		'post_claim-rc-fourth_appeal-timeline_type': null,
		'post_claim-rc-fourth_appeal-in_process': 0,
		'post_claim-rc-fourth_appeal-at_risk': 0,
		'post_claim-rc-fourth_appeal-escalation': 0,
		'post_claim-rc-fourth_appeal-untimely': 0,
		'post_claim-rc-judicial_review-timeline': 0,
		'post_claim-rc-judicial_review-timeline_type': null,
		'post_claim-rc-judicial_review-in_process': 0,
		'post_claim-rc-judicial_review-at_risk': 0,
		'post_claim-rc-judicial_review-escalation': 0,
		'post_claim-rc-judicial_review-untimely': 0,
		'part_b_drugs-rc-organization_determination-timeline': 0,
		'part_b_drugs-rc-organization_determination-timeline_type': null,
		'part_b_drugs-rc-organization_determination-in_process': 0,
		'part_b_drugs-rc-organization_determination-at_risk': 0,
		'part_b_drugs-rc-organization_determination-escalation': 0,
		'part_b_drugs-rc-organization_determination-untimely': 0,
		'part_b_drugs-rc-first_appeal-timeline': 0,
		'part_b_drugs-rc-first_appeal-timeline_type': null,
		'part_b_drugs-rc-first_appeal-in_process': 0,
		'part_b_drugs-rc-first_appeal-at_risk': 0,
		'part_b_drugs-rc-first_appeal-escalation': 0,
		'part_b_drugs-rc-first_appeal-untimely': 0,
		'part_b_drugs-rc-second_appeal-timeline': 0,
		'part_b_drugs-rc-second_appeal-timeline_type': null,
		'part_b_drugs-rc-second_appeal-in_process': 0,
		'part_b_drugs-rc-second_appeal-at_risk': 0,
		'part_b_drugs-rc-second_appeal-escalation': 0,
		'part_b_drugs-rc-second_appeal-untimely': 0,
		'part_b_drugs-rc-third_appeal-timeline': 0,
		'part_b_drugs-rc-third_appeal-timeline_type': null,
		'part_b_drugs-rc-third_appeal-in_process': 0,
		'part_b_drugs-rc-third_appeal-at_risk': 0,
		'part_b_drugs-rc-third_appeal-escalation': 0,
		'part_b_drugs-rc-third_appeal-untimely': 0,
		'part_b_drugs-rc-fourth_appeal-timeline': 0,
		'part_b_drugs-rc-fourth_appeal-timeline_type': null,
		'part_b_drugs-rc-fourth_appeal-in_process': 0,
		'part_b_drugs-rc-fourth_appeal-at_risk': 0,
		'part_b_drugs-rc-fourth_appeal-escalation': 0,
		'part_b_drugs-rc-fourth_appeal-untimely': 0,
		'part_b_drugs-rc-judicial_review-timeline': 0,
		'part_b_drugs-rc-judicial_review-timeline_type': null,
		'part_b_drugs-rc-judicial_review-in_process': 0,
		'part_b_drugs-rc-judicial_review-at_risk': 0,
		'part_b_drugs-rc-judicial_review-escalation': 0,
		'part_b_drugs-rc-judicial_review-untimely': 0,
		'pre_service-ma_standard-organization_determination-timeline': 0,
		'pre_service-ma_standard-organization_determination-timeline_type':
			null,
		'pre_service-ma_standard-organization_determination-in_process': 0,
		'pre_service-ma_standard-organization_determination-at_risk': 0,
		'pre_service-ma_standard-organization_determination-untimely': 0,
		'pre_service-ma_standard-first_appeal-timeline': 0,
		'pre_service-ma_standard-first_appeal-timeline_type': null,
		'pre_service-ma_standard-first_appeal-in_process': 0,
		'pre_service-ma_standard-first_appeal-at_risk': 0,
		'pre_service-ma_standard-first_appeal-untimely': 0,
		'pre_service-ma_standard-second_appeal-timeline': 0,
		'pre_service-ma_standard-second_appeal-timeline_type': null,
		'pre_service-ma_standard-second_appeal-in_process': 0,
		'pre_service-ma_standard-second_appeal-at_risk': 0,
		'pre_service-ma_standard-second_appeal-untimely': 0,
		'pre_service-ma_standard-third_appeal-timeline': 0,
		'pre_service-ma_standard-third_appeal-timeline_type': null,
		'pre_service-ma_standard-third_appeal-in_process': 0,
		'pre_service-ma_standard-third_appeal-at_risk': 0,
		'pre_service-ma_standard-third_appeal-untimely': 0,
		'pre_service-ma_standard-fourth_appeal-timeline': 0,
		'pre_service-ma_standard-fourth_appeal-timeline_type': null,
		'pre_service-ma_standard-fourth_appeal-in_process': 0,
		'pre_service-ma_standard-fourth_appeal-at_risk': 0,
		'pre_service-ma_standard-fourth_appeal-untimely': 0,
		'pre_service-ma_standard-judicial_review-timeline': 0,
		'pre_service-ma_standard-judicial_review-timeline_type': null,
		'pre_service-ma_standard-judicial_review-in_process': 0,
		'pre_service-ma_standard-judicial_review-at_risk': 0,
		'pre_service-ma_standard-judicial_review-untimely': 0,
		'post_claim_ma_standard-organization_determination-timeline': 0,
		'post_claim_ma_standard-organization_determination-timeline_type': null,
		'post_claim_ma_standard-organization_determination-in_process': 0,
		'post_claim_ma_standard-organization_determination-at_risk': 0,
		'post_claim_ma_standard-organization_determination-untimely': 0,
		'post_claim_ma_standard-first_appeal-timeline': 0,
		'post_claim_ma_standard-first_appeal-timeline_type': null,
		'post_claim_ma_standard-first_appeal-in_process': 0,
		'post_claim_ma_standard-first_appeal-at_risk': 0,
		'post_claim_ma_standard-first_appeal-untimely': 0,
		'post_claim_ma_standard-second_appeal-timeline': 0,
		'post_claim_ma_standard-second_appeal-timeline_type': null,
		'post_claim_ma_standard-second_appeal-in_process': 0,
		'post_claim_ma_standard-second_appeal-at_risk': 0,
		'post_claim_ma_standard-second_appeal-untimely': 0,
		'post_claim_ma_standard-third_appeal-timeline': 0,
		'post_claim_ma_standard-third_appeal-timeline_type': null,
		'post_claim_ma_standard-third_appeal-in_process': 0,
		'post_claim_ma_standard-third_appeal-at_risk': 0,
		'post_claim_ma_standard-third_appeal-untimely': 0,
		'post_claim_ma_standard-fourth_appeal-timeline': 0,
		'post_claim_ma_standard-fourth_appeal-timeline_type': null,
		'post_claim_ma_standard-fourth_appeal-in_process': 0,
		'post_claim_ma_standard-fourth_appeal-at_risk': 0,
		'post_claim_ma_standard-fourth_appeal-untimely': 0,
		'post_claim_ma_standard-judicial_review-timeline': 0,
		'post_claim_ma_standard-judicial_review-timeline_type': null,
		'post_claim_ma_standard-judicial_review-in_process': 0,
		'post_claim_ma_standard-judicial_review-at_risk': 0,
		'post_claim_ma_standard-judicial_review-untimely': 0,
		'part_b_drugs_ma_standard-organization_determination-timeline': 0,
		'part_b_drugs_ma_standard-organization_determination-timeline_type':
			null,
		'part_b_drugs_ma_standard-organization_determination-in_process': 0,
		'part_b_drugs_ma_standard-organization_determination-at_risk': 0,
		'part_b_drugs_ma_standard-organization_determination-untimely': 0,
		'part_b_drugs_ma_standard-first_appeal-timeline': 0,
		'part_b_drugs_ma_standard-first_appeal-timeline_type': null,
		'part_b_drugs_ma_standard-first_appeal-in_process': 0,
		'part_b_drugs_ma_standard-first_appeal-at_risk': 0,
		'part_b_drugs_ma_standard-first_appeal-untimely': 0,
		'part_b_drugs_ma_standard-second_appeal-timeline': 0,
		'part_b_drugs_ma_standard-second_appeal-timeline_type': null,
		'part_b_drugs_ma_standard-second_appeal-in_process': 0,
		'part_b_drugs_ma_standard-second_appeal-at_risk': 0,
		'part_b_drugs_ma_standard-second_appeal-untimely': 0,
		'part_b_drugs_ma_standard-third_appeal-timeline': 0,
		'part_b_drugs_ma_standard-third_appeal-timeline_type': null,
		'part_b_drugs_ma_standard-third_appeal-in_process': 0,
		'part_b_drugs_ma_standard-third_appeal-at_risk': 0,
		'part_b_drugs_ma_standard-third_appeal-untimely': 0,
		'part_b_drugs_ma_standard-fourth_appeal-timeline': 0,
		'part_b_drugs_ma_standard-fourth_appeal-timeline_type': null,
		'part_b_drugs_ma_standard-fourth_appeal-in_process': 0,
		'part_b_drugs_ma_standard-fourth_appeal-at_risk': 0,
		'part_b_drugs_ma_standard-fourth_appeal-untimely': 0,
		'part_b_drugs_ma_standard-judicial_review-timeline': 0,
		'part_b_drugs_ma_standard-judicial_review-timeline_type': null,
		'part_b_drugs_ma_standard-judicial_review-in_process': 0,
		'part_b_drugs_ma_standard-judicial_review-at_risk': 0,
		'part_b_drugs_ma_standard-judicial_review-untimely': 0,
	});
	const [current, setCurrent] = useState(0);
	const [editMode, setEditMode] = useState(false);
	const [hospitalOptions, setHospitalOptions] = useState([]);
	const [insuranceCompanyOptions, setInsuranceCompanyOptions] = useState([]);

	const steps = [
		{
			title: 'Basic Info',
			content: <Step1 />,
		},
		{
			title: 'Pre Service',
			content: <Step2 />,
		},
		{
			title: 'Post Claim',
			content: <Step3 />,
		},
		{
			title: 'Part B Drugs',
			content: <Step4 />,
		},
	];

	// use effects
	useEffect(() => {
		if (id) {
			getPayerConfigurationById({ id: id });
			setEditMode(true);
		}
	}, [id]);

	useEffect(() => {
		if (getPayerConfigurationByIdState.apiState === 'success') {
			setFormData({
				...getPayerConfigurationByIdState.data,
				hospital: getPayerConfigurationByIdState.data.hospital._id,
				insurance_company:
					getPayerConfigurationByIdState.data.insurance_company._id,
				id: getPayerConfigurationByIdState.data._id,
			});
			form.setFieldsValue({
				...getPayerConfigurationByIdState.data,
				hospital: getPayerConfigurationByIdState.data.hospital._id,
				insurance_company:
					getPayerConfigurationByIdState.data.insurance_company._id,
				id: getPayerConfigurationByIdState.data._id,
			});

			setHospitalOptions([
				{
					label: getPayerConfigurationByIdState.data.hospital.name,
					value: getPayerConfigurationByIdState.data.hospital._id,
				},
			]);

			setInsuranceCompanyOptions([
				{
					label: getPayerConfigurationByIdState.data.insurance_company
						.name,
					value: getPayerConfigurationByIdState.data.insurance_company
						._id,
				},
			]);
			getPayerConfigurationByIdReset();
		}
	}, [getPayerConfigurationByIdState]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [current]);

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Payer Configuration" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="payerConfiguration" />
					</Col>
				</>
			)}
			<XContainer fluid className="mt-3">
				<XCardBackBg className="mb-3">
					<Row>
						<Col xs={24}>
							<XButtonBack
								onClick={() => navigate(-1)}
								icon={<ArrowLeftOutlined />}
								size="large"
							/>
							{id ? (
								<XEditTitle>
									Edit Payer Configuration
								</XEditTitle>
							) : (
								<XEditTitle>Add Payer Configuration</XEditTitle>
							)}{' '}
						</Col>
					</Row>
				</XCardBackBg>

				<XCardsTransparent>
					{modulePermissions.sub_modules.includes(
						'add-payer-configuration',
						'update-payer-configuration',
					) ? (
						<FormDataContext.Provider
							value={{
								form: form,
								formDataState: {
									formData,
									setFormData,
								},
								currentState: {
									current,
									setCurrent,
								},
								editModeState: {
									editMode,
									setEditMode,
								},
								hospitalOptionsState: {
									hospitalOptions,
									setHospitalOptions,
								},
								insuranceCompanyOptionsState: {
									insuranceCompanyOptions,
									setInsuranceCompanyOptions,
								},
							}}
						>
							<Row gutter={(15, 30)} className="mt-4">
								<Col xs={24}>
									<XSteps current={current} items={steps} />
								</Col>
								<Col xs={24}>
									<>{steps[current].content}</>
								</Col>
							</Row>
						</FormDataContext.Provider>
					) : (
						<Result
							status="403"
							title="403"
							subTitle="Sorry, you are not authorized to access this page."
							extra={
								<Button
									type="primary"
									onClick={() => navigate('/')}
								>
									Back Home
								</Button>
							}
						/>
					)}
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getPayerConfigurationByIdState: state.getPayerConfigurationById,
});

const mapDispatchToProps = (dispatch) => ({
	getPayerConfigurationById: (data) =>
		dispatch(getPayerConfigurationByIdApi(data)),
	getPayerConfigurationByIdReset: () =>
		dispatch(getPayerConfigurationByIdReset()),
});

export { FormDataContext };
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PayerConfigurationForm);
