import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_CASE = 'ADD_CASE';
export const ADD_CASE_SUCCESS = 'ADD_CASE_SUCCESS';
export const ADD_CASE_FAIL = 'ADD_CASE_FAIL';
export const ADD_CASE_RESET = 'ADD_CASE_RESET';

export const addCase = (params) => {
	return {
		type: ADD_CASE,
		params,
	};
};

export const addCaseSuccess = (response) => {
	return {
		type: ADD_CASE_SUCCESS,
		response,
	};
};

export const addCaseFail = (response) => {
	return {
		type: ADD_CASE_FAIL,
		response,
	};
};

export const addCaseReset = () => {
	return {
		type: ADD_CASE_RESET,
	};
};

export const addCaseApi = (data) => {
	return (dispatch) => {
		dispatch(addCase());
		axios
			.post(`${config.api.base_url}/case/create`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addCaseData = response.data;
				dispatch(addCaseSuccess(addCaseData));
			})
			.catch((error) => {
				const errorMsg = error.response.data;
				dispatch(addCaseFail(errorMsg));
			});
	};
};
