import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CHAT_USERS = 'GET_CHAT_USERS';
export const GET_CHAT_USERS_SUCCESS = 'GET_CHAT_USERS_SUCCESS';
export const GET_CHAT_USERS_FAIL = 'GET_CHAT_USERS_FAIL';
export const GET_CHAT_USERS_RESET = 'GET_CHAT_USERS_RESET';

export const getChatUsers = (params) => {
	return {
		type: GET_CHAT_USERS,
		params,
	};
};

export const getChatUsersSuccess = (response) => {
	return {
		type: GET_CHAT_USERS_SUCCESS,
		response,
	};
};

export const getChatUsersFail = (response) => {
	return {
		type: GET_CHAT_USERS_FAIL,
		response,
	};
};

export const getChatUsersReset = () => {
	return {
		type: GET_CHAT_USERS_RESET,
	};
};

export const getChatUsersApi = (data) => {
	
	return (dispatch) => {
		dispatch(getChatUsers());
		axios
			.get(`${config.api.base_url}/chat/get-chat-user`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				console.log('-----------------------');
				const getChatUsersData = response.data;
				console.log('response.data', response.data);
				dispatch(getChatUsersSuccess(getChatUsersData));
			})
			.catch((error) => {
				console.log('error--------------', error);
				const errorMsg = error.message;
				dispatch(getChatUsersFail(errorMsg));
			});
	};
};
