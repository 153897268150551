import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_CASE = 'EDIT_CASE';
export const EDIT_CASE_SUCCESS = 'EDIT_CASE_SUCCESS';
export const EDIT_CASE_FAIL = 'EDIT_CASE_FAIL';
export const EDIT_CASE_RESET = 'EDIT_CASE_RESET';

export const editCase = (params) => {
	return {
		type: EDIT_CASE,
		params,
	};
};

export const editCaseSuccess = (response) => {
	return {
		type: EDIT_CASE_SUCCESS,
		response,
	};
};

export const editCaseFail = (response) => {
	return {
		type: EDIT_CASE_FAIL,
		response,
	};
};

export const editCaseReset = () => {
	return {
		type: EDIT_CASE_RESET,
	};
};

export const editCaseApi = (data) => {
	return (dispatch) => {
		dispatch(editCase());
		axios
			.post(`${config.api.base_url}/case/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const editCaseData = response.data;
				dispatch(editCaseSuccess(editCaseData));
			})
			.catch((error) => {
				const errorMsg = error.response.data;
				dispatch(editCaseFail(errorMsg));
			});
	};
};
