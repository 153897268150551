import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_PAYER_CONFIGURATION = 'ADD_PAYER_CONFIGURATION';
export const ADD_PAYER_CONFIGURATION_SUCCESS = 'ADD_PAYER_CONFIGURATION_SUCCESS';
export const ADD_PAYER_CONFIGURATION_FAIL = 'ADD_PAYER_CONFIGURATION_FAIL';
export const ADD_PAYER_CONFIGURATION_RESET = 'ADD_PAYER_CONFIGURATION_RESET';

export const addPayerConfiguration = (params) => {
	return {
		type: ADD_PAYER_CONFIGURATION,
		params,
	};
};

export const addPayerConfigurationSuccess = (response) => {
	return {
		type: ADD_PAYER_CONFIGURATION_SUCCESS,
		response,
	};
};

export const addPayerConfigurationFail = (response) => {
	return {
		type: ADD_PAYER_CONFIGURATION_FAIL,
		response,
	};
};

export const addPayerConfigurationReset = () => {
	return {
		type: ADD_PAYER_CONFIGURATION_RESET,
	};
};

export const addPayerConfigurationApi = (data) => {
	return (dispatch) => {
		dispatch(addPayerConfiguration());
		axios
			.post(`${config.api.base_url}/payer-configuration`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addPayerConfigurationData = response.data;
				dispatch(addPayerConfigurationSuccess(addPayerConfigurationData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addPayerConfigurationFail(errorMsg));
			});
	};
};
