import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAIL = 'GET_REGIONS_FAIL';
export const GET_REGIONS_RESET = 'GET_REGIONS_RESET';

export const getRegions = (params) => {
	return {
		type: GET_REGIONS,
		params,
	};
};

export const getRegionsSuccess = (response) => {
	return {
		type: GET_REGIONS_SUCCESS,
		response,
	};
};

export const getRegionsFail = (response) => {
	return {
		type: GET_REGIONS_FAIL,
		response,
	};
};

export const getRegionsReset = () => {
	return {
		type: GET_REGIONS_RESET,
	};
};

export const getRegionsApi = (data) => {
	return (dispatch) => {
		dispatch(getRegions());
		axios
			.get(`${config.api.base_url}/regions/all`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getRegionsData = response.data;
				dispatch(getRegionsSuccess(getRegionsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getRegionsFail(errorMsg));
			});
	};
};
