import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_CASE = 'DELETE_CASE';
export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS';
export const DELETE_CASE_FAIL = 'DELETE_CASE_FAIL';
export const DELETE_CASE_RESET = 'DELETE_CASE_RESET';

export const deleteCase = (params) => {
	return {
		type: DELETE_CASE,
		params,
	};
};

export const deleteCaseSuccess = (response) => {
	return {
		type: DELETE_CASE_SUCCESS,
		response,
	};
};

export const deleteCaseFail = (response) => {
	return {
		type: DELETE_CASE_FAIL,
		response,
	};
};

export const deleteCaseReset = () => {
	return {
		type: DELETE_CASE_RESET,
	};
};

export const deleteCaseApi = (data) => {
	return (dispatch) => {
		dispatch(deleteCase());
		axios
			.delete(`${config.api.base_url}/case/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const deleteCaseData = response.data;
				dispatch(deleteCaseSuccess(deleteCaseData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteCaseFail(errorMsg));
			});
	};
};
