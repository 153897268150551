import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Drawer, Space } from 'antd';
import { XButton2 } from '../../styles/GlobalStyle';
import { XForm } from './CaseSearchStyle';

const CaseSearch = (props) => {
	let { onClose, open, applyFilters, clearFilters, form } = props;
	const [displayOrganization, setDisplayOrganization] = useState(false);
	const [displayRegion, setDisplayRegion] = useState(false);
	const [displayHospital, setDisplayHospital] = useState(false);

	useEffect(() => {
		const userRole = localStorage.getItem('role');

		if (userRole === 'organization-admin') {
			setDisplayOrganization(true);
		} else if (userRole === 'region-admin') {
			setDisplayOrganization(true);
			setDisplayRegion(true);
		} else if (userRole === 'hospital-admin') {
			setDisplayOrganization(true);
			setDisplayRegion(true);
			setDisplayHospital(true);
		}
	}, []);

	return (
		<>
			<Drawer
				title={'Filter'}
				placement="right"
				onClose={onClose}
				open={open}
				extra={
					<Space>
						<Button
							onClick={() => {
								clearFilters();
								onClose();
							}}
							style={{
								border: 'none',
								background: 'transparent',
								width: '100%',
								boxShadow: 'none',
								color: '#007299',
							}}
						>
							Clear
						</Button>
					</Space>
				}
				zIndex="9999"
			>
				<XForm name="search" layout="vertical" form={form}>
					<Row gutter={(15, 30)}>
						{props.filters?.length > 0 &&
							props.filters.map((filter, index) => {
								const filterName = filter.props.name;
								if (
									(filterName === 'organization' &&
										displayOrganization) ||
									(filterName === 'region' &&
										displayRegion) ||
									(filterName === 'hospital' &&
										displayHospital)
								) {
									return null;
								}
								return (
									<Col xs={24} sm={24} lg={24} xl={24}>
										{filter}
									</Col>
								);
							})}
					</Row>

					<XButton2
						onClick={() => {
							applyFilters();
							onClose();
						}}
						style={{
							marginTop: '10px',
							width: '100%',
							background: '#007299',
							color: '#fff',
						}}
					>
						Apply
					</XButton2>
				</XForm>
			</Drawer>
		</>
	);
};

export default CaseSearch;
