import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XContainer,
	XCardsTransparent,
	XTable,
	XTag,
} from '../../styles/GlobalStyle';
import { XButtonImg } from '../Manage/ManageStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import {
	EditOutlined,
	DeleteOutlined,
	ExclamationCircleFilled,
} from '@ant-design/icons';
import CaseSearch from '../../components/CaseSearch/CaseSearch';

const showDeleteConfirm = () => {
	Modal.confirm({
		title: 'Are you sure delete this case?',
		icon: <ExclamationCircleFilled />,
		//content: 'Some descriptions',
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'No',
		onOk() {
			console.log('OK');
		},
		onCancel() {
			console.log('Cancel');
		},
	});
};

const CasePreparation = (props) => {
	const navigate = useNavigate();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const columns = [
		{
			title: 'CASE Name',
			dataIndex: 'casename',
		},
		{
			title: 'Allocated to',
			dataIndex: 'allocated',
		},
		{
			title: 'status',
			dataIndex: 'status',
		},
		{
			title: 'last date',
			dataIndex: 'lastdate',
		},
		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
			width: '10%',
		},
	];
	const data = [];
	for (let i = 1; i < 7; i++) {
		data.push({
			key: i,
			casename: `Robert Anthony`,
			allocated: `Amin Sayyed`,
			status: (
				<>
					{i === 1 || i === 4 ? (
						<XTag className="completed">Completed</XTag>
					) : (
						''
					)}
					{i === 2 || i === 5 ? (
						<XTag className="pending">Pending</XTag>
					) : (
						''
					)}
					{i === 3 || i === 6 ? (
						<XTag className="ReAllocated">Re-allocated</XTag>
					) : (
						''
					)}
				</>
			),
			lastdate: (
				<div className="showDate">
					<img
						className="img-fluid"
						src="./images/date-icon.svg"
						alt=""
					/>
					04 Oct 2023 , 18:00 PM
				</div>
			),
			action: (
				<div className="text-center">
					<Row>
						<Col xs={12}>
							<XButtonImg
								onClick={() => navigate('/case/edit/123')}
								type="text"
							>
								<EditOutlined />
							</XButtonImg>
						</Col>
						<Col xs={12}>
							<XButtonImg onClick={showDeleteConfirm} type="text">
								<DeleteOutlined />
							</XButtonImg>
						</Col>
					</Row>
				</div>
			),
		});
	}
	return (
		<>
			<HeaderFile activeNav="casePreparation" />
			<XContainer fluid>
				<XCardsTransparent>
					<CaseSearch searchOption="casePreparation" />
					<XTable
						rowSelection={rowSelection}
						columns={columns}
						dataSource={data}
						scroll={{ x: 991 }}
						pagination={false}
					/>
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CasePreparation);
