import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card, Button, Radio, Avatar, Collapse } from 'antd';

export const TimelineCard = styled(Card)`
	width: 100%;
	position: relative;
	background: #f7f8fa;
	border: 1px solid rgba(87, 99, 111, 0.1);
	border-radius: 5px;
	margin-top: 5px;
	.ant-card-body {
		padding: 0px;
	}
	.hasPadding {
		width: 100%;
		position: relative;
		padding: 20px;
	}
	h6.title {
		margin: 0px;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		color: ${({ theme }) => theme.colors.menu2};
	}

	span.innerButton {
		margin-right: 10px;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: #007299;
		cursor: pointer;
	}

	@media only screen and (max-width: 768.99px) {
	}
`;
export const TimelineEditCard = styled(Card)`
	width: 100%;
	position: relative;
	background: #f7f8fa;
	border: 1px solid rgba(87, 99, 111, 0.1);
	border-radius: 0px 0px 5px 5px;
	.ant-card-body {
		padding: 10px 20px !important;
	}
	.titleText {
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	@media only screen and (max-width: 768.99px) {
	}
`;
export const XCollapse = styled(Collapse)`
	background: none !important;
	.ant-collapse-header {
		background: none !important;
		padding: 0px !important;
		font-weight: 500;
		font-size: 12px;
		line-height: 40px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-collapse-content-box {
		padding: 0px !important;
	}
	@media only screen and (max-width: 768.99px) {
	}
`;
