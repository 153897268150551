// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';
// import pp from '../../public/firebase-messaging-sw.js'

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyCyOPQld3Pftksd23Op29ErYcYHh-135zM',
	authDomain: 'decodex-e2819.firebaseapp.com',
	projectId: 'decodex-e2819',
	storageBucket: 'decodex-e2819.appspot.com',
	messagingSenderId: '129565485538',
	appId: '1:129565485538:web:0b3a51119a8f6dcd74f1bb',
	measurementId: 'G-YZ06P5P6LV',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// console.log('messagingmessagingmessagingmessaging',messaging);

export const requestNotificationPermission = async () => {
	try {
		const permission = await Notification.requestPermission();
		if (permission !== 'granted') {
			console.log('Permission not granted for Notification');
			return false;
		}
		return true;
	} catch (error) {
		console.error('Error requesting notification permission', error);
		return false;
	}
};

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.addEventListener('message', (event) => {
		if (event.data && event.data.type === 'REDIRECT' && event.data.url) {
			console.log('Redirecting to:', event.data.url);
			window.location.href = event.data.url; // Perform the redirection
		}
	});
	window.addEventListener('load', async () => {
		try {
			const registration = await navigator.serviceWorker.register(
				'/firebase-messaging-sw.js',
			);

			if (registration.active) {
				registration.active.postMessage({
					appEnv: process.env.REACT_APP_APP_ENV, // Pass the constant dynamically
				});
			}

			console.log(
				'Service Worker registered with scope:',
				registration.scope,
			);
		} catch (error) {
			console.error('Service Worker registration failed:', error);
		}
	});
}

export const requestPermissionAndGetToken = async () => {
	try {
		// Call the permission request function
		const permissionGranted = await requestNotificationPermission();
		console.log('permissionGranted', permissionGranted);
		if (!permissionGranted) {
			return;
		}

		const currentToken = await getToken(messaging, {
			vapidKey:
				'BPHnv0VpQzzeRcMRiiXtyvB5MlPHe7pkOIHu5ytyn3IwWthlVluiyjaA7WQ33nFGlAJ9A9r5eOqg628kcMCXMGw',
		});

		if (currentToken) {
			localStorage.setItem('fcmToken', currentToken);
			// Send token to your server
			axios
				.post(
					`${config.api.base_url}/notification/save-token`,
					{ token: currentToken },
					{
						headers: {
							'Content-type': 'application/json; charset=UTF-8',
							Authorization: 'Bearer ' + getUserToken(),
						},
					},
				)
				.then((response) => {
					console.log('Fcm token saves successfully to database');
				})
				.catch((error) => {
					console.log('error=============', error);
				});
			// return currentToken;
		} else {
			console.log(
				'No registration token available. Request permission to generate one.',
			);
		}
	} catch (error) {
		console.error('An error occurred while retrieving token. ', error);
	}
};

// Listen for token refresh
// messaging.onTokenRefresh(messaging, async () => {
// 	console.log('Token refreshed');
// 	try {
// 		const newToken = await getToken(messaging, {
// 			vapidKey:
// 				'BPHnv0VpQzzeRcMRiiXtyvB5MlPHe7pkOIHu5ytyn3IwWthlVluiyjaA7WQ33nFGlAJ9A9r5eOqg628kcMCXMGw',
// 		});
// 		if (newToken) {
// 			// console.log('New FCM Token:', newToken);
// 			localStorage.setItem('fcmToken', newToken);
// 			// Send token to your server
// 			axios
// 				.post(
// 					`${config.api.base_url}/notification/save-token`,
// 					{ token: newToken },
// 					{
// 						headers: {
// 							'Content-type': 'application/json; charset=UTF-8',
// 							Authorization: 'Bearer ' + getUserToken(),
// 						},
// 					},
// 				)
// 				.then((response) => {
// 					console.log('Fcm token saves successfully to database');
// 				})
// 				.catch((error) => {
// 					console.log('error=============', error);
// 				});
// 		} else {
// 			console.log(
// 				'No registration token available. Request permission to generate one.',
// 			);
// 		}
// 	} catch (error) {
// 		console.error('An error occurred while retrieving token. ', error);
// 	}
// });

export { messaging };
