import React, { useRef, useEffect } from 'react';
import {
	Chart,
	DoughnutController,
	ArcElement,
	Tooltip,
	Legend,
} from 'chart.js';

Chart.register(DoughnutController, ArcElement, Tooltip, Legend);

const HalfDonutChart = ({ data, type, colors, legends }) => {
	const chartRef = useRef(null);

	const formatNumber = (cost) => {
		if (cost === 0) {
			return '0';
		}
		const costFloat = parseFloat(cost);
		const suffixes = ['K', 'M', 'B', 'T'];

		let formattedCost = costFloat;

		let suffix = '';
		for (const s of suffixes) {
			if (formattedCost < 1000) {
				break;
			}
			formattedCost /= 1000.0;
			suffix = s;
		}
		return `${parseFloat(formattedCost.toFixed(2))}${suffix}`;
	};

	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');
		const chart = new Chart(ctx, {
			type: 'doughnut',
			data: {
				labels: legends.map((legend) => legend.text),
				datasets: [
					{
						data: data,
						backgroundColor: colors,
					},
				],
			},
			options: {
				animation: {
					animateRotate:false,
				},
				rotation: -90,
				circumference: 180,
				plugins: {
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							padding: 20,
							font: {
								size: 12,
								lineHeight: 1.2,
							},
							usePointStyle: true,
							pointStyle: 'circle',
						},
					},
					tooltip: {
						enabled: true,
						callbacks: {
							label: function (context) {
								let value = context.raw || 0;
								if (type === 'cases') {
									return ` ${value}%`;
								} else if (type === 'amount') {
									return ` $${formatNumber(value)}`;
								}
							},
						},
					},
				},
				cutout: '80%',
				responsive: true,
				maintainAspectRatio: false,
			},
		});

		return () => {
			chart.destroy();
		};
	}, [data, colors]);

	return <canvas ref={chartRef} className="half-donut-chart"></canvas>;
};

export default HalfDonutChart;
