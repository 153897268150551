import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Avatar, Card, Upload } from 'antd';

export const XCardsProfileBg = styled(Card)`
	.profileHead {
		width: 100%;
		position: relative;
		background: var(
			--Linear,
			linear-gradient(
				90deg,
				rgba(105, 234, 203, 0.15) 0%,
				rgba(234, 204, 248, 0.15) 48%,
				rgba(102, 84, 241, 0.15) 100%
			)
		);
		border-radius: 20px 20px 0px 0px;
		padding: 10px;
		height: 100px;
		@media only screen and (max-width: 768.99px) {
			height: 65px;
		}
	}
	border: none;
	background: none !important;
	.ant-card-body {
		background: #fff;
		border-radius: 20px;
		padding: 0px;
		padding-bottom: 2%;
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		.innerBody {
			width: 100%;
			position: relative;
			padding: 15px 30px;
		}
		@media only screen and (max-width: 768.99px) {
			padding: 0px !important;
		}
	}
`;
export const XAvatar = styled(Avatar)`
	margin-top: -65px;
	border: 2px solid #dadee3;
`;
export const XUpload = styled(Upload)`
	button {
		background: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.menu2};
		border-radius: 20px;
		border: 1px solid #dadee3;
		box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32),
			0px 5px 5px 0px rgba(0, 0, 0, 0.1);
		// &:hover {
		// 	background: #007299 !important;
		// 	color: ${({ theme }) => theme.colors.white} !important;
		// }
	}
`;
