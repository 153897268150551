import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_INSURANCE_COMPANY = 'ADD_INSURANCE_COMPANY';
export const ADD_INSURANCE_COMPANY_SUCCESS = 'ADD_INSURANCE_COMPANY_SUCCESS';
export const ADD_INSURANCE_COMPANY_FAIL = 'ADD_INSURANCE_COMPANY_FAIL';
export const ADD_INSURANCE_COMPANY_RESET = 'ADD_INSURANCE_COMPANY_RESET';

export const addInsuranceCompany = (params) => {
	return {
		type: ADD_INSURANCE_COMPANY,
		params,
	};
};

export const addInsuranceCompanySuccess = (response) => {
	return {
		type: ADD_INSURANCE_COMPANY_SUCCESS,
		response,
	};
};

export const addInsuranceCompanyFail = (response) => {
	return {
		type: ADD_INSURANCE_COMPANY_FAIL,
		response,
	};
};

export const addInsuranceCompanyReset = () => {
	return {
		type: ADD_INSURANCE_COMPANY_RESET,
	};
};

export const addInsuranceCompanyApi = (data) => {
	return (dispatch) => {
		dispatch(addInsuranceCompany());
		axios
			.post(`${config.api.base_url}/insurance-companies/create`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addInsuranceCompanyData = response.data;
				dispatch(addInsuranceCompanySuccess(addInsuranceCompanyData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addInsuranceCompanyFail(errorMsg));
			});
	};
};
