import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'; // Import the isBetween plugin

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Input,
	Button,
	DatePicker,
	InputNumber,
	notification,
	Result,
	Select,
	Upload,
	Modal,
	Card,
	Image,
	Tooltip,
	Space,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XCardsAddCaseInner,
	XCardsCase,
	XCardsCaseBg,
	XCardsTransparent,
	XCustomHeader,
	XForm,
	XModal,
	XSelect,
	XSelectAddCase,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	FieldTimeOutlined,
	DownloadOutlined,
	PlusOutlined,
	InboxOutlined,
	FilePdfOutlined,
	EyeOutlined,
	DeleteOutlined,
	MinusOutlined,
} from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
	XButtonBackRound,
} from './CaseFormStyle';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';

import {
	filterByLabel,
	futureDateDisable,
	handleDateChanged,
	handleInputChange,
	handleNumberInputChange,
	handleSelectChange,
	validateNonFutureDate,
} from '../../utils/formFunctions';
// actions
import {
	getUserDropdownApi,
	getUserDropdownReset,
} from '../../actions/getUserDropdownAction';
import {
	getCaseByIdApi,
	getCaseByIdReset,
} from '../../actions/getCaseByIdAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import { addCaseApi, addCaseReset } from '../../actions/addCaseAction';
import {
	checkModulePermissions,
	disableFutureDates,
	getUserToken,
} from '../../utils/helper';
import { deleteCaseApi, deleteCaseReset } from '../../actions/deleteCaseAction';
import { getCasesApi } from '../../actions/getCasesAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import {
	getHospitalDropdownApi,
	getHospitalDropdownReset,
} from '../../actions/getHospitalDropdownAction';
import {
	getConfigurationOptionsApi,
	getConfigurationOptionsReset,
} from '../../actions/getConfigurationOptionsAction';
import {
	getOrganizationsApi,
	getOrganizationsReset,
} from '../../actions/getOrganizationsAction';
import { analyseCoverageApi } from '../../actions/analyseCoverageAction';
import Header from '../../components/Header/Header';
import { getConfigurationsApi } from '../../actions/getConfigurationsAction';
import axios from 'axios';
import config from '../../config';
import { getRegionsApi } from '../../actions/getRegionsAction';
import {
	processTypes,
	revenueCycleOptions,
	revenueCycleStage,
} from '../../utils/constants';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
	XButtonAdd,
	XButtonAddDate,
	XButtonConfirm,
	XTagDate,
	XuploadAddCase,
} from '../AnalyseCoverage/AnalyseCoverageStyle';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { XDragger } = XuploadAddCase;
const CaseForm = (props) => {
	// Extend dayjs to use isBetween
	dayjs.extend(isBetween);
	const {
		getUserDropdown,
		getUserDropdownState,
		getCaseById,
		getCaseByIdReset,
		getCaseByIdState,
		editCase,
		editCaseReset,
		editCaseState,
		addCase,
		addCaseReset,
		addCaseState,
		deleteCase,
		deleteCaseReset,
		deleteCaseState,
		getCases,
		getStateDropdownState,
		getStateDropdown,
		getHospitalDropdown,
		getHospitalDropdownState,
		getRegions,
		getRegionsState,
		getConfigurationsState,
		getConfigurations,
		getOrganizations,
		getOrganizationsState,
		getOrganizationsReset,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;
	let modulePermissions = checkModulePermissions('case-management');
	const [form] = XForm.useForm();
	const [formUpload] = XForm.useForm();
	const [formData, setFormData] = useState({
		status: 'pending',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: 0,
		successful_claim_commission_percentage_value: 0,
		rejected_claim_commission_fixed_value: 0,
		case_amount: null,
		claim_amount: null,
		date_of_service: {
			single_date: [], // Array to hold single dates
			date_range: [], // Array to hold date ranges (will implement later)
		},
		mrn: '',
		state: '',
		hospital: '',
		title: '',
		revenue_cycle_updates: {
			remarks: '',
			status: '',
		},
	});

	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const [visitOptions, setVisitOptions] = useState([]);
	const [titleData, setTitleData] = useState([]);
	const [denialReasonData, setDenialReasonData] = useState([]);
	const [genderData, setGenderData] = useState([]);
	const [caseDropdown, setCaseDropdown] = useState([]);
	const [displayOrganization, setDisplayOrganization] = useState(true);
	const [displayRegion, setDisplayRegion] = useState(true);
	const [displayHospital, setDisplayHospital] = useState(true);
	const [fileList, setFileList] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [updatedFileList, setUpdatedFileList] = useState([]);
	const [displayDenialInputs, setDisplayDenialInputs] = useState(
		'organization_determination',
	);
	const [selectedOption, setSelectedOption] = useState(
		'revenue_cycle_supporting_text',
	); // Initial value can be 'textarea' or 'upload'

	const [clinicalSelectedOption, setClinicalSelectedOption] =
		useState('clinical_text'); // Initial value can be 'textarea' or 'upload'
	const [submitCase, setSubmitCase] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [documentName, setDocumentName] = useState('');
	const [supportingDoc, setSupportingDoc] = useState([]);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [deletedClinicalDocuments, setDeletedClinicalDocuments] = useState(
		[],
	);
	const [supportingFiles, setSupportingFiles] = useState([]);
	const [uploadedSupporingDoc, setUploadedSupporingDoc] = useState([]);
	const [updatedUploadedSupporingDoc, setUpdatedUploadedSupporingDoc] =
		useState([]);
	const [deletedUploadedSupportingDoc, setDeletedUploadedSupportingDoc] =
		useState([]);
	const [submitLoading, setSubmitLoading] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [rangePickers, setRangePickers] = useState([{}]);
	const [dateDropdownValue, setDateDropdownValue] = useState('1');
	const [currentDate, setCurrentDate] = useState([]);

	// Handler to update single dates
	const handleDateChange = (dates) => {
		//set current dates to hold date before clicking add dates button
		if (dates) {
			const newDates = dates.map((date) => dayjs(date).toISOString());
			// setCurrentDate(dates.map((date) => dayjs(date).toISOString()));
			setFormData((prev) => ({
				...prev,
				date_of_service: {
					...prev.date_of_service,
					single_date: [
						...(prev?.date_of_service?.single_date || []), // Preserve previous values
						...newDates, // Add currentDate state
					],
				},
			}));
		}
	};

	// handle single date disabled if already selected
	const isDateDisabled = (currentDate) => {
		// Check if the current date is in the single_date array
		return formData?.date_of_service?.single_date?.some((date) =>
			dayjs(date).isSame(currentDate, 'day'),
		);
	};

	// Function to check if current date is within any of the date ranges
	const isDateRangeDisabled = (currentDate) => {
		const dayjsCurrentDate = dayjs(currentDate); // Convert currentDate to a dayjs object
		return formData?.date_of_service?.date_range?.some(
			([startDate, endDate]) =>
				dayjsCurrentDate.isBetween(
					dayjs(startDate),
					dayjs(endDate),
					'day',
					'[]',
				), // '[]' makes it inclusive
		);
	};

	// Handler to update range dates and control input box removal
	const handleRangeChange = (dates, index) => {
		let updatedRanges = [...(formData?.date_of_service?.date_range || [])];

		if (dates) {
			const selectedRange = [
				dayjs(dates[0]).toISOString(),
				dayjs(dates[1]).toISOString(),
			];
			updatedRanges = selectedRange; // Update the specific range
			setFormData((prev) => ({
				...prev,
				date_of_service: {
					...prev.date_of_service,
					date_range: [
						...(prev?.date_of_service?.date_range || []), // Preserve previous values
						[...updatedRanges], // Add currentDate state
					],
				},
			}));
		}
	};

	// const addRangePicker = (currentDate) => {
	// 	if (dateDropdownValue === '1') {
	// 		setFormData((prev) => ({
	// 			...prev,
	// 			date_of_service: {
	// 				...prev.date_of_service,
	// 				single_date: [
	// 					...(prev?.date_of_service?.single_date || []), // Preserve previous values
	// 					...currentDate, // Add currentDate state
	// 				],
	// 			},
	// 		}));
	// 	} else if (dateDropdownValue === '2') {
	// 		setFormData((prev) => ({
	// 			...prev,
	// 			date_of_service: {
	// 				...prev.date_of_service,
	// 				date_range: [
	// 					...(prev?.date_of_service?.date_range || []), // Preserve previous values
	// 					[...currentDate], // Add currentDate state
	// 				],
	// 			},
	// 		}));
	// 	}
	// 	setCurrentDate([]);
	// };

	const handleRemoveSingleDate = (index) => {
		// Remove the selected date by index
		setFormData((prev) => ({
			...prev,
			date_of_service: {
				...prev.date_of_service,
				single_date: prev.date_of_service.single_date.filter(
					(_, i) => i !== index,
				), // Filter out the date at the given index
			},
		}));
	};

	// Handler to remove a specific range picker
	const removeRangePicker = (index) => {
		const updatedRanges = [...(formData.date_of_service.date_range || [])];
		updatedRanges.splice(index, 1);

		setFormData((prev) => ({
			...prev,
			date_of_service: {
				...prev.date_of_service,
				date_range: updatedRanges,
			},
		}));

		// Remove the range picker from the UI
		const updatedPickers = [...rangePickers];
		updatedPickers.splice(index, 1);
		setRangePickers(updatedPickers);
	};

	//set the date of services dropdown value
	const handleSelectDateDropdown = (value) => {
		setDateDropdownValue(value);
		setCurrentDate([]);
	};

	const handleSupportingDocSubmit = async () => {
		formUpload
			.validateFields()
			.then(async () => {
				if (supportingDoc && supportingDoc.length > 0 && documentName) {
					let obj = {
						url: supportingDoc[0],
						document_name: documentName,
					};
					setUploadedSupporingDoc((prevFiles) => [...prevFiles, obj]);
					obj.preview = await getBase64(
						supportingDoc[0].originFileObj,
					);
					setSupportingFiles((prevFiles) => [...prevFiles, obj]);
					setSupportingDoc([]);
					setDocumentName('');
					formUpload.resetFields();
					setIsUploadModalOpen(false);
				}
			})
			.catch((err) => {});
	};

	const handleUploadCancel = () => {
		setSupportingDoc([]);
		setDocumentName('');
		formUpload.setFieldsValue('document_name', '');
		setIsUploadModalOpen(false);
	};

	const handleSupportingDocChange = ({ fileList: newFileList }) => {
		// Filter out unwanted image file types
		const filteredFileList = newFileList.filter((file) => {
			const isGIF = file.type === 'image/gif';
			const isVideo = file.type.startsWith('video/');
			const isAudio = file.type.startsWith('audio/');
			const isJPEG = file.type === 'image/jpeg';
			const isJPG = file.type === 'image/jpg';
			const isPNG = file.type === 'image/png';
			const isLt10M = file.size / 1024 / 1024 < 10;

			// Exclude image files
			return (
				isLt10M &&
				!(isGIF || isVideo || isAudio || isJPEG || isJPG || isPNG)
			);
		});

		const updatedFileList = filteredFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setSupportingDoc(updatedFileList);
		formUpload.validateFields(['supporting_docs']);
	};

	const handleClinicalDocumentChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		form.setFieldsValue({
			clinical_summary: '',
		});
		setFormData({
			...formData,
			clinical_summary: '',
		});
		form.validateFields(['clinical_document']);
	};

	const handleRemoveClinicalDocuments = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = clinicalDocumentFileList.filter(
					(item) => item.uid !== file.uid,
				);
				setClinicalDocumentFileList(updatedFileList);
				setUpdatedClinicalDocumentFileList(updatedFileList);
				form.validateFields(['clinical_document']);
				if (!file.originFileObj) {
					setDeletedClinicalDocuments((prevFiles) => [
						...prevFiles,
						file,
					]);
				}
			},
			onCancel: () => {
				form.validateFields(['clinical_document']);
				console.log('Deletion cancelled');
			},
		});
	};

	const uploadSupportingDocButton = (
		<Button
			onClick={() => {
				showUploadModal();
			}}
			className="m-0"
			style={{
				borderRadius: '6px',
				border: '1px dashed #d9d9d9',
				display: 'block',
				height: '100px',
				padding: '4px 15px',
				width: '100px',
				background: 'rgba(0, 0, 0, 0.02)',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const showUploadModal = () => {
		setIsUploadModalOpen(true);
	};

	const handleRemove = async (file) => {
		const updatedFileList = fileList.filter(
			(item) => item.uid !== file.uid,
		);
		setFileList(updatedFileList);
	};

	const handleRemoveFile = async (file) => {
		Modal.confirm({
			title: 'Confirm Deletion',
			content: 'Are you sure you want to delete this file?',
			okText: 'Yes',
			cancelText: 'No',
			cancelButtonProps: {
				// Add an icon to the Cancel button
				style: {
					backgroundColor: '#fff', // Red background for Cancel
					color: '#007299', // Text color
				},
			},
			okButtonProps: {
				style: {
					backgroundColor: '#007299',
					borderColor: '#007299',
					color: '#fff',
				},
			},
			onOk: async () => {
				const updatedFileList = supportingFiles.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				const UploadedFile = uploadedSupporingDoc.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				const updatedUploadedFile = updatedUploadedSupporingDoc.filter(
					(item) => item.url.uid !== file.url.uid,
				);
				setSupportingFiles(updatedFileList);
				setUpdatedUploadedSupporingDoc(updatedUploadedFile);
				setUploadedSupporingDoc(UploadedFile);
				if (!file.url.originFileObj) {
					setDeletedUploadedSupportingDoc((prevFiles) => [
						...prevFiles,
						file,
					]);
				}
			},
			onCancel: () => {
				console.log('Deletion cancelled');
			},
		});
	};

	const handleDeletedClinicalDocsFileFroms3 = async (file) => {
		// Make an API call to delete the file from S3 and your collection
		try {
			// Extract file name and extension from the file URL
			const urlParts = file.split('/');
			const fileName = urlParts[urlParts.length - 1];
			const case_id = urlParts[urlParts.length - 2];
			const [patientName, ext] = fileName.split('.');

			// Make API calls to delete the file from S3 and collection
			await axios.delete(
				`${config.api.base_url}/settings/delete-signed-url?folder_name=clinical_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);
			console.log('File deleted successfully from S3 and collection');
		} catch (error) {
			console.error('Error deleting file:', error);
		}
	};

	const handleDeletedSupportingDocsFileFromS3 = async (file) => {
		// Make an API call to delete the file from S3 and your collection
		try {
			// Extract file name and extension from the file URL
			const urlParts = file.url.split('/');
			const fileName = urlParts[urlParts.length - 1];
			const case_id = urlParts[urlParts.length - 2];
			const [patientName, ext] = fileName.split('.');

			// Make API calls to delete the file from S3 and collection
			await axios.delete(
				`${config.api.base_url}/settings/delete-signed-url?folder_name=case_supporting_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);
			console.log('File deleted successfully from S3 and collection');
		} catch (error) {
			setSubmitLoading(false);
			console.error('Error deleting file:', error);
		}
	};

	const onDOBChange = (date, otherData) => {
		if (date) {
			// Calculate age based on the selected date of birth
			const today = new Date();
			const birthDate = new Date(date);

			// Calculate the difference in years
			var years = today.getFullYear() - birthDate.getFullYear();

			// Calculate the difference in months
			var months = today.getMonth() - birthDate.getMonth();

			// Calculate the difference in days
			var days = today.getDate() - birthDate.getDate();

			// Adjust for negative months or days
			if (months < 0 || (months === 0 && days < 0)) {
				years--;
				months += 12;
			}

			if (days < 0) {
				months--;
				// Calculate the days difference considering the month's length
				var tempDate = new Date(today);
				tempDate.setMonth(today.getMonth() - 1);
				var daysInLastMonth = new Date(
					tempDate.getFullYear(),
					tempDate.getMonth() + 1,
					0,
				).getDate();
				days += daysInLastMonth;
			}
			if (otherData) {
				setFormData({
					...otherData,
					deadline_date: '',
					treatment_date: '',
					id: otherData._id,
				});
				form.setFieldsValue({
					...otherData,
					id: otherData._id,
					allocated_to: otherData.allocated_to?.name
						? otherData.allocated_to.name
						: '',
					insurance_company: otherData.insurance_company?.name
						? otherData.insurance_company.name
						: '',
					// treatment_date: dayjs(otherData.treatment_date),
					hearing_date: otherData.hearing_date
						? dayjs(otherData.hearing_date)
						: '',
					date_of_birth: dayjs(otherData.date_of_birth),
					date_of_admission: otherData.date_of_admission
						? dayjs(otherData.date_of_admission)
						: '',
					date_of_discharge: otherData.date_of_discharge
						? dayjs(otherData.date_of_discharge)
						: '',
					date_of_eob: otherData.date_of_eob
						? dayjs(otherData.date_of_eob)
						: '',
					// deadline_date: otherData.deadline_date
					// 	? dayjs(otherData.deadline_date)
					// 	: '',
					denial_date: otherData.denial_date
						? dayjs(otherData.denial_date)
						: '',

					years: years,
					months: months,
					days: days,
				});
			} else {
				form.setFieldsValue({
					years: years,
					months: months,
					days: days,
				});
				setFormData({ ...formData, date_of_birth: date });
			}
		} else {
			form.setFieldsValue({ years: null, months: null, days: null });
			setFormData({ ...formData, date_of_birth: date, age: null });
		}
	};

	const showDeleteConfirm = (_id) => {
		XModal.confirm({
			title: 'Delete Case?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete this case?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteCase({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	//This is for demo purposes only, will be removed on production
	const showCaseIdDropdown = () => {
		axios
			.get(`${config.api.base_url}/case/all-ids`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				let dropdown = [];
				response.data.data.map((ele) => {
					return dropdown.push({
						label: `${ele.mrn} | ${ele.patient_first_name} | ${ele.case_id}`,
						value: ele._id,
					});
				});
				setCaseDropdown(dropdown);
			})
			.catch((error) => {
				console.log('error', error);
			});
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handlePreviewClinicalSummary = async (file) => {
		// Check if the file is a PDF
		if (file && file.name && file.name.split('.').includes('pdf')) {
			// If there's a URL, use it directly; otherwise, generate a preview from the file
			const fileUrl = file.url ? `${config.s3_url}${file.url}` : null;
			setPreviewImage(fileUrl || file.preview);
			setPdfPreviewOpen(true); // Open PDF preview modal
		} else {
			// For non-PDF files, generate a preview if necessary
			if (!file.url && !file.preview && file.originFileObj) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true); // Open image preview modal
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileList(updatedFileList);
		setFormData((prevState) => ({
			...prevState,
			revenue_cycle_updates: {
				...prevState.revenue_cycle_updates,
				remarks: '',
			},
		}));
		form.setFieldsValue({
			denail_remarks: '',
		});
		form.validateFields(['revenue_cycle_supporting_documents']);
	};
	// Function to handle changes in the form fields
	const handleRemarkChange = (e) => {
		const { name, value } = e.target;
		if (name === 'denail_remarks') {
			setFormData((prevState) => ({
				...prevState,
				revenue_cycle_updates: {
					...prevState.revenue_cycle_updates,
					revenue_cycle_status: formData.revenue_cycle_stage,
					appeal_status: 'rejected',
					remarks: value,
				},
			}));
			setUpdatedFileList([]);
			setFileList([]);
		}
	};

	const uploadButton = (
		<Button
			style={{
				border: 0,
				background: 'none',
				margin: '0',
				boxShadow: 'none',
			}}
		>
			<PlusOutlined style={{ color: '#000' }} />
			<div style={{ color: '#000' }}>Upload</div>
		</Button>
	);

	const handleDownload = (file) => {
		// Assuming 'file' contains the URL of the file to download
		window.open(file.url, '_blank');
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		setPdfWidth(width + 40);
	};

	const handleSubmit = async () => {
		setSubmitLoading(true);
		try {
			let uploadPromises = [];

			// Clinical Docs
			if (clinicalDocumentFileList.length > 0) {
				const fileListUid = clinicalDocumentFileList?.[0]?.uid;
				const formDataUid = formData?.clinical_docs?.[0]?.url?.uid;
				if (fileListUid && fileListUid !== formDataUid) {
					const clinicalDocPromises = clinicalDocumentFileList.map(
						async (fileData) => {
							let get_ext =
								fileData.originFileObj.name.split('.');
							let ext = get_ext[get_ext.length - 1];
							const res = await axios.get(
								`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${formData.patient_last_name}${fileData.originFileObj.uid}&ext=${ext}&id=${formData.case_id}`,
								{
									headers: {
										'Content-type':
											'application/json; charset=UTF-8',
										Authorization:
											'Bearer ' + getUserToken(),
									},
								},
							);
							const result = await axios.put(
								res.data.data,
								fileData.originFileObj,
							);
							if (result.status === 200) {
								const documentPath = `/clinical_documents/${formData.case_id}/${formData.patient_last_name}${fileData.originFileObj.uid}.${ext}`;
								const newFile = {
									uid: fileData.originFileObj.uid,
									name: fileData.originFileObj.name,
									status: 'done',
									url: documentPath,
								};
								setUpdatedClinicalDocumentFileList(
									(prevFileList) => [{ url: newFile }],
								);
							}
						},
					);
					uploadPromises = uploadPromises.concat(clinicalDocPromises);
				}
			}

			// Denial Docs
			if (fileList.length > 0) {
				const fileListUid = fileList?.[0]?.uid;
				const formDataUid =
					formData?.revenue_cycle_updates?.url?.[0]?.uid;
				if (fileListUid && fileListUid !== formDataUid) {
					const denialDocPromises = fileList.map(async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${formData.patient_last_name}${fileData.originFileObj.uid}&ext=${ext}&id=${formData.case_id}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);
						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/case_supporting_documents/${formData.case_id}/${formData.patient_last_name}${fileData.originFileObj.uid}.${ext}`;
							const newFile = {
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
							setUpdatedFileList((prevFileList) => [newFile]);
						}
					});
					uploadPromises = uploadPromises.concat(denialDocPromises);
				}
			}

			// Supporting Docs
			if (uploadedSupporingDoc.length > 0) {
				const supportingDocPromises = uploadedSupporingDoc.map(
					async (fileData) => {
						let get_ext =
							fileData.url.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${formData.patient_last_name}${fileData.url.originFileObj.uid}&ext=${ext}&id=${formData.case_id}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);
						const result = await axios.put(
							res.data.data,
							fileData.url.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/case_supporting_documents/${formData.case_id}/${formData.patient_last_name}${fileData.url.originFileObj.uid}.${ext}`;
							const newFile = {
								uid: fileData.url.originFileObj.uid,
								name: fileData.url.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
							setUpdatedUploadedSupporingDoc((prevFileList) => {
								if (id) {
									return [
										...prevFileList,
										{
											url: newFile,
											document_name:
												fileData.document_name,
										},
									];
								} else {
									return [
										...prevFileList,
										{
											url: newFile,
											document_name:
												fileData.document_name,
										},
									];
								}
							});
						}
					},
				);
				uploadPromises = uploadPromises.concat(supportingDocPromises);
			}

			// Deleted Clinical Docs
			if (deletedClinicalDocuments.length > 0) {
				const deleteClinicalDocPromises = deletedClinicalDocuments.map(
					async (fileData) => {
						await handleDeletedClinicalDocsFileFroms3(fileData.url);
					},
				);
				uploadPromises = uploadPromises.concat(
					deleteClinicalDocPromises,
				);
			}

			// Deleted Supporting Docs
			if (deletedUploadedSupportingDoc.length > 0) {
				const deleteSupportingDocPromises =
					deletedUploadedSupportingDoc.map(async (fileData) => {
						await handleDeletedSupportingDocsFileFromS3(
							fileData.url,
						);
					});
				uploadPromises = uploadPromises.concat(
					deleteSupportingDocPromises,
				);
			}

			// Wait for all uploads/deletions to complete
			await Promise.all(uploadPromises);

			// Once all promises are resolved, proceed with case submission
			setSubmitCase(true);
		} catch (error) {
			setSubmitLoading(false);
			// Handle error if any
			console.error('Error occurred:', error);
		}
	};

	useEffect(() => {
		if (submitCase) {
			if (id) {
				// editCase({
				// 	id: id,
				// 	...formData,
				// 	revenue_cycle_updates: {
				// 		...formData.revenue_cycle_updates,
				// 		url: updatedFileList,
				// 		revenue_cycle_status: formData.revenue_cycle_stage,
				// 		appeal_status: "rejected",
				// 	},
				// 	clinical_docs: updatedClinicalDocumentFileList,
				// 	supporting_docs: updatedUploadedSupporingDoc,
				// 	upload_documents: true,
				// 	deleted_supporting_docs: deletedUploadedSupportingDoc,
				// 	deleted_clinical_docs: deletedClinicalDocuments,
				// 	remove: true,
				// });
				// Prepare the form data
				// Create a shallow copy of formData without revenue_cycle_updates
				const { revenue_cycle_updates, ...formDataWithoutUpdates } =
					formData;

				const payload = {
					id: id,
					...formDataWithoutUpdates,
					clinical_docs: updatedClinicalDocumentFileList,
					supporting_docs: updatedUploadedSupporingDoc,
					upload_documents: true,
					deleted_supporting_docs: deletedUploadedSupportingDoc,
					deleted_clinical_docs: deletedClinicalDocuments,
					remove: true,
				};

				// Conditionally add revenue_cycle_updates if updatedFileList exists
				if (updatedFileList && updatedFileList.length > 0) {
					payload.revenue_cycle_updates = {
						...revenue_cycle_updates,
						url: updatedFileList,
						revenue_cycle_status: formData.revenue_cycle_stage,
						appeal_status: 'rejected',
					};
				}

				// Call the editCase function with the prepared payload
				editCase(payload);
			} else {
				const {
					_id,
					createdAt,
					updatedAt,
					// supporting_docs,
					revenue_cycle_updates,
					...formDataWithoutId
				} = formData;
				const payload = {
					...formDataWithoutId,
					clinical_docs: updatedClinicalDocumentFileList,
					supporting_docs: updatedUploadedSupporingDoc,
					upload_documents: true,
				};
				// Conditionally add revenue_cycle_updates if updatedFileList exists
				if (
					(formData.revenue_cycle_updates &&
						formData.revenue_cycle_updates.remarks) ||
					(updatedFileList && updatedFileList.length > 0)
				) {
					payload.revenue_cycle_updates = {
						...formDataWithoutId.revenue_cycle_updates,
						url: updatedFileList,
						appeal_status: 'rejected',
						revenue_cycle_status:
							formDataWithoutId.revenue_cycle_stage,
						remarks: formData.revenue_cycle_updates.remarks,
					};
				}
				// Call the editCase function with the prepared payload
				addCase(payload);
			}
		}
	}, [submitCase]);

	useEffect(() => {
		if (getConfigurationsState.apiState === 'success') {
			if (getConfigurationsState.data.all_configurations.length > 0) {
				getConfigurationsState.data.all_configurations.map((option) => {
					if (option.key === 'title') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setTitleData(optionsList);
					}
					if (option.key === 'denial_reason') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setDenialReasonData(optionsList);
					}
					if (option.key === 'gender') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setGenderData(optionsList);
					}
				});
			}
		}
	}, [getConfigurationsState]);

	useEffect(() => {
		if (deleteCaseState.apiState === 'success') {
			notification.success({ message: deleteCaseState.message });
			getCases();
			deleteCaseReset();
			navigate('/case-management');
		}
	}, [deleteCaseState]);

	// use effects
	useEffect(() => {
		if (id) getCaseById({ id: id });
		getUserDropdown();
		getStateDropdown();
		getConfigurations({});
	}, [id]);

	useEffect(() => {
		if (getCaseByIdState.apiState === 'success') {
			// Parse dates into dayjs objects
			const data = getCaseByIdState.data;

			// Convert strings into dayjs date objects
			const singleDates =
				data.date_of_service?.single_date?.map((date) => {
					const dayjsDate = dayjs(date);
					return dayjsDate.isValid() ? dayjsDate : null; // Check validity
				}) || [];

			const dateRanges =
				data.date_of_service?.date_range
					?.map((range) => {
						if (Array.isArray(range) && range.length === 2) {
							const startDate = dayjs(range[0]);
							const endDate = dayjs(range[1]);
							return startDate.isValid() && endDate.isValid()
								? [startDate, endDate]
								: null; // Check validity
						}
						return null; // Return null if the range is invalid
					})
					.filter(Boolean) || []; // Filter out any null values
			// // Update range pickers count
			const newRangePickers = Array(dateRanges.length || 1).fill({});

			setFormData({
				...getCaseByIdState.data,
				id: getCaseByIdState.data._id,
				date_of_service: {
					single_date: singleDates,
					date_range: dateRanges,
				},
			});

			setRangePickers(newRangePickers);

			form.setFieldsValue({
				...getCaseByIdState.data,
				id: getCaseByIdState.data._id,
				allocated_to: getCaseByIdState.data.allocated_to?.name
					? getCaseByIdState.data.allocated_to.name
					: '',
				insurance_company: getCaseByIdState.data.insurance_company?.name
					? getCaseByIdState.data.insurance_company.name
					: '',
				// treatment_date: dayjs(getCaseByIdState.data.treatment_date),
				hearing_date: dayjs(getCaseByIdState.data.hearing_date),
				denial_date: dayjs(getCaseByIdState.data.denial_date),
				date_of_birth: dayjs(getCaseByIdState.data.date_of_birth),
				rejected_category: 'revenue_cycle_supporting_text',
			});
			// if (getCaseByIdState.data.revenue_cycle_updates.url.length > 0) {
			// 	setFileList(getCaseByIdState.data.revenue_cycle_updates.url);
			// 	setSelectedOption('revenue_cycle_supporting_documents');
			// }

			const clinicalDocs = getCaseByIdState.data.clinical_docs;
			if (clinicalDocs && clinicalDocs.length > 0) {
				setClinicalSelectedOption('clinical_document');
				setClinicalDocumentFileList(
					clinicalDocs.map((doc) => ({
						...doc.url,
						// originFileObj: { uid: doc.url.uid }, // Mock the originFileObj with uid
					})),
				);
				setUpdatedClinicalDocumentFileList([...clinicalDocs]);
				form.setFieldsValue({
					clinical_category: 'clinical_document',
				});
			} else {
				setClinicalSelectedOption('clinical_text');
				form.setFieldsValue({
					clinical_category: 'clinical_text',
				});
				setClinicalDocumentFileList([]);
				setUpdatedClinicalDocumentFileList([]);
			}

			if (
				getCaseByIdState.data.revenue_cycle_updates &&
				getCaseByIdState.data.revenue_cycle_updates.remarks &&
				id
			) {
				setFormData((old_data) => {
					let new_data = {
						...old_data,
						denail_remarks:
							getCaseByIdState.data.revenue_cycle_updates.remarks,
					};
					return new_data;
				});

				form.setFieldsValue({
					denail_remarks:
						getCaseByIdState.data.revenue_cycle_updates.remarks,
				});
			}

			const supportingDocs =
				getCaseByIdState.data.revenue_cycle_updates &&
				getCaseByIdState.data.revenue_cycle_updates.url;

			if (supportingDocs && supportingDocs.length > 0) {
				// Modify fileList to add the prefix to URLs
				const modifiedFileList = supportingDocs.map((file) => ({
					...file,
					url: `${config.s3_url}${file.url}`,
				}));
				setFileList([...modifiedFileList]);
				// setUpdatedFileList([...supportingDocs]);
				form.setFieldsValue({
					rejected_category: 'revenue_cycle_supporting_documents',
				});
				setSelectedOption('revenue_cycle_supporting_documents');
			} else {
				form.setFieldsValue({
					rejected_category: 'revenue_cycle_supporting_text',
				});
				setSelectedOption('revenue_cycle_supporting_text');
			}

			const supporitngFiles = getCaseByIdState.data.supporting_docs;

			if (supporitngFiles && supporitngFiles.length > 0) {
				const modifiedFileList = supporitngFiles.map((file) => ({
					...file,
					url: {
						...file.url,
						url: `${config.s3_url}${file.url.url}`,
					},
				}));
				setSupportingFiles([...modifiedFileList]);
				setUpdatedUploadedSupporingDoc([...supporitngFiles]);
			}

			setDisplayDenialInputs(getCaseByIdState.data.revenue_cycle_stage);

			onDOBChange(
				getCaseByIdState.data.date_of_birth,
				getCaseByIdState.data,
			);
			displayOrganization && getOrganizations({});
			displayHospital &&
				getHospitalDropdown({
					organization_id: getCaseByIdState.data.organization,
					region_id: getCaseByIdState.data.region,
				});
			if (displayRegion) {
				getRegions({
					organization_id: getCaseByIdState.data.organization,
				});
			}

			getCaseByIdReset();
		}
	}, [getCaseByIdState]);

	useEffect(() => {
		if (addCaseState.apiState === 'success') {
			setSubmitCase(false);
			notification.success({ message: 'Case created successfully' });
			addCaseReset();
			setSubmitLoading(false);
			if (localStorage.getItem('role') === 'revenue-cycle-manager') {
				navigate(`/case/filings/${addCaseState.data._id}`);
			} else {
				navigate(-1);
			}
		} else if (addCaseState.apiState === 'error') {
			notification.error({ message: addCaseState.message });
			setSubmitLoading(false);
			setUpdatedUploadedSupporingDoc([]);
			setSubmitCase(false);
		}
	}, [addCaseState]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			setSubmitCase(false);
			notification.success({ message: 'Case updated successfully' });
			editCaseReset();
			setSubmitLoading(false);
			navigate(-1);
		} else if (editCaseState.apiState === 'error') {
			notification.error({ message: editCaseState.message });
			setSubmitLoading(false);
			setSubmitCase(false);
		}
	}, [editCaseState]);

	useEffect(() => {
		if (localStorage.getItem('role') === 'organization-admin') {
			setDisplayOrganization(false);
			getRegions();
		}
		if (localStorage.getItem('role') === 'region-admin') {
			setDisplayOrganization(false);
			setDisplayRegion(false);
			getHospitalDropdown();
		}
		if (
			localStorage.getItem('role') === 'hospital-admin' ||
			localStorage.getItem('role') === 'case-creator' ||
			localStorage.getItem('role') === 'case-analyser' ||
			localStorage.getItem('role') === 'case-manager'
		) {
			setDisplayOrganization(false);
			setDisplayRegion(false);
			setDisplayHospital(false);
		} else {
			setDisplayOrganization(true);
			setDisplayRegion(true);
			setDisplayHospital(true);
		}
		getOrganizations();
		getStateDropdown();
		form.setFieldsValue({
			clinical_category: 'clinical_text',
		});
		form.setFieldsValue({
			rejected_category: 'revenue_cycle_supporting_text',
		});
	}, []);

	const validateFileBeforeUpload = (file) => {
		const isGIF = file.type === 'image/gif';
		const isVideo = file.type.startsWith('video/');
		const isAudio = file.type.startsWith('audio/');
		const isJPEG = file.type === 'image/jpeg';
		const isJPG = file.type === 'image/jpg';
		const isPNG = file.type === 'image/png';
		const isLt10M = file.size / 1024 / 1024 < 10;

		if (!isLt10M) {
			notification.error({
				message: 'File Size Exceeded',
				description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
			});
			return false;
		}
		if (isGIF || isVideo || isAudio || isJPEG || isJPG || isPNG) {
			notification.error({
				message: 'File Type Error',
				description: `GIF, video, image and audio files are not allowed.`,
			});
			return false; // Prevent upload
		}

		return (
			isLt10M &&
			!isGIF &&
			!isVideo &&
			!isAudio &&
			!isJPEG &&
			!isJPG &&
			!isPNG
		);
	};

	useEffect(() => {
		document.body.classList.add('customBodyBg');
		return () => {
			document.body.classList.remove('customBodyBg');
		};
	}, []);

	// Utility function to format dates for display
	const formatDate = (date) => dayjs(date).format('MM-DD-YYYY');

	// Utility function to format date ranges for display
	const formatDateRange = (range) =>
		`${formatDate(range[0])} - ${formatDate(range[1])}`;

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					{/* <Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Case Management" />
					</Col> */}
					<XCustomHeader>
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24} sm={24} lg={12}>
								<XButtonBackRound
									onClick={() => navigate(-1)}
									icon={<ArrowLeftOutlined />}
									size="large"
									type="primary"
								/>
								{id ? (
									<XEditTitle>Edit Case</XEditTitle>
								) : (
									<XEditTitle>Create Case</XEditTitle>
								)}
							</Col>
							<Col
								xs={24}
								sm={24}
								lg={12}
								className="text-md-end"
							>
								{/* This is for demo purposes only will be removed on production */}
								{id ? (
									<></>
								) : (
									<>
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col
												xs={24}
												sm={24}
												lg={10}
												className="mt-3 mt-md-0 mt-lg-0"
											>
												<label
													className="addCaseLabel"
													name="mrn"
												>
													Select Case from EHR
												</label>
											</Col>
											<Col xs={24} sm={24} lg={14}>
												<Select
													size="large"
													placeholder="Search Case"
													name="mrn"
													allowClear
													onClear={() => {
														form.resetFields();
													}}
													showSearch={true}
													filterOption={(
														input,
														option,
													) =>
														option.label
															.toLowerCase()
															.includes(
																input.toLowerCase(),
															)
													}
													className="w-100 text-start demo-case-css"
													options={caseDropdown}
													onFocus={showCaseIdDropdown}
													onSelect={(value) => {
														getCaseById({
															id: value,
															new_case: true,
														});
													}}
												/>
											</Col>
										</Row>
									</>
								)}
							</Col>
						</Row>
					</XCustomHeader>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="caseManagement" />
					</Col>
				</>
			)}

			<XContainer
				fluid
				className="marginTopMobileNoHeader mt-3"
				// style={{ background: '#DBDBDB' }}
			>
				<XCardsCase>
					{modulePermissions.sub_modules.includes(
						'add-cases',
						'update-cases',
					) ? (
						<>
							<XForm
								form={form}
								name="loginForm"
								layout="vertical"
								autoComplete="off"
								onFinish={handleSubmit}
								scrollToFirstError
							>
								<Row gutter={(15, 30)}>
									<Col xs={24} className="mb-3">
										<div className="title">
											Personal Details
										</div>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<Row gutter={16}>
											<Col
												xs={24}
												sm={12}
												md={12}
												lg={12}
											>
												<XForm.Item
													className="addCaseLabel"
													name="mrn"
													label="Patient's Unique Id"
													rules={[
														{
															required: true,
															message:
																'Please enter patient ID',
														},
													]}
												>
													<Input
														className="inputBg"
														size="large"
														placeholder="Enter patient ID"
														name="mrn"
														onChange={(e) =>
															handleInputChange(
																e,
																formData,
																setFormData,
															)
														}
													/>
												</XForm.Item>
											</Col>

											<Col
												xs={24}
												sm={12}
												md={12}
												lg={12}
											>
												<XForm.Item
													className="addCaseLabel"
													name="patient_title"
													label={'Title'}
													rules={[
														{
															required: true,
															message:
																'Please select title',
														},
													]}
												>
													<XSelectAddCase
														style={{
															background: '#FFF',
														}}
														size="large"
														placeholder="Select Title"
														name="patient_title"
														showSearch={true}
														filterOption={(
															input,
															option,
														) =>
															option.label
																.toLowerCase()
																.includes(
																	input.toLowerCase(),
																)
														}
														options={titleData}
														onSelect={(value) => {
															handleSelectChange(
																'patient_title',
																value,
																formData,
																setFormData,
															);
														}}
													/>
												</XForm.Item>
											</Col>
										</Row>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											name="patient_first_name"
											label="Patient First Name"
											className="addCaseLabel"
											rules={[
												{
													required: true,
													message:
														'Please enter patient first name',
												},
											]}
										>
											<Input
												className="inputBg"
												size="large"
												placeholder="Enter patient first name"
												name="patient_first_name"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="patient_last_name"
											label="Patient Last Name"
											rules={[
												{
													required: true,
													message:
														'Please enter patient last name',
												},
											]}
										>
											<Input
												className="inputBg"
												size="large"
												placeholder="Enter patient last name"
												name="patient_last_name"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="gender"
											label="Gender"
											rules={[
												{
													required: true,
													message:
														'Please select gender',
												},
											]}
										>
											{/* <XRadio.Group
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
												name="gender"
											>
												{genderData.length > 0 &&
													genderData.map((opt) => (
														<XRadio
															value={opt.value}
														>
															{opt.label}
														</XRadio>
													))}
											</XRadio.Group> */}
											<XSelectAddCase
												size="large"
												placeholder="Select"
												onChange={(value) => {
													handleSelectChange(
														'gender',
														value,
														formData,
														setFormData,
													);
												}}
												options={genderData.map((opt) => ({
													value: opt.value,
													label: opt.label,
												}))}
												// options={[
												// 	{
												// 		value: 'Male',
												// 		label: 'Male',
												// 	},
												// 	{
												// 		value: 'Female',
												// 		label: 'Female',
												// 	},
												// 	{
												// 		value: 'Non-Binary',
												// 		label: 'Non-Binary',
												// 	},
												// 	{
												// 		value: 'Prefer Not to Disclose',
												// 		label: 'Prefer Not to Disclose',
												// 	},
												// ]}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="date_of_birth"
											label="Date of Birth"
											rules={[
												{
													required: true,
													message:
														'Please select date of birth',
												},
												{
													validator:
														validateNonFutureDate,
												},
											]}
										>
											<DatePicker
												className="inputBg w-100"
												placeholder="MM/DD/YYYY" // Add placeholder
												format="MM/DD/YYYY" // Set display format
												size="large"
												name="date_of_birth"
												disabledDate={futureDateDisable}
												onChange={(date) => {
													onDOBChange(date);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<Row gutter={16}>
											<Col xs={24} sm={12} md={8} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="years"
													label="Years"
												>
													<InputNumber
														size="large"
														placeholder="Years"
														name="years"
														style={{
															width: '100%',
														}}
														disabled
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={8} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="months"
													label="Months"
												>
													<InputNumber
														size="large"
														placeholder="Months"
														name="months"
														style={{
															width: '100%',
														}}
														disabled
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={8} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="days"
													label="Days"
												>
													<InputNumber
														size="large"
														placeholder="Days"
														name="days"
														style={{
															width: '100%',
														}}
														disabled
													/>
												</XForm.Item>
											</Col>
										</Row>
									</Col>
									<Col xs={24} className="mt-2">
										<hr className="addCaseHr" />
									</Col>
									<Col xs={24} className="mb-3">
										<div className="title">
											Organization
										</div>
									</Col>
									{displayOrganization ? (
										<Col xs={24} lg={8} md={12}>
											<XForm.Item
												className="addCaseLabel"
												name="organization"
												label={'Organization'}
												rules={[
													{
														required: true,
														message:
															'Please enter Region name',
													},
												]}
											>
												<XSelectAddCase
													size="large"
													placeholder="Select Organization"
													name="organization"
													showSearch={true}
													className="removeBg"
													onSearch={(search) => {
														if (
															search.length >= 3
														) {
															getOrganizations({
																hello: search,
															});
														} else if (
															search.length === 0
														) {
															getOrganizations();
														}
													}}
													// onFocus={(search) => {
													// 	getOrganizations({
													// 		search,
													// 	});
													// }}
													filterOption={filterByLabel}
													options={
														getOrganizationsState.apiState ===
															'success' &&
														getOrganizationsState
															.data.list?.length >
															0 &&
														getOrganizationsState.data.list.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'organization',
															value,
															formData,
															setFormData,
														);
														getRegions({
															organization_id:
																value,
														});
														getHospitalDropdown({
															organization: value,
														});
														form.setFieldValue(
															'region',
															null,
														);
														form.setFieldValue(
															'hospital',
															null,
														);
													}}
												></XSelectAddCase>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									{displayRegion ? (
										<Col xs={24} lg={8} md={12}>
											<XForm.Item
												className="addCaseLabel"
												name="region"
												label={'Region'}
												rules={[
													{
														required: true,
														message:
															'Please enter Region name',
													},
												]}
											>
												<XSelectAddCase
													size="large"
													placeholder="Select Region"
													name="region"
													className="removeBg"
													filterOption={filterByLabel}
													showSearch={true}
													options={
														getRegionsState.apiState ===
															'success' &&
														getRegionsState.data
															.regions?.length >
															0 &&
														getRegionsState.data.regions.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'region',
															value,
															formData,
															setFormData,
														);
														getHospitalDropdown({
															region_id: value,
														});
														form.setFieldValue(
															'hospital',
															null,
														);
													}}
												></XSelectAddCase>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									{displayHospital ? (
										<Col xs={24} sm={12} md={12} lg={8}>
											<XForm.Item
												className="addCaseLabel"
												name="hospital"
												label={'Hospital'}
												rules={[
													{
														required: true,
														message:
															'Please select hospital',
													},
												]}
											>
												<XSelectAddCase
													size="large"
													placeholder="Select Hospital"
													name="hospital"
													filterOption={filterByLabel}
													options={
														getHospitalDropdownState.apiState ===
															'success' &&
														getHospitalDropdownState
															.data.hospitals
															?.length > 0 &&
														getHospitalDropdownState.data.hospitals.map(
															(itm) => {
																return {
																	label: itm.name,
																	value: itm._id,
																	visit_types:
																		itm.visit_types,
																};
															},
														)
													}
													onSelect={(
														value,
														options,
													) => {
														handleSelectChange(
															'hospital',
															value,
															formData,
															setFormData,
														);
														form.setFieldValue(
															'visit_type',
															null,
														);
														setVisitOptions(
															options.visit_types,
														);
													}}
												/>
											</XForm.Item>
										</Col>
									) : (
										<></>
									)}
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="visit_type"
											label={'Visit Type'}
											rules={[
												{
													required: true,
													message:
														'Please select visit type',
												},
											]}
										>
											<XSelectAddCase
												size="large"
												placeholder="Select Visit Type"
												name="visit_type"
												filterOption={filterByLabel}
												options={visitOptions?.map(
													(itm) => {
														return {
															label: itm,
															value: itm,
														};
													},
												)}
												onSelect={(value) => {
													handleSelectChange(
														'visit_type',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									{displayDenialInputs !== 'first_appeal' && (
										<Col xs={24} sm={12} md={12} lg={8}>
											<XForm.Item
												className="addCaseLabel"
												name="date_of_eob"
												label="EOB Date"
												rules={[
													{
														required: true,
														message:
															'Please select EOB date',
													},
												]}
											>
												<DatePicker
													className="inputBg w-100"
													size="large"
													name="date_of_eob"
													placeholder="MM/DD/YYYY" // Add placeholder
													format="MM/DD/YYYY" // Set display format
													onChange={(date) => {
														handleDateChanged(
															date,
															'date_of_eob',
															formData,
															setFormData,
														);
													}}
												/>
											</XForm.Item>
										</Col>
									)}
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="date_of_admission"
											label="Admission Date"
										>
											<DatePicker
												className="inputBg w-100"
												placeholder="MM/DD/YYYY" // Add placeholder
												format="MM/DD/YYYY" // Set display format
												size="large"
												name="date_of_admission"
												onChange={(date) => {
													handleDateChanged(
														date,
														'date_of_admission',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="date_of_discharge"
											label="Discharge Date"
										>
											<DatePicker
												className="inputBg w-100"
												size="large"
												placeholder="MM/DD/YYYY" // Add placeholder
												format="MM/DD/YYYY" // Set display format
												name="date_of_discharge"
												onChange={(date) => {
													handleDateChanged(
														date,
														'date_of_discharge',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="hearing_date"
											label="Hearing Date"
										>
											<DatePicker
												className="inputBg w-100"
												placeholder="MM/DD/YYYY" // Add placeholder
												format="MM/DD/YYYY" // Set display format
												size="large"
												name="hearing_date"
												onChange={(date) => {
													handleDateChanged(
														date,
														'hearing_date',
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="state"
											label={'State'}
											rules={[
												{
													required: true,
													message:
														'Please select State',
												},
											]}
										>
											<XSelectAddCase
												size="large"
												placeholder="Select State"
												name="state"
												showSearch={true}
												// onFocus={() => {
												// 	getStateDropdown();
												// }}
												// requested
												// onSearch={(search) => {
												// 	if (search.length >= 3) {
												// 		getStateDropdown({
												// 			search,
												// 		});
												// 	} else if (
												// 		search.length === 0
												// 	) {
												// 		getStateDropdown();
												// 	}
												// }}
												filterOption={filterByLabel}
												options={
													getStateDropdownState.apiState ===
														'success' &&
													getStateDropdownState.data
														.all_states?.length >
														0 &&
													getStateDropdownState.data.all_states.map(
														(itm) => {
															return {
																label: itm.state,
																value: itm.state,
																providers:
																	itm.providers,
															};
														},
													)
												}
												onSelect={(value, options) => {
													handleSelectChange(
														'state',
														value,
														formData,
														setFormData,
													);
													form.setFieldValue(
														'insurance_company',
														null,
													);
													setInsuranceCompanies(
														options.providers,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} className="mt-2">
										<hr className="addCaseHr" />
									</Col>
									<Col xs={24} className="mb-3">
										<div className="title">Insurance</div>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="insurance_company"
											label="Insurance Company"
											rules={[
												{
													required: true,
													message:
														'Please enter insurance company',
												},
											]}
										>
											<XSelectAddCase
												size="large"
												placeholder="Select Insurance Company"
												name="insurance_company"
												showSearch={true}
												filterOption={filterByLabel}
												options={
													insuranceCompanies.length >
														0 &&
													insuranceCompanies.map(
														(itm) => {
															return {
																label: itm.name,
																value: itm._id,
															};
														},
													)
												}
												onSelect={(value) => {
													handleSelectChange(
														'insurance_company',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="allocated_to"
											label={
												id
													? 'Reallocate To'
													: 'Allocate To'
											}
											rules={[
												{
													required: true,
													message:
														'Please select whom to allocate',
												},
											]}
										>
											<XSelectAddCase
												disabled={
													!modulePermissions.sub_modules.includes(
														'allocate-cases',
													)
												}
												size="large"
												placeholder="Allocate To"
												name="allocated_to"
												showSearch={true}
												onFocus={() => {
													getUserDropdown();
												}}
												onSearch={(search) => {
													if (search.length >= 3) {
														getUserDropdown({
															search,
														});
													} else if (
														search.length === 0
													) {
														getUserDropdown();
													}
												}}
												filterOption={filterByLabel}
												options={
													getUserDropdownState.apiState ===
														'success' &&
													getUserDropdownState.data
														.all_users?.length >
														0 &&
													getUserDropdownState.data.all_users.map(
														(user) => {
															return {
																label: user.name,
																value: user._id,
															};
														},
													)
												}
												onSelect={(value) => {
													handleSelectChange(
														'allocated_to',
														{ _id: value },
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="case_amount"
											label="Claim Amount"
											rules={[
												{
													required: true,
													message:
														'Please enter claim amount',
												},
											]}
										>
											<InputNumber
												className="inputBg"
												size="large"
												placeholder="Claim Amount"
												name="case_amount"
												style={{ width: '100%' }}
												prefix="$"
												onChange={(v) => {
													handleNumberInputChange(
														'case_amount',
														v,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="insurance_claim_number"
											label="Insurance claim number"
											rules={[
												{
													required: true,
													message:
														'Please enter Insurance claim number',
												},
											]}
										>
											<Input
												className="inputBg"
												size="large"
												placeholder="Insurance claim number"
												name="insurance_claim_number"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={12} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="providers_encounter_number"
											label="Providers encounter number"
											rules={[
												{
													required: true,
													message:
														'Please enter Providers encounter number',
												},
											]}
										>
											<Input
												className="inputBg"
												size="large"
												placeholder="Providers encounter number"
												name="providers_encounter_number"
												onChange={(e) =>
													handleInputChange(
														e,
														formData,
														setFormData,
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={8} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="process_type"
											label={'Process Type'}
											rules={[
												{
													required: true,
													message:
														'Please Select Process Type',
												},
											]}
										>
											<XSelectAddCase
												size="large"
												placeholder="Select Process Type"
												name="process_type"
												showSearch={true}
												filterOption={(input, option) =>
													option.label
														.toLowerCase()
														.includes(
															input.toLowerCase(),
														)
												}
												options={processTypes}
												onSelect={(value) => {
													handleSelectChange(
														'process_type',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={12} md={8} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="revenue_cycle"
											label={'Revenue Cycle'}
											rules={[
												{
													required: true,
													message:
														'Please Select Revenue Cycle',
												},
											]}
										>
											<XSelectAddCase
												size="large"
												placeholder="Select Revenue Cycle"
												name="revenue_cycle"
												showSearch={true}
												filterOption={(input, option) =>
													option.label
														.toLowerCase()
														.includes(
															input.toLowerCase(),
														)
												}
												// options={revenueCycleOptions}
												options={
													formData.process_type ===
													'ma_expedited'
														? revenueCycleOptions.filter(
																(option) =>
																	option.value !==
																	'post_claim',
														  )
														: revenueCycleOptions
												}
												onSelect={(value) => {
													handleSelectChange(
														'revenue_cycle',
														value,
														formData,
														setFormData,
													);
												}}
											/>
										</XForm.Item>
									</Col>
								</Row>
								<XForm.Item
									className="addCaseLabel"
									name="dos"
									label="Date of Service"
									rules={[
										{
											required: true,
											validator: (_, value) => {
												const hasSingleDate =
													formData.date_of_service
														?.single_date?.length >
													0;
												const hasDateRange =
													formData.date_of_service?.date_range?.some(
														(range) =>
															Array.isArray(
																range,
															) &&
															range[0] &&
															range[1],
													);

												if (
													hasSingleDate ||
													hasDateRange
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(
														'At least one date must be selected.',
													),
												);
											},
										},
									]}
								>
									<XCardsAddCaseInner className="mb-0">
										<Row
											gutter={(15, 30)}
											className="align-items-center"
										>
											<Col xs={24} sm={12} md={12} lg={7}>
												<XForm.Item
													className="mb-3 mb-sm-0"
													// name="date_of_service"
												>
													<XSelectAddCase
														size="large"
														placeholder="Select"
														defaultValue="1"
														options={[
															{
																value: '1',
																label: 'Single Date',
															},
															{
																value: '2',
																label: 'Date Range',
															},
														]}
														onChange={
															handleSelectDateDropdown
														} // Handle select change
													/>
												</XForm.Item>
											</Col>
											{/* datepicker */}
											<Col
												xs={24}
												sm={12}
												md={12}
												lg={17}
											>
												<XForm.Item
													// name="dos"
													className="mb-0"
												>
													{dateDropdownValue ===
														'1' && (
														<DatePicker
															multiple
															className="inputBg"
															size="large"
															onChange={
																handleDateChange
															}
															placeholder="MM/DD/YYYY"
															format="MM/DD/YYYY"
															disabledDate={
																isDateDisabled
															}
															value={
																currentDate?.map(
																	(date) =>
																		dayjs(
																			date,
																		),
																) || []
															}
														/>
													)}
													{dateDropdownValue ===
														'2' && (
														<Col
															xs={24}
															className="d-flex"
														>
															{/* Multiple Range Pickers */}
															<RangePicker
																// key={index}
																size="large"
																placeholder="MM/DD/YYYY"
																format="MM/DD/YYYY"
																onChange={(
																	dates,
																) =>
																	handleRangeChange(
																		dates,
																		// index,
																	)
																}
																style={{
																	width: '100%',
																	// marginBottom:
																	// 	'10px',
																}}
																value={
																	currentDate &&
																	currentDate?.length >
																		0
																		? [
																				dayjs(
																					currentDate[0],
																				),
																				dayjs(
																					currentDate[1],
																				),
																		  ]
																		: null
																}
																disabledDate={
																	(date) =>
																		isDateRangeDisabled(
																			date,
																		)
																	// isDateRangeDisabled
																}
															/>
														</Col>
													)}
												</XForm.Item>
											</Col>

											{/* <Col
												xs={24}
												sm={12}
												md={12}
												lg={3}
												className="text-end"
											>
												<XButtonAddDate
													onClick={addRangePicker}
													// icon={<PlusOutlined />}
													disabled={
														currentDate &&
														currentDate?.length > 0
															? false
															: true
													}
												>
													Add Date
												</XButtonAddDate>
											</Col> */}
										</Row>
										<hr className="customHr" />
										<Row gutter={(15, 15)}>
											<Col xs={24} className="mb-3">
												{(formData?.date_of_service
													?.single_date?.length > 0 ||
													formData?.date_of_service
														?.date_range?.length >
														0) && (
													<span className="addCaseLabel">
														Added Dates of Service
													</span>
												)}
											</Col>
											<Col xs={24}>
												{formData?.date_of_service?.single_date?.map(
													(date, index) => (
														<XTagDate
															className="mb-2"
															key={date}
															onClose={() =>
																handleRemoveSingleDate(
																	index,
																)
															}
															closable
														>
															{formatDate(date)}
														</XTagDate>
													),
												)}
												{formData?.date_of_service?.date_range?.map(
													(range, i) => (
														<XTagDate
															className="mb-2"
															key={range}
															onClose={() =>
																removeRangePicker(
																	i,
																)
															}
															closable
														>
															{formatDateRange(
																range,
															)}
														</XTagDate>
													),
												)}

												{/* <XTagDate closable>
													21-09-2024 - 25-09-20240
												</XTagDate>
												<XTagDate closable>
													21-09-2024 - 25-09-20240
												</XTagDate>
												<XTagDate closable>
													27-09-20240
												</XTagDate> */}
											</Col>
										</Row>
									</XCardsAddCaseInner>
								</XForm.Item>
								<Row gutter={(15, 30)}>
									<Col xs={24} className="mt-2">
										<hr className="addCaseHr" />
									</Col>
									<Col xs={24} className="mb-3">
										<div className="title">
											Clinical Summary
										</div>
									</Col>
									<Col xs={24} sm={12} md={8} lg={8}>
										<XForm.Item
											className="addCaseLabel"
											name="revenue_cycle_stage"
											label={'Revenue Cycle Stage'}
											rules={[
												{
													required: true,
													message:
														'Please Select Revenue Cycle Stage',
												},
											]}
										>
											<XSelectAddCase
												size="large"
												placeholder="Select Revenue Cycle Stage"
												name="revenue_cycle_stage"
												showSearch={true}
												filterOption={(input, option) =>
													option.label
														.toLowerCase()
														.includes(
															input.toLowerCase(),
														)
												}
												disabled={id ? true : false}
												options={revenueCycleStage}
												onSelect={(value) => {
													handleSelectChange(
														'revenue_cycle_stage',
														value,
														formData,
														setFormData,
													);
													if (
														value ===
														'organization_determination'
													) {
														form.setFieldsValue({
															denial_date: '',
															denial_code1: '',
															denial_code2: '',
															denail_remarks: '',
															date_of_eob: '',
														});
														setFormData(
															(prevState) => ({
																...prevState,
																denial_date: '',
																denial_code1:
																	'',
																denial_code2:
																	'',
																denail_remarks:
																	'',
																date_of_eob: '',
																denial_reason:
																	'',
															}),
														);
														form.resetFields([
															'denial_reason',
														]);
														setUpdatedFileList([]);
														setFileList([]);
														setFormData(
															(prevState) => ({
																...prevState,
																revenue_cycle_updates:
																	{},
															}),
														);
													}
													if (
														value !==
														'organization_determination'
													) {
														setFormData(
															(prevState) => ({
																...prevState,
																revenue_cycle_updates:
																	{
																		...prevState.revenue_cycle_updates,
																		revenue_cycle_status:
																			value,
																		appeal_status:
																			'rejected',
																	},
															}),
														);
													}
													setDisplayDenialInputs(
														value,
													);
												}}
											/>
										</XForm.Item>
									</Col>
									{displayDenialInputs !==
									'organization_determination' ? (
										<>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="denial_reason"
													label={'Denial Type'}
													rules={[
														{
															required: true,
															message:
																'Please select Denial Type!',
														},
													]}
												>
													<XSelectAddCase
														size="large"
														placeholder="Select Denial Type"
														name="denial_reason"
														filterOption={
															filterByLabel
														}
														options={
															denialReasonData
														}
														disabled={
															id ? true : false
														}
														onSelect={(value) => {
															handleSelectChange(
																'denial_reason',
																value,
																formData,
																setFormData,
															);
														}}
													/>
												</XForm.Item>{' '}
											</Col>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="denial_date"
													label="Denial Date"
													rules={[
														{
															required: !id,
															message:
																'Please select Date!',
														},
													]}
												>
													<DatePicker
														className="inputBg w-100"
														size="large"
														name="denial_date"
														placeholder="MM/DD/YYYY" // Add placeholder
														format="MM/DD/YYYY" // Set display format
														onChange={(date) => {
															handleDateChanged(
																date,
																'denial_date',
																formData,
																setFormData,
															);
														}}
														disabled={
															id ? true : false
														}
														disabledDate={
															disableFutureDates
														}
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="denial_code1"
													label="Denial Code 1"
												>
													<Input
														className="inputBg"
														disabled={
															id ? true : false
														}
														size="large"
														placeholder="Enter CARC"
														name="denial_code1"
														onChange={(e) =>
															handleInputChange(
																e,
																formData,
																setFormData,
															)
														}
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} sm={12} md={12} lg={8}>
												<XForm.Item
													className="addCaseLabel"
													name="denial_code2"
													label="Denial Code 2"
												>
													<Input
														className="inputBg"
														disabled={
															id ? true : false
														}
														size="large"
														placeholder="Enter RARC"
														name="denial_code2"
														onChange={(e) =>
															handleInputChange(
																e,
																formData,
																setFormData,
															)
														}
													/>
												</XForm.Item>
											</Col>
											<Col xs={24}>
												<Row gutter={[15, 15]}>
													<Col xs={24}>
														<XForm.Item
															className="addCaseLabel mb-1"
															name="rejected_category"
															label={
																displayDenialInputs ===
																'first_appeal'
																	? 'Denial EOB Reason' // Show EOB if first_appeal
																	: 'Denial Reason' // Show regular label otherwise
															}
															// label="Denial Reason"
															rules={[
																{
																	required: true,
																	message:
																		'Please select option!',
																},
															]}
														>
															<XRadio.Group
																defaultValue={
																	selectedOption
																}
																onChange={(
																	e,
																) => {
																	handleInputChange(
																		e,
																		formData,
																		setFormData,
																	);
																	form.setFieldsValue(
																		{
																			remarks:
																				'',
																		},
																	);
																	setFormData(
																		{
																			...formData,
																			remarks:
																				'',
																		},
																	);
																	if (
																		e.target
																			.value ===
																		'revenue_cycle_supporting_text'
																	) {
																		setSelectedOption(
																			'revenue_cycle_supporting_text',
																		);
																	} else if (
																		e.target
																			.value ===
																		'revenue_cycle_supporting_documents'
																	) {
																		setSelectedOption(
																			'revenue_cycle_supporting_documents',
																		);
																	}
																}}
																disabled={
																	id
																		? true
																		: false
																}
																name="rejected_category"
																className=""
															>
																<XRadio
																	className="pb-2"
																	value={
																		'revenue_cycle_supporting_text'
																	}
																>
																	{displayDenialInputs ===
																	'first_appeal'
																		? 'Enter Denial EOB Reason' // Conditionally show EOB in radio option
																		: 'Enter Denial Reason'}
																</XRadio>
																<XRadio
																	className="pb-2"
																	value={
																		'revenue_cycle_supporting_documents'
																	}
																>
																	{displayDenialInputs ===
																	'first_appeal'
																		? 'Upload Denial EOB Letter' // Conditionally show EOB in radio option
																		: 'Upload Denial Letter'}
																</XRadio>
															</XRadio.Group>
														</XForm.Item>
													</Col>

													{selectedOption ===
														'revenue_cycle_supporting_text' && (
														<Col
															xs={24}
															className="text-end"
														>
															<XForm.Item
																name="denail_remarks"
																// label="Denial Text"
																rules={[
																	{
																		required: true,
																		message:
																			'Please enter Denial Reason',
																	},
																]}
															>
																<TextArea
																	name="denail_remarks"
																	showCount
																	style={{
																		height: 120,
																		marginBottom: 24,
																	}}
																	onChange={
																		handleRemarkChange
																	}
																	disabled={
																		id
																			? true
																			: false
																	}
																	placeholder={
																		displayDenialInputs ===
																		'first_appeal'
																			? 'Enter Denial EOB Reason' // Placeholder with EOB
																			: 'Enter Denial Reason' // Placeholder without EOB
																	}
																/>
															</XForm.Item>
														</Col>
													)}

													{selectedOption ===
														'revenue_cycle_supporting_documents' && (
														<Col
															xs={24}
															md={24}
															lg={12}
														>
															<>
																<XForm.Item
																	name="revenue_cycle_supporting_documents"
																	rules={[
																		{
																			required: true,
																			validator:
																				(
																					_,
																					value,
																				) => {
																					if (
																						fileList.length ===
																						0
																					) {
																						return Promise.reject(
																							new Error(
																								'Please upload denial letter!',
																							),
																						);
																					}
																					return Promise.resolve();
																				},
																		},
																	]}
																>
																	<Card
																		style={{
																			padding:
																				'8px',
																		}}
																		className="no-padding-card"
																	>
																		<Upload
																			action={
																				null
																			}
																			style={{
																				padding: 0,
																				margin: 0,
																			}}
																			showUploadList={{
																				showPreviewIcon: true,
																				showRemoveIcon:
																					id
																						? false
																						: true,
																			}}
																			className="supporting_files"
																			listType="picture-card"
																			fileList={
																				fileList
																			}
																			onPreview={
																				handlePreview
																			}
																			onChange={
																				handleChange
																			}
																			onRemove={
																				handleRemove
																			}
																			accept=".pdf"
																			beforeUpload={(
																				file,
																			) => {
																				const isJPEG =
																					file.type ===
																					'image/jpeg';
																				const isJPG =
																					file.type ===
																					'image/jpg';
																				const isPNG =
																					file.type ===
																					'image/png';
																				const isGIF =
																					file.type ===
																					'image/gif';
																				const isVideo =
																					file.type.startsWith(
																						'video/',
																					);
																				const isAudio =
																					file.type.startsWith(
																						'audio/',
																					);

																				const isLt10M =
																					file.size /
																						1024 /
																						1024 <
																					10;

																				if (
																					!isLt10M
																				) {
																					notification.error(
																						{
																							message:
																								'File Size Exceeded',
																							description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																						},
																					);
																				}
																				if (
																					isGIF ||
																					isVideo ||
																					isAudio ||
																					isJPEG ||
																					isJPG ||
																					isPNG
																				) {
																					notification.error(
																						{
																							message:
																								'File Type Error',
																							description: `GIF, video, image and audio files are not allowed.`,
																						},
																					);
																				}

																				return (
																					isLt10M &&
																					!isGIF &&
																					!isVideo &&
																					!isAudio &&
																					!isJPEG &&
																					!isJPG &&
																					!isPNG
																				);
																			}}
																			onDownload={
																				handleDownload
																			}
																		>
																			{fileList.length >=
																			1
																				? null
																				: uploadButton}
																		</Upload>
																	</Card>
																</XForm.Item>
																<Modal
																	open={
																		previewOpen
																	}
																	title={
																		previewTitle
																	}
																	footer={
																		null
																	}
																	onCancel={
																		handleCancel
																	}
																>
																	{previewImage ? (
																		<img
																			alt="example"
																			style={{
																				width: '100%',
																			}}
																			src={
																				previewImage
																			}
																		/>
																	) : null}
																</Modal>
															</>
														</Col>
													)}
												</Row>
											</Col>
										</>
									) : (
										<></>
									)}

									{/* Clinical Summary */}
									<Col xs={24}>
										<Row gutter={[15, 15]}>
											<Col xs={24}>
												<XForm.Item
													className="addCaseLabel mb-1"
													name="clinical_category"
													// label="Clinical Summary"
													rules={[
														{
															required: true,
															message:
																'Please select option!',
														},
													]}
												>
													<XRadio.Group
														// defaultValue={
														// 	clinicalSelectedOption
														// }
														onChange={(e) => {
															handleInputChange(
																e,
																formData,
																setFormData,
															);
															if (
																e.target
																	.value ===
																'clinical_text'
															) {
																setClinicalSelectedOption(
																	'clinical_text',
																);
															} else if (
																e.target
																	.value ===
																'clinical_document'
															) {
																setClinicalSelectedOption(
																	'clinical_document',
																);
															}
														}}
														name="clinical_category"
														className=""
													>
														<XRadio
															className="pb-2"
															value={
																'clinical_text'
															}
														>
															Enter Clinical
															Summary
														</XRadio>
														<XRadio
															className="pb-2"
															value={
																'clinical_document'
															}
														>
															Upload Clinical
															Summary
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>

											{clinicalSelectedOption ===
												'clinical_text' && (
												<Col xs={24}>
													<XForm.Item
														className="addCaseLabel mb-0"
														name="clinical_summary"
														rules={[
															{
																required: true,
																message:
																	'Please enter clinical summary',
															},
														]}
													>
														<TextArea
															className="inputBg"
															name="clinical_summary"
															showCount
															style={{
																marginBottom: 24,
															}}
															onChange={(e) => {
																handleInputChange(
																	e,
																	formData,
																	setFormData,
																);
																setClinicalDocumentFileList(
																	[],
																);
																setUpdatedClinicalDocumentFileList(
																	[],
																);
															}}
															autoSize={{
																minRows: 5,
															}}
															placeholder="Enter Detailed Clinical Summary"
														/>
													</XForm.Item>
												</Col>
											)}

											{clinicalSelectedOption ===
												'clinical_document' && (
												<Col xs={24} md={24} lg={24}>
													<>
														<XForm.Item
															className="addCaseLabe"
															name="clinical_document"
															rules={[
																{
																	required: true,
																	validator: (
																		_,
																		value,
																	) => {
																		if (
																			clinicalDocumentFileList.length ===
																			0
																		) {
																			return Promise.reject(
																				new Error(
																					'Please upload Clinical Document!',
																				),
																			);
																		}
																		return Promise.resolve();
																	},
																},
															]}
														>
															<XuploadAddCase
																action={null}
																multiple={false}
																maxCount={1}
																showUploadList={{
																	showPreviewIcon: true,
																	showRemoveIcon: true,
																}}
																className="supporting_files"
																listType="picture-card"
																fileList={
																	clinicalDocumentFileList
																}
																onPreview={
																	handlePreviewClinicalSummary
																}
																onChange={
																	handleClinicalDocumentChange
																}
																onRemove={(
																	file,
																) => {
																	handleRemoveClinicalDocuments(
																		file,
																	);
																	return false; // Prevent default removal behavior to avoid triggering onChange
																}}
																accept=".pdf"
																beforeUpload={(
																	file,
																) => {
																	const isJPEG =
																		file.type ===
																		'image/jpeg';
																	const isJPG =
																		file.type ===
																		'image/jpg';
																	const isPNG =
																		file.type ===
																		'image/png';
																	const isGIF =
																		file.type ===
																		'image/gif';
																	const isVideo =
																		file.type.startsWith(
																			'video/',
																		);
																	const isAudio =
																		file.type.startsWith(
																			'audio/',
																		);

																	const isLt10M =
																		file.size /
																			1024 /
																			1024 <
																		10;

																	if (
																		!isLt10M
																	) {
																		notification.error(
																			{
																				message:
																					'File Size Exceeded',
																				description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																			},
																		);
																	}
																	if (
																		isGIF ||
																		isVideo ||
																		isAudio ||
																		isJPEG ||
																		isJPG ||
																		isPNG
																	) {
																		notification.error(
																			{
																				message:
																					'File Type Error',
																				description: `GIF, video, image and audio files are not allowed.`,
																			},
																		);
																	}

																	return (
																		isLt10M &&
																		!isGIF &&
																		!isVideo &&
																		!isAudio &&
																		!isJPEG &&
																		!isJPG &&
																		!isPNG
																	);
																}}
																onDownload={
																	handleDownload
																}
															>
																{clinicalDocumentFileList.length >=
																1
																	? null
																	: uploadButton}
															</XuploadAddCase>
														</XForm.Item>
													</>
												</Col>
											)}
										</Row>
									</Col>
									{/* Clinical Summary Ends */}

									{/* Supporting Docs new column start */}
									<Col xs={24}>
										<XForm.Item
											className="addCaseLabel"
											label="Upload supporting documents"
										>
											<Space
												size={[15, 15]}
												style={{
													flexWrap: 'wrap',
													alignItems: 'start',
												}}
											>
												{supportingFiles.length > 0 &&
													supportingFiles.map(
														(ele) => {
															return (
																<>
																	{(
																		ele.url
																			? ele.url.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																			: ele.originFileObj.name
																					.split(
																						'.',
																					)
																					.includes(
																						'pdf',
																					)
																	) ? (
																		<>
																			<div className="image-container_case_filing">
																				<Card
																					hoverable
																					style={{
																						// display:
																						// 	'flex',
																						justifyContent:
																							'center',
																						alignItems:
																							'center',
																						borderRadius:
																							'6px',
																						border: '1px dashed #d9d9d9',
																						display:
																							'block',
																						height: '100px',
																						padding:
																							'4px 15px',
																						width: '100px',
																						background:
																							'rgba(0, 0, 0, 0.02)',
																						// margin: '20px',
																					}}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																					id="timeline-documents-hover"
																				>
																					<div
																						style={{
																							display:
																								'flex',
																							justifyContent:
																								'center',
																							alignItems:
																								'center',
																							flexDirection:
																								'column',
																							textAlign:
																								'center',
																						}}
																					>
																						<FilePdfOutlined
																							style={{
																								fontSize:
																									'30px',
																								border: 'none',
																								background:
																									'transparent',
																							}}
																						/>
																					</div>
																				</Card>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	) : (
																		<>
																			<div className="image-container_case_filing">
																				<Image
																					width={
																						100
																					}
																					height={
																						100
																					}
																					style={{
																						borderRadius:
																							'10px',
																					}}
																					className="supporting-docs-hover"
																					src={`${
																						ele.url &&
																						ele
																							.url
																							.url
																							? ele
																									.url
																									.url
																							: ele.preview
																					}`}
																					onClick={() =>
																						handlePreview(
																							ele.url,
																						)
																					}
																				/>
																				<div
																					className="overlay_case_filing"
																					style={{
																						height: '100px',
																					}}
																				>
																					<EyeOutlined
																						onClick={() =>
																							handlePreview(
																								ele.url,
																							)
																						}
																						className="icon eye-icon"
																					/>
																					<DeleteOutlined
																						onClick={() =>
																							handleRemoveFile(
																								ele,
																							)
																						}
																						className="icon download-icon"
																					/>
																				</div>
																				<Tooltip
																					title={
																						ele.document_name
																					}
																				>
																					<div
																						style={{
																							textAlign:
																								'center',
																							marginTop:
																								'5px',
																							fontSize:
																								'12px',
																							color: '#000',
																							whiteSpace:
																								'nowrap',
																							overflow:
																								'hidden',
																							textOverflow:
																								'ellipsis',
																							width: '100px', // or any other width as per requirement
																						}}
																					>
																						{
																							ele.document_name
																						}
																					</div>
																				</Tooltip>
																			</div>
																		</>
																	)}
																</>
															);
														},
													)}
												{supportingFiles.length >
												25 ? null : (
													<>
														{checkModulePermissions(
															'ma-plan-appeals-processing',
														).sub_modules.includes(
															'upload-supporting-documents',
														) ||
														checkModulePermissions(
															'case-filing',
														).sub_modules.includes(
															'upload-supporting-documents',
														) ? (
															uploadSupportingDocButton
														) : (
															<>
																You are not
																autthorized to
																upload
																supporitng
																Documents
															</>
														)}
													</>
												)}
											</Space>
										</XForm.Item>
									</Col>
									{/* Supporting Docs new column end */}
								</Row>

								<Row className="mt-2">
									<Col
										xs={24}
										lg={{ span: 6, offset: 0 }}
										md={{ span: 6, offset: 0 }}
										sm={{ span: 6, offset: 0 }}
										className="text-start"
									>
										{id ? (
											<Button
												style={{
													color: '#007299',
													marginLeft: '0px',
												}}
												className="cancel"
												onClick={() =>
													showDeleteConfirm(id)
												}
											>
												Delete Case
											</Button>
										) : (
											<></>
										)}
									</Col>
									<Col
										xs={24}
										lg={18}
										md={18}
										sm={18}
										className="text-end"
									>
										{/* <div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												gap: '8px',
												flexWrap: 'wrap',
											}}
										> */}
										<Button
											style={{
												color: '#007299',
											}}
											onClick={() => navigate(-1)}
											className="cancel"
										>
											Cancel
										</Button>
										<XButtonConfirm
											loading={submitLoading}
											type="primary"
											style={{
												background: '#007299',
												color: '#fff',
											}}
											htmlType="submit"
										>
											Submit
										</XButtonConfirm>
										{/* </div> */}
									</Col>
								</Row>
							</XForm>
						</>
					) : (
						<Result
							status="403"
							title="403"
							subTitle="Sorry, you are not authorized to access this page."
							extra={
								<Button
									type="primary"
									onClick={() => navigate('/')}
								>
									Back Home
								</Button>
							}
						/>
					)}
				</XCardsCase>
			</XContainer>
			<Modal
				title="Upload Supporting Documents"
				open={isUploadModalOpen}
				onOk={handleSupportingDocSubmit}
				onCancel={handleUploadCancel}
				okButtonProps={{
					style: {
						backgroundColor: '#007299',
						borderColor: '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				cancelButtonProps={{
					style: { color: '#007299', borderRadius: '20px' },
				}}
			>
				<XForm form={formUpload} layout="vertical" autoComplete="off">
					<XForm.Item
						name="Supporting_docs"
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (supportingDoc.length === 0) {
										return Promise.reject(
											new Error('Please Select file!'),
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Dragger
							fileList={supportingDoc}
							maxCount={1}
							beforeUpload={(file) => {
								return validateFileBeforeUpload(file); // Check if file should be uploaded
								// 	const isGIF = file.type === 'image/gif';
								// 	const isVideo = file.type.startsWith('video/');
								// 	const isAudio = file.type.startsWith('audio/');
								// 	const isJPEG = file.type === 'image/jpeg';
								// 	const isJPG = file.type === 'image/jpg';
								// 	const isPNG = file.type === 'image/png';
								// 	const isLt10M = file.size / 1024 / 1024 < 10;

								// 	if (!isLt10M) {
								// 		notification.error({
								// 			message: 'File Size Exceeded',
								// 			description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
								// 		});
								// 		return false;
								// 	}
								// 	if (
								// 		isGIF ||
								// 		isVideo ||
								// 		isAudio ||
								// 		isJPEG ||
								// 		isJPG ||
								// 		isPNG
								// 	) {
								// 		notification.error({
								// 			message: 'File Type Error',
								// 			description: `GIF, video, image and audio files are not allowed.`,
								// 		});
								// 		return false; // Prevent upload
								// 	}

								// 	return (
								// 		isLt10M &&
								// 		!isGIF &&
								// 		!isVideo &&
								// 		!isAudio &&
								// 		!isJPEG &&
								// 		!isJPG &&
								// 		!isPNG
								// 	);
							}}
							// accept=".pdf,.jpeg,.jpg,.png"
							accept=".pdf"
							onChange={handleSupportingDocChange}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined style={{ color: '#007299' }} />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</XForm.Item>
					<XForm.Item
						name="document_name"
						label="Document Name"
						className="mt-3"
						rules={[
							{
								required: true,
								message: 'Document Name is required',
							},
						]}
					>
						<Input
							placeholder="Enter Document Name"
							// value={documentName}
							name="document_name"
							onChange={(e) => {
								setDocumentName(e.target.value);
							}}
						/>
					</XForm.Item>
				</XForm>
			</Modal>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				{previewImage ? (
					<img
						alt="example"
						style={{
							width: '100%',
						}}
						src={previewImage}
					/>
				) : null}
			</Modal>
			{/* Modal for preview */}
			<Modal
				open={pdfPreviewOpen}
				title="Preview"
				footer={null}
				onCancel={() => setPdfPreviewOpen(false)}
				width={pdfWidth} // Set the width of the modal dynamically
				// Other modal props...
			>
				<div>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
						<div
							style={{
								height: '750px',
							}}
						>
							<Viewer fileUrl={previewImage} />
						</div>
					</Worker>
				</div>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getUserDropdownState: state.getUserDropdown,
	getCaseByIdState: state.getCaseById,
	editCaseState: state.editCase,
	addCaseState: state.addCase,
	deleteCaseState: state.deleteCase,
	getCasesState: state.getCases,
	analyseCoverageState: state.analyseCoverage,
	getStateDropdownState: state.getStateDropdown,
	getHospitalDropdownState: state.getHospitalDropdown,
	getInsuranceDropdownState: state.getInsuranceDropdown,
	getConfigurationOptionsState: state.getConfigurationOptions,
	getConfigurationsState: state.getConfigurations,
	getRegionsState: state.getRegions,
	getOrganizationsState: state.getOrganizations,
});

const mapDispatchToProps = (dispatch) => ({
	getUserDropdown: () => dispatch(getUserDropdownApi()),
	getUserDropdownReset: () => dispatch(getUserDropdownReset()),
	getCaseById: (data) => dispatch(getCaseByIdApi(data)),
	getCaseByIdReset: () => dispatch(getCaseByIdReset()),
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	addCase: (data) => dispatch(addCaseApi(data)),
	addCaseReset: () => dispatch(addCaseReset()),
	deleteCase: (data) => dispatch(deleteCaseApi(data)),
	deleteCaseReset: () => dispatch(deleteCaseReset()),
	getCases: (data) => dispatch(getCasesApi(data)),
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getHospitalDropdown: (params) => dispatch(getHospitalDropdownApi(params)),
	getHospitalDropdownReset: () => dispatch(getHospitalDropdownReset()),
	getConfigurationOptions: (params) =>
		dispatch(getConfigurationOptionsApi(params)),
	getConfigurationOptionsReset: () =>
		dispatch(getConfigurationOptionsReset()),
	getConfigurations: (params) => dispatch(getConfigurationsApi(params)),
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
	getOrganizationsReset: () => dispatch(getOrganizationsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseForm);
