import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL';
export const DELETE_ORGANIZATION_RESET = 'DELETE_ORGANIZATION_RESET';

export const deleteOrganization = (params) => {
	return {
		type: DELETE_ORGANIZATION,
		params,
	};
};

export const deleteOrganizationSuccess = (response) => {
	return {
		type: DELETE_ORGANIZATION_SUCCESS,
		response,
	};
};

export const deleteOrganizationFail = (response) => {
	return {
		type: DELETE_ORGANIZATION_FAIL,
		response,
	};
};

export const deleteOrganizationReset = () => {
	return {
		type: DELETE_ORGANIZATION_RESET,
	};
};

export const deleteOrganizationApi = (data) => {
	return (dispatch) => {
		dispatch(deleteOrganization());
		axios
			.delete(`${config.api.base_url}/organization/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const deleteOrganizationData = response.data;
				dispatch(deleteOrganizationSuccess(deleteOrganizationData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteOrganizationFail(errorMsg));
			});
	};
};
