import axios from 'axios';
import config from '../config';

export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
	'SEND_PASSWORD_RESET_EMAIL_SUCCESS';
export const SEND_PASSWORD_RESET_EMAIL_FAIL = 'SEND_PASSWORD_RESET_EMAIL_FAIL';
export const SEND_PASSWORD_RESET_EMAIL_RESET =
	'SEND_PASSWORD_RESET_EMAIL_RESET';

export const sendPasswordResetEmail = (params) => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL,
		params,
	};
};

export const sendPasswordResetEmailSuccess = (response) => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
		response,
	};
};

export const sendPasswordResetEmailFail = (response) => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL_FAIL,
		response,
	};
};

export const sendPasswordResetEmailReset = () => {
	return {
		type: SEND_PASSWORD_RESET_EMAIL_RESET,
	};
};

export const sendPasswordResetEmailApi = (data) => {
	return (dispatch) => {
		dispatch(sendPasswordResetEmail());
		axios
			.post(`${config.api.base_url}/auth/send-reset-password-email`, data)
			.then((response) => {
				const sendPasswordResetEmailData = response.data;
				dispatch(
					sendPasswordResetEmailSuccess(sendPasswordResetEmailData),
				);
			})
			.catch((error) => {
				dispatch(sendPasswordResetEmailFail(error.response.data));
			});
	};
};
