import React from 'react';
import { TestContainer } from './WebviewTestStyle';

const WebviewTest = () => {
    return (
        <TestContainer>
            <div className="container">
                <div className="header">
                    <h1>Webview Test Page</h1>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h2>Device Information</h2>
                    </div>
                    <div className="card-content">
                        <ul className="info-list">
                            <li>User Agent: <span id="userAgent">{navigator.userAgent}</span></li>
                            <li>Screen Width: <span id="screenWidth">{window.innerWidth}</span></li>
                            <li>Screen Height: <span id="screenHeight">{window.innerHeight}</span></li>
                            <li>Platform: <span id="platform">{navigator.platform}</span></li>
                        </ul>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h2>Interaction Tests</h2>
                    </div>
                    <div className="card-content">
                        <div className="test-section">
                            <h3>Button Test</h3>
                            <button 
                                className="test-button" 
                                onClick={() => {
                                    document.getElementById('buttonResult').textContent = 
                                        'Button clicked at ' + new Date().toLocaleTimeString();
                                }}
                            >
                                Test Button
                            </button>
                            <div id="buttonResult" className="result"></div>
                        </div>
                    </div>
                </div>
            </div>
        </TestContainer>
    );
};

export default WebviewTest; 