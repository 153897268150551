
import { 
    GET_USER_DROPDOWN,
    GET_USER_DROPDOWN_SUCCESS,
    GET_USER_DROPDOWN_FAIL, 
    GET_USER_DROPDOWN_RESET,
} from "../actions/getUserDropdownAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const getUserDropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DROPDOWN:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_USER_DROPDOWN_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_USER_DROPDOWN_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case GET_USER_DROPDOWN_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getUserDropdownReducer;
