import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_REGION = 'DELETE_REGION';
export const DELETE_REGION_SUCCESS = 'DELETE_REGION_SUCCESS';
export const DELETE_REGION_FAIL = 'DELETE_REGION_FAIL';
export const DELETE_REGION_RESET = 'DELETE_REGION_RESET';

export const deleteRegion = (params) => {
	return {
		type: DELETE_REGION,
		params,
	};
};

export const deleteRegionSuccess = (response) => {
	return {
		type: DELETE_REGION_SUCCESS,
		response,
	};
};

export const deleteRegionFail = (response) => {
	return {
		type: DELETE_REGION_FAIL,
		response,
	};
};

export const deleteRegionReset = () => {
	return {
		type: DELETE_REGION_RESET,
	};
};

export const deleteRegionApi = (data) => {
	return (dispatch) => {
		dispatch(deleteRegion());
		axios
			.delete(`${config.api.base_url}/regions/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const deleteRegionData = response.data;
				dispatch(deleteRegionSuccess(deleteRegionData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteRegionFail(errorMsg));
			});
	};
};
