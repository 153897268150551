import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_PAYER_CONFIGURATION = 'EDIT_PAYER_CONFIGURATION';
export const EDIT_PAYER_CONFIGURATION_SUCCESS = 'EDIT_PAYER_CONFIGURATION_SUCCESS';
export const EDIT_PAYER_CONFIGURATION_FAIL = 'EDIT_PAYER_CONFIGURATION_FAIL';
export const EDIT_PAYER_CONFIGURATION_RESET = 'EDIT_PAYER_CONFIGURATION_RESET';

export const editPayerConfiguration = (params) => {
	return {
		type: EDIT_PAYER_CONFIGURATION,
		params,
	};
};

export const editPayerConfigurationSuccess = (response) => {
	return {
		type: EDIT_PAYER_CONFIGURATION_SUCCESS,
		response,
	};
};

export const editPayerConfigurationFail = (response) => {
	return {
		type: EDIT_PAYER_CONFIGURATION_FAIL,
		response,
	};
};

export const editPayerConfigurationReset = () => {
	return {
		type: EDIT_PAYER_CONFIGURATION_RESET,
	};
};

export const editPayerConfigurationApi = (data) => {
	return (dispatch) => {
		dispatch(editPayerConfiguration());
		axios
			.put(`${config.api.base_url}/payer-configuration/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const editPayerConfigurationData = response.data;
				dispatch(editPayerConfigurationSuccess(editPayerConfigurationData));
			})
			.catch((error) => {
				const errorMsg = error.response.data;
				dispatch(editPayerConfigurationFail(errorMsg));
			});
	};
};
