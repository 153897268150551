import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { AutoComplete, Button, Form, Select } from 'antd';

export const OuterDiv = styled.div`
	width: 1440px;
	height: 960.3px;
	flex-grow: 0;
	margin: 0 0 318.7px;
	padding: 28px 43px 109.3px 118px;
	opacity: 0.8;
`;

export const ChatBoxDiv = styled.div`
	width: 100%;
	position: relative;
	border-radius: 20px;
	box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	padding-bottom: 5px;
`;
// border-bottom-left-radius: 20px; /* Match parent border radius */
// 	border-bottom-right-radius: 20px;

export const ChatHeader = styled.div`
	width: 100%;
	position: relative;
	padding: 15px 25px;
	background-color: #e6e4f9;
	border-top-left-radius: 20px; /* Match parent border radius */
	border-top-right-radius: 20px;
	@media only screen and (max-width: 768.99px) {
		border-radius: 0px;
	}
`;

export const HeaderIcon = styled.div`
	float: left;
	height: 48px;
	padding: 14px 14px 1px 4px;
	border-radius: 30px;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
	background-color: #007299;
`;

export const Heading = styled.span`
	font-family: Poppins;
	font-size: 18px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 48px;
	padding-left: 15px;
	letter-spacing: normal;
	text-align: left;
	color: #212226;
`;

export const ChatRight = styled.div`
	width: 70%;
	position: relative;
	float: right;
	padding: 15px 20px;
	background-color: #676ee0;
	border-radius: 16px 16px 0px 16px;
	margin-bottom: 15px;
	text-align-right: ;
`;

export const ChatLeft = styled.div`
	width: 70%;
	position: relative;
	padding: 15px 20px;
	background-color: #f7f7fc;
	border-radius: 16px 16px 16px 0px;
	margin-bottom: 15px;
`;

export const SmallTextRight = styled.div`
	height: 16px;
	font-family: Inter;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	display: flex;
	justify-content: space-between;
`;

export const SmallTextLeft = styled.div`
	height: 16px;
	font-family: Inter;
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #adb5bd;
	display: flex;
	justify-content: space-between;
`;

export const InputDiv = styled.div`
	width: 100%;
	padding: 20px 10px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: 4px 0 20px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	@media only screen and (max-width: 768.99px) {
		position: fixed;
		bottom: 0px;
		border-radius: 0px;
	}

	textarea {
		background: #f7f7fc !important;
		border-radius: 30px !important;
		border-color: #f7f7fc !important;
		resize: none;
		overflow-y: auto;
		/* WebKit Browsers */
		&::-webkit-scrollbar {
			width: 5px; /* Width of the scrollbar */
		}
		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px grey;
			border-radius: 3px;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: grey;
			border-radius: 3px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #b30000;
		}
	}
`;

export const InnerInput = styled.div`
	width: 264px;
	height: 50px;
	margin: 2px 10px 0 0;
	padding: 10px 124px 10px 24px;
	border-radius: 30px;
	background-color: #f7f7fc;
`;

export const SendButton = styled(Button)`
	background: black;
	padding: 20px 10px;
	border-radius: 20px;
	&:hover {
		background: black !important; // Ensures background stays black on hover
	}

	&:active {
		background: black !important; // Ensures background stays black when clicked
	}
`;

export const XForm = styled(Form)`
	.ant-form-item-label label {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-top: 5px;
	}
	@media only screen and (min-width: 768.99px) {
	}
`;

export const ChatWrapper = styled.div`
	width: 100%;
	position: relative;
	padding: 16px;
	margin-bottom: 60px;
	overflow-y: scroll;
	min-height: 200px;
	height: auto;
	height: 65vh;

	@media only screen and (max-width: 768.99px) {
		${
			'' /* background: #fff;
		max-height: 75vh;
		position: absolute;
		top: 0;
		right: 0; */
		}
		background:#FFF;
		display: block;
		border: none;
		position: fixed;
		inset: auto 0px 0px auto;
		min-width: 100%;
		height: auto;
		min-height: 200px;
		max-height: 100%;
		opacity: 1;
		color-scheme: none;
		margin: 0px;
		max-width: 100vw;
		transform: translateY(0px);
		transition: none 0s ease 0s !important;
		visibility: visible;
		z-index: -1 !important;
		padding: 90px 0px;
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 3px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: grey;
		border-radius: 3px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #b30000;
	}
`;
