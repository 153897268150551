import {
	GET_CODE_UPDATE_DATES,
	GET_CODE_UPDATE_DATES_SUCCESS,
	GET_CODE_UPDATE_DATES_FAIL,
	GET_CODE_UPDATE_DATES_RESET,
} from '../actions/getCodeUpdateDatesAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

export default function getCodeUpdateDatesReducer(
	state = initialState,
	action,
) {
	switch (action.type) {
		case GET_CODE_UPDATE_DATES:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_CODE_UPDATE_DATES_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_CODE_UPDATE_DATES_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case GET_CODE_UPDATE_DATES_RESET:
			return initialState;
		default:
			return state;
	}
}
