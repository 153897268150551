import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_REGION = 'EDIT_REGION';
export const EDIT_REGION_SUCCESS = 'EDIT_REGION_SUCCESS';
export const EDIT_REGION_FAIL = 'EDIT_REGION_FAIL';
export const EDIT_REGION_RESET = 'EDIT_REGION_RESET';

export const editRegion = (params) => {
	return {
		type: EDIT_REGION,
		params,
	};
};

export const editRegionSuccess = (response) => {
	return {
		type: EDIT_REGION_SUCCESS,
		response,
	};
};

export const editRegionFail = (response) => {
	return {
		type: EDIT_REGION_FAIL,
		response,
	};
};

export const editRegionReset = () => {
	return {
		type: EDIT_REGION_RESET,
	};
};

export const editRegionApi = (data) => {
	return (dispatch) => {
		dispatch(editRegion());
		axios
			.post(`${config.api.base_url}/regions/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const editRegionData = response.data;
				dispatch(editRegionSuccess(editRegionData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editRegionFail(errorMsg));
			});
	};
};
