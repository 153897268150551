import styled from 'styled-components';
import { Tooltip } from 'antd';

export const Container = styled.div`
	/*
.ant-menu-light .ant-menu-item-selected{
    border-radius:0px;
    margin:0px;
    padding:25px 0px;
    width: calc(100% - 0px);
    background: transparent url("../images/menuActive.svg") no-repeat left center;
    color: ${({ theme }) => theme.colors.bg};
    
    
}
*/

	.ant-menu-item-selected img.menuSvg {
		filter: brightness(0) saturate(100%) invert(55%) sepia(71%)
			saturate(3035%) hue-rotate(345deg) brightness(92%) contrast(95%) !important;
	}

	.ant-menu-title-content span {
		margin-right: 10px;
		margin-top: -5px;
		vertical-align: middle;
	}
	.ant-menu-light.ant-menu-root.ant-menu-inline {
		border: none !important;
		width: calc(100% - 0px);
	}
	.ant-menu-item {
		color: ${({ theme }) => theme.colors.text};
		background: none;
		font-size: 16px;
		width: calc(100% - 0px);
		margin: 0;
		border-radius: 0px;
	}
	.ant-menu-light .ant-menu-item-selected {
		border-radius: 0px;
		margin: 0px;
		width: calc(100% - 0px);
		background: transparent url('../images/menuActive.svg') no-repeat left
			center;
		color: ${({ theme }) => theme.colors.bg};
	}
	.ant-menu-item-active,
	.ant-menu-light:not(.ant-menu-horizontal)
		.ant-menu-item:not(.ant-menu-item-selected):hover {
		background: transparent url('../images/menuActive.svg') no-repeat left
			center;
		color: ${({ theme }) => theme.colors.bg};
		margin: 0;
		border-radius: 0;
		font-weight: normal;
	}
	.ant-menu-light:not(.ant-menu-horizontal)
		.ant-menu-item:not(.ant-menu-item-selected):active {
		background: none !important;
	}
`;

export const LogoutBottom = styled.div`
	position: fixed;
	width: 80px;
	bottom: 0px;
	left: 0;
	right: 0;
	padding: 10px;
	background: none;
	text-align: center;
`;

export const XTooltip = styled(Tooltip)`
	display: block;
	@media only screen and (max-width: 769px) {
		display: none;
	}
`;
