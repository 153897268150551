import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_HOSPITAL = 'EDIT_HOSPITAL';
export const EDIT_HOSPITAL_SUCCESS = 'EDIT_HOSPITAL_SUCCESS';
export const EDIT_HOSPITAL_FAIL = 'EDIT_HOSPITAL_FAIL';
export const EDIT_HOSPITAL_RESET = 'EDIT_HOSPITAL_RESET';

export const editHospital = (params) => {
	return {
		type: EDIT_HOSPITAL,
		params,
	};
};

export const editHospitalSuccess = (response) => {
	return {
		type: EDIT_HOSPITAL_SUCCESS,
		response,
	};
};

export const editHospitalFail = (response) => {
	return {
		type: EDIT_HOSPITAL_FAIL,
		response,
	};
};

export const editHospitalReset = () => {
	return {
		type: EDIT_HOSPITAL_RESET,
	};
};

export const editHospitalApi = (data) => {
	return (dispatch) => {
		dispatch(editHospital());
		axios
			.post(`${config.api.base_url}/hospitals/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const editHospitalData = response.data;
				dispatch(editHospitalSuccess(editHospitalData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editHospitalFail(errorMsg));
			});
	};
};
