import moment from 'moment';
export const handleInputChange = (e, formData, setFormData) => {
	setFormData({ ...formData, [e.target.name]: e.target.value });
};

export const handleNumberInputChange = (
	fieldName,
	fieldValue,
	formData,
	setFormData,
) => {
	setFormData({ ...formData, [fieldName]: fieldValue });
};

export const handleSelectChange = (
	fieldName,
	fieldValue = null,
	formData,
	setFormData,
) => {
	setFormData({ ...formData, [fieldName]: fieldValue });
};

export const handleDateChanged = (
	fieldValue,
	fieldName,
	formData,
	setFormData,
) => {
	console.log('Sss');
	setFormData({
		...formData,
		[fieldName]: fieldValue,
	});
};

export const validateDateOfBirth = (rule, value) => {
	// Check if the selected date is at least 18 years in the past
	if (value && value.isBefore(moment().subtract(18, 'years'))) {
		return Promise.resolve();
	}
	return Promise.reject('Date of birth must be at least 18 years ago');
};

export const filterByLabel = (input, option) => {
	return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const lessThan18 = (current) => {
	// Calculate today's date minus 18 years
	const minDate = new Date();
	minDate.setFullYear(minDate.getFullYear() - 18);

	// Disable dates that are greater than or equal to 18 years ago
	return current && current >= minDate;
};

export const validateMobileNumber = (rule, value, callback) => {
	const mobileNumberRegex = /^(\+\d{1,4})?\d{6,}$/;

	if (value && !mobileNumberRegex.test(value)) {
		callback('Please enter a valid mobile number');
	} else {
		callback();
	}
};

export const futureDateDisable = (current) => {
	// Get the current date
	const currentDate = new Date();

	// Disable if the date is in the future
	return current && current > currentDate;
};

// Custom validation rule to disallow future dates
export const validateNonFutureDate = (rule, value, callback) => {
	if (value && value.isAfter()) {
		callback('Future dates are not allowed.');
	} else {
		callback();
	}
};

export const lowerCaseInput = (event) => {
	event.target.value = event.target.value.toLowerCase();
};

export const upperCaseInput = (event) => {
	event.target.value = event.target.value.toUpperCase();
};
