import {
	GET_ROLES_DROPDOWN,
	GET_ROLES_DROPDOWN_SUCCESS,
	GET_ROLES_DROPDOWN_FAIL,
	GET_ROLES_DROPDOWN_RESET,
} from '../actions/getRolesDropdownAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const getRolesDropdownReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ROLES_DROPDOWN:
			return {
				...state,
				apiState: 'loading',
			};
		case GET_ROLES_DROPDOWN_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case GET_ROLES_DROPDOWN_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case GET_ROLES_DROPDOWN_RESET:
			return initialState;
		default:
			return state;
	}
};

export default getRolesDropdownReducer;
