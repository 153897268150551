import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const UPDATE_NOTIFICATION_PERMISSION = 'UPDATE_NOTIFICATION_PERMISSION';
export const UPDATE_NOTIFICATION_PERMISSION_SUCCESS =
	'UPDATE_NOTIFICATION_PERMISSION_SUCCESS';
export const UPDATE_NOTIFICATION_PERMISSION_FAIL =
	'UPDATE_NOTIFICATION_PERMISSION_FAIL';
export const UPDATE_NOTIFICATION_PERMISSION_RESET =
	'UPDATE_NOTIFICATION_PERMISSION_RESET';

export const updateNotificationPermission = (params) => {
	return {
		type: UPDATE_NOTIFICATION_PERMISSION,
		params,
	};
};

export const updateNotificationPermissionSuccess = (response) => {
	return {
		type: UPDATE_NOTIFICATION_PERMISSION_SUCCESS,
		response,
	};
};

export const updateNotificationPermissionFail = (response) => {
	return {
		type: UPDATE_NOTIFICATION_PERMISSION_FAIL,
		response,
	};
};

export const updateNotificationPermissionReset = () => {
	return {
		type: UPDATE_NOTIFICATION_PERMISSION_RESET,
	};
};

export const updateNotificationPermissionApi = (data) => {
	return (dispatch) => {
		dispatch(updateNotificationPermission());
		axios
			.post(`${config.api.base_url}/notification/permissions`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const updatePermission = response.data;
				dispatch(updateNotificationPermissionSuccess(updatePermission));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(updateNotificationPermissionFail(errorMsg));
			});
	};
};
