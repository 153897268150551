import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ORGANIZATION_ROLE_BY_ID = 'GET_ORGANIZATION_ROLE_BY_ID';
export const GET_ORGANIZATION_ROLE_BY_ID_SUCCESS =
	'GET_ORGANIZATION_ROLE_BY_ID_SUCCESS';
export const GET_ORGANIZATION_ROLE_BY_ID_FAIL =
	'GET_ORGANIZATION_ROLE_BY_ID_FAIL';
export const GET_ORGANIZATION_ROLE_BY_ID_RESET =
	'GET_ORGANIZATION_ROLE_BY_ID_RESET';

export const getOrganizationRoleById = (params) => {
	return {
		type: GET_ORGANIZATION_ROLE_BY_ID,
		params,
	};
};

export const getOrganizationRoleByIdSuccess = (response) => {
	return {
		type: GET_ORGANIZATION_ROLE_BY_ID_SUCCESS,
		response,
	};
};

export const getOrganizationRoleByIdFail = (response) => {
	return {
		type: GET_ORGANIZATION_ROLE_BY_ID_FAIL,
		response,
	};
};

export const getOrganizationRoleByIdReset = () => {
	return {
		type: GET_ORGANIZATION_ROLE_BY_ID_RESET,
	};
};

export const getOrganizationRoleByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getOrganizationRoleById());
		axios
			.get(
				`${config.api.base_url}/organization-role-mapping/${data.id}`,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const getOrganizationRoleByIdData = response.data;
				dispatch(
					getOrganizationRoleByIdSuccess(getOrganizationRoleByIdData),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getOrganizationRoleByIdFail(errorMsg));
			});
	};
};
