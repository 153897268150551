import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Card, Flex, Table, Space } from 'antd';
import { XForm, XContainer, XSearch } from '../../styles/GlobalStyle';
import Header from '../../components/Header/Header';
import { getUsersApi, getUsersReset } from '../../actions/getUsersAction';
import {
	updateNotificationPermissionApi,
	updateNotificationPermissionReset,
} from '../../actions/updateNotificationPermission';
import moment from 'moment';
import { checkModulePermissions, urlPagination } from '../../utils/helper';
import {
	SearchButton,
	XInputText,
} from '../AnalyseCoverage/AnalyseCoverageStyle';

const NotificationManagement = (props) => {
	const {
		getUsers,
		getUsersState,
		getUsersReset,
		updateNotificationPermission,
		updateNotificationPermissionState,
		updateNotificationPermissionReset,
	} = props;
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');
	const [loading, setLoading] = useState(false);
	const defaultFilters = {
		search: '',
		page: 1,
		patient_name: '',
		role: '',
		created_on: '',
		limit: 10,
	};
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const columns = [
		{
			title: 'User Name',
			dataIndex: 'userName',
		},
		{
			title: 'Role',
			dataIndex: 'role',
		},
		{
			title: 'Organization',
			dataIndex: 'organization',
		},
		{
			title: 'Region',
			dataIndex: 'region',
		},
		{
			title: 'Hospital',
			dataIndex: 'hospital',
		},
		// {
		// 	title: 'Active',
		// 	dataIndex: 'is_active',
		// },
		{
			title: 'Joined On',
			dataIndex: 'createdAt',
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortDirections: ['ascend', 'descend'],
		},
		// {
		// 	title: <div className="text-center">Action</div>,
		// 	dataIndex: 'action',
		// 	width: '10%',
		// },
	];

	const start = () => {
		setSelectedRowKeys([]);
	};

	const handleSubmit = () => {
		setLoading(true);
		updateNotificationPermission({ userIds: selectedRowKeys });
	};
	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const hasSelected = selectedRowKeys.length > 0;

	useEffect(() => {
		getUsers({});
	}, []);

	useEffect(() => {
		if (getUsersState.apiState === 'success') {
			if (getUsersState.data.all_users.length > 0) {
				setTotalRecords(getUsersState.data.total_count);
				const updatedUsers = [];
				const selectedUsers = [];

				getUsersState.data.all_users.map((item, index) => {
					updatedUsers.push({
						key: item._id,
						_id: item._id,
						userName: item.name,
						name: item.name,
						// is_active: item.is_active ? (
						// 	<Tag color="green">Active</Tag>
						// ) : (
						// 	<Tag color="red">Suspended</Tag>
						// ),
						createdAt: moment(item.createdAt).format('DD-MM-YYYY'),
						role: item.role.display_name,
						organization: item.organization
							? item.organization
							: '-',
						region: item.region ? item.region : '-',
						hospital: item.hospital ? item.hospital : '-',
					});

					if (item.adminNotification) {
						selectedUsers.push(item._id);
					}
				});

				setSelectedRowKeys(selectedUsers);
				setTableData(updatedUsers);
				setLoading(false);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
			getUsersReset();
		}
	}, [getUsersState]);

	useEffect(() => {
		if (updateNotificationPermissionState.apiState === 'success') {
			getUsers({});
			updateNotificationPermissionReset();
		} else if (updateNotificationPermissionState.apiState === 'error') {
			updateNotificationPermissionReset();
		}
	}, [updateNotificationPermissionState]);

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions('user-management').sub_modules.includes(
			'list-users',
		) && getUsers({ ...updatedFilters });
	}, [window.location.search]);

	const fetchCases = (pagination, _, sorter, ___) => {
		const queryParams = new URLSearchParams();

		// Append each filter to the query string
		Object.entries(filters).forEach(([key, value]) => {
			queryParams.append(key, value);
		});

		//Setting page filters: page size, sort, sort direction and limit in the query string
		queryParams.set(
			'page',
			pagination.current ? pagination.current : pagination,
		);
		queryParams.set('limit', pagination?.pageSize ?? 10);

		if (sorter && Object.keys(sorter).length > 0) {
			queryParams.set('sort', sorter ? sorter.field : 'createdAt');
			queryParams.set(
				'sortDirection',
				sorter ? sorter.order : filters.sortDirection,
			);
		} else {
			queryParams.set('sort', 'createdAt');
			queryParams.set('sortDirection', 'descend');
		}

		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
		setFilters({
			...filters,
			sort: sorter ? sorter.field : 'createdAt',
			sortDirection: sorter ? sorter.order : 'descend',
			page: pagination.current ? pagination.current : pagination,
			limit: pagination.pageSize ? pagination.pageSize : 10,
		});
	};

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Notifications Management" />
				</Col>
				{/* <Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile headerTag="Notifications Management" />
				</Col> */}
				<XContainer fluid>
					<Card
						bodyStyle={{ padding: '10px' }}
						style={{ borderRadius: '30px' }}
					>
						<Row gutter={(15, 30)} style={{ padding: '20px ' }}>
							<Col
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
								xs={24}
								md={10}
								lg={3}
								xl={3}
								xxl={3}
							>
								<p className="tableHeader">
									Total {totalRecords} Users
								</p>{' '}
							</Col>
							<Col
								xs={24}
								md={14}
								lg={{ span: 6 }}
								xl={{ span: 6 }}
								xxl={{ span: 6 }}
								className="mb-4 mb-lg-0"
							>
								<Space>
									<XInputText
										allowClear
										style={{
											borderRadius: '25px',
											// flex: 3,
										}}
										// size="large"
										placeholder="Search for Cases"
										defaultValue={
											filters.patient_name
												? filters.patient_name
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'patient_name',
													'',
												);
												setFilters({
													...filters,
													patient_name: '',
												});
											} else {
												setFilters({
													...filters,
													patient_name:
														v.target.value,
												});
											}
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												const searchValue =
													filters.patient_name
														? filters.patient_name
														: '';
												if (searchValue) {
													urlPagination(
														filters,
														'patient_name',
														searchValue,
													);
													setFilters({
														...filters,
														patient_name:
															searchValue,
													});
												}
											}
										}}
									/>
									<SearchButton
										style={{
											borderRadius: '20px',
											color: '#007299',
										}}
										onClick={() => {
											// Trigger search when the button is clicked
											const searchValue =
												filters.patient_name
													? filters.patient_name
													: '';
											if (searchValue) {
												urlPagination(
													filters,
													'patient_name',
													searchValue,
												);
												setFilters({
													...filters,
													patient_name: searchValue,
												});
											}
										}}
										className="px-4"
										size="large"
									>
										Search
									</SearchButton>
								</Space>
								{/* <XForm.Item>
									<XSearch
										placeholder="Search By User Name"
										allowClear
										enterButton="Search"
										size="large"
										defaultValue={
											filters.patient_name
												? filters.patient_name
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'patient_name',
													'',
												);
												setFilters({
													...filters,
													patient_name: '',
												});
											}
										}}
										onSearch={(v) => {
											urlPagination(
												filters,
												'patient_name',
												v,
											);
											setFilters({
												...filters,
												patient_name: v,
											});
										}}
									/>
								</XForm.Item> */}
							</Col>
						</Row>
						{/* user table start */}
						<Flex gap="middle" vertical>
							<Flex align="center" gap="middle">
								<Button
									style={{
										background: '#007299',
										color: 'white',
									}}
									// type="primary"
									onClick={start}
									disabled={!hasSelected}
									// loading={loading}
								>
									Reset
								</Button>
								<Button
									// type="primary"
									style={{
										background: '#007299',
										color: 'white',
									}}
									onClick={handleSubmit}
									// disabled={!hasSelected}
									loading={loading}
								>
									Allow Notifications
								</Button>
								{/* {hasSelected
									? `Selected ${selectedRowKeys.length} items`
									: null} */}
							</Flex>
							<Table
								rowSelection={rowSelection}
								columns={columns}
								loading={
									getUsersState.apiState === 'loading'
										? true
										: false
								}
								dataSource={tableData}
								pagination={{
									total: totalRecords,
									current: Number(filters.page),
									showSizeChanger: true,
									pageSize: filters.limit,
								}}
								onChange={fetchCases}
							/>
						</Flex>
						{/* user table end */}
					</Card>
				</XContainer>
			</>
		</>
	);
};

const mapStateToProps = (state) => ({
	getUsersState: state.getUsers,
	updateNotificationPermissionState: state.updateNotificationPermission,
});

const mapDispatchToProps = (dispatch) => ({
	getUsers: (params) => dispatch(getUsersApi(params)),
	getUsersReset: () => dispatch(getUsersReset()),
	updateNotificationPermission: (params) =>
		dispatch(updateNotificationPermissionApi(params)),
	updateNotificationPermissionReset: () =>
		dispatch(updateNotificationPermissionReset()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NotificationManagement);
