import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_DEFAULT_ROLES = 'GET_DEFAULT_ROLES';
export const GET_DEFAULT_ROLES_SUCCESS = 'GET_DEFAULT_ROLES_SUCCESS';
export const GET_DEFAULT_ROLES_FAIL = 'GET_DEFAULT_ROLES_FAIL';
export const GET_DEFAULT_ROLES_RESET = 'GET_DEFAULT_ROLES_RESET';

export const getDefaultRoles = (params) => {
	return {
		type: GET_DEFAULT_ROLES,
		params,
	};
};

export const getDefaultRolesSuccess = (response) => {
	return {
		type: GET_DEFAULT_ROLES_SUCCESS,
		response,
	};
};

export const getDefaultRolesFail = (response) => {
	return {
		type: GET_DEFAULT_ROLES_FAIL,
		response,
	};
};

export const getDefaultRolesReset = () => {
	return {
		type: GET_DEFAULT_ROLES_RESET,
	};
};

export const getDefaultRolesApi = (params) => {
	return (dispatch) => {
		dispatch(getDefaultRoles());
		axios
			.get(`${config.api.base_url}/settings/get-default-role-list`, {
				params: params,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getDefaultRolesData = response.data;
				dispatch(getDefaultRolesSuccess(getDefaultRolesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getDefaultRolesFail(errorMsg));
			});
	};
};
