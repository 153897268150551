import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_FAIL = 'ADD_ORGANIZATION_FAIL';
export const ADD_ORGANIZATION_RESET = 'ADD_ORGANIZATION_RESET';

export const addOrganization = (params) => {
	return {
		type: ADD_ORGANIZATION,
		params,
	};
};

export const addOrganizationSuccess = (response) => {
	return {
		type: ADD_ORGANIZATION_SUCCESS,
		response,
	};
};

export const addOrganizationFail = (response) => {
	return {
		type: ADD_ORGANIZATION_FAIL,
		response,
	};
};

export const addOrganizationReset = () => {
	return {
		type: ADD_ORGANIZATION_RESET,
	};
};

export const addOrganizationApi = (data) => {
	return (dispatch) => {
		dispatch(addOrganization());
		axios
			.post(`${config.api.base_url}/organization`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addOrganizationData = response.data;
				dispatch(addOrganizationSuccess(addOrganizationData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addOrganizationFail(errorMsg));
			});
	};
};
