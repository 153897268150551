import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_FAIL = 'GET_MODULES_FAIL';
export const GET_MODULES_RESET = 'GET_MODULES_RESET';

export const getModules = (params) => {
	return {
		type: GET_MODULES,
		params,
	};
};

export const getModulesSuccess = (response) => {
	return {
		type: GET_MODULES_SUCCESS,
		response,
	};
};

export const getModulesFail = (response) => {
	return {
		type: GET_MODULES_FAIL,
		response,
	};
};

export const getModulesReset = () => {
	return {
		type: GET_MODULES_RESET,
	};
};

export const getModulesApi = (params) => {
	return (dispatch) => {
		dispatch(getModules());
		axios
			.get(`${config.api.base_url}/settings/get-modules`, {
				params: params,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getModulesData = response.data;
				dispatch(getModulesSuccess(getModulesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getModulesFail(errorMsg));
			});
	};
};
