import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const ADD_USER_RESET = 'ADD_USER_RESET';

export const addUser = (params) => {
	return {
		type: ADD_USER,
		params,
	};
};

export const addUserSuccess = (response) => {
	return {
		type: ADD_USER_SUCCESS,
		response,
	};
};

export const addUserFail = (response) => {
	return {
		type: ADD_USER_FAIL,
		response,
	};
};

export const addUserReset = () => {
	return {
		type: ADD_USER_RESET,
	};
};

export const addUserApi = (data) => {
	return (dispatch) => {
		dispatch(addUser());
		axios
			.post(`${config.api.base_url}/settings/add-user`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addUserData = response.data;
				dispatch(addUserSuccess(addUserData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addUserFail(error.response.data));
			});
	};
};
