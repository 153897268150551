import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const SAVE_APPEAL_LETTER = 'SAVE_APPEAL_LETTER';
export const SAVE_APPEAL_LETTER_SUCCESS = 'SAVE_APPEAL_LETTER_SUCCESS';
export const SAVE_APPEAL_LETTER_FAIL = 'SAVE_APPEAL_LETTER_FAIL';
export const SAVE_APPEAL_LETTER_RESET = 'SAVE_APPEAL_LETTER_RESET';

export const saveAppealLetter = (params) => {
	return {
		type: SAVE_APPEAL_LETTER,
		params,
	};
};

export const saveAppealLetterSuccess = (response) => {
	return {
		type: SAVE_APPEAL_LETTER_SUCCESS,
		response,
	};
};

export const saveAppealLetterFail = (response) => {
	return {
		type: SAVE_APPEAL_LETTER_FAIL,
		response,
	};
};

export const saveAppealLetterReset = () => {
	return {
		type: SAVE_APPEAL_LETTER_RESET,
	};
};

export const saveAppealLetterApi = (data) => {
	return (dispatch) => {
		dispatch(saveAppealLetter());
		axios
			.put(`${config.api.base_url}/appeal-letter/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const saveAppealLetterData = response.data;
				dispatch(saveAppealLetterSuccess(saveAppealLetterData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(saveAppealLetterFail(errorMsg));
			});
	};
};
