import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Skeleton, Spin } from 'antd';
import { XAffix, XForm, XSelectAddCase } from '../../styles/GlobalStyle';
import {
	PlusOutlined,
	DownOutlined,
	UpOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import {
	getIcdCodesApi,
	getIcdCodesReset,
} from '../../actions/getIcdCodesAction';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import {
	getCptCodesApi,
	getCptCodesReset,
} from '../../actions/getCptCodesAction';
import {
	XRelatedReadMore,
	XButtonModal,
	XICDModal,
	XICDModalFooterBTN,
	XDrawerAddCode,
	XTabIcdCpt,
	XRelatedCard,
} from '../../pages/CaseForm/CaseFormStyle';
import {
	XViewMoreBTN,
	XButtonConfirm,
} from '../../pages/AnalyseCoverage/AnalyseCoverageStyle';
import { XcaseAnsyCardScroll } from '../../pages/CaseAnalysis/CaseAnalysisStyle';
import { debounce } from 'lodash';

const DrawerCodingTools = (props) => {
	const {
		icdform,
		cptform,
		activeCode,
		setActiveCode,
		closeIcdCptDrawer,
		openIcdCptDrawer,
		handleCodeClick,
		setModalOpen,
		setModalTextArea,
		closeIcdCptDrawer2,
		openIcdCptDrawer2,
		modalOpen,
		updatePageContent,
		modalTextArea,
		setIcdCodes,
		icdCodes,
		searchResults,
		setSearchResults,
		icdLoading,
		setIcdLoading,
		triggeredBySearch,
		setTriggeredBySearch,
		triggeredByEnter,
		setTriggeredByEnter,
		loadMore,
		setLoadMore,
		icdSearchLoading,
		setIcdSearchLoading,
		cptCodes,
		setCptCodes,
		cptLoading,
		setCptLoading,
		cptSearchResults,
		setCptSearchResults,
		cptTriggeredBySearch,
		setCptTriggeredBySearch,
		cptTriggeredByEnter,
		setCptTriggeredByEnter,
		cptLoadMore,
		setCptLoadMore,
		cptSearchLoading,
		setCptSearchLoading,
		icdFilters,
		setIcdFilters,
		cptFilters,
		setCptFilters,
		getIcdCodes,
		getIcdCodesState,
		getIcdCodesReset,
		getCptCodes,
		getCptCodesState,
		getCptCodesReset,
		fixed,
		previewMode,
	} = props;
	const [icdModal, setIcdModal] = useState();
	// Add these refs at the top of your component
	const searchTimeoutRef = useRef(null);
	const latestSearchRef = useRef('');
	//ICD Codes

	const handleIcdFilters = (search, type) => {
		if (type === 'CPT') {
			setCptFilters((prev) => ({
				search: search,
			}));
		} else {
			setIcdFilters((prev) => ({
				search: search,
			}));
		}
	};

	// Handle Enter key press
	const handleSearchKeyDown = (e, type) => {
		if (type === 'CPT' && e.key === 'Enter' && e.target.value) {
			// Cancel any pending debounced searches
			debouncedSearch.cancel();
			if (searchTimeoutRef.current) {
				clearTimeout(searchTimeoutRef.current);
			}
			// Store the latest search term
			latestSearchRef.current = e.target.value;
			setCptSearchLoading(false);
			setCptLoading(true);
			handleIcdFilters(e.target.value, 'CPT'); // Set the search term
			cptform.setFieldsValue({ cpt_search: e.target.value });
			setCptTriggeredBySearch(false); // Mark as a search-triggered call
			setCptTriggeredByEnter(true);
			fetchCptCodes({ search: e.target.value }); // API call with search term
			setActiveCode({
				...activeCode,
				description: '',
			});
		} else {
			if (e.key === 'Enter' && e.target.value) {
				// Cancel any pending debounced searches
				debouncedSearch.cancel();
				if (searchTimeoutRef.current) {
					clearTimeout(searchTimeoutRef.current);
				}
				// Store the latest search term
				latestSearchRef.current = e.target.value;
				setIcdSearchLoading(false);
				setIcdLoading(true);
				handleIcdFilters(e.target.value); // Set the search term
				icdform.setFieldsValue({ icd_search: e.target.value });
				setTriggeredBySearch(false); // Mark as a search-triggered call
				setTriggeredByEnter(true);
				fetchIcdCodes({ search: e.target.value }); // API call with search term
				setActiveCode({
					...activeCode,
					description: '',
				});
			}
		}
	};

	// Create a debounced search function
	const debouncedSearch = useCallback(
		debounce((searchTerm, type) => {
			// Only proceed if this is still the latest search term
			if (searchTerm === latestSearchRef.current) {
				if (type === 'CPT') {
					setCptSearchLoading(true);
					cptform.setFieldsValue({ icd_search: searchTerm });
					handleIcdFilters(searchTerm, 'CPT');
					setCptTriggeredBySearch(true);
					setCptTriggeredByEnter(false);
					fetchCptCodes({ search: searchTerm });
				} else {
					setIcdSearchLoading(true);
					icdform.setFieldsValue({ icd_search: searchTerm });
					handleIcdFilters(searchTerm);
					setTriggeredBySearch(true);
					setTriggeredByEnter(false);
					fetchIcdCodes({ search: searchTerm });
				}
			}
		}, 300), // 300ms delay
		[],
	);

	// Replace your existing handleSearchChange with this
	const handleSearchChange = (search, type) => {
		if (!search) return;

		if (search.length >= 3) {
			// Clear any existing timeout
			if (searchTimeoutRef.current) {
				clearTimeout(searchTimeoutRef.current);
			}

			// Store the latest search term
			latestSearchRef.current = search;

			// Cancel previous debounced call
			debouncedSearch.cancel();

			// Trigger the debounced search
			debouncedSearch(search, type);
		}
	};

	// Add cleanup on component unmount
	useEffect(() => {
		return () => {
			if (searchTimeoutRef.current) {
				clearTimeout(searchTimeoutRef.current);
			}
			debouncedSearch.cancel();
		};
	}, []);

	// Fetch ICD codes based on the filters
	const fetchIcdCodes = (filters) => {
		getIcdCodes(filters); // API call
	};

	// Fetch ICD codes based on the filters
	const fetchCptCodes = (filters) => {
		getCptCodes(filters); // API call
	};

	useEffect(() => {
		if (icdFilters.related) {
			fetchIcdCodes(icdFilters);
		} else if (loadMore) {
			fetchIcdCodes(icdFilters);
		}
	}, [icdFilters]);

	useEffect(() => {
		if (cptFilters.related) {
			fetchCptCodes(cptFilters);
		} else if (cptLoadMore) {
			fetchCptCodes(cptFilters);
		}
	}, [cptFilters]);

	useEffect(() => {
		if (getCptCodesState.apiState === 'loading') {
			if (!cptSearchLoading) {
				setCptLoading(true);
			}
		}
		if (getCptCodesState.apiState === 'success') {
			if (cptTriggeredBySearch && !cptTriggeredByEnter) {
				// If just typing, populate search results only
				setCptSearchResults(getCptCodesState.data.list);
			} else if (
				cptLoadMore &&
				!cptTriggeredBySearch &&
				!cptTriggeredByEnter
			) {
				// Spread the old data and add new data when loading more
				setCptCodes((prevCodes) => ({
					...prevCodes,
					list: [...prevCodes.list, ...getCptCodesState.data.list], // Append new list to the old list
					loaded_count:
						prevCodes.loaded_count +
						getCptCodesState.data.loaded_count, // Increment loaded_count
					total_count: getCptCodesState.data.total_count, // Keep total_count from the new data
				}));
				setCptSearchResults();
			} else if (
				cptTriggeredByEnter &&
				!cptTriggeredBySearch &&
				!cptLoadMore
			) {
				// If Enter was pressed (full search), populate ICD codes below
				setCptCodes(getCptCodesState.data);
				setCptSearchResults();
			}
			setCptLoading(false);
			setCptTriggeredBySearch(false);
			setCptSearchLoading(false);
			setCptLoadMore(false);
			setCptTriggeredByEnter(false);
		}
		if (getCptCodesState.apiState === 'error') {
			setCptLoading(false);
			setCptTriggeredBySearch(false);
			setCptSearchLoading(false);
			setCptLoadMore(false);
			setCptTriggeredByEnter(false);
		}
		getCptCodesReset();
	}, [getCptCodesState]);

	useEffect(() => {
		if (getIcdCodesState.apiState === 'loading') {
			if (!icdSearchLoading) {
				setIcdLoading(true);
			}
		}
		if (getIcdCodesState.apiState === 'success') {
			if (triggeredBySearch && !triggeredByEnter) {
				// If just typing, populate search results only
				setSearchResults(getIcdCodesState.data.list);
			} else if (loadMore && !triggeredBySearch && !triggeredByEnter) {
				// Spread the old data and add new data when loading more
				setIcdCodes((prevCodes) => ({
					...prevCodes,
					list: [...prevCodes.list, ...getIcdCodesState.data.list], // Append new list to the old list
					loaded_count:
						prevCodes.loaded_count +
						getIcdCodesState.data.loaded_count, // Increment loaded_count
					total_count: getIcdCodesState.data.total_count, // Keep total_count from the new data
				}));
				setSearchResults();
			} else if (triggeredByEnter && !triggeredBySearch && !loadMore) {
				// If Enter was pressed (full search), populate ICD codes below
				setIcdCodes(getIcdCodesState.data);
				setSearchResults();
			}
			setIcdLoading(false);
			setTriggeredBySearch(false);
			setIcdSearchLoading(false);
			setLoadMore(false);
			setTriggeredByEnter(false);
		}
		if (getIcdCodesState.apiState === 'error') {
			setIcdLoading(false);
			setTriggeredBySearch(false);
			setIcdSearchLoading(false);
			setLoadMore(false);
			setTriggeredByEnter(false);
		}
		getIcdCodesReset();
	}, [getIcdCodesState]);

	// Handle loading more records (View More functionality)
	const handleLoadMore = (loaded_count, type) => {
		console.log('load more');
		// Increase the loaded count by the limit and call API again
		if (type === 'CPT') {
			console.log('load more cpt');
			setCptFilters((prev) => ({
				...prev,
				loaded_count: loaded_count,
			}));
			setCptLoadMore(true);
		} else {
			setIcdFilters((prev) => ({
				...prev,
				loaded_count: loaded_count,
			}));
			setLoadMore(true);
		}
	};

	return (
		<>
			{/* ///todo Search Drawer for icd and cpt */}
			{!fixed && (
				<>
					<XDrawerAddCode
						title="Coding Tools"
						onClose={closeIcdCptDrawer}
						open={openIcdCptDrawer}
						mask={false}
						zIndex={9999}
					>
						<div className="contentPadding pt-0">
							<XTabIcdCpt
								type="card"
								className="pt-3"
								defaultActiveKey="1"
								items={[
									{
										key: 1,
										label: 'ICD-10 Codes',
										children: (
											<>
												<Row>
													<Col
														xs={24}
														className="mb-3"
													>
														<XForm
															form={icdform}
															name="icdform"
														>
															<XForm.Item name="icd_search">
																<XSelectAddCase
																	allowClear
																	defaultActiveFirstOption={
																		false
																	}
																	style={{
																		width: '100%',
																	}}
																	suffixIcon={
																		null
																	}
																	showSearch
																	notFoundContent={
																		icdSearchLoading ? (
																			<Spin size="small" />
																		) : null
																	}
																	onSearch={
																		handleSearchChange
																	}
																	onClear={() => {
																		icdform.resetFields();
																	}}
																	onInputKeyDown={
																		handleSearchKeyDown
																	} // Handle Enter key
																	filterOption={
																		false
																	} // Disable default filtering
																	options={
																		searchResults &&
																		searchResults.length >
																			0
																			? searchResults.map(
																					(
																						item,
																					) => ({
																						label: `${item.icd_code}: ${item.icd_title}`,
																						value: item.icd_code,
																					}),
																			  )
																			: []
																	}
																	onSelect={(
																		value,
																		option,
																	) => {
																		// Handle selection and fetch related codes
																		handleCodeClick(
																			'ICD-10',
																			{
																				code: option.value,
																				description:
																					option.label,
																				codeTypeText:
																					'pastHistory',
																				buttontext:
																					'Add in Past',
																			},
																		);
																	}}
																	size="large"
																	placeholder="Search ICD-10 Code"
																/>
															</XForm.Item>
														</XForm>
													</Col>
												</Row>
												{!loadMore &&
												icdLoading &&
												!icdSearchLoading ? (
													<>
														<Skeleton
															active
															paragraph={{
																rows: 2,
															}}
														/>
													</>
												) : (
													<>
														{icdCodes &&
															icdCodes.list &&
															icdCodes?.list
																?.length >
																0 && (
																<Col
																	xs={24}
																	className="mb-3"
																>
																	<span className="titleICD">
																		ICD-10
																		Codes
																	</span>
																</Col>
															)}
														{icdCodes &&
															icdCodes.list &&
															icdCodes?.list
																?.length > 0 &&
															icdCodes?.list?.map(
																(icdCode) => (
																	<>
																		<XRelatedCard className="mb-3">
																			<Row
																				gutter={
																					(15,
																					15)
																				}
																				className="align-items-center"
																			>
																				<Col
																					xs={
																						20
																					}
																					onClick={() => {
																						// Handle selection and fetch related codes
																						handleCodeClick(
																							'ICD-10',
																							{
																								code: icdCode.icd_code,
																								description:
																									icdCode.icd_title,
																								codeTypeText:
																									'pastHistory',
																								buttontext:
																									'Add in Past',
																							},
																						);
																					}}
																					role="button"
																				>
																					<span class="icdPara1">
																						{/* ICD10:150.9 */}
																						{
																							icdCode?.icd_code
																						}
																					</span>
																				</Col>
																				<Col
																					xs={
																						4
																					}
																					className="text-end"
																				>
																					{!previewMode && (
																						<XButtonConfirm
																							className="btnAdd"
																							shape="circle"
																							icon={
																								<PlusOutlined />
																							}
																							type="text"
																							size="small"
																							onClick={() => {
																								setIcdModal(
																									true,
																								);
																								setActiveCode(
																									{
																										type: 'ICD-10',
																										code: icdCode?.icd_code,
																										description:
																											icdCode?.icd_title,
																										codeTypeText:
																											'',
																										buttontext:
																											'',
																									},
																								);
																								setModalOpen(
																									[
																										false,
																										icdCode?.icd_code,
																										icdCode?.icd_title,
																										null,
																										activeCode?.codeTypeText,
																									],
																								);
																								setModalTextArea(
																									`${icdCode?.icd_title} - ICD-10 Code: ${icdCode?.icd_code}`,
																								);
																							}}
																						/>
																					)}
																				</Col>
																				<Col
																					xs={
																						24
																					}
																					className="my-1"
																				>
																					<div className="icdPara3">
																						{
																							icdCode?.icd_title
																						}
																					</div>
																				</Col>
																			</Row>
																		</XRelatedCard>
																	</>
																),
															)}
														{icdCodes &&
															icdCodes?.list
																?.length ===
																0 &&
															activeCode?.type !==
																'CPT' && (
																<XRelatedCard className="mb-3">
																	<Row
																		gutter={
																			(15,
																			15)
																		}
																		className="align-items-center"
																	>
																		<Col
																			xs={
																				24
																			}
																			className="my-1"
																		>
																			<div className="icdPara3">
																				There
																				is
																				no
																				code
																				available
																				for
																				the
																				searched
																				ICD-10
																				Code
																			</div>
																		</Col>
																	</Row>
																</XRelatedCard>
															)}
														{icdCodes &&
															icdCodes.loaded_count <
																icdCodes.total_count && (
																<Row>
																	<Col
																		xs={24}
																		className="text-center"
																	>
																		<XViewMoreBTN
																			loading={
																				icdLoading
																			}
																			size="large"
																			type="primary"
																			onClick={() => {
																				// Handle selection and fetch related codes
																				handleLoadMore(
																					icdCodes.loaded_count,
																				);
																			}}
																		>
																			View
																			More
																		</XViewMoreBTN>
																	</Col>
																</Row>
															)}
													</>
												)}
											</>
										),
									},
									{
										key: 2,
										label: 'CPT Codes',
										children: (
											<>
												<Row>
													<Col
														xs={24}
														className="mb-3"
													>
														<XForm
															form={cptform}
															name="cptform"
														>
															<XForm.Item name="cpt_search">
																<XSelectAddCase
																	allowClear
																	defaultActiveFirstOption={
																		false
																	}
																	style={{
																		width: '100%',
																	}}
																	suffixIcon={
																		null
																	}
																	showSearch
																	notFoundContent={
																		cptSearchLoading ? (
																			<Spin size="small" />
																		) : null
																	}
																	onSearch={(
																		searchTerm,
																	) =>
																		handleSearchChange(
																			searchTerm,
																			'CPT',
																		)
																	}
																	onClear={() => {
																		cptform.resetFields();
																	}}
																	onInputKeyDown={(
																		event,
																	) =>
																		handleSearchKeyDown(
																			event,
																			'CPT',
																		)
																	}
																	filterOption={
																		false
																	} // Disable default filtering
																	options={
																		cptSearchResults &&
																		cptSearchResults.length >
																			0
																			? cptSearchResults.map(
																					(
																						item,
																					) => ({
																						label: `${item.cpt_code}: ${item.cpt_title}`,
																						value: item.cpt_code,
																					}),
																			  )
																			: []
																	}
																	onSelect={(
																		value,
																		option,
																	) => {
																		// Handle selection and fetch related codes
																		handleCodeClick(
																			'CPT',
																			{
																				code: option.value,
																				description:
																					option.label,
																				codeTypeText:
																					'recommendedProcedure',
																				buttontext:
																					'Add in Denial',
																			},
																		);
																	}}
																	size="large"
																	placeholder="Search CPT Code"
																/>
															</XForm.Item>
														</XForm>
													</Col>
												</Row>
												{!cptLoadMore &&
												cptLoading &&
												!cptSearchLoading ? (
													<>
														<Skeleton
															active
															paragraph={{
																rows: 2,
															}}
														/>
													</>
												) : (
													<>
														{cptCodes &&
															cptCodes.list &&
															cptCodes?.list
																?.length >
																0 && (
																<Col
																	xs={24}
																	className="mb-3"
																>
																	<span className="titleICD">
																		CPT
																		Codes
																	</span>
																</Col>
															)}
														{cptCodes &&
															cptCodes.list &&
															cptCodes?.list
																?.length > 0 &&
															cptCodes?.list?.map(
																(cptCode) => (
																	<>
																		<XRelatedCard
																			variant="CPT"
																			className="mb-0"
																		>
																			<Row
																				gutter={
																					(15,
																					15)
																				}
																				className="align-items-center"
																			>
																				<Col
																					xs={
																						20
																					}
																					onClick={() => {
																						// Handle selection and fetch related codes
																						handleCodeClick(
																							'CPT',
																							{
																								code: cptCode.cpt_code,
																								description:
																									cptCode.cpt_title,
																								codeTypeText:
																									'recommendedProcedure',
																								buttontext:
																									'Add in Denial',
																							},
																						);
																					}}
																					role="button"
																				>
																					<span class="icdPara1">
																						{/* ICD10:150.9 */}
																						{
																							cptCode?.cpt_code
																						}
																					</span>
																				</Col>
																				<Col
																					xs={
																						4
																					}
																					className="text-end"
																				>
																					{!previewMode && (
																						<XButtonConfirm
																							className="btnAdd"
																							shape="circle"
																							icon={
																								<PlusOutlined />
																							}
																							type="text"
																							size="small"
																							onClick={() => {
																								setIcdModal(
																									true,
																								);
																								setActiveCode(
																									{
																										type: 'CPT',
																										code: cptCode?.cpt_code,
																										description:
																											cptCode?.cpt_title,
																										codeTypeText:
																											'',
																										buttontext:
																											'',
																									},
																								);
																								setModalOpen(
																									[
																										false,
																										cptCode?.cpt_code,
																										cptCode?.cpt_title,
																										null,
																										activeCode?.codeTypeText,
																									],
																								);
																								setModalTextArea(
																									`${cptCode?.cpt_title} - CPT Code: ${cptCode?.cpt_code}`,
																								);
																							}}
																						/>
																					)}
																				</Col>
																				<Col
																					xs={
																						24
																					}
																					className="my-1"
																				>
																					<div className="icdPara3">
																						{
																							cptCode?.cpt_title
																						}
																					</div>
																				</Col>
																			</Row>
																		</XRelatedCard>
																		<XRelatedReadMore>
																			<Row>
																				<Col
																					xs={
																						24
																					}
																				>
																					<div className="icdPara2">
																						<ReactReadMoreReadLess
																							charLimit={
																								0
																							}
																							ellipsis={
																								' '
																							}
																							readMoreText={
																								<>
																									<Row className="align-items-center">
																										<Col
																											xs={
																												12
																											}
																										>
																											View
																											More
																										</Col>
																										<Col
																											xs={
																												12
																											}
																											className="text-end"
																										>
																											<DownOutlined />
																										</Col>
																									</Row>
																								</>
																							}
																							readLessText={
																								<>
																									<Row className="align-items-center">
																										<Col
																											xs={
																												12
																											}
																										>
																											View
																											Less
																										</Col>
																										<Col
																											xs={
																												12
																											}
																											className="text-end"
																										>
																											<UpOutlined />
																										</Col>
																									</Row>
																								</>
																							}
																						>
																							{
																								cptCode?.long_description
																							}
																						</ReactReadMoreReadLess>
																					</div>
																				</Col>
																			</Row>
																		</XRelatedReadMore>
																	</>
																),
															)}
														{cptCodes &&
															cptCodes?.list
																?.length ===
																0 && (
																<XRelatedCard className="mb-3">
																	<Row
																		gutter={
																			(15,
																			15)
																		}
																		className="align-items-center"
																	>
																		<Col
																			xs={
																				24
																			}
																			className="my-1"
																		>
																			<div className="icdPara3">
																				There
																				is
																				no
																				code
																				available
																				for
																				the
																				searched
																				CPT
																				Code
																			</div>
																		</Col>
																	</Row>
																</XRelatedCard>
															)}
														{cptCodes &&
															cptCodes.loaded_count <
																cptCodes.total_count && (
																<Row>
																	<Col
																		xs={24}
																		className="text-center"
																	>
																		<XViewMoreBTN
																			loading={
																				cptLoading
																			}
																			size="large"
																			type="primary"
																			onClick={() => {
																				// Handle selection and fetch related codes
																				handleLoadMore(
																					cptCodes.loaded_count,
																					'CPT',
																				);
																			}}
																		>
																			View
																			More
																		</XViewMoreBTN>
																	</Col>
																</Row>
															)}
													</>
												)}
											</>
										),
									},
								]}
							/>
						</div>
					</XDrawerAddCode>

					{/* //todo Related codes Drawer */}
					<XDrawerAddCode
						title={
							<>
								{activeCode && activeCode.description
									? `${activeCode.type}: ${activeCode.code}`
									: activeCode
									? `${activeCode.type}`
									: ''}
							</>
						}
						onClose={closeIcdCptDrawer2}
						open={openIcdCptDrawer2}
						// open={''} //todo
						mask={false}
						zIndex={9999}
					>
						<Row className="align-items-center">
							{/* // todo search  */}
							{activeCode?.type === 'CPT' && (
								<>
									<Col xs={24} className="px-4 pt-4">
										<XForm form={cptform} name="cptform">
											<XForm.Item name="cpt_search">
												<XSelectAddCase
													allowClear
													defaultActiveFirstOption={
														false
													}
													style={{
														width: '100%',
													}}
													suffixIcon={null}
													showSearch
													notFoundContent={
														cptSearchLoading ? (
															<Spin size="small" />
														) : null
													}
													onSearch={(searchTerm) =>
														handleSearchChange(
															searchTerm,
															'CPT',
														)
													}
													onClear={() => {
														cptform.resetFields();
													}}
													onInputKeyDown={(event) =>
														handleSearchKeyDown(
															event,
															'CPT',
														)
													}
													filterOption={false} // Disable default filtering
													options={
														cptSearchResults &&
														cptSearchResults.length >
															0
															? cptSearchResults.map(
																	(item) => ({
																		label: `${item.cpt_code}: ${item.cpt_title}`,
																		value: item.cpt_code,
																	}),
															  )
															: []
													}
													onSelect={(
														value,
														option,
													) => {
														// Handle selection and fetch related codes
														handleCodeClick('CPT', {
															code: option.value,
															description:
																option.label,
															codeTypeText:
																'recommendedProcedure',
															buttontext:
																'Add in Denial',
														});
													}}
													size="large"
													placeholder="Search CPT Code"
												/>
											</XForm.Item>
										</XForm>
									</Col>
								</>
							)}
							{activeCode?.type === 'ICD-10' && (
								<Col xs={24} className="px-4 pt-4">
									<XForm form={icdform} name="icdform">
										<XForm.Item name="icd_search">
											<XSelectAddCase
												allowClear
												defaultActiveFirstOption={false}
												style={{
													width: '100%',
												}}
												suffixIcon={null}
												showSearch
												notFoundContent={
													icdSearchLoading ? (
														<Spin size="small" />
													) : null
												}
												onSearch={handleSearchChange}
												onClear={() => {
													icdform.resetFields();
												}}
												onInputKeyDown={
													handleSearchKeyDown
												} // Handle Enter key
												filterOption={false} // Disable default filtering
												options={
													searchResults &&
													searchResults.length > 0
														? searchResults.map(
																(item) => ({
																	label: `${item.icd_code}: ${item.icd_title}`,
																	value: item.icd_code,
																}),
														  )
														: []
												}
												onSelect={(value, option) => {
													// Handle selection and fetch related codes
													handleCodeClick('ICD-10', {
														code: option.value,
														description:
															option.label,
														codeTypeText:
															'pastHistory',
														buttontext:
															'Add in Past',
													});
												}}
												size="large"
												placeholder="Search ICD-10 Code"
											/>
										</XForm.Item>
									</XForm>
								</Col>
							)}
						</Row>
						{activeCode && activeCode.description && (
							<div className="titleBg pt-4">
								<Row
									gutter={(15, 15)}
									className="align-items-center"
								>
									<Col xs={24}>
										<span class="titleICD">
											{
												<>
													{activeCode?.type}:{' '}
													{activeCode?.code}
												</>
											}
										</span>
									</Col>
								</Row>
								{(!loadMore &&
									icdLoading &&
									!icdSearchLoading) ||
								(!cptLoadMore &&
									cptLoading &&
									!cptSearchLoading) ? (
									<>
										<Row gutter={(15, 15)} className="mt-3">
											<Skeleton
												active
												paragraph={{
													rows: 1,
												}}
											/>
										</Row>
									</>
								) : (
									<>
										<Row gutter={(15, 15)} className="mt-3">
											<Col xs={24}>
												<p className="icdPara3">
													{activeCode?.type === 'CPT'
														? cptCodes?.cpt_title ||
														  activeCode?.description
														: icdCodes?.icd_title ||
														  activeCode?.description}
													{/* {icdCodes === undefined
										? null // Show nothing while icdCodes is undefined (loading or empty state)
										: icdCodes?.icd_title
										? icdCodes.icd_title
										: activeCode?.description} */}
												</p>
											</Col>
										</Row>
										{activeCode?.type === 'CPT' &&
											cptCodes?.long_description && (
												<Row
													gutter={(15, 15)}
													className="mt-2"
												>
													<Col xs={24}>
														<div className="icdPara2">
															{
																cptCodes?.long_description
															}
														</div>
													</Col>
												</Row>
											)}
									</>
								)}
							</div>
						)}
						<div className="contentPadding">
							<Row>
								<>
									{activeCode?.type === 'CPT' && (
										<Col xs={24}>
											{!cptLoadMore &&
											cptLoading &&
											!cptSearchLoading ? (
												<>
													<Skeleton
														active
														paragraph={{
															rows: 2,
														}}
													/>
												</>
											) : (
												<>
													{cptCodes &&
														cptCodes.list &&
														cptCodes?.list?.length >
															0 && (
															<Col
																xs={24}
																className="mb-3"
															>
																<span className="titleICD">
																	CPT Codes
																</span>
															</Col>
														)}
													{cptCodes &&
														cptCodes.list &&
														cptCodes?.list?.length >
															0 &&
														cptCodes?.list?.map(
															(cptCode) => (
																<>
																	<XRelatedCard
																		variant="CPT"
																		className="mb-0"
																	>
																		<Row
																			gutter={
																				(15,
																				15)
																			}
																			className="align-items-center"
																		>
																			<Col
																				xs={
																					20
																				}
																				onClick={() => {
																					// Handle selection and fetch related codes
																					handleCodeClick(
																						'CPT',
																						{
																							code: cptCode.cpt_code,
																							description:
																								cptCode.cpt_title,
																							codeTypeText:
																								'recommendedProcedure',
																							buttontext:
																								'Add in Denial',
																						},
																					);
																				}}
																				role="button"
																			>
																				<span class="icdPara1">
																					{/* ICD10:150.9 */}
																					{
																						cptCode?.cpt_code
																					}
																				</span>
																			</Col>
																			<Col
																				xs={
																					4
																				}
																				className="text-end"
																			>
																				{!previewMode && (
																					<XButtonConfirm
																						className="btnAdd"
																						shape="circle"
																						icon={
																							<PlusOutlined />
																						}
																						type="text"
																						size="small"
																						onClick={() => {
																							setIcdModal(
																								true,
																							);
																							setModalOpen(
																								[
																									false,
																									cptCode?.cpt_code,
																									cptCode?.cpt_title,
																									null,
																									activeCode?.codeTypeText,
																								],
																							);
																							setModalTextArea(
																								`${cptCode?.cpt_title} - CPT Code: ${cptCode?.cpt_code}`,
																							);
																						}}
																					/>
																				)}
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				className="my-1"
																			>
																				<div className="icdPara3">
																					{
																						cptCode?.cpt_title
																					}
																				</div>
																			</Col>
																		</Row>
																	</XRelatedCard>
																	<XRelatedReadMore>
																		<Row>
																			<Col
																				xs={
																					24
																				}
																			>
																				<div className="icdPara2">
																					<ReactReadMoreReadLess
																						charLimit={
																							0
																						}
																						ellipsis={
																							' '
																						}
																						readMoreText={
																							<>
																								<Row className="align-items-center">
																									<Col
																										xs={
																											12
																										}
																									>
																										View
																										More
																									</Col>
																									<Col
																										xs={
																											12
																										}
																										className="text-end"
																									>
																										<DownOutlined />
																									</Col>
																								</Row>
																							</>
																						}
																						readLessText={
																							<>
																								<Row className="align-items-center">
																									<Col
																										xs={
																											12
																										}
																									>
																										View
																										Less
																									</Col>
																									<Col
																										xs={
																											12
																										}
																										className="text-end"
																									>
																										<UpOutlined />
																									</Col>
																								</Row>
																							</>
																						}
																					>
																						{
																							cptCode?.long_description
																						}
																					</ReactReadMoreReadLess>
																				</div>
																			</Col>
																		</Row>
																	</XRelatedReadMore>
																</>
															),
														)}
													{cptCodes &&
														cptCodes.loaded_count <
															cptCodes.total_count && (
															<Row>
																<Col
																	xs={24}
																	className="text-center"
																>
																	<XViewMoreBTN
																		loading={
																			cptLoading
																		}
																		size="large"
																		type="primary"
																		onClick={() => {
																			// Handle selection and fetch related codes
																			handleLoadMore(
																				cptCodes.loaded_count,
																				'CPT',
																			);
																		}}
																	>
																		View
																		More
																	</XViewMoreBTN>
																</Col>
															</Row>
														)}
												</>
											)}
										</Col>
									)}
									{activeCode?.type === 'ICD-10' && (
										<Col xs={24}>
											{activeCode &&
												activeCode.description && (
													<Row>
														<Col
															xs={24}
															className="mb-3"
														>
															<span className="titleICD">
																Related Codes
															</span>
														</Col>
													</Row>
												)}
											{!loadMore &&
											icdLoading &&
											!icdSearchLoading ? (
												<>
													<Skeleton
														active
														paragraph={{
															rows: 2,
														}}
													/>
												</>
											) : (
												<>
													{icdCodes &&
														icdCodes?.list &&
														icdCodes?.list?.length >
															0 &&
														icdCodes?.list?.map(
															(
																relatedCode,
																index,
															) => (
																<>
																	<XRelatedCard className="mb-3">
																		<Row
																			gutter={
																				(15,
																				15)
																			}
																			className="align-items-center"
																		>
																			<Col
																				xs={
																					20
																				}
																				onClick={() => {
																					// Handle selection and fetch related codes
																					handleCodeClick(
																						'ICD-10',
																						{
																							code: relatedCode.icd_code,
																							description:
																								relatedCode.icd_title,
																							codeTypeText:
																								'pastHistory',
																							buttontext:
																								'Add in Past',
																						},
																					);
																				}}
																				role="button"
																			>
																				<span class="icdPara1">
																					{
																						relatedCode?.icd_code
																					}
																				</span>
																			</Col>
																			<Col
																				xs={
																					4
																				}
																				className="text-end"
																			>
																				{!previewMode && (
																					<XButtonConfirm
																						className="btnAdd"
																						shape="circle"
																						icon={
																							<PlusOutlined />
																						}
																						type="text"
																						size="small"
																						onClick={() => {
																							setIcdModal(
																								true,
																							);
																							setModalOpen(
																								[
																									false,
																									relatedCode?.icd_code,
																									relatedCode?.icd_title,
																									null,
																									activeCode?.codeTypeText,
																								],
																							);
																							setModalTextArea(
																								`${relatedCode?.icd_title} - ICD-10 Code: ${relatedCode?.icd_code}`,
																							);
																						}}
																					/>
																				)}
																			</Col>
																			<Col
																				xs={
																					24
																				}
																				className="my-1"
																			>
																				<div className="icdPara3">
																					{
																						relatedCode?.icd_title
																					}
																				</div>
																			</Col>
																		</Row>
																	</XRelatedCard>
																</>
															),
														)}
													{icdCodes &&
														icdCodes?.total_count ==
															0 &&
														activeCode?.type !==
															'CPT' && (
															<XRelatedCard
																className="mb-3"
																style={{
																	borderRadius:
																		'10px',
																}}
															>
																<Row
																	gutter={
																		(15, 15)
																	}
																	className="align-items-center"
																>
																	<Col
																		xs={24}
																	>
																		<div className="icdPara3">
																			There
																			are
																			no
																			codes
																			available
																			for
																			the
																			above
																			ICD-10
																			Code
																		</div>
																	</Col>
																</Row>
															</XRelatedCard>
														)}
													{icdCodes &&
														icdCodes.loaded_count <
															icdCodes.total_count && (
															<Row>
																<Col
																	xs={24}
																	className="text-center"
																>
																	<XViewMoreBTN
																		loading={
																			icdLoading
																		}
																		size="large"
																		type="primary"
																		onClick={() => {
																			// Handle selection and fetch related codes
																			handleLoadMore(
																				icdCodes.loaded_count,
																			);
																		}}
																	>
																		View
																		More
																	</XViewMoreBTN>
																</Col>
															</Row>
														)}
												</>
											)}
										</Col>
									)}
								</>
							</Row>
						</div>
					</XDrawerAddCode>
				</>
			)}

			{fixed && (
				<>
					<XAffix offsetTop={20}>
						{/* Coding tool inside card */}
						{openIcdCptDrawer ? (
							<XTabIcdCpt
								type="card"
								className="pt-0"
								defaultActiveKey="1"
								items={[
									{
										key: 1,
										label: 'ICD-10 Codes',
										children: (
											<XcaseAnsyCardScroll id="stckyScroll">
												<>
													<Row>
														<Col
															xs={24}
															className="mb-3"
														>
															<XForm
																form={icdform}
																name="icdform"
															>
																<XForm.Item name="icd_search">
																	<XSelectAddCase
																		allowClear
																		defaultActiveFirstOption={
																			false
																		}
																		style={{
																			width: '100%',
																		}}
																		suffixIcon={
																			null
																		}
																		showSearch
																		notFoundContent={
																			icdSearchLoading ? (
																				<Spin size="small" />
																			) : null
																		}
																		onSearch={
																			handleSearchChange
																		}
																		onClear={() => {
																			icdform.resetFields();
																		}}
																		onInputKeyDown={
																			handleSearchKeyDown
																		} // Handle Enter key
																		filterOption={
																			false
																		} // Disable default filtering
																		options={
																			searchResults &&
																			searchResults.length >
																				0
																				? searchResults.map(
																						(
																							item,
																						) => ({
																							label: `${item.icd_code}: ${item.icd_title}`,
																							value: item.icd_code,
																						}),
																				  )
																				: []
																		}
																		onSelect={(
																			value,
																			option,
																		) => {
																			// Handle selection and fetch related codes
																			handleCodeClick(
																				'ICD-10',
																				{
																					code: option.value,
																					description:
																						option.label,
																					codeTypeText:
																						'pastHistory',
																					buttontext:
																						'Add in Past',
																				},
																			);
																		}}
																		size="large"
																		placeholder="Search ICD-10 Code"
																	/>
																</XForm.Item>
															</XForm>
														</Col>
													</Row>
													{!loadMore &&
													icdLoading &&
													!icdSearchLoading ? (
														<>
															<Skeleton
																active
																paragraph={{
																	rows: 2,
																}}
															/>
														</>
													) : (
														<>
															{icdCodes &&
																icdCodes.list &&
																icdCodes?.list
																	?.length >
																	0 && (
																	<Col
																		xs={24}
																		className="mb-3"
																	>
																		<span className="titleICD">
																			ICD-10
																			Codes
																		</span>
																	</Col>
																)}
															{icdCodes &&
																icdCodes.list &&
																icdCodes?.list
																	?.length >
																	0 &&
																icdCodes?.list?.map(
																	(
																		icdCode,
																	) => (
																		<>
																			<XRelatedCard className="mb-3">
																				<Row
																					gutter={
																						(15,
																						15)
																					}
																					className="align-items-center"
																				>
																					<Col
																						xs={
																							20
																						}
																						onClick={() => {
																							// Handle selection and fetch related codes
																							handleCodeClick(
																								'ICD-10',
																								{
																									code: icdCode.icd_code,
																									description:
																										icdCode.icd_title,
																									codeTypeText:
																										'pastHistory',
																									buttontext:
																										'Add in Past',
																								},
																							);
																						}}
																						role="button"
																					>
																						<span class="icdPara1">
																							{
																								icdCode?.icd_code
																							}
																						</span>
																					</Col>
																					<Col
																						xs={
																							4
																						}
																						className="text-end"
																					>
																						{!previewMode && (
																							<XButtonConfirm
																								className="btnAdd"
																								shape="circle"
																								icon={
																									<PlusOutlined />
																								}
																								type="text"
																								size="small"
																								onClick={() => {
																									setIcdModal(
																										true,
																									);
																									setActiveCode(
																										{
																											type: 'ICD-10',
																											code: icdCode?.icd_code,
																											description:
																												icdCode?.icd_title,
																											codeTypeText:
																												'',
																											buttontext:
																												'',
																										},
																									);
																									setModalOpen(
																										[
																											false,
																											icdCode?.icd_code,
																											icdCode?.icd_title,
																											null,
																											activeCode?.codeTypeText,
																										],
																									);
																									setModalTextArea(
																										`${icdCode?.icd_title} - ICD-10 Code: ${icdCode?.icd_code}`,
																									);
																								}}
																							/>
																						)}
																					</Col>
																					<Col
																						xs={
																							24
																						}
																						className="my-1"
																					>
																						<div className="icdPara3">
																							{
																								icdCode?.icd_title
																							}
																						</div>
																					</Col>
																				</Row>
																			</XRelatedCard>
																		</>
																	),
																)}
															{icdCodes &&
																icdCodes?.list
																	?.length ===
																	0 &&
																activeCode?.type !==
																	'CPT' && (
																	<XRelatedCard className="mb-3">
																		<Row
																			gutter={
																				(15,
																				15)
																			}
																			className="align-items-center"
																		>
																			<Col
																				xs={
																					24
																				}
																				className="my-1"
																			>
																				<div className="icdPara3">
																					There
																					is
																					no
																					code
																					available
																					for
																					the
																					searched
																					ICD-10
																					Code
																				</div>
																			</Col>
																		</Row>
																	</XRelatedCard>
																)}
															{icdCodes &&
																icdCodes.loaded_count <
																	icdCodes.total_count && (
																	<Row>
																		<Col
																			xs={
																				24
																			}
																			className="text-center"
																		>
																			<XViewMoreBTN
																				loading={
																					icdLoading
																				}
																				size="large"
																				type="primary"
																				onClick={() => {
																					// Handle selection and fetch related codes
																					handleLoadMore(
																						icdCodes.loaded_count,
																					);
																				}}
																			>
																				View
																				More
																			</XViewMoreBTN>
																		</Col>
																	</Row>
																)}
														</>
													)}
												</>
											</XcaseAnsyCardScroll>
										),
									},
									{
										key: 2,
										label: 'CPT Codes',
										children: (
											<XcaseAnsyCardScroll id="stckyScroll">
												<>
													<Row>
														<Col
															xs={24}
															className="mb-3"
														>
															<XForm
																form={cptform}
																name="cptform"
															>
																<XForm.Item name="cpt_search">
																	<XSelectAddCase
																		allowClear
																		defaultActiveFirstOption={
																			false
																		}
																		style={{
																			width: '100%',
																		}}
																		suffixIcon={
																			null
																		}
																		showSearch
																		notFoundContent={
																			cptSearchLoading ? (
																				<Spin size="small" />
																			) : null
																		}
																		onSearch={(
																			searchTerm,
																		) =>
																			handleSearchChange(
																				searchTerm,
																				'CPT',
																			)
																		}
																		onClear={() => {
																			cptform.resetFields();
																		}}
																		onInputKeyDown={(
																			event,
																		) =>
																			handleSearchKeyDown(
																				event,
																				'CPT',
																			)
																		}
																		filterOption={
																			false
																		} // Disable default filtering
																		options={
																			cptSearchResults &&
																			cptSearchResults.length >
																				0
																				? cptSearchResults.map(
																						(
																							item,
																						) => ({
																							label: `${item.cpt_code}: ${item.cpt_title}`,
																							value: item.cpt_code,
																						}),
																				  )
																				: []
																		}
																		onSelect={(
																			value,
																			option,
																		) => {
																			// Handle selection and fetch related codes
																			handleCodeClick(
																				'CPT',
																				{
																					code: option.value,
																					description:
																						option.label,
																					codeTypeText:
																						'recommendedProcedure',
																					buttontext:
																						'Add in Denial',
																				},
																			);
																		}}
																		size="large"
																		placeholder="Search CPT Code"
																	/>
																</XForm.Item>
															</XForm>
														</Col>
													</Row>
													{!cptLoadMore &&
													cptLoading &&
													!cptSearchLoading ? (
														<>
															<Skeleton
																active
																paragraph={{
																	rows: 2,
																}}
															/>
														</>
													) : (
														<>
															{cptCodes &&
																cptCodes.list &&
																cptCodes?.list
																	?.length >
																	0 && (
																	<Col
																		xs={24}
																		className="mb-3"
																	>
																		<span className="titleICD">
																			CPT
																			Codes
																		</span>
																	</Col>
																)}
															{cptCodes &&
																cptCodes.list &&
																cptCodes?.list
																	?.length >
																	0 &&
																cptCodes?.list?.map(
																	(
																		cptCode,
																	) => (
																		<>
																			<XRelatedCard
																				variant="CPT"
																				className="mb-0"
																			>
																				<Row
																					gutter={
																						(15,
																						15)
																					}
																					className="align-items-center"
																				>
																					<Col
																						xs={
																							20
																						}
																						onClick={() => {
																							// Handle selection and fetch related codes
																							handleCodeClick(
																								'CPT',
																								{
																									code: cptCode.cpt_code,
																									description:
																										cptCode.cpt_title,
																									codeTypeText:
																										'recommendedProcedure',
																									buttontext:
																										'Add in Denial',
																								},
																							);
																						}}
																						role="button"
																					>
																						<span class="icdPara1">
																							{/* ICD10:150.9 */}
																							{
																								cptCode?.cpt_code
																							}
																						</span>
																					</Col>
																					<Col
																						xs={
																							4
																						}
																						className="text-end"
																					>
																						{!previewMode && (
																							<XButtonConfirm
																								className="btnAdd"
																								shape="circle"
																								icon={
																									<PlusOutlined />
																								}
																								type="text"
																								size="small"
																								onClick={() => {
																									setIcdModal(
																										true,
																									);
																									setActiveCode(
																										{
																											type: 'CPT',
																											code: cptCode?.cpt_code,
																											description:
																												cptCode?.cpt_title,
																											codeTypeText:
																												'',
																											buttontext:
																												'',
																										},
																									);
																									setModalOpen(
																										[
																											false,
																											cptCode?.cpt_code,
																											cptCode?.cpt_title,
																											null,
																											activeCode?.codeTypeText,
																										],
																									);
																									setModalTextArea(
																										`${cptCode?.cpt_title} - CPT Code: ${cptCode?.cpt_code}`,
																									);
																								}}
																							/>
																						)}
																					</Col>
																					<Col
																						xs={
																							24
																						}
																						className="my-1"
																					>
																						<div className="icdPara3">
																							{
																								cptCode?.cpt_title
																							}
																						</div>
																					</Col>
																				</Row>
																			</XRelatedCard>
																			<XRelatedReadMore>
																				<Row>
																					<Col
																						xs={
																							24
																						}
																					>
																						<div className="icdPara2">
																							<ReactReadMoreReadLess
																								charLimit={
																									0
																								}
																								ellipsis={
																									' '
																								}
																								readMoreText={
																									<>
																										<Row className="align-items-center">
																											<Col
																												xs={
																													12
																												}
																											>
																												View
																												More
																											</Col>
																											<Col
																												xs={
																													12
																												}
																												className="text-end"
																											>
																												<DownOutlined />
																											</Col>
																										</Row>
																									</>
																								}
																								readLessText={
																									<>
																										<Row className="align-items-center">
																											<Col
																												xs={
																													12
																												}
																											>
																												View
																												Less
																											</Col>
																											<Col
																												xs={
																													12
																												}
																												className="text-end"
																											>
																												<UpOutlined />
																											</Col>
																										</Row>
																									</>
																								}
																							>
																								{
																									cptCode?.long_description
																								}
																							</ReactReadMoreReadLess>
																						</div>
																					</Col>
																				</Row>
																			</XRelatedReadMore>
																		</>
																	),
																)}
															{cptCodes &&
																cptCodes?.list
																	?.length ===
																	0 && (
																	<XRelatedCard className="mb-3">
																		<Row
																			gutter={
																				(15,
																				15)
																			}
																			className="align-items-center"
																		>
																			<Col
																				xs={
																					24
																				}
																				className="my-1"
																			>
																				<div className="icdPara3">
																					There
																					is
																					no
																					code
																					available
																					for
																					the
																					searched
																					CPT
																					Code
																				</div>
																			</Col>
																		</Row>
																	</XRelatedCard>
																)}
															{cptCodes &&
																cptCodes.loaded_count <
																	cptCodes.total_count && (
																	<Row>
																		<Col
																			xs={
																				24
																			}
																			className="text-center"
																		>
																			<XViewMoreBTN
																				loading={
																					cptLoading
																				}
																				size="large"
																				type="primary"
																				onClick={() => {
																					// Handle selection and fetch related codes
																					handleLoadMore(
																						cptCodes.loaded_count,
																						'CPT',
																					);
																				}}
																			>
																				View
																				More
																			</XViewMoreBTN>
																		</Col>
																	</Row>
																)}
														</>
													)}
												</>
											</XcaseAnsyCardScroll>
										),
									},
								]}
							/>
						) : (
							<XcaseAnsyCardScroll
								className="mt-0"
								bodyStyle={{ padding: '0' }}
							>
								<div className="p-4">
									<Row
										gutter={(15, 15)}
										className="align-items-center"
									>
										<Col xs={20}>
											<span class="titleICD">
												{<>{activeCode?.type}</>}
											</span>
										</Col>
										<Col xs={4} className="text-end">
											<XButtonConfirm
												icon={<CloseOutlined />}
												type="text"
												size="small"
												style={{
													background: 'none',
													border: 'none',
												}}
												onClick={closeIcdCptDrawer2}
											/>
										</Col>
									</Row>
								</div>
								<Row className="align-items-center">
									{/* // todo search  */}
									{activeCode?.type === 'CPT' && (
										<Col xs={24} className="px-4">
											<XForm
												form={cptform}
												name="cptform"
											>
												<XForm.Item name="cpt_search">
													<XSelectAddCase
														allowClear
														defaultActiveFirstOption={
															false
														}
														style={{
															width: '100%',
														}}
														suffixIcon={null}
														showSearch
														notFoundContent={
															cptSearchLoading ? (
																<Spin size="small" />
															) : null
														}
														onSearch={(
															searchTerm,
														) =>
															handleSearchChange(
																searchTerm,
																'CPT',
															)
														}
														onClear={() => {
															cptform.resetFields();
														}}
														onInputKeyDown={(
															event,
														) =>
															handleSearchKeyDown(
																event,
																'CPT',
															)
														}
														filterOption={false} // Disable default filtering
														options={
															cptSearchResults &&
															cptSearchResults.length >
																0
																? cptSearchResults.map(
																		(
																			item,
																		) => ({
																			label: `${item.cpt_code}: ${item.cpt_title}`,
																			value: item.cpt_code,
																		}),
																  )
																: []
														}
														onSelect={(
															value,
															option,
														) => {
															// Handle selection and fetch related codes
															handleCodeClick(
																'CPT',
																{
																	code: option.value,
																	description:
																		option.label,
																	codeTypeText:
																		'recommendedProcedure',
																	buttontext:
																		'Add in Denial',
																},
															);
														}}
														size="large"
														placeholder="Search CPT Code"
													/>
												</XForm.Item>
											</XForm>
										</Col>
									)}
									{activeCode?.type === 'ICD-10' && (
										<Col xs={24} className="px-4">
											<XForm
												form={icdform}
												name="icdform"
											>
												<XForm.Item name="icd_search">
													<XSelectAddCase
														allowClear
														defaultActiveFirstOption={
															false
														}
														style={{
															width: '100%',
														}}
														suffixIcon={null}
														showSearch
														notFoundContent={
															icdSearchLoading ? (
																<Spin size="small" />
															) : null
														}
														onSearch={
															handleSearchChange
														}
														onClear={() => {
															icdform.resetFields();
														}}
														onInputKeyDown={
															handleSearchKeyDown
														} // Handle Enter key
														filterOption={false} // Disable default filtering
														options={
															searchResults &&
															searchResults.length >
																0
																? searchResults.map(
																		(
																			item,
																		) => ({
																			label: `${item.icd_code}: ${item.icd_title}`,
																			value: item.icd_code,
																		}),
																  )
																: []
														}
														onSelect={(
															value,
															option,
														) => {
															// Handle selection and fetch related codes
															handleCodeClick(
																'ICD-10',
																{
																	code: option.value,
																	description:
																		option.label,
																	codeTypeText:
																		'pastHistory',
																	buttontext:
																		'Add in Past',
																},
															);
														}}
														size="large"
														placeholder="Search ICD-10 Code"
													/>
												</XForm.Item>
											</XForm>
										</Col>
									)}
								</Row>
								{activeCode && activeCode.description && (
									<div className="titleBg">
										<Row
											gutter={(15, 15)}
											className="align-items-center"
										>
											<Col xs={24}>
												<span class="titleICD">
													{
														<>
															{activeCode?.type}:{' '}
															{activeCode?.code}
														</>
													}
												</span>
											</Col>
										</Row>
										{(!loadMore &&
											icdLoading &&
											!icdSearchLoading) ||
										(!cptLoadMore &&
											cptLoading &&
											!cptSearchLoading) ? (
											<>
												<Row
													gutter={(15, 15)}
													className="mt-3"
												>
													<Skeleton
														active
														paragraph={{
															rows: 1,
														}}
													/>
												</Row>
											</>
										) : (
											<>
												<Row
													gutter={(15, 15)}
													className="mt-3"
												>
													<Col xs={24}>
														<p className="icdPara3">
															{activeCode?.type ===
															'CPT'
																? cptCodes?.cpt_title ||
																  activeCode?.description
																: icdCodes?.icd_title ||
																  activeCode?.description}
														</p>
													</Col>
												</Row>
												{activeCode?.type === 'CPT' &&
													cptCodes?.long_description && (
														<Row
															gutter={(15, 15)}
															className="mt-2"
														>
															<Col xs={24}>
																<div className="icdPara2">
																	{
																		cptCodes?.long_description
																	}
																</div>
															</Col>
														</Row>
													)}
											</>
										)}
									</div>
								)}
								<div className="contentPadding">
									<div className="scrollDiv">
										<Row>
											<>
												{activeCode?.type === 'CPT' && (
													<Col xs={24}>
														{!cptLoadMore &&
														cptLoading &&
														!cptSearchLoading ? (
															<>
																<Skeleton
																	active
																	paragraph={{
																		rows: 2,
																	}}
																/>
															</>
														) : (
															<>
																{cptCodes &&
																	cptCodes.list &&
																	cptCodes
																		?.list
																		?.length >
																		0 && (
																		<Col
																			xs={
																				24
																			}
																			className="mb-3"
																		>
																			<span className="titleICD">
																				CPT
																				Codes
																			</span>
																		</Col>
																	)}
																{cptCodes &&
																	cptCodes.list &&
																	cptCodes
																		?.list
																		?.length >
																		0 &&
																	cptCodes?.list?.map(
																		(
																			cptCode,
																		) => (
																			<>
																				<XRelatedCard
																					variant="CPT"
																					className="mb-0"
																				>
																					<Row
																						gutter={
																							(15,
																							15)
																						}
																						className="align-items-center"
																					>
																						<Col
																							xs={
																								20
																							}
																							onClick={() => {
																								// Handle selection and fetch related codes
																								handleCodeClick(
																									'CPT',
																									{
																										code: cptCode.cpt_code,
																										description:
																											cptCode.cpt_title,
																										codeTypeText:
																											'recommendedProcedure',
																										buttontext:
																											'Add in Denial',
																									},
																								);
																							}}
																							role="button"
																						>
																							<span class="icdPara1">
																								{/* ICD10:150.9 */}
																								{
																									cptCode?.cpt_code
																								}
																							</span>
																						</Col>
																						<Col
																							xs={
																								4
																							}
																							className="text-end"
																						>
																							{!previewMode && (
																								<XButtonConfirm
																									className="btnAdd"
																									shape="circle"
																									icon={
																										<PlusOutlined />
																									}
																									type="text"
																									size="small"
																									onClick={() => {
																										setIcdModal(
																											true,
																										);
																										setModalOpen(
																											[
																												false,
																												cptCode?.cpt_code,
																												cptCode?.cpt_title,
																												null,
																												activeCode?.codeTypeText,
																											],
																										);
																										setModalTextArea(
																											`${cptCode?.cpt_title} - CPT Code: ${cptCode?.cpt_code}`,
																										);
																									}}
																								/>
																							)}
																						</Col>
																						<Col
																							xs={
																								24
																							}
																							className="my-1"
																						>
																							<div className="icdPara3">
																								{
																									cptCode?.cpt_title
																								}
																							</div>
																						</Col>
																					</Row>
																				</XRelatedCard>
																				<XRelatedReadMore>
																					<Row>
																						<Col
																							xs={
																								24
																							}
																						>
																							<div className="icdPara2">
																								<ReactReadMoreReadLess
																									charLimit={
																										0
																									}
																									ellipsis={
																										' '
																									}
																									readMoreText={
																										<>
																											<Row className="align-items-center">
																												<Col
																													xs={
																														12
																													}
																												>
																													View
																													More
																												</Col>
																												<Col
																													xs={
																														12
																													}
																													className="text-end"
																												>
																													<DownOutlined />
																												</Col>
																											</Row>
																										</>
																									}
																									readLessText={
																										<>
																											<Row className="align-items-center">
																												<Col
																													xs={
																														12
																													}
																												>
																													View
																													Less
																												</Col>
																												<Col
																													xs={
																														12
																													}
																													className="text-end"
																												>
																													<UpOutlined />
																												</Col>
																											</Row>
																										</>
																									}
																								>
																									{
																										cptCode?.long_description
																									}
																								</ReactReadMoreReadLess>
																							</div>
																						</Col>
																					</Row>
																				</XRelatedReadMore>
																			</>
																		),
																	)}
																{cptCodes &&
																	cptCodes.loaded_count <
																		cptCodes.total_count && (
																		<Row>
																			<Col
																				xs={
																					24
																				}
																				className="text-center"
																			>
																				<XViewMoreBTN
																					loading={
																						cptLoading
																					}
																					size="large"
																					type="primary"
																					onClick={() => {
																						// Handle selection and fetch related codes
																						handleLoadMore(
																							cptCodes.loaded_count,
																							'CPT',
																						);
																					}}
																				>
																					View
																					More
																				</XViewMoreBTN>
																			</Col>
																		</Row>
																	)}
															</>
														)}
													</Col>
												)}
												{activeCode?.type ===
													'ICD-10' && (
													<Col xs={24}>
														{activeCode &&
															activeCode.description && (
																<Row>
																	<Col
																		xs={24}
																		className="mb-3"
																	>
																		<span className="titleICD">
																			Related
																			Codes
																		</span>
																	</Col>
																</Row>
															)}
														{!loadMore &&
														icdLoading &&
														!icdSearchLoading ? (
															<>
																<Skeleton
																	active
																	paragraph={{
																		rows: 2,
																	}}
																/>
															</>
														) : (
															<>
																{icdCodes &&
																	icdCodes?.list &&
																	icdCodes
																		?.list
																		?.length >
																		0 &&
																	icdCodes?.list?.map(
																		(
																			relatedCode,
																			index,
																		) => (
																			<>
																				<XRelatedCard className="mb-3">
																					<Row
																						gutter={
																							(15,
																							15)
																						}
																						className="align-items-center"
																					>
																						<Col
																							xs={
																								20
																							}
																							onClick={() => {
																								// Handle selection and fetch related codes
																								handleCodeClick(
																									'ICD-10',
																									{
																										code: relatedCode.icd_code,
																										description:
																											relatedCode.icd_title,
																										codeTypeText:
																											'pastHistory',
																										buttontext:
																											'Add in Past',
																									},
																								);
																							}}
																							role="button"
																						>
																							<span class="icdPara1">
																								{
																									relatedCode?.icd_code
																								}
																							</span>
																						</Col>
																						<Col
																							xs={
																								4
																							}
																							className="text-end"
																						>
																							{!previewMode && (
																								<XButtonConfirm
																									className="btnAdd"
																									shape="circle"
																									icon={
																										<PlusOutlined />
																									}
																									type="text"
																									size="small"
																									onClick={() => {
																										setIcdModal(
																											true,
																										);
																										setModalOpen(
																											[
																												false,
																												relatedCode?.icd_code,
																												relatedCode?.icd_title,
																												null,
																												activeCode?.codeTypeText,
																											],
																										);
																										setModalTextArea(
																											`${relatedCode?.icd_title} - ICD-10 Code: ${relatedCode?.icd_code}`,
																										);
																									}}
																								/>
																							)}
																						</Col>
																						<Col
																							xs={
																								24
																							}
																							className="my-1"
																						>
																							<div className="icdPara3">
																								{
																									relatedCode?.icd_title
																								}
																							</div>
																						</Col>
																					</Row>
																				</XRelatedCard>
																			</>
																		),
																	)}
																{icdCodes &&
																	icdCodes?.total_count ==
																		0 &&
																	activeCode?.type !==
																		'CPT' && (
																		<XRelatedCard
																			className="mb-3"
																			style={{
																				borderRadius:
																					'10px',
																			}}
																		>
																			<Row
																				gutter={
																					(15,
																					15)
																				}
																				className="align-items-center"
																			>
																				<Col
																					xs={
																						24
																					}
																				>
																					<div className="icdPara3">
																						There
																						are
																						no
																						codes
																						available
																						for
																						the
																						above
																						ICD-10
																						Code
																					</div>
																				</Col>
																			</Row>
																		</XRelatedCard>
																	)}
																{icdCodes &&
																	icdCodes.loaded_count <
																		icdCodes.total_count && (
																		<Row>
																			<Col
																				xs={
																					24
																				}
																				className="text-center"
																			>
																				<XViewMoreBTN
																					loading={
																						icdLoading
																					}
																					size="large"
																					type="primary"
																					onClick={() => {
																						// Handle selection and fetch related codes
																						handleLoadMore(
																							icdCodes.loaded_count,
																						);
																					}}
																				>
																					View
																					More
																				</XViewMoreBTN>
																			</Col>
																		</Row>
																	)}
															</>
														)}
													</Col>
												)}
											</>
										</Row>
									</div>
								</div>
							</XcaseAnsyCardScroll>
						)}
					</XAffix>
				</>
			)}

			{/* ICD and CPT Modal 21.09.2024 */}
			{!previewMode && (
				<XICDModal
					centered
					open={icdModal}
					onCancel={() => {
						setIcdModal(false);
						setModalOpen([false, '', ' ']);
					}}
					footer={null}
					closeIcon={
						<img src="/images/modal-close-icon.svg" alt="Close" />
					}
					zIndex={9999}
				>
					<Row gutter={(15, 15)} className="mt-2">
						<Col xs={4} lg={4}>
							<div className="icdIcons">
								<XButtonModal
									shape="circle"
									size="small"
									icon={<PlusOutlined />}
								/>
							</div>
						</Col>
						<Col xs={20}>
							<div className="modalTitle">
								{activeCode?.type}: {modalOpen[1]}
							</div>
							<div className="modalTitleSub mt-1 mb-2">
								{modalOpen[2]}
							</div>
							<div className="modalParagraph">
								Are you sure you want to add this code?
							</div>
						</Col>
					</Row>
					<Row gutter={(15, 15)} className="mt-4 justify-content-end">
						{activeCode?.type === 'ICD-10' && (
							<>
								<Col xs={12} className="text-end">
									<XICDModalFooterBTN
										size="large"
										type="primary"
										onClick={() => {
											setIcdModal(false);
											updatePageContent(
												'pastHistory',
												modalOpen[3],
												modalTextArea,
											);
										}}
									>
										Add in Past
									</XICDModalFooterBTN>
								</Col>
								<Col xs={12}>
									<XICDModalFooterBTN
										size="large"
										type="primary"
										onClick={() => {
											setIcdModal(false);
											updatePageContent(
												'presentComplaint',
												modalOpen[3],
												modalTextArea,
											);
										}}
									>
										Add in Present
									</XICDModalFooterBTN>
								</Col>
							</>
						)}
						{activeCode?.type === 'CPT' && (
							<>
								<Col xs={12} className="text-end">
									<XICDModalFooterBTN
										size="large"
										type="primary"
										onClick={() => {
											setIcdModal(false);
											updatePageContent(
												'recommendedProcedure',
												modalOpen[3],
												modalTextArea,
											);
										}}
									>
										Add in Denial Procedure
									</XICDModalFooterBTN>
								</Col>
							</>
						)}
					</Row>
				</XICDModal>
			)}
			{/* Coding Tools End */}
		</>
	);
};

const mapStateToProps = (state) => ({
	getIcdCodesState: state.getIcdCodes,
	getCptCodesState: state.getCptCodes,
});

const mapDispatchToProps = (dispatch) => ({
	getIcdCodes: (data) => dispatch(getIcdCodesApi(data)),
	getIcdCodesReset: () => dispatch(getIcdCodesReset()),
	getCptCodes: (data) => dispatch(getCptCodesApi(data)),
	getCptCodesReset: () => dispatch(getCptCodesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerCodingTools);
