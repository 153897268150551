import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAIL = 'ADD_MESSAGE_FAIL';
export const ADD_MESSAGE_RESET = 'ADD_MESSAGE_RESET';

export const addMessage = (params) => {
	return {
		type: ADD_MESSAGE,
		params,
	};
};

export const addMessageSuccess = (response) => {
	return {
		type: ADD_MESSAGE_SUCCESS,
		response,
	};
};

export const addMessageFail = (response) => {
	return {
		type: ADD_MESSAGE_FAIL,
		response,
	};
};

export const addMessageReset = () => {
	return {
		type: ADD_MESSAGE_RESET,
	};
};

export const addMessageApi = (data) => {
	return (dispatch) => {
		dispatch(addMessage());
		axios
			.post(`${config.api.base_url}/chat/create`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addConfigurationData = response.data;
				dispatch(addMessageSuccess(addConfigurationData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addMessageFail(errorMsg));
			});
	};
};
