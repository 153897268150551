import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CPT_CODES = 'GET_CPT_CODES';
export const GET_CPT_CODES_SUCCESS = 'GET_CPT_CODES_SUCCESS';
export const GET_CPT_CODES_FAIL = 'GET_CPT_CODES_FAIL';
export const GET_CPT_CODES_RESET = 'GET_CPT_CODES_RESET';

export const getCptCodes = (params) => {
	return {
		type: GET_CPT_CODES,
		params,
	};
};

export const getCptCodesSuccess = (response) => {
	return {
		type: GET_CPT_CODES_SUCCESS,
		response,
	};
};

export const getCptCodesFail = (response) => {
	return {
		type: GET_CPT_CODES_FAIL,
		response,
	};
};

export const getCptCodesReset = () => {
	return {
		type: GET_CPT_CODES_RESET,
	};
};

export const getCptCodesApi = (data) => {
	return (dispatch) => {
		dispatch(getCptCodes());
		axios
			.get(`${config.api.base_url}/codes/cptCodes`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getCptCodesData = response.data;
				dispatch(getCptCodesSuccess(getCptCodesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCptCodesFail(errorMsg));
			});
	};
};
