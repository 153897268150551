import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Result, Radio, Input, notification } from 'antd';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XForm,
	XContainer,
	XButton,
	FilterButton,
	XSearch,
	XSelect,
	OutlineCircle,
} from '../../styles/GlobalStyle';
import { RightOutlined } from '@ant-design/icons';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
// actions

import {
	getHospitalDropdownApi,
	getHospitalDropdownReset,
} from '../../actions/getHospitalDropdownAction';

import {
	editPayerConfigurationApi,
	editPayerConfigurationReset,
} from '../../actions/editPayerConfigurationAction';

import {
	checkPayerConfigurationApi,
	checkPayerConfigurationReset,
} from '../../actions/checkPayerConfigurationAction';

import {
	getInsuranceCompaniesApi,
	getInsuranceCompaniesReset,
} from '../../actions/getInsuranceCompaniesAction';

import { checkModulePermissions, truncateString } from '../../utils/helper';
import { dateFilters } from '../../utils/constants';
import HeaderFile from '../../components/Header/HeaderFile';
import DataTable from '../../components/DataTable/DataTable';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import { filterByLabel } from '../../utils/formFunctions';
import { FormDataContext } from './PayerConfigurationForm';

const Step1 = (props) => {
	const {
		getHospitalDropdown,
		getHospitalDropdownState,
		getInsuranceCompanies,
		getInsuranceCompaniesState,
		editPayerConfiguration,
		editPayerConfigurationReset,
		editPayerConfigurationState,
		checkPayerConfiguration,
		checkPayerConfigurationReset,
		checkPayerConfigurationState,
	} = props;

	const {
		form,
		formDataState,
		currentState,
		editModeState,
		hospitalOptionsState,
		insuranceCompanyOptionsState,
	} = useContext(FormDataContext);

	useEffect(() => {
		if (editPayerConfigurationState.apiState == 'success') {
			notification.success({
				message: editPayerConfigurationState.message,
			});
			editPayerConfigurationReset();
		} else if (editPayerConfigurationState.apiState == 'error') {
			notification.error({
				message: editPayerConfigurationState.message,
			});
			editPayerConfigurationReset();
		}
	}, [editPayerConfigurationState]);

	useEffect(() => {
		if (getHospitalDropdownState.apiState == 'success') {
			if (getHospitalDropdownState.data.hospitals?.length > 0) {
				let hospitals = [];

				getHospitalDropdownState.data.hospitals.forEach((element) => {
					hospitals.push({
						label: element.name,
						value: element._id,
					});
				});

				hospitalOptionsState.setHospitalOptions(hospitals);
			}
		}
	}, [getHospitalDropdownState]);

	useEffect(() => {
		if (getInsuranceCompaniesState.apiState == 'success') {
			if (getInsuranceCompaniesState.data.insurances?.length > 0) {
				let insuranceCompanies = [];

				getInsuranceCompaniesState.data.insurances.forEach(
					(element) => {
						insuranceCompanies.push({
							label: element.name,
							value: element._id,
						});
					},
				);

				insuranceCompanyOptionsState.setInsuranceCompanyOptions(
					insuranceCompanies,
				);
			}
		}
	}, [getInsuranceCompaniesState]);

	useEffect(() => {
		if (checkPayerConfigurationState.apiState == 'success') {
			formDataState.setFormData((old) => {
				return { ...old, ...form.getFieldValues() };
			});
			currentState.setCurrent((old) => {
				return old + 1;
			});
		} else if (checkPayerConfigurationState.apiState == 'error') {
			notification.error({
				message: checkPayerConfigurationState.message,
			});
			checkPayerConfigurationReset();
		}
	}, [checkPayerConfigurationState]);

	useEffect(() => {
		getHospitalDropdown();
		getInsuranceCompanies();
	}, []);

	const handleNext = () => {
		form.validateFields([
			'hospital',
			'insurance_company',
			'interest_rate',
			'appeal_letter_approval_threshold',
		])
			.then((values) => {
				if (editModeState.editMode) {
					formDataState.setFormData((old) => {
						return { ...old, ...values };
					});
					currentState.setCurrent((old) => {
						return old + 1;
					});
				} else {
					checkPayerConfiguration(values);
				}
			})
			.catch((error) => {
				return;
			});
	};

	const handleUpdate = () => {
		form.validateFields([
			'hospital',
			'insurance_company',
			'interest_rate',
			'appeal_letter_approval_threshold',
		])
			.then((values) => {
				formDataState.setFormData((old) => {
					return { ...old, ...values };
				});

				editPayerConfiguration({
					update_type: 'basic',
					id: formDataState.formData.id,
					...values,
				});
			})
			.catch((error) => {
				return;
			});
	};
	return (
		<>
			<XForm
				form={form}
				name="timelineConfigFrom"
				layout="vertical"
				autoComplete="off"
			>
				<Row gutter={(15, 30)} className="mt-4">
					<Col xs={24} lg={8} md={12}>
						<XForm.Item
							name="hospital"
							label="Hospital"
							rules={[
								{
									required: true,
									message: 'Please select the Hospital',
								},
							]}
						>
							<XSelect
								size="large"
								placeholder="Select Hospital"
								name="hospital"
								allowClear
								className="filter-sub-item"
								showSearch={true}
								// onFocus={() => {
								//     getHospitalDropdown();
								// }}
								onSearch={(search) => {
									if (search.length >= 3) {
										getHospitalDropdown({
											search,
										});
									} else if (search.length === 0) {
										getHospitalDropdown();
									}
								}}
								filterOption={filterByLabel}
								options={hospitalOptionsState.hospitalOptions}
							></XSelect>
						</XForm.Item>
					</Col>
					<Col xs={24} lg={8} md={12}>
						<XForm.Item
							name="insurance_company"
							label="Insurance Company"
							rules={[
								{
									required: true,
									message:
										'Please select the Insurance Company',
								},
							]}
						>
							<XSelect
								size="large"
								placeholder="Select Insurance Company"
								name="insurance_company"
								allowClear
								className="filter-sub-item"
								showSearch={true}
								// onFocus={() => {
								//     getInsuranceCompanies();
								// }}
								onSearch={(search) => {
									if (search.length >= 3) {
										getInsuranceCompanies({
											search,
										});
									} else if (search.length === 0) {
										getInsuranceCompanies();
									}
								}}
								filterOption={filterByLabel}
								options={
									insuranceCompanyOptionsState.insuranceCompanyOptions
								}
							></XSelect>
						</XForm.Item>
					</Col>
					<Col xs={24} lg={8} md={12}>
						<XForm.Item
							name="interest_rate"
							label="Interest Rate"
							rules={[
								{
									required: true,
									message: 'Please enter interest rate',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Interest Rate"
								name="interest_rate"
								onChange={(e) => {
									return true;
								}}
								type="number"
							/>
						</XForm.Item>
					</Col>
					<Col xs={24} lg={8} md={12}>
						<XForm.Item
							name="appeal_letter_approval_threshold"
							label="Appeal Letter Approval Threshold"
							rules={[
								{
									required: true,
									message:
										'Please enter appeal letter approval threshold',
								},
							]}
						>
							<Input
								size="large"
								placeholder="Enter Appeal Letter Approval Threshold"
								name="appeal_letter_approval_threshold"
								onChange={(e) => {
									return true;
								}}
								type="number"
							/>
						</XForm.Item>
					</Col>
				</Row>
				<Row gutter={[15, 5]}>
					{editModeState.editMode ? (
						<Col xs={24} className="text-end">
							<Button
								style={{ color: '#fff', background: '#007299' }}
								onClick={handleUpdate}
							>
								Update
							</Button>
							<div
								style={{
									display: 'inline-block',
									marginLeft: '15px',
									cursor: 'pointer',
								}}
								onClick={handleNext}
							>
								{/* <OutlineCircle> */}
								<RightOutlined
									style={{
										color: '#007299',
										fontSize: '20px',
									}}
								/>
								{/* </OutlineCircle> */}
							</div>
						</Col>
					) : (
						<Col xs={24} className="text-end">
							<></>
							<Button
								style={{ color: '#fff', background: '#007299' }}
								onClick={handleNext}
							>
								Next
							</Button>
						</Col>
					)}
				</Row>
			</XForm>
		</>
	);
};

const mapStateToProps = (state) => ({
	getHospitalDropdownState: state.getHospitalDropdown,
	getInsuranceCompaniesState: state.getInsuranceCompanies,
	editPayerConfigurationState: state.editPayerConfiguration,
	checkPayerConfigurationState: state.checkPayerConfiguration,
});

const mapDispatchToProps = (dispatch) => ({
	getHospitalDropdown: (data) => dispatch(getHospitalDropdownApi(data)),
	getHospitalDropdownReset: () => dispatch(getHospitalDropdownReset()),
	getInsuranceCompanies: (data) => dispatch(getInsuranceCompaniesApi(data)),
	getInsuranceCompaniesReset: () => dispatch(getInsuranceCompaniesReset()),
	editPayerConfiguration: (data) => dispatch(editPayerConfigurationApi(data)),
	editPayerConfigurationReset: () => dispatch(editPayerConfigurationReset()),
	checkPayerConfiguration: (data) =>
		dispatch(checkPayerConfigurationApi(data)),
	checkPayerConfigurationReset: () =>
		dispatch(checkPayerConfigurationReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
