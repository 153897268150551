import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CASE_CHART_DETAILS = 'GET_CASE_CHART_DETAILS';
export const GET_CASE_CHART_DETAILS_SUCCESS = 'GET_CASE_CHART_DETAILS_SUCCESS';
export const GET_CASE_CHART_DETAILS_FAIL = 'GET_CASE_CHART_DETAILS_FAIL';
export const GET_CASE_CHART_DETAILS_RESET = 'GET_CASE_CHART_DETAILS_RESET';

export const getCaseChartDetails = (params) => {
	return {
		type: GET_CASE_CHART_DETAILS,
		params,
	};
};

export const getCaseChartDetailsSuccess = (response) => {
	return {
		type: GET_CASE_CHART_DETAILS_SUCCESS,
		response,
	};
};

export const getCaseChartDetailsFail = (response) => {
	return {
		type: GET_CASE_CHART_DETAILS_FAIL,
		response,
	};
};

export const getCaseChartDetailsReset = () => {
	return {
		type: GET_CASE_CHART_DETAILS_RESET,
	};
};

export const getCaseChartDetailsApi = (data) => {
	return (dispatch) => {
		dispatch(getCaseChartDetails());
		axios
			.get(`${config.api.base_url}/dashboard/get-case-chart-details`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getCaseChartDetailsData = response.data;
				dispatch(getCaseChartDetailsSuccess(getCaseChartDetailsData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCaseChartDetailsFail(errorMsg));
			});
	};
};
