import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_ORGANIZATION_ROLE = 'DELETE_ORGANIZATION_ROLE';
export const DELETE_ORGANIZATION_ROLE_SUCCESS =
	'DELETE_ORGANIZATION_ROLE_SUCCESS';
export const DELETE_ORGANIZATION_ROLE_FAIL = 'DELETE_ORGANIZATION_ROLE_FAIL';
export const DELETE_ORGANIZATION_ROLE_RESET = 'DELETE_ORGANIZATION_ROLE_RESET';

export const deleteOrganizationRole = (params) => {
	return {
		type: DELETE_ORGANIZATION_ROLE,
		params,
	};
};

export const deleteOrganizationRoleSuccess = (response) => {
	return {
		type: DELETE_ORGANIZATION_ROLE_SUCCESS,
		response,
	};
};

export const deleteOrganizationRoleFail = (response) => {
	return {
		type: DELETE_ORGANIZATION_ROLE_FAIL,
		response,
	};
};

export const deleteOrganizationRoleReset = () => {
	return {
		type: DELETE_ORGANIZATION_ROLE_RESET,
	};
};

export const deleteOrganizationRoleApi = (data) => {
	return (dispatch) => {
		dispatch(deleteOrganizationRole());
		axios
			.patch(`${config.api.base_url}/organization-role-mapping`, null, {
				headers: { Authorization: 'Bearer ' + getUserToken() },
				params: data,
			})
			.then((response) => {
				const deleteOrganizationRoleData = response.data;
				dispatch(
					deleteOrganizationRoleSuccess(deleteOrganizationRoleData),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteOrganizationRoleFail(errorMsg));
			});
	};
};
