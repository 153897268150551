import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'GET_NOTIFICATION_FAIL';
export const GET_NOTIFICATION_RESET = 'GET_NOTIFICATION_RESET';

export const getNotification = (params) => {
	return {
		type: GET_NOTIFICATION,
		params,
	};
};

export const getNotificationSuccess = (response) => {
	return {
		type: GET_NOTIFICATION_SUCCESS,
		response,
	};
};

export const getNotificationFail = (response) => {
	return {
		type: GET_NOTIFICATION_FAIL,
		response,
	};
};

export const getNotificationReset = () => {
	return {
		type: GET_NOTIFICATION_RESET,
	};
};

export const getNotificationApi = () => {
	return (dispatch) => {
		dispatch(getNotification());
		axios
		.get(`${config.api.base_url}/notification/get-notification`, {
			headers: {
				Authorization: 'Bearer ' + getUserToken(),
			},
		}).then((response) => {
				const GET_NOTIFICATIONData = response.data;
				dispatch(getNotificationSuccess(GET_NOTIFICATIONData));
			})
			.catch((error) => {
				dispatch(getNotificationFail(error.response.data));
			});
	};
};
