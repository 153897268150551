import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XContainer, XForm } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { Row, Col, Button, Input, notification } from 'antd';
import { XCardsProfileBg } from '../Profile/ProfileStyle';
// actions
import {
	changePasswordApi,
	changePasswordReset,
} from '../../actions/changePasswordAction';
import { handleInputChange } from '../../utils/formFunctions';

const ChangePassword = (props) => {
	const { changePassword, changePasswordReset, changePasswordState } = props;
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});

	// use effects
	useEffect(() => {
		if (changePasswordState.apiState === 'success') {
			notification.success({ message: changePasswordState.message });
			changePasswordReset();
			form.resetFields();
		} else if (changePasswordState.apiState === 'error') {
			notification.error({ message: changePasswordState.message });
			changePasswordReset();
		}
	}, [changePasswordState]);
	return (
		<>
			<HeaderFile />
			<XContainer fluid>
				<XCardsProfileBg>
					<div className="profileHead"></div>
					<XForm
						form={form}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
						onFinish={() => changePassword(formData)}
					>
						<div className="innerBody mt-4">
							<Row gutter={(15, 30)}>
								<Col
									xs={{ span: 24, offset: 0 }}
									sm={{ span: 24, offset: 0 }}
									lg={{ span: 8, offset: 8 }}
								>
									<Row className="mb-5 pb-5">
										<Col xs={24}>
											<XForm.Item
												name="password"
												label="Old Password"
												rules={[
													{
														required: true,
														message:
															'Please enter your old password',
													},
												]}
											>
												<Input
													size="large"
													placeholder="Enter your old password"
													name="password"
													type="password"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24}>
											<XForm.Item
												name="new_password"
												label="New Password"
												rules={[
													{
														required: true,
														message:
															'Please input your password!',
													},
													{
														min: 8,
														message:
															'Password must be at least 8 characters long!',
													},
													{
														pattern:
															/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
														message:
															'Password must include at least one letter, one number, and one special character!',
													},
												]}
											>
												<Input
													size="large"
													placeholder="Enter your password"
													name="new_password"
													type="password"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24}>
											<XForm.Item
												name="confirm_password"
												label="Confirm New Password"
												dependencies={['new_password']}
												hasFeedback
												rules={[
													{
														required: true,
														message:
															'Please confirm your new password',
													},
													({ getFieldValue }) => ({
														validator(_, value) {
															if (
																!value ||
																getFieldValue(
																	'new_password',
																) == value
															) {
																return Promise.resolve();
															}
															return Promise.reject(
																new Error(
																	'The two passwords that you entered do not match!',
																),
															);
														},
													}),
												]}
											>
												<Input
													size="large"
													placeholder="Confirm new password"
													name="confirm_password"
													type="password"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col
											xs={24}
											style={{
												display: 'flex',
												flexDirection: 'row',
											}}
											className="text-center"
										>
											<Button
												onClick={() => navigate(-1)}
												className="cancel"
											>
												Cancel
											</Button>
											<Button
												htmlType="submit"
												type="primary"
											>
												Submit
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</XForm>
				</XCardsProfileBg>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	changePasswordState: state.changePassword,
});

const mapDispatchToProps = (dispatch) => ({
	changePassword: (data) => dispatch(changePasswordApi(data)),
	changePasswordReset: () => dispatch(changePasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
