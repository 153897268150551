import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification, Result, Divider } from 'antd';
import { Button, Input, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
	XForm,
	XContainer,
	XSelect,
	XCardsTransparent,
	XUploadCustom,
} from '../../styles/GlobalStyle';
import axios from 'axios';
import { UploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// actions
import {
	getInsuranceCompaniesApi,
	getInsuranceCompaniesReset,
} from '../../actions/getInsuranceCompaniesAction';
import {
	addInsuranceCompanyApi,
	addInsuranceCompanyReset,
} from '../../actions/addInsuranceComapnyAction';
import {
	editInsuranceCompanyApi,
	editInsuranceCompanyReset,
} from '../../actions/editInsuranceCompanyAction';
import {
	deleteInsuranceCompanyApi,
	deleteInsuranceCompanyReset,
} from '../../actions/deleteInsuranceCompanyAction';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import { checkModulePermissions, getUserToken } from '../../utils/helper';
import HeaderFile from '../../components/Header/HeaderFile';
import Header from '../../components/Header/Header';
import {
	filterByLabel,
	handleInputChange,
	lowerCaseInput,
	validateMobileNumber,
} from '../../utils/formFunctions';
import {
	getInsuranceCompanyByIdApi,
	getInsuranceCompanyByIdReset,
} from '../../actions/getInsuranceCompanyByIdAction';
import TextArea from 'antd/es/input/TextArea';
import {
	getStatesListApi,
	getStatesListReset,
} from '../../actions/getStatesListAction';
import config from '../../config';
import { XButtonConfirm } from '../AnalyseCoverage/AnalyseCoverageStyle';
import {
	XButtonBack,
	XCardBackBg,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';

const InsuranceCompanyForm = (props) => {
	const navigate = useNavigate();
	const id = useParams().id;
	const {
		editInsuranceCompany,
		editInsuranceCompanyState,
		addInsuranceCompanyState,
		addInsuranceCompany,
		getInsuranceCompanyByIdState,
		getInsuranceCompanyByIdReset,
		getInsuranceCompanyById,
		addInsuranceCompanyReset,
		editInsuranceCompanyReset,
		deleteInsuranceCompany,
		deleteInsuranceCompanyState,
		deleteInsuranceCompanyReset,
		getStateDropdownState,
		getStateDropdown,
		getStatesList,
		getStatesListState,
	} = props;
	let modulePermissions = checkModulePermissions(
		'insurance-company-management',
	);
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	console.log('formData', formData);
	// const [editCompanyActive, setEditCompanyActive] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [fileChanged, setFileChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const handleSubmit = async () => {
		try {
			let newFile = {};
			await form.validateFields(); // Wait for form validation

			if (fileList.length > 0 && fileChanged) {
				await Promise.all(
					fileList.map(async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=insurance_companies&file_name=${formData.name}&ext=${ext}&id=${fileData.originFileObj.uid}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);

						if (result.status === 200) {
							const documentPath = `/insurance_companies/${fileData.originFileObj.uid}/${formData.name}.${ext}`;
							newFile = {
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
						}
					}),
				);
				console.log('Files Uploaded');
			}

			// if (!fileChanged) {
			// 	// Create a URL object and extract the pathname to give old file path without adding s3 url when edit
			// 	let file = fileList[0];
			// 	const url = new URL(file.url);
			// 	const exactDocPath = url.pathname;
			// 	fileList[0].url = exactDocPath;
			// }

			if (formData.id) {
				await editInsuranceCompany({
					...formData,
					...(fileChanged
						? { logo: newFile }
						: { logo: formData.logo }),
					// : { logo: fileList[0] }),
				});
			} else {
				await addInsuranceCompany({
					...formData,
					...(fileChanged
						? { logo: newFile }
						: { logo: fileList[0] }),
				});
			}
		} catch (error) {
			// Handle error if any
			notification.error({
				message: 'An error occurred',
			});
			console.error('Error occurred:', error);
		}
	};

	const handleState = (name, value) => {
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleChange = (info) => {
		let newFileList = [...info.fileList];

		// 1. Limit the number of uploaded files
		// Only to show two recent uploaded files, and old ones will be replaced by the new
		newFileList = newFileList.slice(-2);

		// 2. Read from response and show file link
		newFileList = newFileList.map((file) => {
			if (file.response) {
				// Component will show file.url as link
				file.url = file.response.url;
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileChanged(true);
		setFileList(newFileList);
	};

	useEffect(() => {
		if (editInsuranceCompanyState.apiState === 'success') {
			setSubmitLoading(false);
			form.resetFields();
			setFormData({});
			setFileList([]);
			setFileChanged(false);
			editInsuranceCompanyReset();
			// getInsuranceCompanies();
			notification.success({
				message: editInsuranceCompanyState.message,
			});
			navigate(-1);
			// setModalOpen([false, '']);
		} else if (editInsuranceCompanyState.apiState === 'error') {
			notification.error({
				message: editInsuranceCompanyState.message,
			});
			editInsuranceCompanyReset();
			navigate(-1);
		}
	}, [editInsuranceCompanyState]);

	useEffect(() => {
		if (addInsuranceCompanyState.apiState === 'success') {
			setSubmitLoading(false);
			form.resetFields();
			setFormData({});
			setFileList([]);
			setFileChanged(false);

			addInsuranceCompanyReset();
			notification.success({
				message: addInsuranceCompanyState.message,
			});
			navigate(-1);
			// setModalOpen([false, '']);
		} else if (addInsuranceCompanyState.apiState === 'error') {
			notification.error({
				message: 'Error is adding Provider.',
			});
			addInsuranceCompanyReset();
			navigate(-1);
		}
	}, [addInsuranceCompanyState]);

	useEffect(() => {
		console.log('form in effect', formData);
		if (id) {
			setLoading(true);
			getInsuranceCompanyById({ id: id });
		}
		// localStorage.getItem('role') === 'organization-admin' && getRegions();
		// getStateDropdown();
		// getOrganizations();
	}, [id]);
	console.log(
		'getInsuranceCompanyByIdState----',
		getInsuranceCompanyByIdState,
	);

	useEffect(() => {
		if (getInsuranceCompanyByIdState.apiState === 'success') {
			setFormData({
				id: getInsuranceCompanyByIdState.data._id,
				name: getInsuranceCompanyByIdState.data.name,
				contact_person_email_address:
					getInsuranceCompanyByIdState.data
						.contact_person_email_address,
				contact_person_mobile_number:
					getInsuranceCompanyByIdState.data
						.contact_person_mobile_number,
				fax_number: getInsuranceCompanyByIdState.data.fax_number,
				address: getInsuranceCompanyByIdState.data.address,
				functional_in_states:
					getInsuranceCompanyByIdState.data.functional_in_states,
				hq_state: getInsuranceCompanyByIdState.data.hq_state,
				pincode: getInsuranceCompanyByIdState.data.pincode,
				city: getInsuranceCompanyByIdState.data.city,
				updatedAt: getInsuranceCompanyByIdState.data.updatedAt,
				logo: getInsuranceCompanyByIdState.data.logo,
			});
			form.setFieldsValue({
				id: getInsuranceCompanyByIdState.data._id,
				name: getInsuranceCompanyByIdState.data.name,
				contact_person_email_address:
					getInsuranceCompanyByIdState.data
						.contact_person_email_address,
				contact_person_mobile_number:
					getInsuranceCompanyByIdState.data
						.contact_person_mobile_number,
				fax_number: getInsuranceCompanyByIdState.data.fax_number,
				address: getInsuranceCompanyByIdState.data.address,
				functional_in_states:
					getInsuranceCompanyByIdState.data.functional_in_states,
				hq_state: getInsuranceCompanyByIdState.data.hq_state,

				pincode: getInsuranceCompanyByIdState.data.pincode,
				city: getInsuranceCompanyByIdState.data.city,
				updatedAt: getInsuranceCompanyByIdState.data.updatedAt,
			});
			if (getInsuranceCompanyByIdState.data.logo !== null) {
				const updatedLogo = {
					...getInsuranceCompanyByIdState.data.logo,
					url: `${config.s3_url}${getInsuranceCompanyByIdState.data.logo.url}`,
				};
				setFileList([updatedLogo]);
			}
			setLoading(false);
			getInsuranceCompanyByIdReset();
		} else if (getInsuranceCompanyByIdState.apiState === 'error') {
			notification.error({
				message: 'Error in get insurance company.',
			});
			getInsuranceCompanyByIdReset();
			navigate(-1);
		}
	}, [getInsuranceCompanyByIdState]);

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Insurance Company" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="insuranceCompany" />
					</Col>
				</>
			)}
			<Spin spinning={loading}>
				<XContainer fluid>
					<XCardBackBg className="mb-3">
						<Row>
							<Col xs={24}>
								<XButtonBack
									onClick={() => {
										form.resetFields();
										setFormData({});
										setFileList([]);
										navigate(-1);
									}}
									icon={<ArrowLeftOutlined />}
									size="large"
								/>
								{id ? (
									<XEditTitle>
										Edit Insurance Company
									</XEditTitle>
								) : (
									<XEditTitle>
										Add Insurance Company
									</XEditTitle>
								)}{' '}
							</Col>
						</Row>
					</XCardBackBg>

					<XCardsTransparent>
						{checkModulePermissions(
							'insurance-company-management',
						).sub_modules.includes(
							'add-insurance-companies',
							'update-insurance-companies',
						) ? (
							<>
								<XForm
									form={form}
									name="roleForm"
									layout="vertical"
									autoComplete="off"
									onFinish={() => {
										// editCompanyActive
										// 	? updateInsuranceComapnySubmit()
										// 	: addInsuranceComapnySubmit();
										setSubmitLoading(true);
										handleSubmit();
									}}
								>
									<Row gutter={(15, 30)} className="mt-4">
										<Col xs={24} lg={3} md={6}>
											<XForm.Item
												name="logo"
												label="Upload Logo"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																fileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please add logo!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<XUploadCustom
													listType="picture-card"
													maxCount={1}
													accept="image/png, image/jpeg, image/jpg"
													fileList={fileList}
													showUploadList={{
														showPreviewIcon: false,
														showRemoveIcon: true,
													}}
													onChange={handleChange}
												>
													<UploadOutlined />
													Upload
												</XUploadCustom>
											</XForm.Item>
										</Col>
										<Divider />
										<Col xs={24} lg={12}>
											<XForm.Item
												name="name"
												label="Name"
												rules={[
													{
														required: true,
														message:
															'Please enter Company name',
													},
												]}
											>
												<Input
													size="large"
													placeholder="Enter Company Name"
													name="name"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="contact_person_email_address"
												label="Contact Person Email Address"
												rules={[
													{
														type: 'email',
														message:
															'Please enter a valid email address',
													},
												]}
											>
												<Input
													size="large"
													placeholder="Enter Contact Person Email Address"
													name="contact_person_email_address"
													onInput={lowerCaseInput}
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="contact_person_mobile_number"
												label="Contact Person Mobile Number"
												rules={[
													{
														validator:
															validateMobileNumber,
													},
												]}
											>
												<Input
													size="large"
													placeholder="Enter Contact Person Mobile Number"
													name="contact_person_mobile_number"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="fax_number"
												label="Fax Number"
											>
												<Input
													size="large"
													placeholder="Enter Fax Number"
													name="fax_number"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										{/* <Col xs={24} lg={12}>
										<XForm.Item
											name="logo"
											label="Logo"
											rules={[
												{
													required: true,
													message: 'Please Add Logo',
												},
											]}
										>
											<Upload
												size="large"
												fileList={fileList}
												onChange={handleChange}
												multiple={false}
												maxCount={1}
											>
												<Button
													icon={
														<UploadOutlined
															style={{
																marginRight:
																	'3px',
															}}
														/>
													}
													size="large"
													style={{
														width: '100%',
														borderRadius: '10px',
														height: '67px',
														background: '#fff',
														color: '#212226',
														marginLeft: '0px',
														//
														// display: 'flex',
														// flexDirection: 'column',
														// alignItems: 'center',
														// justifyContent: 'center',
													}}
												>
													Upload
												</Button>
											</Upload>
										</XForm.Item>
									</Col> */}
										<Col xs={24} lg={12}>
											<XForm.Item
												name="address"
												label="Address"
											>
												<TextArea
													size="large"
													placeholder="Enter Address"
													name="address"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="hq_state"
												label={'HQ State'}
											>
												<XSelect
													size="large"
													placeholder="Select State"
													name="hq_state"
													showSearch={true}
													className="removeBg"
													defaultValue={
														formData.hq_state
													}
													onFocus={() => {
														getStatesList();
													}}
													// onSearch={(search) => {
													// 	if (
													// 		search.length >= 3
													// 	) {
													// 		getStatesList({
													// 			search,
													// 		});
													// 	} else if (
													// 		search.length === 0
													// 	) {
													// 		getStatesList();
													// 	}
													// }}
													filterOption={filterByLabel}
													options={
														getStatesListState.apiState ===
															'success' &&
														getStatesListState.data
															.all_states
															?.length > 0 &&
														getStatesListState.data.all_states.map(
															(itm) => {
																return {
																	label: itm.state,
																	value: itm.state,
																	providers:
																		itm.providers,
																};
															},
														)
													}
													onChange={(
														value,
														options,
													) => {
														handleState(
															'hq_state',
															value,
														);
													}}
												></XSelect>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="functional_in_states"
												label={'Functional In States'}
											>
												<XSelect
													size="large"
													mode="multiple"
													placeholder="Select States"
													name="functional_in_states"
													maxTagCount={
														(3, 'responsive')
													}
													showSearch={true}
													className="removeBg"
													defaultValue={
														formData.functional_in_states
													}
													onFocus={() => {
														getStatesList();
													}}
													onSearch={(search) => {
														if (
															search.length >= 3
														) {
															getStatesList({
																search,
															});
														} else if (
															search.length === 0
														) {
															getStatesList();
														}
													}}
													filterOption={filterByLabel}
													options={
														getStatesListState.apiState ===
															'success' &&
														getStatesListState.data
															.all_states
															?.length > 0 &&
														getStatesListState.data.all_states.map(
															(itm) => {
																return {
																	label: itm.state,
																	value: itm.state,
																	providers:
																		itm.providers,
																};
															},
														)
													}
													onChange={(
														value,
														options,
													) => {
														// handleInputChange(
														// 	value,
														// 	formData,
														// 	setFormData,
														// );
														handleState(
															'functional_in_states',
															value,
														);
													}}
												></XSelect>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="pincode"
												label="Pincode"
											>
												<Input
													size="large"
													placeholder="Enter Pincode"
													name="pincode"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											<XForm.Item
												name="city"
												label="City"
											>
												<Input
													size="large"
													placeholder="Enter City"
													name="city"
													onChange={(e) =>
														handleInputChange(
															e,
															formData,
															setFormData,
														)
													}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} lg={12}>
											{/* <XForm.Item className="text-right mt-3">
								<XShareButton
									style={{
										background: '#fff',
										margin: 0,
										color: '#007299',
									}}
									size="large"
									onClick={() => {
										form.resetFields();
										setFormData({});
										setModalOpen([false, '']);
									}}
								>
									Cancel
								</XShareButton>
							</XForm.Item> */}
										</Col>
										<Col
											xs={24}
											lg={24}
											md={18}
											sm={18}
											className="text-end"
										>
											<div
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
													gap: '8px',
													flexWrap: 'wrap',
												}}
											>
												<XForm.Item className="text-right mt-3">
													<Button
														style={{
															background: '#fff',
															margin: 0,
															color: '#007299',
														}}
														size="large"
														onClick={() => {
															form.resetFields();
															setFormData({});
															setFileList([]);
															navigate(
																'/insurance-company',
															);
															// setModalOpen([
															// 	false,
															// 	'',
															// ]);
														}}
													>
														Cancel
													</Button>
												</XForm.Item>
												<XForm.Item className="text-right mt-3">
													<XButtonConfirm
														loading={submitLoading}
														style={{
															background:
																'#007299',
															color: '#fff',
															margin: 0,
														}}
														htmlType="submit"
														size="large"
														// disabled={
														// 	(editCompanyActive &&
														// 		!modulePermissions.sub_modules.includes(
														// 			'update-insurance-companies',
														// 		)) ||
														// 	!modulePermissions.sub_modules.includes(
														// 		'add-insurance-companies',
														// 	)
														// }
													>
														Submit
													</XButtonConfirm>
												</XForm.Item>
											</div>
										</Col>{' '}
									</Row>
								</XForm>
							</>
						) : (
							<Result
								status="403"
								title="403"
								subTitle="Sorry, you are not authorized to access this page."
								extra={
									<Button
										type="primary"
										onClick={() => navigate('/')}
									>
										Back Home
									</Button>
								}
							/>
						)}
					</XCardsTransparent>
				</XContainer>
			</Spin>
		</>
	);
};

const mapStateToProps = (state) => ({
	addInsuranceCompanyState: state.addInsuranceCompany,
	editInsuranceCompanyState: state.editInsuranceCompany,
	getInsuranceCompanyByIdState: state.getInsuranceCompanyById,
	deleteInsuranceCompanyByIdState: state.deleteInsuranceCompanyById,
	deleteInsuranceCompanyState: state.deleteInsuranceCompany,
	getStateDropdownState: state.getStateDropdown,
	getStatesListState: state.getStatesList,
});

const mapDispatchToProps = (dispatch) => ({
	addInsuranceCompany: (data) => dispatch(addInsuranceCompanyApi(data)),
	addInsuranceCompanyReset: () => dispatch(addInsuranceCompanyReset()),
	editInsuranceCompany: (data) => dispatch(editInsuranceCompanyApi(data)),
	editInsuranceCompanyReset: () => dispatch(editInsuranceCompanyReset()),
	getInsuranceCompanyById: (data) =>
		dispatch(getInsuranceCompanyByIdApi(data)),
	getInsuranceCompanyByIdReset: () =>
		dispatch(getInsuranceCompanyByIdReset()),
	deleteInsuranceCompany: (data) => dispatch(deleteInsuranceCompanyApi(data)),
	deleteInsuranceCompanyReset: () => dispatch(deleteInsuranceCompanyReset()),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getStatesList: (params) => dispatch(getStatesListApi(params)),
	getStatesListReset: () => dispatch(getStatesListReset()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(InsuranceCompanyForm);
