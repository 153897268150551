import styled from 'styled-components';

export const TestContainer = styled.div`
    .card {
        background: ${({ theme }) => theme.colors.white};
        border: 1px solid rgba(0, 33, 65, 0.1);
        border-radius: 10px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    .card-header {
        background: #f2f8fa;
        padding: 15px 24px;
        border-bottom: 1px dashed #dbdbdb;
        h2 {
            margin: 0;
            color: #007299;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .card-content {
        padding: 24px;
    }

    .test-button {
        background: #007299;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background: #005a7a;
        }
    }

    .result {
        margin-top: 10px;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.menu2};
    }
`; 