import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID';
export const GET_ORGANIZATION_BY_ID_SUCCESS = 'GET_ORGANIZATION_BY_ID_SUCCESS';
export const GET_ORGANIZATION_BY_ID_FAIL = 'GET_ORGANIZATION_BY_ID_FAIL';
export const GET_ORGANIZATION_BY_ID_RESET = 'GET_ORGANIZATION_BY_ID_RESET';

export const getOrganizationById = (params) => {
	return {
		type: GET_ORGANIZATION_BY_ID,
		params,
	};
};

export const getOrganizationByIdSuccess = (response) => {
	return {
		type: GET_ORGANIZATION_BY_ID_SUCCESS,
		response,
	};
};

export const getOrganizationByIdFail = (response) => {
	return {
		type: GET_ORGANIZATION_BY_ID_FAIL,
		response,
	};
};

export const getOrganizationByIdReset = () => {
	return {
		type: GET_ORGANIZATION_BY_ID_RESET,
	};
};

export const getOrganizationByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getOrganizationById());
		axios
			.get(`${config.api.base_url}/organization/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getOrganizationByIdData = response.data;
				dispatch(getOrganizationByIdSuccess(getOrganizationByIdData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getOrganizationByIdFail(errorMsg));
			});
	};
};
