import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_REVISION = 'GET_REVISION';
export const GET_REVISION_SUCCESS = 'GET_REVISION_SUCCESS';
export const GET_REVISION_FAIL = 'GET_REVISION_FAIL';
export const GET_REVISION_RESET = 'GET_REVISION_RESET';

export const getRevision = (params) => {
	return {
		type: GET_REVISION,
		params,
	};
};

export const getRevisionSuccess = (response) => {
	return {
		type: GET_REVISION_SUCCESS,
		response,
	};
};

export const getRevisionFail = (response) => {
	return {
		type: GET_REVISION_FAIL,
		response,
	};
};

export const getRevisionReset = () => {
	return {
		type: GET_REVISION_RESET,
	};
};

export const getRevisionApi = (data) => {
	console.log('data---------', data);
	return (dispatch) => {
		dispatch(getRevision());
		axios
			.get(`${config.api.base_url}/revision/get-revision-list`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getRevisionData = response.data;
				dispatch(getRevisionSuccess(getRevisionData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getRevisionFail(errorMsg));
			});
	};
};
