import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_INSURANCE_COMPANY_BY_ID = 'GET_INSURANCE_COMPANY_BY_ID';
export const GET_INSURANCE_COMPANY_BY_ID_SUCCESS =
	'GET_INSURANCE_COMPANY_BY_ID_SUCCESS';
export const GET_INSURANCE_COMPANY_BY_ID_FAIL =
	'GET_INSURANCE_COMPANY_BY_ID_FAIL';
export const GET_INSURANCE_COMPANY_BY_ID_RESET =
	'GET_INSURANCE_COMPANY_BY_ID_RESET';

export const getInsuranceCompanyById = (params) => {
	return {
		type: GET_INSURANCE_COMPANY_BY_ID,
		params,
	};
};

export const getInsuranceCompanyByIdSuccess = (response) => {
	return {
		type: GET_INSURANCE_COMPANY_BY_ID_SUCCESS,
		response,
	};
};

export const getInsuranceCompanyByIdFail = (response) => {
	return {
		type: GET_INSURANCE_COMPANY_BY_ID_FAIL,
		response,
	};
};

export const getInsuranceCompanyByIdReset = () => {
	return {
		type: GET_INSURANCE_COMPANY_BY_ID_RESET,
	};
};

export const getInsuranceCompanyByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getInsuranceCompanyById());
		axios
			.get(`${config.api.base_url}/insurance-companies/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getInsuranceCompanyByIdData = response.data;
				dispatch(
					getInsuranceCompanyByIdSuccess(getInsuranceCompanyByIdData),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getInsuranceCompanyByIdFail(errorMsg));
			});
	};
};
