import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';
import { saveAs } from 'file-saver';

export const GENERATE_APPEAL_LETTER = 'GENERATE_APPEAL_LETTER';
export const GENERATE_APPEAL_LETTER_SUCCESS = 'GENERATE_APPEAL_LETTER_SUCCESS';
export const GENERATE_APPEAL_LETTER_FAIL = 'GENERATE_APPEAL_LETTER_FAIL';
export const GENERATE_APPEAL_LETTER_RESET = 'GENERATE_APPEAL_LETTER_RESET';

export const generateAppealLetter = (params) => {
	return {
		type: GENERATE_APPEAL_LETTER,
		params,
	};
};

export const generateAppealLetterSuccess = (response) => {
	return {
		type: GENERATE_APPEAL_LETTER_SUCCESS,
		response,
	};
};

export const generateAppealLetterFail = (response) => {
	return {
		type: GENERATE_APPEAL_LETTER_FAIL,
		response,
	};
};

export const generateAppealLetterReset = () => {
	return {
		type: GENERATE_APPEAL_LETTER_RESET,
	};
};

export const generateAppealLetterApi = (data) => {
	return (dispatch) => {
		dispatch(generateAppealLetter());
		axios
			.get(
				`${config.api.base_url}/appeal-letter/generate-letter/${data.id}`,
				{
					params: data,
					responseType: 'arraybuffer', // Specify the response type
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const generateAppealLetterData = response.data;
				const blob = new Blob([generateAppealLetterData], {
					type: 'application/pdf',
				});
				saveAs(blob, 'Appeal-letter.pdf'); // Use saveAs to trigger the download
				dispatch(generateAppealLetterSuccess());
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(generateAppealLetterFail(errorMsg));
			});
	};
};
