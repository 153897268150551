import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_CODE_UPDATE_DATES = 'GET_CODE_UPDATE_DATES';
export const GET_CODE_UPDATE_DATES_SUCCESS = 'GET_CODE_UPDATE_DATES_SUCCESS';
export const GET_CODE_UPDATE_DATES_FAIL = 'GET_CODE_UPDATE_DATES_FAIL';
export const GET_CODE_UPDATE_DATES_RESET = 'GET_CODE_UPDATE_DATES_RESET';

export const getCodeUpdateDates = (params) => {
	return {
		type: GET_CODE_UPDATE_DATES,
		params,
	};
};

export const getCodeUpdateDatesSuccess = (response) => {
	return {
		type: GET_CODE_UPDATE_DATES_SUCCESS,
		response,
	};
};

export const getCodeUpdateDatesFail = (response) => ({
	type: GET_CODE_UPDATE_DATES_FAIL,
	response,
});

export const getCodeUpdateDatesReset = () => {
	return {
		type: GET_CODE_UPDATE_DATES_RESET,
	};
};

export const getCodeUpdateDatesApi = (data) => {
	return (dispatch) => {
		dispatch(getCodeUpdateDates());
		axios
			.get(`${config.api.base_url}/code-updates/latest`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getDates = response.data;
				dispatch(getCodeUpdateDatesSuccess(getDates));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getCodeUpdateDatesFail(errorMsg));
			});
	};
};
