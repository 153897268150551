import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_HOSPITALS_BASED_ON_REGION = 'GET_HOSPITALS_BASED_ON_REGION';
export const GET_HOSPITALS_BASED_ON_REGION_SUCCESS =
	'GET_HOSPITALS_BASED_ON_REGION_SUCCESS';
export const GET_HOSPITALS_BASED_ON_REGION_FAIL =
	'GET_HOSPITALS_BASED_ON_REGION_FAIL';
export const GET_HOSPITALS_BASED_ON_REGION_RESET =
	'GET_HOSPITALS_BASED_ON_REGION_RESET';

export const getHospitalsBasedOnRegion = (params) => {
	return {
		type: GET_HOSPITALS_BASED_ON_REGION,
		params,
	};
};

export const getHospitalsBasedOnRegionSuccess = (response) => {
	return {
		type: GET_HOSPITALS_BASED_ON_REGION_SUCCESS,
		response,
	};
};

export const getHospitalsBasedOnRegionFail = (response) => {
	return {
		type: GET_HOSPITALS_BASED_ON_REGION_FAIL,
		response,
	};
};

export const getHospitalsBasedOnRegionReset = () => {
	return {
		type: GET_HOSPITALS_BASED_ON_REGION_RESET,
	};
};

export const getHospitalsBasedOnRegionApi = (data) => {
	return (dispatch) => {
		dispatch(getHospitalsBasedOnRegion());
		axios
			.get(`${config.api.base_url}/regions/hospital-based-region`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getHospitalsBasedOnRegionData = response.data;
				dispatch(
					getHospitalsBasedOnRegionSuccess(
						getHospitalsBasedOnRegionData,
					),
				);
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getHospitalsBasedOnRegionFail(errorMsg));
			});
	};
};
