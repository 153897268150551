import styled from 'styled-components';
import '../../styles/GlobalStyle';
import {
	Card,
	Table,
	Select,
	AutoComplete,
	Collapse,
	Button,
	Switch,
	Modal,
} from 'antd';
import Lottie from 'lottie-react';
import ReactSpeedometer from 'react-d3-speedometer';

export const XCardBg = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 27px 34px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
		}
		.date {
			color: ${({ theme }) => theme.colors.color1};
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			@media only screen and (max-width: 768.99px) {
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
	@media only screen and (min-width: 1920.99px) {
	}
	h1 {
		margin: 0px;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #081d16;
	}
`;
export const XLottieWrapp = styled.section`
	width: 100%;
	position: relative;
	div {
		position: relative;
		width: 150px;
		height: 150px;
		margin: -30px;
	}
	@media only screen and (max-width: 768.99px) {
	}
`;

export const XLottie = styled(Lottie)`
	position: relative;
	background: transparent;
	width: 100%;
`;
export const Title = styled.h1`
	position: relative;
	width: 100%;
	background: var(
		--new,
		linear-gradient(90deg, #69eacb 0%, #e2a3ff 48%, #6654f1 100%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 0px;
	font-size: 28px;
	line-height: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	@media only screen and (max-width: 768.99px) {
		font-size: 20px !important;
		line-height: 30px;
	}
`;
export const Title2 = styled.h2`
	position: relative;
	width: 100%;
	color: ${({ theme }) => theme.colors.color1};
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 15px;
	span {
		color: ${({ theme }) => theme.colors.color2};
	}
	@media only screen and (max-width: 768.99px) {
		margin-top: 10px;
		font-size: 14px !important;
		line-height: 16px;
	}
`;
export const XCardData = styled(Card)`
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	height: 100px;
	.ant-card-body {
		padding: 15px !important;
		.subHeading {
			color: ${({ theme }) => theme.colors.color4};
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.headingMain {
			color: ${({ theme }) => theme.colors.color2};
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
		@media only screen and (max-width: 768.99px) {
		}
	}
`;
export const XCardData2 = styled(Card)`
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	height: 100%;
	.ant-card-body {
		padding: 25px 30px !important;
		.subHeading {
			color: ${({ theme }) => theme.colors.color4};
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.headingMain {
			color: ${({ theme }) => theme.colors.color2};
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
		@media only screen and (max-width: 768.99px) {
		}
	}
`;

export const XCardData3 = styled(Card)`
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	height: 100%;
	.ant-card-body {
		padding: 18px 30px !important;
		.subHeading {
			color: ${({ theme }) => theme.colors.color4};
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}
		.headingMain {
			color: ${({ theme }) => theme.colors.color2};
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}
		@media only screen and (max-width: 1180.99px) {
			padding: 5px !important;
		}
		@media only screen and (max-width: 1023.99px) {
			padding: 30px 0 !important;
		}

		@media only screen and (max-width: 991.99px) {
			padding: 24px !important;
		}
	}
`;

/*********** Charts ****************/
export const XTableCharts = styled(Table)`
	position: relative;
	background: transparent;
	width: 100%;
	.ant-table-pagination.ant-pagination {
		margin: 15px 0px 0px 0px;
	}
	.ant-table-cell {
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		color: #7f8e89 !important;
	}
	td.ant-table-cell {
		text-transform: capitalize;
		color: #11263c !important;
		font-weight: normal;
	}

	ul.barGraph {
		width: 100%;
		position: relative;
		margin: 0px;
		padding: 0px;
		background: #dddddd;
		overflow: auto;
		border-radius: 10px;
		box-shadow: inset 0 0 15px 0 #dddddd;
		${'' /* box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.8); */}
		color:#FFF;
	}
	ul.barGraph li {
		list-style-type: none;
		display: inline-block !important;
		float: left;
		box-shadow: inset 0px 12px 25px 5px rgba(0, 0, 0, 0.1);
		animation: wave 2s linear alternate;
		transform: translate3d(0, 0, 0);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;
		height: 100% !important;
		line-height: 20px !important;
	}

	@keyframes wave {
		2% {
			transform: translateX(1);
		}

		25% {
			transform: translateX(-25%);
		}

		50% {
			transform: translateX(-50%);
		}

		75% {
			transform: translateX(-25%);
		}

		100% {
			transform: translateX(1);
		}
	}

	ul.barGraph li.step1 {
		background: #1f9254;
		border-right: 1px solid #fff;
	}
	ul.barGraph li.step2 {
		background: #e5aa11;
		border-right: 1px solid #fff;
	}
	ul.barGraph li.step3 {
		background: #e53711;
	}
	ul.barGraph li.step4 {
		background: #3b82f6;
		border-right: 1px solid #fff;
	}
	ul.barGraph li.step5 {
		background: #19dae7;
		border-right: 1px solid #fff;
	}
	ul.barGraph li.step6 {
		background: #d5001a;
		border-right: 1px solid #fff;
	}
`;

export const pieChartWrap = styled.div``;
/****************************************/
export const XCardNonBg = styled(Card)`
	border-radius: 20px;
	background: none;
	h1 {
		margin: 0px;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #081d16;
	}
	.ant-card-body {
		padding: 27px 34px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
		}
	}
`;
export const XSelectCategories = styled(Select)`
	width: 100%;
	.ant-select-selector {
		background: #ffffff !important;
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		border-radius: 25px !important;
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 16px !important;
		color: #212226 !important;
	}
`;
export const XAutoComplete = styled(AutoComplete)`
	width: 100%;
	.ant-select-selector {
		background: #ffffff !important;
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		border-radius: 25px !important;
		font-weight: 400 !important;
		font-size: 14px !important;
		line-height: 16px !important;
		color: #212226 !important;
	}
`;
export const XPyersCard = styled(Card)`
	width: 100%;
	background: #ffffff;
	border: 1px solid #d0e0df;
	border-radius: 20px;
	margin-bottom: 30px;
	.ant-card-body {
		padding: 15px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 15px !important;
		}
		.headerData {
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			color: #11263c;
			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.03em;
			}
			.headerColor1 {
				color: #d5001a;
				font-style: normal;
			}
			.headerColor2 {
				color: #ff6a00;
				font-style: normal;
			}
			.headerColor3 {
				color: #e5aa11;
				font-style: normal;
			}
			.headerColor4 {
				color: #1f9254;
				font-style: normal;
			}
		}
		h6 {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: 0.03em;
			color: #11263c;
		}
		h5 {
			margin: 0px;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			display: flex;
			align-items: center;
			letter-spacing: 0.03em;
			color: #11263c;
		}

		h6.overallScoreList {
			padding: 8px 15px;
			font-weight: 400;
			font-size: 13px;
			line-height: 18px;
			letter-spacing: 0.03em;
			span {
				font-weight: 500;
				position: absolute;
				text-align: center;
				right: 15px;
				z-index: 1;
			}
		}
		h6.listColor1 {
			background-color: #6ae9cb;
			background-image: -webkit-linear-gradient(
				150deg,
				#6ae9cb 25%,
				#e1fbf5 10%
			);
		}
		h6.listColor2 {
			background-color: #b5bdeb;
			background-image: -webkit-linear-gradient(
				180deg,
				#b5bdeb 30%,
				#f0f2fb 10%
			);
		}
		h6.listColor3 {
			background-color: #dca5fc;
			background-image: -webkit-linear-gradient(
				180deg,
				#dca5fc 30%,
				#f8edfe 10%
			);
		}
		h6.overallScoreList2 {
			display: flex;
			align-items: center;
			margin: 0px;
			padding: 20px 0px;
			font-weight: 400;
			font-size: 13px;
			line-height: 13px;
			letter-spacing: 0.03em;
			border-bottom: 1px dashed #dbdbdb;
			span {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				right: 0;
				width: 40px;
				height: 40px;
				line-height: 25px;
				border-radius: 30px;
				text-align: center;
				color: #fff;
				font-weight: 600;
				font-size: 10px;
				letter-spacing: 0.03em;
				color: #ffffff;
			}
			.dashboard-tooltip-css {
				height: auto;
				position: relative;
				color: black;
				display: block;
				width: auto;
				margin-left: 5px;
			}
			.list2Color1 {
				background: #d5001a;
			}
			.list2Color2 {
				background: #ff6a00;
			}
			.list2Color3 {
				background: #e5aa11;
			}
			.list2Color4 {
				background: #1f9254;
			}
		}
	}
`;
export const XCardData4 = styled(Card)`
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.2) !important;
	border: 1px solid ${({ theme }) => theme.colors.color3};
	.ant-card-body {
		padding: 18px 30px !important;
		@media only screen and (max-width: 1180.99px) {
			padding: 5px !important;
		}
		@media only screen and (max-width: 1023.99px) {
			padding: 30px !important;
		}

		@media only screen and (max-width: 991.99px) {
			padding: 24px !important;
		}
	}
	h1 {
		margin: 0px;
		font-weight: 500;
		font-size: 16px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #081d16;
	}
	ul.markData {
		margin: 0px;
		padding: 0px;
	}
	ul.markData li {
		display: list-item;
		list-style-type: none;
		padding-left: 10px;
		font-weight: 500;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #081d16;
		text-align: left;
		@media only screen and (max-width: 575.99px) {
			display: inline-block;
			margin-top: 30px;
		}
		@media only screen and (max-width: 445px) {
			margin-top: 5px;
		}
	}
	ul.markData li em {
		position: absolute;
		left: 0;
		margin-top: 10px;
		width: 10px;
		height: 10px;
		border-radius: 20px;
		@media only screen and (max-width: 575.99px) {
			position: relative;
			margin-top: 12px;
			margin-right: 5px;
			float: left;
		}
	}
	ul.markData li em.color1 {
		background: #1f9254;
	}
	ul.markData li em.color2 {
		background: #e5aa11;
	}
	ul.markData li em.color3 {
		background: #e53711;
	}
	ul.markData li em.color4 {
		background: #2f8dfa;
	}
	ul.markData li em.color5 {
		background: #1fd0bf;
	}
	ul.markData li em.color6 {
		background: #d5001a;
	}
	.dataValue {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		bottom: 25%;
		z-index: 1;
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 36px;
		letter-spacing: -0.02em;
		color: rgba(8, 29, 22, 0.6);
		span {
			font-weight: 700;
			font-size: 50px;
			color: #081d16;
		}
		@media only screen and (max-width: 768.99px) {
			bottom: 25%;
		}
		@media only screen and (max-width: 380.99px) {
			bottom: 40%;
			line-height: 16px;
			span {
				font-weight: 700;
				font-size: 22px;
				line-height: 26px;
				color: #081d16;
			}
		}
	}
`;
export const XReactSpeedometer = styled(ReactSpeedometer)``;
export const XButtonSeg = styled(Button)`
	border: 1px solid rgba(6, 36, 66, 0.1);
	border-radius: 25px !important;
	margin: 0px 5px;
	line-height: 16px;
	font-weight: 400;
	font-size: 14px !important;
	color: #212226;
`;
export const XSwitch = styled(Switch)``;
export const SwitchBg = styled.div`
	width: 100%;
	position: relative;
	background: #ffffff;
	border: 1px solid rgba(6, 36, 66, 0.1);
	border-radius: 25px;
	padding: 10px 5px;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;
	color: #081d16;
	text-align: center;
	button.ant-switch-checked {
		background: #000 !important;
	}
`;
export const XModalAnimation = styled(Modal)`
	.ant-modal-close {
		top: -5px !important;
		inset-inline-end: 0px !important;
		display: none;
	}
	.ant-card {
		border: none;
		background: transparent;
		box-shadow: none;
	}
	.ant-modal-footer {
		margin: 0px;
	}
	.ant-modal-content {
		background: transparent !important;
		box-shadow: none;
		padding: 80px 60px;
		@media only screen and (max-width: 511.99px) {
			padding: 15px !important;
		}
		&:after {
			position: absolute;
			left: 39%;
			right: 0;
			top: 45px;
			content: '';
			display: block;
			width: 114px;
			height: 51px;
			background: url('/images/loader_pin.svg') no-repeat center top;
			@media only screen and (max-width: 511.99px) {
				top: -20px;
				left: 30%;
			}
		}
	}
	.ant-card-body {
		background: #fff;
		border-radius: 40px;
		border: 15px solid rgba(2, 117, 155, 0.1);
		ul {
			margin: 0px;
			padding: 0px;
			min-height: 285px;
		}
		ul li {
			display: list-item;
			list-style-type: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 30px;
			margin-bottom: 30px;
			padding-left: 40px;
			@media only screen and (max-width: 511.99px) {
				line-height: 16px;
			}
			&:last-child {
				margin-bottom: 0px;
			}
			span {
				position: absolute;
				left: 30px;
				width: 30px;
				height: 30px;
				border-radius: 30px;
				text-align: center;
				@media only screen and (max-width: 511.99px) {
					line-height: 30px;
				}
			}
		}
		ul li.active {
			color: #008542;
			transition: color 3s;
			span {
				background: #008542;
				transition: background-color 3s;
			}
		}
		ul li.inprocess {
			color: #e5aa11;
			transition: color 3s;
			span {
				background: #e5aa11;
				transition: background-color 3s;
			}
		}

		.laser {
			width: 115%;
			margin-left: -15%;
			background-color: rgba(2, 117, 155, 0.1);
			border-radius: 10px;
			height: 1px;
			position: absolute;
			height: 10px;
			top: 3%;
			z-index: 3;
			-webkit-animation: scanning 5s infinite;
			-moz-animation: scanning 5s infinite;
			animation: scanning 5s infinite;
		}

		@keyframes scanning {
			50% {
				-webkit-transform: translateY(370px);
				-moz-transform: translateY(370px);
				transform: translateY(370px);
			}
		}
	}
`;
export const XLottieLoader = styled.section`
	width: 100%;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 20px;
	bottom: 0;
`;
