import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const REVISE_COVERAGE = 'REVISE_COVERAGE';
export const REVISE_COVERAGE_SUCCESS = 'REVISE_COVERAGE_SUCCESS';
export const REVISE_COVERAGE_FAIL = 'REVISE_COVERAGE_FAIL';
export const REVISE_COVERAGE_RESET = 'REVISE_COVERAGE_RESET';

export const reviseCoverage = (params) => {
	return {
		type: REVISE_COVERAGE,
		params,
	};
};

export const reviseCoverageSuccess = (response) => {
	return {
		type: REVISE_COVERAGE_SUCCESS,
		response,
	};
};

export const reviseCoverageFail = (response) => {
	return {
		type: REVISE_COVERAGE_FAIL,
		response,
	};
};

export const reviseCoverageReset = () => {
	return {
		type: REVISE_COVERAGE_RESET,
	};
};

export const reviseCoverageApi = (data) => {
	return (dispatch) => {
		dispatch(reviseCoverage());
		axios
			.post(`${config.api.base_url}/coverage/revise-coverage`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
				timeout: 300000,
			})
			.then((response) => {
				const reviseCoverageData = response.data;
				dispatch(reviseCoverageSuccess(reviseCoverageData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(reviseCoverageFail(errorMsg));
			});
	};
};
