import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Result, Avatar } from 'antd';
import { Button, Input, Tag } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { XContainer, XTabs1 } from '../../styles/GlobalStyle';

// actions

import { checkModulePermissions } from '../../utils/helper';
import HeaderFile from '../../components/Header/HeaderFile';

import Header from '../../components/Header/Header';

import FilingManagement from './PreServiceFilingManagement';
import TabPane from 'antd/es/tabs/TabPane';
import { getCountByFilingAndRevenueApi } from '../../actions/getCountByFilingAndRevenueAction';

const CaseManagement = (props) => {
	const { getCountByFilingAndRevenueState, getCountByFilingAndRevenue } =
		props;
	const navigate = useNavigate();
	const location = useLocation();

	const [activeTab, setActiveTab] = useState('all');
	const [filingStatusCount, setFilingStatusCount] = useState({
		escalation: 0,
		at_risk: 0,
		in_process: 0,
		untimely: 0,
	});
	const [filingStatus, setFilingStatus] = useState();
	const [revenueCycleCount, setRevenueCycleCount] = useState({
		pre_service: 0,
		post_claim: 0,
		part_b_drugs: 0,
		all: 0,
	});
	const [appealLetterApprovals, setAppealLetterApprovals] = useState(0);
	const onChange = (key) => {
		// Update the URL with the selected tab's value
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('revenue_cycle', key);

		// Update other query parameters as needed
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');

		// Construct the new URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

		// Push the new URL to the browser history
		window.history.pushState({}, '', newUrl);

		// Optionally, you can set the active tab state here if needed
		setActiveTab(key);
	};

	const handleClick = (filing_status) => {
		// Update the URL with the filing status
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('filing_status', filing_status); // Assuming 'filing_status' is the query parameter to store the filing status

		// Construct the new URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

		// Push the new URL to the browser history
		window.history.pushState({}, '', newUrl);
		setFilingStatus(filing_status);
		// window.location.reload();
	};

	const items = [
		{
			key: 'all',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					All{' '}
					<Tag
						style={{
							marginLeft: '10px',
							// background: '#DEE9E9',
							background: 'rgba(0, 114, 153, 0.1)',
							color: '#11263C',
						}}
					>
						{revenueCycleCount.all}
					</Tag>
					{/* <span
						className="ml-2"
						style={{
							fontSize: '10px',
							marginLeft: '4px',
						}}
					>
						- {revenueCycleCount.all}{' '}
					</span> */}
					{/* <Avatar size={20} className="ml-2">
						{revenueCycleCount.all}{' '}
					</Avatar> */}
				</div>
			),
			children: <FilingManagement filingStatus={filingStatus} />,
		},
		{
			key: 'pre_service',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Pre-Service{' '}
					<Tag
						style={{
							marginLeft: '10px',
							// background: '#DEE9E9',
							background: 'rgba(0, 114, 153, 0.1)',
							color: '#11263C',
						}}
					>
						{revenueCycleCount.pre_service}
					</Tag>
					{/* <span
						className="ml-2"
						style={{
							fontSize: '10px',
							marginLeft: '4px',
						}}
					>
						- {revenueCycleCount.pre_service}{' '}
					</span> */}
					{/* <Avatar size={20} className="ml-2">
						{revenueCycleCount.pre_service}{' '}
					</Avatar> */}
				</div>
			),
			children: <FilingManagement filingStatus={filingStatus} />,
		},
		{
			key: 'post_claim',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Post Claim{' '}
					<Tag
						style={{
							marginLeft: '10px',
							// background: '#DEE9E9',
							background: 'rgba(0, 114, 153, 0.1)',
							color: '#11263C',
						}}
					>
						{revenueCycleCount.post_claim}
					</Tag>
					{/* <span
						className="ml-2"
						style={{
							fontSize: '10px',
							marginLeft: '4px',
						}}
					>
						- {revenueCycleCount.post_claim}{' '}
					</span> */}
					{/* <Avatar size={20} className="ml-2">
						{revenueCycleCount.post_claim}{' '}
					</Avatar> */}
				</div>
			),
			children: <FilingManagement filingStatus={filingStatus} />,
		},
		{
			key: 'part_b_drugs',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Part B Drugs{' '}
					<Tag
						style={{
							marginLeft: '10px',
							// background: '#DEE9E9',
							background: 'rgba(0, 114, 153, 0.1)',
							color: '#11263C',
						}}
					>
						{revenueCycleCount.part_b_drugs}
					</Tag>
					{/* <span
						className="ml-2"
						style={{
							fontSize: '10px',
							marginLeft: '4px',
						}}
					>
						- {revenueCycleCount.part_b_drugs}{' '}
					</span> */}
					{/* <Avatar size={20} className="ml-2">
						{revenueCycleCount.part_b_drugs}{' '}
					</Avatar> */}
				</div>
			),
			children: <FilingManagement filingStatus={filingStatus} />,
		},
		checkModulePermissions('appeal-letter').sub_modules.includes(
			'confirm-letter',
		) && {
			key: 'appeal_letter_approvals',
			label: (
				<div
					className="gilroy-semiBold"
					style={{ fontFamily: 'Poppins' }}
				>
					Appeal letter Approvals{' '}
					<Tag
						style={{
							marginLeft: '10px',
							// background: '#DEE9E9',
							background: 'rgba(0, 114, 153, 0.1)',
							color: '#11263C',
						}}
					>
						{appealLetterApprovals}
					</Tag>
					{/* <span
						className="ml-2"
						style={{
							fontSize: '10px',
							marginLeft: '4px',
						}}
					>
						- {appealLetterApprovals}{' '}
					</span> */}
					{/* <Avatar size={20} className="ml-2">
						{appealLetterApprovals}{' '}
					</Avatar> */}
				</div>
			),
			children: (
				<FilingManagement filingStatus={'appeal_letter_approvals'} />
			),
		},
	];

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const activeTabFromUrl = searchParams.get('revenue_cycle');
		getCountByFilingAndRevenue();
		if (
			activeTabFromUrl &&
			items.some((item) => item.key === activeTabFromUrl)
		) {
			setActiveTab(activeTabFromUrl);
		}
	}, [location.search]);

	useEffect(() => {
		if (getCountByFilingAndRevenueState.apiState === 'success') {
			const data = getCountByFilingAndRevenueState.data;

			// Initialize an object to hold the updated counts
			const updatedRevenueCycleCount = {
				pre_service: 0,
				post_claim: 0,
				part_b_drugs: 0,
				all: 0,
			};
			const updatedFilingStatusCount = {
				escalation: 0,
				at_risk: 0,
				in_process: 0,
				untimely: 0,
			};

			// Iterate through the revenue_cycle array and update the counts
			data.revenue_cycle.forEach((item) => {
				updatedRevenueCycleCount[item._id] = item.count;
			});

			// Update the state with the new counts
			setRevenueCycleCount(updatedRevenueCycleCount);

			setAppealLetterApprovals(data.pending_appeal_letter_approvals);

			// Iterate through the revenue_cycle array and update the counts
			data.filing_status.forEach((item) => {
				updatedFilingStatusCount[item._id] = item.count;
			});

			// Update the state with the new counts
			setFilingStatusCount(updatedFilingStatusCount);
		}
	}, [getCountByFilingAndRevenueState]);

	// use effects

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="Case Filings" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="caseManagement" />
					</Col>
				</>
			)}
			{checkModulePermissions('case-filing').sub_modules.includes(
				'list-cases',
			) ? (
				<XContainer fluid>
					<Row gutter={16}>
						<Col
							className="gutter-row"
							span={6}
							xs={24}
							md={12}
							lg={6}
							style={{ marginTop: '5px' }}
						>
							{' '}
							<Card
								style={{
									borderRadius: '20px',
									background: '#F9F2EB',
								}}
								className="case-filing"
								onClick={() => handleClick('escalation')}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexDirection: 'column',
											alignItems: 'left',
										}}
									>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '30px',
												fontWeight: '600',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#E57711',
											}}
										>
											{filingStatusCount.escalation}
										</p>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '16px',
												fontWeight: '400',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#002141',
												gap: '0px',
												opacity: '0.5',
												margin: 0,
											}}
										>
											Escalation
										</p>
									</div>
									<div>
										<img src="/images/escalation.svg" />
									</div>
								</div>
							</Card>
						</Col>
						<Col
							className="gutter-row"
							span={6}
							xs={24}
							md={12}
							lg={6}
							style={{ marginTop: '5px' }}
						>
							{' '}
							<Card
								style={{
									borderRadius: '20px',
									background: '#F9F8EB',
								}}
								className="case-filing"
								onClick={() => handleClick('at_risk')}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexDirection: 'column',
											alignItems: 'left',
										}}
									>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '30px',
												fontWeight: '600',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#E5AA11',
											}}
										>
											{filingStatusCount.at_risk}
										</p>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '16px',
												fontWeight: '400',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#002141',
												gap: '0px',
												opacity: '0.5',
												margin: 0,
											}}
										>
											At Risk
										</p>
									</div>
									<div>
										<img src="/images/at_risk.svg" />
									</div>
								</div>
							</Card>
						</Col>
						<Col
							className="gutter-row"
							span={6}
							xs={24}
							md={12}
							lg={6}
							style={{ marginTop: '5px' }}
						>
							{' '}
							<Card
								style={{
									borderRadius: '20px',
									background: '#EBF9F1',
								}}
								className="case-filing"
								onClick={() => handleClick('in_process')}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexDirection: 'column',
											alignItems: 'left',
										}}
									>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '30px',
												fontWeight: '600',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#1F9254',
											}}
										>
											{filingStatusCount.in_process}
										</p>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '16px',
												fontWeight: '400',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#002141',
												gap: '0px',
												opacity: '0.5',
												margin: 0,
											}}
										>
											In Process
										</p>
									</div>
									<div>
										<img src="/images/in_process.svg" />
									</div>
								</div>
							</Card>
						</Col>
						<Col
							className="gutter-row"
							span={6}
							xs={24}
							md={12}
							lg={6}
							style={{ marginTop: '5px' }}
						>
							<Card
								style={{
									borderRadius: '20px',
									background: '#F9EBEB',
								}}
								className="case-filing"
								onClick={() => handleClick('untimely')}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexDirection: 'column',
											alignItems: 'left',
										}}
									>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '30px',
												fontWeight: '600',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#DB3A17',
											}}
										>
											{filingStatusCount.untimely}
										</p>
										<p
											style={{
												fontFamily: 'Poppins',
												fontSize: '16px',
												fontWeight: '400',
												lineHeight: '24px',
												textAlign: 'left',
												color: '#002141',
												gap: '0px',
												opacity: '0.5',
												margin: 0,
											}}
										>
											Untimely
										</p>
									</div>
									<div>
										<img src="/images/timer.svg" />
									</div>
								</div>
							</Card>
						</Col>
					</Row>
					<Row style={{ marginTop: '20px' }}>
						<Col xs={24}>
							<XTabs1
								className="child-tabs"
								activeKey={activeTab}
								onChange={onChange}
							>
								{items.map((item) => (
									<TabPane tab={item.label} key={item.key}>
										{activeTab === item.key &&
											item.children}
									</TabPane>
								))}
							</XTabs1>
						</Col>
					</Row>
				</XContainer>
			) : (
				<Result
					status="403"
					title="403"
					subTitle="Sorry, you are not authorized to access this page."
					extra={
						<Button type="primary" onClick={() => navigate('/')}>
							Back Home
						</Button>
					}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	getCountByFilingAndRevenueState: state.getCountByFilingAndRevenue,
});

const mapDispatchToProps = (dispatch) => ({
	getCountByFilingAndRevenue: (data) =>
		dispatch(getCountByFilingAndRevenueApi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);
