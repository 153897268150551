import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAIL = 'SEND_FEEDBACK_FAIL';
export const SEND_FEEDBACK_RESET = 'SEND_FEEDBACK_RESET';

export const sendFeedback = (params) => {
	return {
		type: SEND_FEEDBACK,
		params,
	};
};

export const sendFeedbackSuccess = (response) => {
	return {
		type: SEND_FEEDBACK_SUCCESS,
		response,
	};
};

export const sendFeedbackFail = (response) => {
	return {
		type: SEND_FEEDBACK_FAIL,
		response,
	};
};

export const sendFeedbackReset = () => {
	return {
		type: SEND_FEEDBACK_RESET,
	};
};

export const sendFeedbackApi = (data) => {
	return (dispatch) => {
		dispatch(sendFeedback());
		axios
			.post(`${config.api.base_url}/coverage/send-feedback`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const sendFeedbackData = response.data;
				dispatch(sendFeedbackSuccess(sendFeedbackData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(sendFeedbackFail(errorMsg));
			});
	};
};
