import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_MODULES_LIST = 'GET_MODULES_LIST';
export const GET_MODULES_LIST_SUCCESS = 'GET_MODULES_LIST_SUCCESS';
export const GET_MODULES_LIST_FAIL = 'GET_MODULES_LIST_FAIL';
export const GET_MODULES_LIST_RESET = 'GET_MODULES_LIST_RESET';

export const getModulesList = (params) => {
	return {
		type: GET_MODULES_LIST,
		params,
	};
};

export const getModulesListSuccess = (response) => {
	return {
		type: GET_MODULES_LIST_SUCCESS,
		response,
	};
};

export const getModulesListFail = (response) => {
	return {
		type: GET_MODULES_LIST_FAIL,
		response,
	};
};

export const getModulesListReset = () => {
	return {
		type: GET_MODULES_LIST_RESET,
	};
};

export const getModulesListApi = (data) => {
	return (dispatch) => {
		dispatch(getModulesList());
		axios
			.get(`${config.api.base_url}/modules/all`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getModulesListData = response.data;
				dispatch(getModulesListSuccess(getModulesListData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getModulesListFail(errorMsg));
			});
	};
};
