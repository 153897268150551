
import { 
    GET_REIMBURSEMENTS,
    GET_REIMBURSEMENTS_SUCCESS,
    GET_REIMBURSEMENTS_FAIL, 
    GET_REIMBURSEMENTS_RESET,
} from "../actions/getReimbursementsAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const getReimbursementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REIMBURSEMENTS:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_REIMBURSEMENTS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_REIMBURSEMENTS_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case GET_REIMBURSEMENTS_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getReimbursementsReducer;
