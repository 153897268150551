import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	XContainer,
	XModal,
	XForm,
	XSelect,
	XCardsTransparent,
	XSwitch,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Form, Switch, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

// Actions
import {
	getRolesDropdownApi,
	getRolesDropdownReset,
} from '../../actions/getRolesDropdownAction';
import { addUserApi, addUserReset } from '../../actions/addUserAction';
import {
	getUserByIdApi,
	getUserByIdReset,
} from '../../actions/getUserByIdAction';
import { editUserApi, editUserReset } from '../../actions/editUserAction';
import Header from '../../components/Header/Header';
import { checkModulePermissions, toTitleCase } from '../../utils/helper';
import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
} from '../../utils/formFunctions';
import {
	XButtonBack,
	XCardBackBg,
	XEditTitle,
} from '../CaseForm/CaseFormStyle';
import {
	getHospitalDropdownApi,
	getHospitalDropdownReset,
} from '../../actions/getHospitalDropdownAction';
import { getRegionsApi } from '../../actions/getRegionsAction';
import {
	getOrganizationsApi,
	getOrganizationsReset,
} from '../../actions/getOrganizationsAction';
import { deleteUserApi, deleteUserReset } from '../../actions/deleteUserAction';

const AddEditUser = (props) => {
	const {
		addUser,
		addUserReset,
		addUserState,
		getUserById,
		getUserByIdState,
		editUser,
		editUserReset,
		editUserState,
		deleteUser,
		deleteUserReset,
		deleteUserState,
		getRolesDropdown,
		getRolesDropdownState,
		getHospitalDropdown,
		getHospitalDropdownState,
		getRegions,
		getRegionsState,
		getOrganizations,
		getOrganizationsState,
	} = props;
	const navigate = useNavigate();
	const id = useParams().id;

	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		is_active: false,
		role: '',
	});
	const [displayOrganization, setDisplayOrganization] = useState(true);
	const [displayRegion, setDisplayRegion] = useState(true);
	const [displayHospital, setDisplayHospital] = useState(true);

	useEffect(() => {
		if (addUserState.apiState === 'success') {
			form.resetFields();
			setFormData({});
			navigate(`/user-role-management`);
			notification.success({
				message: addUserState.message,
			});
			addUserReset();
		} else if (addUserState.apiState === 'error') {
			for (const fieldName in addUserState.errors) {
				if (addUserState.errors.hasOwnProperty(fieldName)) {
					const errorMessage = addUserState.errors[fieldName];
					form.setFields([
						{
							name: fieldName,
							errors: [
								errorMessage.replace(
									fieldName,
									toTitleCase(fieldName),
								),
							],
						},
					]);
				}
			}
			notification.error({
				message: addUserState.message,
			});
			addUserReset();
		}
	}, [addUserState]);

	useEffect(() => {
		if (getUserByIdState.apiState === 'success') {
			setFormData({
				id: getUserByIdState.data._id,
				name: getUserByIdState.data.name,
				email: getUserByIdState.data.email,
				role: getUserByIdState.data.role,
				is_active: getUserByIdState.data.is_active,
				organization: getUserByIdState.data.organization,
				region: getUserByIdState.data.region,
				hospital: getUserByIdState.data.hospital,
			});
			form.setFieldsValue({
				id: getUserByIdState.data._id,
				name: getUserByIdState.data.name,
				email: getUserByIdState.data.email,
				role: getUserByIdState.data.role,
				is_active: getUserByIdState.data.is_active,
				organization: getUserByIdState.data.organization,
				region: getUserByIdState.data.region,
				hospital: getUserByIdState.data.hospital,
			});
			getHospitalDropdown({
				organization_id: getUserByIdState.data.organization,
				region_id: getUserByIdState.data.region,
			});
			getRegions({
				organization_id: getUserByIdState.data.organization,
			});

			handleDisplayInputs(getUserByIdState.data.role_name);
		}
	}, [getUserByIdState]);

	useEffect(() => {
		if (editUserState.apiState === 'success') {
			form.resetFields();
			setFormData({});
			editUserReset();
			navigate(`/user-role-management`);
			notification.success({
				message: editUserState.message,
			});
		} else if (editUserState.apiState === 'error') {
			notification.error({
				message: editUserState.message,
			});
			editUserReset();
		}
	}, [editUserState]);

	useEffect(() => {
		if (deleteUserState.apiState === 'success') {
			notification.success({
				message: deleteUserState.message,
			});
			navigate(`/user-role-management`);
			deleteUserReset();
		}
	}, [deleteUserState]);

	useEffect(() => {
		if (getRolesDropdownState.apiState === 'success') {
			if (getRolesDropdownState.data.length > 0) {
				const updatedTableData = [];
				getRolesDropdownState.data.map((item, index) => {
					updatedTableData.push({
						key: item._id,
						role: item.display_name,
					});
				});
				// setRolesData(updatedTableData);
			}
		}
	}, [getRolesDropdownState]);

	useEffect(() => {
		setFormData({});
		if (id) getUserById({ id: id });
		getRolesDropdown({ type: 'super-admin' });
		getOrganizations();
		setDisplayOrganization(true);
		setDisplayHospital(true);
		setDisplayRegion(true);
		form.resetFields();
	}, []);

	useEffect(() => {
		if (localStorage.getItem('role') === 'organization-admin')
			setDisplayOrganization(false);
		if (localStorage.getItem('role') === 'region-admin') {
			setDisplayOrganization(false);
			setDisplayRegion(false);
		}
		if (localStorage.getItem('role') === 'hospital-admin') {
			setDisplayOrganization(false);
			setDisplayRegion(false);
			setDisplayHospital(false);
		}
	}, []);

	// Functions
	const showDeleteConfirm = (_id, name) => {
		XModal.confirm({
			title: 'Delete User?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete {name}?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteUser({ id: _id });
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const updateUserDetails = (id) => {
		editUser(formData);
		form.resetFields();
	};

	const saveUser = () => {
		addUser(formData);
	};

	const handleDisplayInputs = (role) => {
		const userRole = localStorage.getItem('role');
		let updatedFormData = {};
		switch (role) {
			case 'super-admin':
				setDisplayOrganization(false);
				setDisplayHospital(false);
				setDisplayRegion(false);
				updatedFormData = {
					organization: null,
					hospital: null,
					region: null,
				};
				setFormData((prev) => ({ ...prev, ...updatedFormData }));
				break;
			case 'sub-admin':
				setDisplayOrganization(false);
				setDisplayHospital(false);
				setDisplayRegion(false);
				updatedFormData = {
					organization: null,
					hospital: null,
					region: null,
				};
				setFormData((prev) => ({ ...prev, ...updatedFormData }));
				break;
			case 'case-creator':
			case 'case-analyzer':
			case 'case-manager':
				if (userRole === 'super-admin') {
					setDisplayOrganization(true);
					setDisplayHospital(true);
					setDisplayRegion(true);
				} else if (userRole === 'organization-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(true);
					setDisplayRegion(true);
				} else if (userRole === 'region-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(true);
					setDisplayRegion(false);
				}
				break;
			case 'organization-admin':
				if (userRole === 'super-admin') {
					setDisplayOrganization(true);
					setDisplayHospital(false);
					setDisplayRegion(false);
					updatedFormData = {
						hospital: null,
						region: null,
					};
				} else if (userRole === 'organization-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(false);
					setDisplayRegion(false);
					updatedFormData = { hospital: null, region: null };
				}
				setFormData((prev) => ({ ...prev, ...updatedFormData }));
				break;
			case 'region-admin':
				if (userRole === 'super-admin') {
					setDisplayOrganization(true);
					setDisplayHospital(false);
					setDisplayRegion(true);
					updatedFormData = {
						hospital: null,
					};
				} else if (userRole === 'organization-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(false);
					setDisplayRegion(true);
					updatedFormData = {
						hospital: null,
					};
				} else if (userRole === 'region-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(false);
					setDisplayRegion(false);
					updatedFormData = {
						hospital: null,
					};
				}
				setFormData((prev) => ({ ...prev, ...updatedFormData }));
				break;
			case 'hospital-admin':
				if (userRole === 'super-admin') {
					setDisplayOrganization(true);
					setDisplayHospital(true);
					setDisplayRegion(true);
				} else if (userRole === 'organization-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(true);
					setDisplayRegion(true);
				} else if (userRole === 'region-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(true);
					setDisplayRegion(false);
				} else if (userRole === 'hospital-admin') {
					setDisplayOrganization(false);
					setDisplayHospital(false);
					setDisplayRegion(false);
				}
				break;
			default:
				setDisplayOrganization(true);
				setDisplayHospital(true);
				setDisplayRegion(true);
				break;
		}
	};

	return (
		<>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header headerTag="User Management" />
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="userRoleManagement" />
					</Col>
				</>
			)}
			<XContainer fluid>
				<XCardBackBg className="mb-3">
					<Row>
						<Col xs={24}>
							<XButtonBack
								onClick={() => navigate(-1)}
								icon={<ArrowLeftOutlined />}
								size="large"
							/>
							{id ? (
								<XEditTitle>Edit User</XEditTitle>
							) : (
								<XEditTitle>Add User</XEditTitle>
							)}
						</Col>
					</Row>
				</XCardBackBg>

				<XCardsTransparent>
					<XForm
						form={form}
						name="groupForm"
						layout="vertical"
						autoComplete="off"
						onFinish={() => {
							if (id) {
								updateUserDetails();
							} else saveUser();
						}}
					>
						<Row gutter={(15, 30)}>
							<Col xs={24} sm={12} md={12} lg={8}>
								<XForm.Item
									name="name"
									label="User Name"
									rules={[
										{
											required: true,
											message: 'Please input User Name!',
										},
									]}
								>
									<Input
										size="large"
										placeholder="Enter user name"
										name="name"
										onChange={(e) =>
											handleInputChange(
												e,
												formData,
												setFormData,
											)
										}
									/>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} md={12} lg={8}>
								<XForm.Item
									name="email"
									label="Email ID"
									rules={[
										{
											required: true,
											message: 'Please input Email ID!',
										},
										{
											type: 'email',
											message:
												'Please input valid Email ID!',
										},
									]}
								>
									<Input
										size="large"
										placeholder="Enter email id"
										name="email"
										onChange={(e) =>
											handleInputChange(
												e,
												formData,
												setFormData,
											)
										}
										disabled={id ? true : false}
									/>
								</XForm.Item>
							</Col>
							<Col xs={24} sm={12} md={12} lg={8}>
								<XForm.Item
									name="role"
									label="Select Role"
									rules={[
										{
											required: true,
											message: 'Please select Role!',
										},
									]}
								>
									<XSelect
										size="large"
										placeholder="Select Role"
										className="removeBg"
										onSelect={(value, option) => {
											console.log(value);
											handleSelectChange(
												'role',
												value,
												formData,
												setFormData,
											);

											handleDisplayInputs(option.slug);
										}}
										filterOption={filterByLabel}
										showSearch
										options={
											getRolesDropdownState.apiState ===
												'success' &&
											getRolesDropdownState.data.all_roles
												.length > 0 &&
											getRolesDropdownState.data.all_roles.map(
												(ele) => {
													return {
														key: ele._id,
														value: ele._id,
														label: ele.display_name,
														slug: ele.role,
													};
												},
											)
										}
									></XSelect>
								</XForm.Item>
							</Col>
							{displayOrganization ? (
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="organization"
										label={'Organization'}
										rules={[
											{
												required: true,
												message:
													'Please enter Region name',
											},
										]}
									>
										<XSelect
											size="large"
											placeholder="Select Organization"
											name="organization"
											showSearch={true}
											className="removeBg"
											onSearch={(search) => {
												if (search.length >= 3) {
													getOrganizations({
														search,
													});
												} else if (
													search.length === 0
												) {
													getOrganizations();
												}
											}}
											filterOption={filterByLabel}
											options={
												getOrganizationsState.apiState ===
													'success' &&
												getOrganizationsState.data.list
													?.length > 0 &&
												getOrganizationsState.data.list.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm._id,
														};
													},
												)
											}
											onSelect={(value, options) => {
												handleSelectChange(
													'organization',
													value,
													formData,
													setFormData,
												);
												getRegions({
													organization_id: value,
												});
												getHospitalDropdown({
													organization: value,
												});
												form.setFieldValue(
													'region',
													null,
												);
												form.setFieldValue(
													'hospital',
													null,
												);
											}}
										></XSelect>
									</XForm.Item>
								</Col>
							) : (
								<></>
							)}
							{displayRegion ? (
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="region"
										label={'Region'}
										rules={[
											{
												required: true,
												message:
													'Please enter Region name',
											},
										]}
									>
										<XSelect
											size="large"
											placeholder="Select Region"
											name="region"
											showSearch={true}
											className="removeBg"
											onSearch={(search) => {
												if (search.length >= 3) {
													getRegions({
														search,
													});
												} else if (
													search.length === 0
												) {
													getRegions();
												}
											}}
											filterOption={filterByLabel}
											options={
												getRegionsState.apiState ===
													'success' &&
												getRegionsState.data.regions
													?.length > 0 &&
												getRegionsState.data.regions.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm._id,
														};
													},
												)
											}
											onSelect={(value, options) => {
												handleSelectChange(
													'region',
													value,
													formData,
													setFormData,
												);
												getHospitalDropdown({
													region_id: value,
												});
												form.setFieldValue(
													'hospital',
													null,
												);
											}}
										></XSelect>
									</XForm.Item>
								</Col>
							) : (
								<></>
							)}
							{displayHospital ? (
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="hospital"
										label={'Hospital'}
										rules={[
											{
												required: true,
												message:
													'Please select hospital',
											},
										]}
									>
										<XSelect
											size="large"
											placeholder="Select Hospital"
											name="hospital"
											showSearch={true}
											onSearch={(search) => {
												if (search.length >= 3) {
													getHospitalDropdown({
														region: formData.region,
														search,
													});
												} else if (
													search.length === 0
												) {
													getHospitalDropdown({
														region: formData.region,
													});
												}
											}}
											filterOption={filterByLabel}
											options={
												getHospitalDropdownState.apiState ===
													'success' &&
												getHospitalDropdownState.data
													.hospitals?.length > 0 &&
												getHospitalDropdownState.data.hospitals.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm._id,
															visit_types:
																itm.visit_types,
														};
													},
												)
											}
											onSelect={(value, options) => {
												handleSelectChange(
													'hospital',
													value,
													formData,
													setFormData,
												);
											}}
										/>
									</XForm.Item>
								</Col>
							) : (
								<></>
							)}
							<Col xs={24} sm={12} md={12} lg={8}>
								<Form.Item
									name="is_active"
									label="Suspend User"
									rules={[
										{
											required: true,
											message: 'Please select Role!',
										},
									]}
								>
									<XSwitch
										checked={
											formData && formData.is_active
												? false
												: true
										}
										style={{
											height: '22px',
											marginLeft: '0px',
										}}
										onClick={(value) =>
											handleSelectChange(
												'is_active',
												!value,
												formData,
												setFormData,
											)
										}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row
							className="mt-4 align-items-center"
							gutter={[15, 15]}
						>
							<Col xs={12}>
								{checkModulePermissions(
									'user-management',
								).sub_modules.includes('delete-users') ? (
									<Button
										onClick={() =>
											showDeleteConfirm(
												formData.id,
												formData.name,
											)
										}
										style={{
											background: '#fff',
											color: '#007299',
											marginLeft: '0px',
										}}
										type="text"
										className="modalButton"
									>
										Delete
									</Button>
								) : (
									<></>
								)}
							</Col>
							<Col xs={12} className="text-end">
								<Button
									htmlType="submit"
									className="modalButton"
									style={{
										background: '#007299',
										color: '#fff',
									}}
								>
									Submit
								</Button>
							</Col>
						</Row>
					</XForm>
				</XCardsTransparent>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	getUserByIdState: state.getUserById,
	editUserState: state.editUser,
	getRolesDropdownState: state.getRolesDropdown,
	addUserState: state.addUser,
	getUsersState: state.getUsers,
	deleteUserState: state.deleteUser,
	getHospitalDropdownState: state.getHospitalDropdown,
	getRegionsState: state.getRegions,
	getOrganizationsState: state.getOrganizations,
});
const mapDispatchToProps = (dispatch) => ({
	addUser: (params) => dispatch(addUserApi(params)),
	addUserReset: () => dispatch(addUserReset()),
	getUserById: (params) => dispatch(getUserByIdApi(params)),
	getUserByIdReset: () => dispatch(getUserByIdReset()),
	editUser: (params) => dispatch(editUserApi(params)),
	editUserReset: () => dispatch(editUserReset()),
	getRolesDropdown: (params) => dispatch(getRolesDropdownApi(params)),
	getRolesDropdownReset: () => dispatch(getRolesDropdownReset()),
	deleteUser: (params) => dispatch(deleteUserApi(params)),
	deleteUserReset: () => dispatch(deleteUserReset()),
	getHospitalDropdown: (params) => dispatch(getHospitalDropdownApi(params)),
	getHospitalDropdownReset: () => dispatch(getHospitalDropdownReset()),
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
	getOrganizationsReset: () => dispatch(getOrganizationsReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
