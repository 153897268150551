import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { Logo, Title, XForm, XLottie } from '../Login/LoginStyle';
import {
	CenterWrapper,
	XPara1,
} from '../ForgotEmailPassword/ForgotEmailPasswordStyle';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import Navbar from 'react-bootstrap/Navbar';
import { XContainer } from '../../styles/GlobalStyle';
// Actions
import {
	resetPasswordApi,
	resetPasswordReset,
} from '../../actions/resetPasswordAction';
import { handleInputChange } from '../../utils/formFunctions';
import {
	changePasswordApi,
	changePasswordReset,
} from '../../actions/changePasswordAction';

const ResetPassword = (props) => {
	const {
		resetPassword,
		resetPasswordReset,
		resetPasswordState,
		changePassword,
		changePasswordReset,
		changePasswordState,
	} = props;

	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		email: localStorage.getItem('email'),
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (resetPasswordState.apiState === 'success') {
			notification.success({ message: resetPasswordState.message });
			resetPasswordReset();
			navigate('/login');
		} else if (resetPasswordState.apiState === 'error') {
			notification.error({ message: resetPasswordState.message });
			resetPasswordReset();
			navigate('/forgot-email-password');
		}
	}, [resetPasswordState]);

	useEffect(() => {
		if (changePasswordState.apiState === 'success') {
			notification.success({ message: changePasswordState.message });
			changePasswordReset();
			navigate('/');
		} else if (changePasswordState.apiState === 'error') {
			notification.error({ message: changePasswordState.message });
			changePasswordReset();
		}
	}, [changePasswordState]);

	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Navbar.Brand href="/">
						<Logo>
							<img
								className="img-fluid"
								src="./images/logo.svg"
								alt="logo"
							/>
						</Logo>
					</Navbar.Brand>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 8, order: 1 }}
					>
						<Title className="mb-2">Reset Password</Title>
						{localStorage.getItem('changePassword') ? (
							<XPara1 className="mb-4 mb-lg-5 text-left">
								Please reset your password for security reasons
								to proceed further.
							</XPara1>
						) : (
							<XPara1 className="mb-4 mb-lg-5 text-left">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua.
							</XPara1>
						)}

						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => {
								if (localStorage.getItem('changePassword')) {
									changePassword(formData);
								} else if (localStorage.getItem('otpVerified'))
									resetPassword(formData);
								else {
									notification.error({
										message: 'Email OTP not verified',
									});
									navigate('/forgot-email-password');
								}
							}}
						>
							<XForm.Item
								name="new_password"
								label="New Password"
								rules={[
									{
										required: true,
										message: 'Please input new password!',
									},
									{
										min: 8,
										message:
											'Password must be at least 8 characters long!',
									},
									{
										pattern:
											/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
										message:
											'Password must include at least one letter, one number, and one special character!',
									},
								]}
							>
								<Input.Password
									size="large"
									name="new_password"
									placeholder="Enter new password"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>

							<XForm.Item
								name="confirm_password"
								label="Confirm Password"
								dependencies={['new_password']}
								hasFeedback
								rules={[
									{
										required: true,
										message:
											'Please confirm your new password',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												!value ||
												getFieldValue(
													'new_password',
												) === value
											) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													'The two passwords that you entered do not match!',
												),
											);
										},
									}),
								]}
							>
								<Input.Password
									size="large"
									name="confirm_password"
									placeholder="Confirm your password"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>

							<XForm.Item className="mt-5">
								<Button htmlType="submit">
									Reset Password
								</Button>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 6, order: 2 }}
						className="text-center"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	resetPasswordState: state.resetPassword,
	changePasswordState: state.changePassword,
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (params) => dispatch(resetPasswordApi(params)),
	resetPasswordReset: () => dispatch(resetPasswordReset()),
	changePassword: (params) => dispatch(changePasswordApi(params)),
	changePasswordReset: () => dispatch(changePasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
