import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';
export const GET_MESSAGES_RESET = 'GET_MESSAGES_RESET';

export const getMessages = (params) => {
	return {
		type: GET_MESSAGES,
		params,
	};
};

export const getMessagesSuccess = (response) => {
	return {
		type: GET_MESSAGES_SUCCESS,
		response,
	};
};

export const getMessagesFail = (response) => {
	return {
		type: GET_MESSAGES_FAIL,
		response,
	};
};

export const getMessagesReset = () => {
	return {
		type: GET_MESSAGES_RESET,
	};
};

export const getMessagesApi = (data) => {
	return (dispatch) => {
		dispatch(getMessages());
		axios
			.get(`${config.api.base_url}/chat/get-messages`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				console.log('-----------------------');

				const getMessagesData = response.data;
				console.log('response.data', response.data);
				dispatch(getMessagesSuccess(getMessagesData));
			})
			.catch((error) => {
				console.log('error--------------', error);
				const errorMsg = error.message;
				dispatch(getMessagesFail(errorMsg));
			});
	};
};
