import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_REGION = 'ADD_REGION';
export const ADD_REGION_SUCCESS = 'ADD_REGION_SUCCESS';
export const ADD_REGION_FAIL = 'ADD_REGION_FAIL';
export const ADD_REGION_RESET = 'ADD_REGION_RESET';

export const addRegion = (params) => {
	return {
		type: ADD_REGION,
		params,
	};
};

export const addRegionSuccess = (response) => {
	return {
		type: ADD_REGION_SUCCESS,
		response,
	};
};

export const addRegionFail = (response) => {
	return {
		type: ADD_REGION_FAIL,
		response,
	};
};

export const addRegionReset = () => {
	return {
		type: ADD_REGION_RESET,
	};
};

export const addRegionApi = (data) => {
	return (dispatch) => {
		dispatch(addRegion());
		axios
			.post(`${config.api.base_url}/regions/create`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addRegionData = response.data;
				dispatch(addRegionSuccess(addRegionData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addRegionFail(errorMsg));
			});
	};
};
