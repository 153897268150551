import styled from 'styled-components';
import '../../styles/GlobalStyle';
import Lottie from 'lottie-react';
import { Card, Button, Divider } from 'antd';

export const XButtonRounded = styled(Button)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.color6};
	color: ${({ theme }) => theme.colors.menu2};
	border-radius: 10px !important;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
	img,
	svg {
		width: 18px !important;
	}
	&:hover:not(:disabled) {
		background: ${({ theme }) => theme.colors.white} !important;
		border-color: ${({ theme }) => theme.colors.color6} !important;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
	.rotateButton {
		transform: rotate(3.142rad);
	}

	@media only screen and (max-width: 767.99px) {
	}
`;
