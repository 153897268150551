import {
	GENERATE_APPEAL_LETTER,
	GENERATE_APPEAL_LETTER_SUCCESS,
	GENERATE_APPEAL_LETTER_FAIL,
	GENERATE_APPEAL_LETTER_RESET,
} from '../actions/generateAppealLetterAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const generateAppealLetterReducer = (state = initialState, action) => {
	switch (action.type) {
		case GENERATE_APPEAL_LETTER:
			return {
				...state,
				apiState: 'loading',
			};
		case GENERATE_APPEAL_LETTER_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response && action.response.data,
				message:
					action.response && action.response.message
						? action.response.message
						: 'success',
			};
		case GENERATE_APPEAL_LETTER_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response && action.response.errors,
				message:
					action.response && action.response.message
						? action.response.message
						: 'error',
			};
		case GENERATE_APPEAL_LETTER_RESET:
			return initialState;
		default:
			return state;
	}
};

export default generateAppealLetterReducer;
