import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';
export const EDIT_ORGANIZATION_FAIL = 'EDIT_ORGANIZATION_FAIL';
export const EDIT_ORGANIZATION_RESET = 'EDIT_ORGANIZATION_RESET';

export const editOrganization = (params) => {
	return {
		type: EDIT_ORGANIZATION,
		params,
	};
};

export const editOrganizationSuccess = (response) => {
	return {
		type: EDIT_ORGANIZATION_SUCCESS,
		response,
	};
};

export const editOrganizationFail = (response) => {
	return {
		type: EDIT_ORGANIZATION_FAIL,
		response,
	};
};

export const editOrganizationReset = () => {
	return {
		type: EDIT_ORGANIZATION_RESET,
	};
};

export const editOrganizationApi = (data) => {
	return (dispatch) => {
		dispatch(editOrganization());
		axios
			.post(`${config.api.base_url}/organization/${data.id}`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const editOrganizationData = response.data;
				dispatch(editOrganizationSuccess(editOrganizationData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editOrganizationFail(errorMsg));
			});
	};
};
