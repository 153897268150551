import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card, Button } from 'antd';

export const AipaCard = styled(Card)`
	width: 100%;
	position: relative;
	background: #ffffff;
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.ant-card-body {
		padding: 15px;
	}
	@media only screen and (max-width: 767.99px) {
		border: 1px solid rgba(87, 99, 111, 0.15);
		.ant-card-body {
			padding: 15px 0px;
		}
		label {
			width: 100% !important;
			position: relative;
			padding: 0px 15px 15px 20px !important;
			border-bottom: 1px dotted #e6e8e9;
			&:first-child {
				margin-top: 0px !important;
			}
			&:last-child {
				border: none;
				padding-bottom: 0px !important;
			}
		}
	}
`;
export const AipaAudioCard = styled(Card)`
	width: 100%;
	position: relative;
	background: #ffffff;
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	text-align: center;
	.timeTitle {
		width: 100%;
		position: relative;
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 60px;
		color: #007299;
		@media only screen and (max-width: 767.99px) {
			font-size: 32px;
			line-height: 48px;
		}
	}
	.audioControlWrap {
		width: 100%;
		position: relative;
	}
	ul.audioControl {
		margin: 0px auto;
		padding: 0px;
		max-width: 200px;
		background: #e3e6ec;
		border-radius: 10px;
	}
	ul.audioControl li {
		list-style-type: none;
		display: inline;
		padding: 15px 0px !important;
		&:nth-child(2) {
			background-color: #e3e6ec;
			border-radius: 100% !important;
			padding: 25px 15px !important;
		}
	}
	@media only screen and (max-width: 767.99px) {
		.ant-card-body {
		}
	}
`;
export const AipaPlayBtn = styled(Button)`
	margin: 0px !important;
	background: #007299 !important;
	padding: 16px 0px !important;
	width: 55px !important;
	border-radius: 100px !important;
	&:hover {
		background: #000 !important;
		border-color: #fff !important;
		svg {
			color: #fff;
		}
	}
	@media only screen and (max-width: 767.99px) {
	}
`;
export const AipaOtherBtn = styled(Button)`
	margin: 5px !important;
	padding: 5px !important;
	padding: 5px !important;
	background: #ffffff !important;
	border-radius: 10px !important;
	svg {
		color: #007299;
	}
	@media only screen and (max-width: 767.99px) {
	}
`;
export const AipaAudioBtn = styled(Button)`
	margin: 0px !important;
	background: #007299 !important;
	padding: 15px;
	width: 60px !important;
	height: 60px !important;
	border-radius: 50% !important;
	border: 5px solid #cce3eb !important;
	svg {
		color: #fff;
		font-size: 24px;
	}
	&:hover {
		background: #000 !important;
		border: 5px solid #cce3eb !important;
		svg {
			color: #fff;
		}
	}
	@media only screen and (max-width: 767.99px) {
	}
`;
