import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const EDIT_CONFIGURATION = 'EDIT_CONFIGURATION';
export const EDIT_CONFIGURATION_SUCCESS = 'EDIT_CONFIGURATION_SUCCESS';
export const EDIT_CONFIGURATION_FAIL = 'EDIT_CONFIGURATION_FAIL';
export const EDIT_CONFIGURATION_RESET = 'EDIT_CONFIGURATION_RESET';

export const editConfiguration = (params) => {
	return {
		type: EDIT_CONFIGURATION,
		params,
	};
};

export const editConfigurationSuccess = (response) => {
	return {
		type: EDIT_CONFIGURATION_SUCCESS,
		response,
	};
};

export const editConfigurationFail = (response) => {
	return {
		type: EDIT_CONFIGURATION_FAIL,
		response,
	};
};

export const editConfigurationReset = () => {
	return {
		type: EDIT_CONFIGURATION_RESET,
	};
};

export const editConfigurationApi = (data) => {
	return (dispatch) => {
		dispatch(editConfiguration());
		axios
			.post(
				`${config.api.base_url}/configurations/${data._id}`,
				data.config,
				{
					headers: {
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			)
			.then((response) => {
				const editConfigurationData = response.data;
				dispatch(editConfigurationSuccess(editConfigurationData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(editConfigurationFail(errorMsg));
			});
	};
};
