import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XContainer, XForm, XSwitch } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import { Row, Col, Button, Input, notification, Flex } from 'antd';
import { XCardsProfileBg, XAvatar, XUpload } from './ProfileStyle';
import { XRadio } from '../CaseForm/CaseFormStyle';
import axios from 'axios';
// actions
import {
	updateProfileApi,
	updateProfileReset,
} from '../../actions/updateProfileAction';
import {
	getUserByIdApi,
	getUserByIdReset,
} from '../../actions/getUserByIdAction';
import {
	editPreCalculatedDataApi,
	editPreCalculatedDataReset,
} from '../../actions/editPreCalculatedDataAction';
// utils
import { handleInputChange } from '../../utils/formFunctions';
import config from '../../config';
import { getUserRole, getUserToken } from '../../utils/helper';
import Header from '../../components/Header/Header';
import { getConfigurationOptionsApi } from '../../actions/getConfigurationOptionsAction';
import { Editor } from '@tinymce/tinymce-react';

const Profile = (props) => {
	const {
		updateProfile,
		updateProfileReset,
		updateProfileState,
		getUserById,
		getUserByIdReset,
		getUserByIdState,
		getConfigurationOptions,
		getConfigurationOptionsState,
		editPreCalculatedDataState,
		editPreCalculatedData,
		editPreCalculatedDataReset,
	} = props;
	const navigate = useNavigate();
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const signatureEditorRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [preview, setPreview] = useState('');
	const [triggerUpdate, setTriggerUpdate] = useState(false);

	const handleTextEditorData = (content, editor) => {
		if (signatureEditorRef.current) {
			const html = signatureEditorRef.current.getContent();
			form.setFieldsValue({
				signature: html,
			});
			setFormData((prevData) => ({
				...prevData,
				signature: html,
			}));
		}
	};

	useEffect(() => {
		getUserById({ profile: true });
		getConfigurationOptions({
			key: 'gender',
		});
	}, []);

	useEffect(() => {
		if (getUserByIdState.apiState === 'success') {
			var fname = getUserByIdState.data.name.split(' ')[0];
			var lname = getUserByIdState.data.name.split(' ')[1] ?? '';

			setFormData({
				id: getUserByIdState.data._id,
				first_name: fname,
				last_name: lname,
				email: getUserByIdState.data.email,
				mobile: getUserByIdState.data.mobile,
				gender: getUserByIdState.data.gender,
				profile_image: getUserByIdState.data.profile_image,
				signature: getUserByIdState.data.signature,
				desktopNotificationsEnabled:
					getUserByIdState.data.desktopNotificationsEnabled,
				emailNotificationsEnabled:
					getUserByIdState.data.emailNotificationsEnabled,
			});
			form.setFieldsValue({
				first_name: fname,
				last_name: lname,
				email: getUserByIdState.data.email,
				mobile: getUserByIdState.data.mobile,
				gender: getUserByIdState.data.gender,
				signature: getUserByIdState.data.signature,
			});
			getUserByIdReset();
		}
		// else if (getUserByIdState.apiState === 'error') {
		// 	notification.error({ message: getUserByIdState.message });
		// 	getUserByIdReset();
		// }
	}, [getUserByIdState]);

	useEffect(() => {
		if (updateProfileState.apiState === 'success') {
			localStorage.setItem(
				'profile',
				updateProfileState.data.profile_image,
			);
			notification.success({ message: updateProfileState.message });
			setTriggerUpdate(false);
			setSubmitLoading(false);
			updateProfileReset();
		} else if (updateProfileState.apiState === 'error') {
			notification.error({ message: updateProfileState.message });
			setSubmitLoading(false);
			updateProfileReset();
		}
	}, [updateProfileState]);

	const handleDesktopNotificationChange = (value) => {
		setFormData({ ...formData, desktopNotificationsEnabled: value });
	};

	const handleEmailNotificationChange = (value) => {
		setFormData({ ...formData, emailNotificationsEnabled: value });
	};

	useEffect(() => {
		if (editPreCalculatedDataState.apiState === 'success') {
			setLoading(false);
			notification.success({
				message: editPreCalculatedDataState.message,
			});
			editPreCalculatedDataReset();
		} else if (editPreCalculatedDataState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: editPreCalculatedDataState.message });
			editPreCalculatedDataReset();
		}
	}, [editPreCalculatedDataState]);

	const handleRecalculateDashboard = () => {
		setLoading(true);
		editPreCalculatedData();
	};

	const handleChange = (options) => {
		const file = options.file;
		const isPDF = file.type.startsWith('application/'); // Check if the file is a PDF
		const isImage = file.type.startsWith('image/'); // Check if the file is an image

		//
		const isGIF = file.type === 'image/gif';
		const isVideo = file.type.startsWith('video/');
		const isAudio = file.type.startsWith('audio/');

		// const isLt10M = file.size / 1024 / 1024 < 10;

		if (isGIF || isVideo || isAudio || isPDF || !isImage) {
			console.error('Only image files are allowed.');
			notification.error({
				message: 'File Type Error',
				description: `GIF, video, pdf and audio files are not allowed.`,
			});
			setFileToUpload(null);
			setPreview('');
			return; // Prevent upload
		}
		//

		const url = URL.createObjectURL(file);
		setPreview(url);

		setFileToUpload(options);
		options.onSuccess(file);
	};

	const uploadFile = async (options) => {
		if (!fileToUpload) {
			setTriggerUpdate(true);
		} else {
			try {
				let get_ext = options.file.name.split('.');
				let fil_name = options.file.name.split('.')[0];

				let ext = get_ext[get_ext.length - 1];
				const res = await axios.get(
					`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=users&file_name=${fil_name}&ext=${ext}&id=${formData.id}`,
					{
						headers: {
							'Content-type': 'application/json; charset=UTF-8',
							Authorization: 'Bearer ' + getUserToken(),
						},
					},
				);
				const result = await axios.put(res.data.data, options.file, {
					onUploadProgress: ({ total, loaded }) => {
						options.onProgress(
							{
								percent: Math.round(
									(loaded / total) * 100,
								).toFixed(0),
							},
							options.file,
						);
					},
				});

				if (result.status === 200) {
					let documentPath = `/users/${formData.id}/${fil_name}.${ext}`;
					setFormData({
						...formData,
						profile_image: documentPath,
					});
					options.onSuccess(result, options.file);
				}
				// return result;
			} catch (err) {
				console.log(err);
				options.onError(err);
			}
			setTriggerUpdate(true);
		}
	};

	useEffect(() => {
		if (triggerUpdate) {
			updateProfile({
				...formData,
				// signature:
				// 	signatureEditorRef.current.getContent(),
			});
		}
	}, [triggerUpdate]);

	const handleOnFinish = () => {
		form.validateFields().then(() => {
			setSubmitLoading(true);
			uploadFile(fileToUpload);
		});
	};

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Profile" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile />
				</Col>
			</>
			<XContainer fluid>
				<XCardsProfileBg>
					<div className="profileHead"></div>
					<XForm
						form={form}
						name="loginForm"
						layout="vertical"
						autoComplete="off"
						onFinish={() => {
							handleOnFinish();
							// updateProfile({
							// 	...formData,
							// 	// signature:
							// 	// 	signatureEditorRef.current.getContent(),
							// });
						}}
					>
						<div className="innerBody">
							<Row>
								<Col className="m-auto text-center">
									<XAvatar
										size={{
											xs: 100,
											sm: 100,
											md: 100,
											lg: 100,
											xl: 100,
											xxl: 100,
										}}
										icon={
											<img
												className="img-fluid"
												style={{ background: '#fff' }}
												src={
													preview
														? preview
														: formData.profile_image
														? `${config.s3_url}${formData.profile_image}`
														: '/images/prifile.svg'
												}
												alt=""
											/>
										}
									/>
								</Col>
							</Row>
							<Row className="my-4">
								<Col className="m-auto text-center">
									<XUpload
										maxCount={1}
										accept=".jpeg, .jpg, .png"
										showUploadList={false} // Hides the file name and upload list
										customRequest={(options) => {
											handleChange(options);
										}}
										// fileList={fileList}
										// onChange={handleChange}
										// customRequest={async (options) => {

										// }}
									>
										<Button
											// className="cancel"
											style={{
												color: '#007299',
											}}
										>
											Upload Profile Picture
										</Button>
									</XUpload>
								</Col>
							</Row>
							<Row gutter={(15, 30)}>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="first_name"
										label="First Name"
										rules={[
											{
												required: true,
												message:
													'Please input First Name!',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter first name"
											name="first_name"
											onChange={(e) => {
												handleInputChange(
													e,
													formData,
													setFormData,
												);
											}}
										/>
									</XForm.Item>
								</Col>
								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="last_name"
										label="Last Name"
										rules={[
											{
												required: true,
												message:
													'Please input Last Name!',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter Last name"
											name="last_name"
											onChange={(e) => {
												handleInputChange(
													e,
													formData,
													setFormData,
												);
											}}
										/>
									</XForm.Item>
								</Col>

								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="email"
										label="Email Address"
										rules={[
											{
												required: true,
												message:
													'Please input Email Address!',
											},
										]}
									>
										<Input
											size="large"
											placeholder="Enter email address"
											name="email"
											onChange={(e) => {
												handleInputChange(
													e,
													formData,
													setFormData,
												);
											}}
											readOnly
										/>
									</XForm.Item>
								</Col>

								<Col xs={24} sm={12} md={12} lg={8}>
									<XForm.Item
										name="gender"
										label="Gender"
										rules={[
											{
												required: true,
												message:
													'Please select Gender!',
											},
										]}
									>
										<XRadio.Group
											onChange={(e) => {
												handleInputChange(
													e,
													formData,
													setFormData,
												);
											}}
											// value={value}
											name="gender"
										>
											{getConfigurationOptionsState.apiState ===
												'success' &&
												getConfigurationOptionsState
													.data.configuration_options
													?.length > 0 &&
												getConfigurationOptionsState.data.configuration_options.map(
													(itm) => (
														<XRadio
															value={itm.value}
														>
															{itm.label}
														</XRadio>
													),
												)}
										</XRadio.Group>
									</XForm.Item>
								</Col>
								{/* add new one start*/}
								<Col xs={24} sm={12} md={12} lg={8}>
									<Row>
										<Col
											xs={18}
											lg={19}
											md={20}
											className="mb-4"
										>
											<span>Desktop Notification</span>
										</Col>
										<Col
											xs={6}
											lg={5}
											md={4}
											className="mb-4"
										>
											<XSwitch
												defaultChecked
												size="small"
												onChange={(value) =>
													handleDesktopNotificationChange(
														value,
													)
												}
												checked={
													formData.desktopNotificationsEnabled
												}
											/>
										</Col>
									</Row>
								</Col>

								<Col xs={24} sm={12} md={12} lg={8}>
									<Row>
										<Col
											xs={18}
											lg={19}
											md={20}
											className="mb-4"
										>
											<span>Email Notification</span>
										</Col>
										<Col
											xs={6}
											lg={5}
											md={4}
											className="mb-4"
										>
											<XSwitch
												defaultChecked
												size="small"
												onChange={(value) =>
													handleEmailNotificationChange(
														value,
													)
												}
												checked={
													formData.emailNotificationsEnabled
												}
											/>
										</Col>
									</Row>
								</Col>
								{getUserRole() === 'super-admin' && (
									<Col xs={24} sm={12} md={12} lg={8}>
										<Row>
											<Col
												xs={18}
												lg={19}
												md={20}
												className="mb-4"
											>
												<span>
													Recalculate All Dashboard
													Metrics
												</span>
											</Col>
											<Col
												xs={6}
												lg={5}
												md={4}
												className="mb-4"
											>
												<Button
													onClick={
														handleRecalculateDashboard
													}
													loading={loading}
													style={{
														background: '#007299',
													}}
													type="primary"
												>
													Recalculate Data
												</Button>
											</Col>
										</Row>
									</Col>
								)}

								{/* add new one end */}
								<Col xs={24} lg={24} md={24} sm={24}>
									<XForm.Item
										label="Signature"
										name="signature"
										rules={[
											{
												required: true,
												message:
													'Please enter signature',
											},
										]}
										validateStatus={
											formData.signature ? '' : 'error'
										}
										help={
											!formData.signature
												? 'Please input Signature.'
												: ''
										}
									>
										<Input
											name="signature"
											readOnly
											style={{ display: 'none' }} // Hide the input field
										/>
										<Editor
											apiKey={config.tiny_url_key}
											value={formData.signature}
											// initialValue={formData.signature}
											onInit={(evt, editor) =>
												(signatureEditorRef.current =
													editor)
											}
											init={{
												menubar: false,
												height: 200,
												width: '100%',
												plugins: [
													'code',
													'image',
													'link',
													'visualblocks',
												],
												branding: false,
												toolbar:
													'undo redo | formatselect | ' +
													'bold italic backcolor | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | ' +
													'bullist numlist outdent indent | image link |' +
													'removeformat | visualblocks code | help ',
												content_style:
													'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
												font_size_formats:
													'8px 10px 12px 14px 18px 24px 36px',
											}}
											onEditorChange={(
												content,
												editor,
											) => {
												handleTextEditorData(
													content,
													editor,
												);
											}}
										/>
									</XForm.Item>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col
									xs={24}
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
									className="text-lg-end"
								>
									<Button
										onClick={() => navigate(-1)}
										className="cancel"
										style={{
											color: '#007299',
										}}
									>
										Cancel
									</Button>
									<Button
										loading={submitLoading}
										style={{ background: '#007299' }}
										htmlType="submit"
										type="primary"
									>
										Submit
									</Button>
								</Col>
							</Row>
						</div>
					</XForm>
				</XCardsProfileBg>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	updateProfileState: state.updateProfile,
	getUserByIdState: state.getUserById,
	getConfigurationOptionsState: state.getConfigurationOptions,
	editPreCalculatedDataState: state.editPreCalculatedData,
});

const mapDispatchToProps = (dispatch) => ({
	updateProfile: (data) => dispatch(updateProfileApi(data)),
	updateProfileReset: () => dispatch(updateProfileReset()),
	getUserById: (params) => dispatch(getUserByIdApi(params)),
	getUserByIdReset: () => dispatch(getUserByIdReset()),
	getConfigurationOptions: (params) =>
		dispatch(getConfigurationOptionsApi(params)),
	editPreCalculatedData: (data) => dispatch(editPreCalculatedDataApi(data)),
	editPreCalculatedDataReset: () => dispatch(editPreCalculatedDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
