import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const ADD_HOSPITAL = 'ADD_HOSPITAL';
export const ADD_HOSPITAL_SUCCESS = 'ADD_HOSPITAL_SUCCESS';
export const ADD_HOSPITAL_FAIL = 'ADD_HOSPITAL_FAIL';
export const ADD_HOSPITAL_RESET = 'ADD_HOSPITAL_RESET';

export const addHospital = (params) => {
	return {
		type: ADD_HOSPITAL,
		params,
	};
};

export const addHospitalSuccess = (response) => {
	return {
		type: ADD_HOSPITAL_SUCCESS,
		response,
	};
};

export const addHospitalFail = (response) => {
	return {
		type: ADD_HOSPITAL_FAIL,
		response,
	};
};

export const addHospitalReset = () => {
	return {
		type: ADD_HOSPITAL_RESET,
	};
};

export const addHospitalApi = (data) => {
	return (dispatch) => {
		dispatch(addHospital());
		axios
			.post(`${config.api.base_url}/hospitals/create`, data, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const addHospitalData = response.data;
				dispatch(addHospitalSuccess(addHospitalData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(addHospitalFail(errorMsg));
			});
	};
};
