import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_APPEAL_LETTER = 'GET_APPEAL_LETTER';
export const GET_APPEAL_LETTER_SUCCESS = 'GET_APPEAL_LETTER_SUCCESS';
export const GET_APPEAL_LETTER_FAIL = 'GET_APPEAL_LETTER_FAIL';
export const GET_APPEAL_LETTER_RESET = 'GET_APPEAL_LETTER_RESET';

export const getAppealLetter = (params) => {
	return {
		type: GET_APPEAL_LETTER,
		params,
	};
};

export const getAppealLetterSuccess = (response) => {
	return {
		type: GET_APPEAL_LETTER_SUCCESS,
		response,
	};
};

export const getAppealLetterFail = (response) => {
	return {
		type: GET_APPEAL_LETTER_FAIL,
		response,
	};
};

export const getAppealLetterReset = () => {
	return {
		type: GET_APPEAL_LETTER_RESET,
	};
};

export const getAppealLetterApi = (data) => {
	return (dispatch) => {
		dispatch(getAppealLetter());
		axios
			.get(`${config.api.base_url}/appeal-letter/${data.id}`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getAppealLetterData = response.data;
				dispatch(getAppealLetterSuccess(getAppealLetterData));
			})
			.catch((error) => {
				const errorMsg = error.response.data;
				dispatch(getAppealLetterFail(errorMsg));
			});
	};
};
