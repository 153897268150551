
import { 
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL, 
    GET_USERS_RESET,
} from "../actions/getUsersAction";

const initialState = {
    apiState: "",
    message: "",
    data: null,
    error: "",
}

const getUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                apiState: "loading",
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
                message: action.response.message ? action.response.message : "success",
            };
        case GET_USERS_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response.errors,
                message: action.response.message ? action.response.message : "error",
            };
        case GET_USERS_RESET:
            return initialState;
        default: 
            return state;
    }
};

export default getUsersReducer;
