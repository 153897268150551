import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { AutoComplete, Form, Select } from 'antd';

export const Title = styled.h1`
	color: ${({ theme }) => theme.colors.menu2};
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.36px;
	margin-bottom: 2px;
	@media only screen and (max-width: 768.99px) {
		font-size: 16px !important;
	}
`;
export const Title2 = styled.h2`
	margin: 0px;
	color: ${({ theme }) => theme.colors.menu2};
	font-weight: 600;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;

	@media only screen and (min-width: 768.99px) {
	}
`;
export const XAutoComplete = styled(AutoComplete)`
	width: 100%;
	.ant-input {
		border-radius: 25px;
		border: 1px solid rgba(6, 36, 66, 0.15);
		box-shadow: none;
		height: 40px;
	}
	background: ${({ theme }) => theme.colors.white};
	@media only screen and (min-width: 768.99px) {
	}
`;
export const XForm = styled(Form)`
	.ant-form-item-label label {
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-top: 5px;
	}
	@media only screen and (min-width: 768.99px) {
	}
`;
export const XSelect = styled(Select)`
	.ant-select-selector {
		border-radius: 25px !important;
		border: 1px solid rgba(6, 36, 66, 0.15) !important;
		background: transparent !important;
		box-shadow: none;
		height: 40px !important;
		color: ${({ theme }) => theme.colors.menu2};
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400 !important;
		line-height: 16px;
	}
	@media only screen and (min-width: 768.99px) {
	}
`;
