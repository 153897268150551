import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Card, Button, Divider, List, Collapse, Dragger } from 'antd';

export const XListItem = styled(List.Item)`
	width: 100%;
	position: relative;
	display: inline-block;
	margin-bottom: 10px;
	background: #ffffff;
	border: 1px solid #d1d7e5;
	box-shadow: inset 0px -5px 2px #eeeff7;
	border-radius: 10px;
	padding-left: 15px !important;
	.ant-list-item {
		padding: 15px 0px 20px 15px !important;
	}
	.textEleps {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
export const XprepareLetter = styled(Card)`
	border: 1px solid rgba(33, 34, 38, 0.1);
	border-radius: 10px;
	.ant-card-body {
		padding: 24px 15px !important;
		background: ${({ theme }) => theme.colors.white};
		@media only screen and (max-width: 768.99px) {
		}
	}
	.heading {
		font-family: ${({ theme }) => theme.colors.fontInter};
		font-weight: 500;
		font-size: 15px;
		line-height: 28px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.dividerLine {
		margin: 15px 0px;
		background: ${({ theme }) => theme.colors.color11};
		opacity: 0.08;
	}
	.referencesCard {
		width: 100%;
		position: relative;
		background: #f6f7fb;
		border: 1px solid #d1d7e5;
		border-radius: 10px;
		padding: 15px;
	}
	.inputBox input {
		background: #fff !important;
	}
	.para1 {
		margin: 0px;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.denialMessageCard {
		width: 100%;
		position: relative;
		background: #dfebf080;
		border-radius: 10px;
		padding: 15px;
		border: 1px solid #dfebf0;
	}
	.denialMessageText {
		font-family: Inter;
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;
		color: #007299;
		word-spacing: 2px; /* Adjust the value as needed */
		letter-spacing: 0.5px; /* Optional: Adjust letter spacing as well */
	}
`;

export const XUploadButton = styled(Button)`
	display: block;
	height: 70px;
	width: 75px;
	background: #ffffff;
	border: 1px solid #d1d7e5;
	box-shadow: inset 0px -5px 2px #eeeff7;
	border-radius: 10px;
	font-size: 12px;
	line-height: 20px;
	.pluseBtn {
		display: inline-block;
		margin-bottom: 5px;
		background: #57636f;
		color: ${({ theme }) => theme.colors.white} !important;
		fill: ${({ theme }) => theme.colors.white} !important;
		padding: 3px 5px !important;
		border-radius: 5px;
	}
`;
export const XCardPdfOther = styled(Card)`
	height: 70px;
	width: 75px;
	background: #ffffff;
	border: 1px solid #d1d7e5;
	box-shadow: inset 0px -5px 2px #eeeff7;
	border-radius: 10px;
	font-sizw: 12px;
	line-height: 20px;
`;
export const XCollapse = styled(Collapse)`
	width: 100% !important;
	background: none !important;
	padding: 5px;
	.ant-collapse-header {
		display:block !important;
		background: none !important;
		padding: 0px 15px 0px 0px !important;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px !important;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-collapse-expand-icon{
		position:absolute;
		right:0px;
	}
	.ant-list-item {
		width: 100%;
		position: relative;
		padding: 0px;
		display: block important;
	}
	.textEleps {
		position:relative;
		width:100%;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
	.fixedButton {
		position: absolute;
		right: 0;
	}
	.ant-collapse-content-box {
		border-top: 1px dashed #e1e2e6;
		padding: 10px 0px !important;
		margin-top: 18px;
	}
	p.para1 {
		margin:0px;
		color: ${({ theme }) => theme.colors.menu2};
		font-family: color: ${({ theme }) => theme.colors.fontInter};
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	}
	.draggerUpload{
		width:100%;
		position:relative;
		.ant-upload-drag-icon{
			margin-bottom:10px !important;
			svg{
				color:${({ theme }) => theme.colors.menu2} !important;
				font-size:32px !important;
			}
		}
		.ant-upload-drag{
			background:${({ theme }) => theme.colors.white} !important;
			border: 1px solid rgba(0, 33, 65, 0.1);
			border-radius: 10px;
		}
		.ant-upload-text{
			font-weight: 400!important;
			font-size: 12px !important;
			line-height: 16px!important;
			text-align: center!important;
			color: ${({ theme }) => theme.colors.menu2} !important;
			opacity:0.5;
		}
	}
	.inputBox{
		background: ${({ theme }) => theme.colors.white} !important;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 11px;
		line-height: 24px;
		color: #002141;
	}
	.btnActive2 button{
		background: #FFF !important;
		color:${({ theme }) => theme.colors.white} !important;
	}
	.btnActive2 button svg{
		filter: invert(0) !important;	
	}
	@media only screen and (max-width: 768.99px) {
	}
`;
export const XUploadMrkButton = styled(Button)`
	width: 100%;
	position: relative;
	margin: 0px !important;
	background: #007299 !important;
	color: ${({ theme }) => theme.colors.white} !important;
	border-radius: 10px !important;
	padding: 10px 0px !important;
	height: auto !important;
	// font-size: 14px !important;
	line-height: normal;
	svg {
		margin-right: 10px;
	}
`;
