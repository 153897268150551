import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_RESET = 'GET_USERS_RESET';

export const getUsers = (params) => {
	return {
		type: GET_USERS,
		params,
	};
};

export const getUsersSuccess = (response) => {
	return {
		type: GET_USERS_SUCCESS,
		response,
	};
};

export const getUsersFail = (response) => {
	return {
		type: GET_USERS_FAIL,
		response,
	};
};

export const getUsersReset = () => {
	return {
		type: GET_USERS_RESET,
	};
};

export const getUsersApi = (data) => {
	return (dispatch) => {
		dispatch(getUsers());
		axios
			.get(`${config.api.base_url}/settings/get-all-users`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getUsersData = response.data;
				dispatch(getUsersSuccess(getUsersData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getUsersFail(errorMsg));
			});
	};
};
