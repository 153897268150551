import {
	ADD_ORGANIZATION_ROLE,
	ADD_ORGANIZATION_ROLE_SUCCESS,
	ADD_ORGANIZATION_ROLE_FAIL,
	ADD_ORGANIZATION_ROLE_RESET,
} from '../actions/addOrganizationRoleAction';

const initialState = {
	apiState: '',
	message: '',
	data: null,
	error: '',
};

const addOrganizationRoleReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_ORGANIZATION_ROLE:
			return {
				...state,
				apiState: 'loading',
			};
		case ADD_ORGANIZATION_ROLE_SUCCESS:
			return {
				...state,
				apiState: 'success',
				data: action.response.data,
				message: action.response.message
					? action.response.message
					: 'success',
			};
		case ADD_ORGANIZATION_ROLE_FAIL:
			return {
				...state,
				apiState: 'error',
				error: action.response.errors,
				message: action.response.message
					? action.response.message
					: 'error',
			};
		case ADD_ORGANIZATION_ROLE_RESET:
			return initialState;
		default:
			return state;
	}
};

export default addOrganizationRoleReducer;
