import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_HOSPITAL_DROPDOWN = 'GET_HOSPITAL_DROPDOWN';
export const GET_HOSPITAL_DROPDOWN_SUCCESS = 'GET_HOSPITAL_DROPDOWN_SUCCESS';
export const GET_HOSPITAL_DROPDOWN_FAIL = 'GET_HOSPITAL_DROPDOWN_FAIL';
export const GET_HOSPITAL_DROPDOWN_RESET = 'GET_HOSPITAL_DROPDOWN_RESET';

export const getHospitalDropdown = (params) => {
	return {
		type: GET_HOSPITAL_DROPDOWN,
		params,
	};
};

export const getHospitalDropdownSuccess = (response) => {
	return {
		type: GET_HOSPITAL_DROPDOWN_SUCCESS,
		response,
	};
};

export const getHospitalDropdownFail = (response) => {
	return {
		type: GET_HOSPITAL_DROPDOWN_FAIL,
		response,
	};
};

export const getHospitalDropdownReset = () => {
	return {
		type: GET_HOSPITAL_DROPDOWN_RESET,
	};
};

export const getHospitalDropdownApi = (data) => {
	return (dispatch) => {
		dispatch(getHospitalDropdown());
		axios
			.get(`${config.api.base_url}/hospitals/dropdown`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getHospitalDropdownData = response.data;
				dispatch(getHospitalDropdownSuccess(getHospitalDropdownData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getHospitalDropdownFail(errorMsg));
			});
	};
};
