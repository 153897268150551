import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const DELETE_HOSPITAL = 'DELETE_HOSPITAL';
export const DELETE_HOSPITAL_SUCCESS = 'DELETE_HOSPITAL_SUCCESS';
export const DELETE_HOSPITAL_FAIL = 'DELETE_HOSPITAL_FAIL';
export const DELETE_HOSPITAL_RESET = 'DELETE_HOSPITAL_RESET';

export const deleteHospital = (params) => {
	return {
		type: DELETE_HOSPITAL,
		params,
	};
};

export const deleteHospitalSuccess = (response) => {
	return {
		type: DELETE_HOSPITAL_SUCCESS,
		response,
	};
};

export const deleteHospitalFail = (response) => {
	return {
		type: DELETE_HOSPITAL_FAIL,
		response,
	};
};

export const deleteHospitalReset = () => {
	return {
		type: DELETE_HOSPITAL_RESET,
	};
};

export const deleteHospitalApi = (data) => {
	return (dispatch) => {
		dispatch(deleteHospital());
		axios
			.delete(`${config.api.base_url}/hospitals/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const deleteHospitalData = response.data;
				dispatch(deleteHospitalSuccess(deleteHospitalData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(deleteHospitalFail(errorMsg));
			});
	};
};
