import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_STATES_LIST = 'GET_STATES_LIST';
export const GET_STATES_LIST_SUCCESS = 'GET_STATES_LIST_SUCCESS';
export const GET_STATES_LIST_FAIL = 'GET_STATES_LIST_FAIL';
export const GET_STATES_LIST_RESET = 'GET_STATES_LIST_RESET';

export const getStatesList = (params) => {
	return {
		type: GET_STATES_LIST,
		params,
	};
};

export const getStatesListSuccess = (response) => {
	return {
		type: GET_STATES_LIST_SUCCESS,
		response,
	};
};

export const getStatesListFail = (response) => {
	return {
		type: GET_STATES_LIST_FAIL,
		response,
	};
};

export const getStatesListReset = () => {
	return {
		type: GET_STATES_LIST_RESET,
	};
};

export const getStatesListApi = (data) => {
	return (dispatch) => {
		dispatch(getStatesList());
		axios
			.get(`${config.api.base_url}/common/states-list`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getStatesListData = response.data;
				dispatch(getStatesListSuccess(getStatesListData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getStatesListFail(errorMsg));
			});
	};
};
