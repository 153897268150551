import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_PRE_CALCULATED_DATA = 'GET_PRE_CALCULATED_DATA';
export const GET_PRE_CALCULATED_DATA_SUCCESS =
	'GET_PRE_CALCULATED_DATA_SUCCESS';
export const GET_PRE_CALCULATED_DATA_FAIL = 'GET_PRE_CALCULATED_DATA_FAIL';
export const GET_PRE_CALCULATED_DATA_RESET = 'GET_PRE_CALCULATED_DATA_RESET';

export const getPreCalculatedData = (params) => {
	return {
		type: GET_PRE_CALCULATED_DATA,
		params,
	};
};

export const getPreCalculatedDataSuccess = (response) => {
	return {
		type: GET_PRE_CALCULATED_DATA_SUCCESS,
		response,
	};
};

export const getPreCalculatedDataFail = (response) => {
	return {
		type: GET_PRE_CALCULATED_DATA_FAIL,
		response,
	};
};

export const getPreCalculatedDataReset = () => {
	return {
		type: GET_PRE_CALCULATED_DATA_RESET,
	};
};

export const getPreCalculatedDataApi = (data) => {
	return (dispatch) => {
		dispatch(getPreCalculatedData());
		axios
			.get(`${config.api.base_url}/dashboard/get-precalculated-data`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const preCalculatedData = response.data;
				dispatch(getPreCalculatedDataSuccess(preCalculatedData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getPreCalculatedDataFail(errorMsg));
			});
	};
};
