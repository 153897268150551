import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_PAYER_CONFIGURATION_BY_ID = 'GET_PAYER_CONFIGURATION_BY_ID';
export const GET_PAYER_CONFIGURATION_BY_ID_SUCCESS = 'GET_PAYER_CONFIGURATION_BY_ID_SUCCESS';
export const GET_PAYER_CONFIGURATION_BY_ID_FAIL = 'GET_PAYER_CONFIGURATION_BY_ID_FAIL';
export const GET_PAYER_CONFIGURATION_BY_ID_RESET = 'GET_PAYER_CONFIGURATION_BY_ID_RESET';

export const getPayerConfigurationById = (params) => {
	return {
		type: GET_PAYER_CONFIGURATION_BY_ID,
		params,
	};
};

export const getPayerConfigurationByIdSuccess = (response) => {
	return {
		type: GET_PAYER_CONFIGURATION_BY_ID_SUCCESS,
		response,
	};
};

export const getPayerConfigurationByIdFail = (response) => {
	return {
		type: GET_PAYER_CONFIGURATION_BY_ID_FAIL,
		response,
	};
};

export const getPayerConfigurationByIdReset = () => {
	return {
		type: GET_PAYER_CONFIGURATION_BY_ID_RESET,
	};
};

export const getPayerConfigurationByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getPayerConfigurationById());
		axios
			.get(`${config.api.base_url}/payer-configuration/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getPayerConfigurationByIdData = response.data;
				dispatch(getPayerConfigurationByIdSuccess(getPayerConfigurationByIdData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getPayerConfigurationByIdFail(errorMsg));
			});
	};
};
