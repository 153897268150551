import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_INSURANCE_COMPANIES_BUCKET = 'GET_INSURANCE_COMPANIES_BUCKET';
export const GET_INSURANCE_COMPANIES_BUCKET_SUCCESS = 'GET_INSURANCE_COMPANIES_BUCKET_SUCCESS';
export const GET_INSURANCE_COMPANIES_BUCKET_FAIL = 'GET_INSURANCE_COMPANIES_BUCKET_FAIL';
export const GET_INSURANCE_COMPANIES_BUCKET_RESET = 'GET_INSURANCE_COMPANIES_BUCKET_RESET';

export const getInsuranceCompaniesBucket = (params) => {
	return {
		type: GET_INSURANCE_COMPANIES_BUCKET,
		params,
	};
};

export const getInsuranceCompaniesBucketSuccess = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_BUCKET_SUCCESS,
		response,
	};
};

export const getInsuranceCompaniesBucketFail = (response) => {
	return {
		type: GET_INSURANCE_COMPANIES_BUCKET_FAIL,
		response,
	};
};

export const getInsuranceCompaniesBucketReset = () => {
	return {
		type: GET_INSURANCE_COMPANIES_BUCKET_RESET,
	};
};

export const getInsuranceCompaniesBucketApi = (data) => {
	return (dispatch) => {
		dispatch(getInsuranceCompaniesBucket());
		axios
			.get(`${config.api.base_url}/dashboard/get-insurance-company-bucket`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getInsuranceCompaniesBucketData = response.data;
				dispatch(getInsuranceCompaniesBucketSuccess(getInsuranceCompaniesBucketData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getInsuranceCompaniesBucketFail(errorMsg));
			});
	};
};
