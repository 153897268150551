import React from 'react';
import { connect } from 'react-redux';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';

import { createRoleApi, createRoleReset } from '../../actions/createRoleAction';
import { Col, Row } from 'antd';
import { XLottieMargin } from '../AnalyseCoverage/AnalyseCoverageStyle';
import { Title, XLottie } from '../Login/LoginStyle';
import LoginAnimation from '../../json/Login.json';
import Header from '../../components/Header/Header';

const CaseLiterature = (props) => {
	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Case Literature" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="caseLiterature" />
				</Col>
			</>
			<XContainer fluid>
				<div id="getQust" style={{ marginTop: '3rem' }}>
					<Row>
						<Col
							xs={{ span: 24, offset: 0 }}
							lg={{ span: 6, offset: 9 }}
							className="text-center"
						>
							<XLottieMargin>
								<XLottie
									animationData={LoginAnimation}
									loop={true}
								/>
							</XLottieMargin>
						</Col>
					</Row>
					<Row>
						<Col xs={24} lg={24}>
							<Title
								style={{
									textAlign: 'center',
									marginTop: '50px',
									fontSize: '3em',
								}}
							>
								Coming Soon...
							</Title>
						</Col>
					</Row>
				</div>
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	createRoleState: state.createRole,
});

const mapDispatchToProps = (dispatch) => ({
	createRole: (params) => dispatch(createRoleApi(params)),
	createRoleReset: () => dispatch(createRoleReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseLiterature);
