import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_FEEDBACK_BY_ID = 'GET_FEEDBACK_BY_ID';
export const GET_FEEDBACK_BY_ID_SUCCESS = 'GET_FEEDBACK_BY_ID_SUCCESS';
export const GET_FEEDBACK_BY_ID_FAIL = 'GET_FEEDBACK_BY_ID_FAIL';
export const GET_FEEDBACK_BY_ID_RESET = 'GET_FEEDBACK_BY_ID_RESET';

export const getFeedbackById = (params) => {
	return {
		type: GET_FEEDBACK_BY_ID,
		params,
	};
};

export const getFeedbackByIdSuccess = (response) => {
	return {
		type: GET_FEEDBACK_BY_ID_SUCCESS,
		response,
	};
};

export const getFeedbackByIdFail = (response) => {
	return {
		type: GET_FEEDBACK_BY_ID_FAIL,
		response,
	};
};

export const getFeedbackByIdReset = () => {
	return {
		type: GET_FEEDBACK_BY_ID_RESET,
	};
};

export const getFeedbackByIdApi = (data) => {
	return (dispatch) => {
		dispatch(getFeedbackById());
		axios
			.get(`${config.api.base_url}/feedback/${data.id}`, {
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getFeedbackByIdData = response.data;
				dispatch(getFeedbackByIdSuccess(getFeedbackByIdData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getFeedbackByIdFail(errorMsg));
			});
	};
};
