import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Button, Select, Input, Tabs, Card } from 'antd';

export const XcalendarBtn = styled(Button)`
	border: 1px solid ${({ theme }) => theme.colors.color6};
`;
export const XFilterSelect = styled(Select)`
	padding-right: 30px;
	min-width: 120px;
	.ant-select-selection-item {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 500 !important;
		letter-spacing: 0.28px;
	}
	.ant-select-arrow {
		margin-right: 15px;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
`;
export const XFilterInput = styled(Input)`
	.ant-input {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-family: ${({ theme }) => theme.colors.fontInter} !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		&:focus,
		&:hover {
			border-color: ${({ theme }) => theme.colors.color6};
		}
	}
`;
export const XTabs = styled(Tabs)`
	.ant-tabs-tab-btn {
		color: #464f60;
		font-size: 14px;
		font-style: normal;
		font-weight: 500 !important;
		line-height: 20px;
		letter-spacing: 0.28px;
		span {
			margin-left: 7px;
			background: ${({ theme }) => theme.colors.color6};
			padding: 3px 10px;
			border-radius: 15px;
			color: #5a6376;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: 0.3px;
		}
	}
	.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: ${({ theme }) => theme.colors.menu2} !important;
			font-weight: 700;
			span {
				font-weight: 700;
			}
		}
	}
	.ant-tabs-nav {
		&::before {
			display: none;
		}
	}
	.ant-tabs-ink-bar {
		background-color: ${({ theme }) => theme.colors.menu2};
	}
`;

export const XcaseAnsyCardScroll = styled(Card)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.contentPadding {
		width: 100%;
		position: relative;
		padding: 24px;
	}
	.titleBg {
		width: 100%;
		position: relative;
		background: #f2f8fa;
		padding: 15px 24px;
		border-bottom: 1px dashed #dbdbdb;
		color: #007299;
	}
	.icdPara3 {
		font-weight: 600 !important;
		font-size: 14px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-card-body {
		border-radius: 10px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
	.titleICD {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.icdPara1 {
		font-weight: 600 !important;
		font-size: 14px;
		line-height: 24px;
		color: #007299;
	}
	.icdPara2 {
		font-weight: 400 !important;
		font-size: 12px;
		line-height: 22px;
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
		.react-read-more-read-less {
			font-weight: 500 !important;
			display: block;
			color: #007299;
		}
	}
	.btnAdd {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
		svg {
			filter: none;
		}
	}
	.scrollDiv {
		width: 100%;
		position: relative;
		max-height: 52vh;
		padding: 0px 5px 15px 0px;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
	}
`;
