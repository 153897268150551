import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { XContainer } from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
// import { XTabs } from '../Manage/ManageStyle';
import { XCardsTransparentTabs } from './../MAPlansAppealsProcessing/MAPlansAppealStyling';
import TabPane from 'antd/es/tabs/TabPane';
import { Row, Col, Modal, Input, Button, Result, Avatar } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

// Actions
import { createRoleApi, createRoleReset } from '../../actions/createRoleAction';
import Header from '../../components/Header/Header';
import { checkModulePermissions } from '../../utils/helper';
import StandardAppealTAT from './StandardAppealTAT';
import { XTabs } from '../Manage/ManageStyle';

const Manage = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [appealLetterApprovals, setAppealLetterApprovals] = useState(0);
	const [maProcessing, setMaProcessing] = useState('assign-coding-team');
	const handleTabChange = (key) => {
		// Update the URL with the filing status
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('monitor_type', key);

		// Construct the new URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

		// Push the new URL to the browser history
		window.history.pushState({}, '', newUrl);
		setMaProcessing(key);
		// window.location.reload();
	};

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Assign Appeal Letter" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="assignAppealLetter" />
				</Col>
			</>
			<XContainer fluid>
				{checkModulePermissions(
					'appeal-letter-assignment',
				).sub_modules.includes('list-cases') ? (
					<XTabs
						type="card"
						activeKey={maProcessing}
						onChange={handleTabChange}
					>
						{checkModulePermissions(
							'appeal-letter-assignment',
						).sub_modules.includes('assign-coding-team') && (
							<TabPane
								key="assign-coding-team"
								tab={
									<div
										className="gilroy-semiBold"
										style={{ fontFamily: 'Poppins' }}
									>
										Assign To Coding Team
									</div>
								}
							>
								<StandardAppealTAT
									maProcessing={maProcessing}
									setMaProcessing={setMaProcessing}
									appealLetterApprovals={
										appealLetterApprovals
									}
									setAppealLetterApprovals={
										setAppealLetterApprovals
									}
								/>
							</TabPane>
						)}
						{checkModulePermissions(
							'appeal-letter-assignment',
						).sub_modules.includes('assign-md-advisor') && (
							<TabPane
								key="assign-md-advisor"
								tab={
									<div
										className="gilroy-semiBold"
										style={{ fontFamily: 'Poppins' }}
									>
										Assign To MD Advisor
									</div>
								}
							>
								<StandardAppealTAT
									maProcessing={maProcessing}
									setMaProcessing={setMaProcessing}
									appealLetterApprovals={
										appealLetterApprovals
									}
									setAppealLetterApprovals={
										setAppealLetterApprovals
									}
								/>
							</TabPane>
						)}
					</XTabs>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
		</>
	);
};

const mapStateToProps = (state) => ({
	createRoleState: state.createRole,
});

const mapDispatchToProps = (dispatch) => ({
	createRole: (params) => dispatch(createRoleApi(params)),
	createRoleReset: () => dispatch(createRoleReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
