import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
// import { ReactComponent as ChatIcon } from '../../../public/images/chat-icon.svg';
// import ChatIcon from '../../../public/images/chat-icon.svg';
import {
	Row,
	Col,
	Input,
	Button,
	Space,
	Form,
	// Timeline,
	Result,
	Tooltip,
	notification,
	Tag,
	Select,
	Upload,
	Modal,
	InputNumber,
	Card,
	DatePicker,
	Avatar,
	Image,
	Affix,
	Checkbox,
	Radio,
	List,
	Skeleton,
	FloatButton,
	Divider,
	Spin,
	Flex,
	Tabs,
} from 'antd';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import {
	XContainer,
	XForm,
	XModal,
	XAlertBar,
	XButton,
	XTag,
	XCardsCriteriaBg,
	XCardsTransparent,
	XAffix,
	XSelect,
	XRoundButton,
	XList,
	XCircle,
	XLabel,
	XModalTitle,
	XModalSubtitle,
	ChatButton,
	ChatBoxWrap,
	XSelectAddCase,
} from '../../styles/GlobalStyle';
import {
	ArrowLeftOutlined,
	WarningOutlined,
	PlusOutlined,
	EditOutlined,
	FileTextOutlined,
	FileImageOutlined,
	UserOutlined,
	AntDesignOutlined,
	DeleteOutlined,
	CloseOutlined,
	FilePdfOutlined,
	InboxOutlined,
	EyeOutlined,
	CaretRightOutlined,
	DownOutlined,
	UpOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
	XAvatarName,
	MainTitle,
	SubTitle,
	ParagraphPage,
	AddressWrap,
	CaseFillingTitle,
	CaseTimelineTitle,
	CaseUpdateCard,
	XCollapseMain,
	XButtonModal,
	XButtonWrap,
	XICDModal,
	XICDModalFooterBTN,
	XAddCodeSticky,
	XDrawerAddCode,
	XTabIcdCpt,
	XRelatedCard,
	XRelatedReadMore,
	XCardSupportingDocument,
	RevisionHistoryNum,
	RevisionHisTitle,
	RevisionHistoryCommentHeading,
	RevisionTime,
	EyeIcon,
	RevisionTimeline,
	RevisionTimeContainer,
	CurrentRevision,
	RevisionDot,
} from '../CaseForm/CaseFormStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import moment from 'moment';
import { XLottie } from '../Login/LoginStyle';
import AppealLoading from '../../json/reloading.json';
import ReloadingAnimation from '../../json/decodex_updated05.json';
import AnalysisLoader from '../../components/AnalysisLoader/AnalysisLoader';
import DownloadAnimation from '../../json/download.json';

// actions
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import { addCaseApi, addCaseReset } from '../../actions/addCaseAction';
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import {
	checkModulePermissions,
	toTitleCase,
	getUserToken,
	disableFutureDates,
} from '../../utils/helper';
import {
	XDivider,
	XPara1,
	XTitleHead,
	XLottieMargin,
	Title,
	XCardsGrdBG,
	XButtonAdd,
	XButtonLike,
	XLottieOverlay,
	XButtonConfirm,
	XNotificationBar,
	XButtonReload,
	XButtonCloseBar,
	XCheckboxGroup,
	XRecommendedCard,
	XInputText,
	XViewMoreBTN,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
import Header from '../../components/Header/Header';
import { XButtonImg } from '../Manage/ManageStyle';
import Timeline from '../../components/HorizontalTimeline/Timeline';
//Todo Chatbox commented for Demo Branch
import ChatBox from '../../components/Chat/Chat';
import { XCardsCaseBg, XCardSummaryEdit } from '../../styles/GlobalStyle';
// import Thanks from '../../json/thanks.json';
import Thanks from '../../json/thanksNew.json';
import axios from 'axios';
import config from '../../config';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '../../components/HorizontalTimeline/index.css';
import { filterByLabel } from '../../utils/formFunctions';
// Prepare letter
import { XButtonRounded } from '../AppealLetter/AppealLetterViewStyle';
import { getAppealLetterApi } from '../../actions/getAppealLetterAction';
import { saveAppealLetterApi } from '../../actions/saveAppealLetterAction';
import { generateAppealLetterApi } from '../../actions/generateAppealLetterAction';
import { getConfigurationsApi } from '../../actions/getConfigurationsAction';
import {
	getIcdCodesApi,
	getIcdCodesReset,
} from '../../actions/getIcdCodesAction';
import PrepareLetter from '../../components/HorizontalTimeline/PrepareLetter';
import { ReactSortable } from 'react-sortablejs';
import { OuterDiv } from '../../components/Chat/ChatStyle';
import ReadMoreArea from '@foxeian/react-read-more';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import CaseOperations from '../../components/HorizontalTimeline/CaseOperations';

import dayjs from 'dayjs';
import {
	getCptCodesApi,
	getCptCodesReset,
} from '../../actions/getCptCodesAction';
import DrawerCodingTools from '../../components/CodingTools/DrawerCodingTools';
import {
	getRevisionApi,
	getRevisionReset,
} from '../../actions/getRevisionAction';
import { XTabs } from './AppealLetterPreviewStyle';
const { Dragger } = Upload;
const { TextArea } = Input;

const CaseForm = (props) => {
	const [form] = XForm.useForm();
	const [updateForm] = XForm.useForm();
	const [newOptionForm] = XForm.useForm();
	const {
		analyseCoverage,
		analyseCoverageReset,
		analyseCoverageState,
		reviseCoverage,
		reviseCoverageReset,
		reviseCoverageState,
		editCaseReset,
		editCaseState,
		getAnalysisState,
		getAnalysis,
		getAnalysisReset,
		sendFeedback,
		sendFeedbackReset,
		sendFeedbackState,
		editCase,
		getAppealLetter,
		getAppealLetterState,
		saveAppealLetter,
		saveAppealLetterState,
		saveAppealLetterReset,
		generateAppealLetterState,
		generateAppealLetter,
		generateAppealLetterReset,
		getConfigurationsState,
		getConfigurations,
		getRevision,
		getRevisionReset,
		getRevisionState,
	} = props;

	const location = useLocation();

	const pathnameIncludesCaseMA = location.pathname.includes('/case/ma/');

	const navigate = useNavigate();
	const id = useParams().id;
	const [formData, setFormData] = useState({
		status: 'success',
		successful_claim_commission_criteria: 'percentage',
		successful_claim_commission_fixed_value: null,
		successful_claim_commission_percentage_value: null,
		rejected_claim_commission_criteria: 'percentage',
		rejected_claim_commission_fixed_value: null,
		rejected_claim_commission_percentage_value: null,
		case_amount: null,
		claim_amount: null,
		status_logs: [],
	});
	const [patientData, setPatientData] = useState({});
	const [revenueCycleFormData, setRevenueCycleFormData] = useState({
		id: id,
		appeal_status: '',
		claim_category: '',
		claim_amount: null,
		status: '',
		revenue_cycle_stage: '',
		is_claim_amount_settled: null,
		claim_amount_updated_at: '',
		appeal_status_updated_at: '',
		reimbursed_at: '',
		revenue_cycle_updates: {
			revenue_cycle_status: '',
			appeal_status: '',
			remarks: '',
			url: [],
		},
		denial_reason: '',
		denial_code1: '',
		denial_code2: '',
	});
	const [pageContent, setPageContent] = useState({
		pastHistory: [],
		presentComplaint: [],
		recommendedProcedure: [],
		medicalCoverage: [],
	});
	const [flag, setFlag] = useState(true);
	const [items, setItems] = useState({});
	const [shouldDisplayCards, setShouldDisplayCards] = useState(false);
	const [matchDateOfBirth, setMatchDateOfbirth] = useState(true);
	const [matchGender, setMatchGender] = useState(true);
	const [feedbackId, setFeedbackId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [appealLetterLoading, setAppealLetterLoading] = useState(false);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [modalTextArea, setModalTextArea] = useState('');
	const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	const [thanksModal, setThanksModal] = useState([false, '']);
	// const [coverage, setCoverage] = useState('Yes');
	const [fileList, setFileList] = useState([]);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	// const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [selectedOption, setSelectedOption] = useState('textarea'); // Initial value can be 'textarea' or 'upload'
	const [revenueCycleStage, setRevenueCycleStage] = useState(''); // Initial value can be 'textarea' or 'upload'
	const [updateModalOpen, setUpdateModalOpen] = useState([false, '']);
	const [revenueCycleStatus, setRevenueCycleStatus] = useState('');
	const [amountReceived, setAmountReceived] = useState('');
	const [timelineData, setTimelineData] = useState({});
	const [affixTop, setAffixTop] = React.useState(10);
	// Appeal Letter
	const [togglePrepareLetter, setTogglePrepareLetter] = useState(false);
	const [updatedFileList, setUpdatedFileList] = useState([]);
	const [submitCase, setSubmitCase] = useState(false); // Initial value can be 'textarea' or 'upload'
	const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
	const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	const [missingFields, setMissingFields] = useState([]);
	const [missingFieldInstructions, setMissingFieldInstructions] = useState(
		[],
	);
	const [missingFieldTitle, setMissingFieldTitle] = useState('');
	const [missingFieldsForm] = XForm.useForm();
	const [lastAnalysisData, setLastAnalysisData] = useState({});
	const [showAnalysisData, setShowAnalysisData] = useState(false);
	const [analysisCompleted, setAnalysisCompleted] = useState('');
	const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	const [denialReasonData, setDenialReasonData] = useState([]);
	const [newOptionText, setNewOptionText] = useState('');
	const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	const [updateCaseLoading, setUpdateCaseLoading] = useState(false);
	const [updateModalButtonText, setUpdateModalButtonText] =
		useState('Update');
	const [approveModalOpen, setApproveModalOpen] = useState(false);
	const missingFieldsCardRef = useRef(null);
	const [mainDataLoading, setMainDataLoading] = useState(true);
	//
	const [chatOpen, setChatOpen] = useState(false);
	const [messageList, setMessageList] = useState([]);
	//
	const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
		useState([]);
	const [clinicalForm] = XForm.useForm();
	const [clinicalDocsForm] = XForm.useForm();
	const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	const [isClinicalUploadModalOpen, setIsClinicalUploadModalOpen] =
		useState(false);
	const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);
	const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
		[],
	);
	const [
		updatedClinicalDocumentFileList,
		setUpdatedClinicalDocumentFileList,
	] = useState([]);
	const [submitClinicalDocs, setSubmitClinicalDocs] = useState(false); // Initial value can be 'textarea' or 'upload'
	const colors = ['#0C79FE', '#5DC466', '#FFA033', '#B67AD5'];
	const [coverageloading, setCoverageLoading] = useState(false);
	const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
		useState(false);
	const [prevActiveCollapse, setPrevActiveCollapse] = useState(null);
	const [currentActiveCollapse, setCurrentActiveCollapse] = useState(null);
	// State for holding filters
	const [icdform] = XForm.useForm();
	const [cptform] = XForm.useForm();
	const [icdFilters, setIcdFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
		related: false,
		childCode: '',
	});

	//CPT Codes
	const [cptFilters, setCptFilters] = useState({
		search: '',
		loaded_count: 0,
		limit: 10,
	});
	const [openIcdCptDrawer, setOpenIcdCptDrawer] = useState(false);
	const [openIcdCptDrawer2, setOpenIcdCptDrawer2] = useState(false);
	const [activeCode, setActiveCode] = useState(null);
	const [icdCodes, setIcdCodes] = useState([]); // To store fetched ICD codes
	const [searchResults, setSearchResults] = useState([]); // To store search results
	const [icdLoading, setIcdLoading] = useState(false); // To manage loading state
	const [triggeredBySearch, setTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [triggeredByEnter, setTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [loadMore, setLoadMore] = useState(false); // To track search-triggered calls
	const [icdSearchLoading, setIcdSearchLoading] = useState(false); // To manage loading state
	const [cptCodes, setCptCodes] = useState([]); // To store fetched ICD codes
	const [cptLoading, setCptLoading] = useState(false); // To manage loading state
	const [cptSearchResults, setCptSearchResults] = useState([]); // To store search results
	const [cptTriggeredBySearch, setCptTriggeredBySearch] = useState(false); // To track search-triggered calls
	const [cptTriggeredByEnter, setCptTriggeredByEnter] = useState(false); // To track search-triggered calls
	const [cptLoadMore, setCptLoadMore] = useState(false); // To track search-triggered calls
	const [cptSearchLoading, setCptSearchLoading] = useState(false); // To manage loading state
	const [denialLetterActionPerform, setDenialLetterActionPerform] =
		useState(false);
	const [supportingDocActionPerform, setSupportingDocActionPerform] =
		useState(false);
	const [sendLetterLoading, setSendLetterLoading] = useState(false);
	const [caseClosed, setCaseClosed] = useState(false);
	const [activeKey, setActiveKey] = useState('1');
	const [revisionList, setRevisionList] = useState([]);
	const [currentRevision, setCurrentRevision] = useState({});
	console.log(
		'currentRevision----------------------------------------',
		currentRevision,
	);

	useEffect(() => {
		console.log('called 12345678910');
		if (
			currentRevision?.field === 'past_history' ||
			currentRevision?.field === 'present_complaint' ||
			currentRevision?.field === 'suggested_treatment'
		) {
			const diff = findArrayDifferences(
				currentRevision?.oldValue,
				currentRevision?.newValue,
			);
			console.log('diff-----------------------', diff);
			// Only update if `finalValue` is different to avoid unnecessary re-renders
			if (
				!currentRevision?.finalValue ||
				JSON.stringify(currentRevision.finalValue) !==
					JSON.stringify(diff)
			) {
				setCurrentRevision((prevRevision) => ({
					...prevRevision,
					finalValue: diff,
				}));
			}
		}
	}, [currentRevision]);

	function findArrayDifferences(oldArray, newArray) {
		const removedItems = oldArray.filter(
			(item) => !newArray.includes(item),
		);
		const addedItems = newArray.filter((item) => !oldArray.includes(item));

		return [
			...removedItems.map((item) => ({ value: item, type: 'removed' })),
			...addedItems.map((item) => ({ value: item, type: 'added' })),
			...newArray
				.filter((item) => oldArray.includes(item))
				.map((item) => ({ value: item, type: 'unchanged' })),
		];
	}

	useEffect(() => {
		if (modalOpen[0] && modalOpen[4] !== 'feedbackSummary') {
			// Check if modal is open and it's not the feedbackSummary modal
			const [description, code] = modalTextArea.split(' - ');
			form.setFieldsValue({
				modal_text_area: description || '', // Prepopulate description
				code: code ? code.split(': ')[1] : '', // Extract and prepopulate code
			});
		}
	}, [modalOpen, modalTextArea, form]);

	const handleIcdFilters = (search) => {
		setIcdFilters((prev) => ({
			search: search,
		}));
	};

	const handleTabsChange = (key) => {
		console.log('key in handleTabsChange', key, typeof key);
		setActiveKey(key);
		if (key === '2') {
			getRevision({
				id: id,
			});
		}
	};

	useEffect(() => {
		let timer;
		if (thanksModal) {
			timer = setTimeout(() => {
				setThanksModal([false, '']);
			}, 3000);
		}

		// Clear the timer if the component unmounts or showThanksModal changes
		return () => clearTimeout(timer);
	}, [thanksModal]);

	// Example of handleCodeClick
	const handleCodeClick = (codeType, codeData) => {
		// Check if the code has leading or trailing spaces
		let trimmedCode = codeData.code;

		if (trimmedCode !== trimmedCode.trim()) {
			trimmedCode = trimmedCode.trim();
		}

		// // Check if it's a child code
		// const isChildCode = trimmedCode.includes('.');
		// const parentCode = isChildCode
		// 	? trimmedCode.split('.')[0]
		// 	: trimmedCode;
		setIcdCodes();
		closeIcdCptDrawer();
		setActiveCode({
			type: codeType,
			code: codeData.code,
			description: codeData.description,
			codeTypeText: codeData.codeTypeText,
			buttontext: codeData.buttontext,
		});

		if (codeType === 'CPT') {
			// // Set filters to fetch related codes
			setCptFilters({
				related: true,
				childCode: trimmedCode,
			});
			setCptTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		} else {
			// Set filters to fetch related codes
			setIcdFilters({
				related: true,
				childCode: trimmedCode,
			});
			setTriggeredByEnter(true);
			setOpenIcdCptDrawer2(true); // Open the related codes drawer
			// setOpenIcdCptDrawer(false);
		}
	};

	const showIcdCptDrawer = () => {
		setOpenIcdCptDrawer(true);
		setIcdCodes();
		setActiveCode(null);
		setSearchResults();
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptCodes();
		setCptSearchResults();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer = () => {
		setOpenIcdCptDrawer(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	const closeIcdCptDrawer2 = () => {
		setOpenIcdCptDrawer2(false);
		setActiveCode(null);
		setIcdLoading(false);
		setTriggeredBySearch(false);
		setIcdSearchLoading(false);
		setLoadMore(false);
		icdform.resetFields();
		setCptLoading(false);
		setCptTriggeredBySearch(false);
		setCptSearchLoading(false);
		setCptLoadMore(false);
		cptform.resetFields();
	};

	// const showDeleteConfirm = (title, id, content, item) => {
	// 	XModal.confirm({
	// 		title: `Delete ${title}?`,
	// 		content: (
	// 			<p className="modalPara1">
	// 				Are you sure you want to delete - {content}?
	// 			</p>
	// 		),
	// 		icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
	// 		okText: 'Yes, Confirm',
	// 		okType: 'danger',
	// 		cancelText: 'No, Cancel',
	// 		className: 'confirmModal',
	// 		onOk() {
	// 			deletePageContent(item, id);
	// 		},
	// 		onCancel() {
	// 			console.log('Cancel');
	// 		},
	// 	});
	// };

	// const deletePageContent = (item, id) => {
	// 	const updatedPageContent = { ...pageContent };
	// 	updatedPageContent[[item]].splice(id, 1);
	// 	setPageContent(updatedPageContent);
	// 	setModalOpen([false, '', ' ']);
	// 	setModalTextArea('');
	// 	form.resetFields();
	// 	document.getElementById('notification')?.classList.remove('d-none');
	// };

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handlePreview = async (file) => {
		// if (file && file.name && file.name.split('.').includes('pdf')) {
		// 	setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
		// 	setPdfPreviewOpen(true);
		// } else {
		// 	if (!file.url && !file.preview) {
		// 		file.preview = await getBase64(file.originFileObj);
		// 	}
		// 	setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
		// 	setPreviewOpen(true);
		// 	setPreviewTitle(
		// 		file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
		// 	);
		// }
	};

	const handlePreviewBeforUpload = async (file) => {
		if (file && file.name && file.name.split('.').includes('pdf')) {
			setPreviewImage(file.url || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			setPreviewImage(file.url || file.preview);
			setPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const handleDownload = (file) => {
		// Assuming 'file' contains the URL of the file to download
		window.open(file.url, '_blank');
	};

	const uploadButton = (
		<Button
			id="revnueModalUploadButton"
			style={{
				border: 0,
				boxShadow: 'none',
				background: 'none',
				color: 'black',
				margin: '0',
			}}
		>
			<PlusOutlined />
			<div>Upload</div>
		</Button>
	);

	// const handleCancel = () => setPreviewOpen(false);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		// setPdfWidth(width + 40);
	};

	const handleColor = (key) => {
		let color;
		switch (key) {
			case 'pending':
				color = '#E78900';
				break;
			case 'analysed':
				color = '#7A670E';
				break;
			case 'rejected':
				color = '#cf1322';
				break;
			case 'successful':
				color = '#14804a';
				break;
			case 'allocated':
				color = '#7A0E27';
				break;
			default:
				break;
		}
		return color;
	};

	const updatePageContent = (title, id, content) => {
		if (id === null) {
			setPageContent({
				...pageContent,
				[title]: [...pageContent[[title]], content],
			});
		} else {
			const updatedPageContent = { ...pageContent };
			updatedPageContent[[title]][id] = content;
			setPageContent(updatedPageContent);
		}
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "MM-DD-YYYY" or any desired format
		return `${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}-${year}`;
	};

	function compareDates(dateString, yearNumber) {
		// Parse the date string using Moment.js
		const dateOfBirth = moment(dateString);

		// Calculate the current year
		const currentYear = moment().year();

		// Extract the year from the parsed date of birth
		const birthYear = dateOfBirth.year();

		// Calculate the age by subtracting the birth year from the current year
		const age = currentYear - birthYear;
		// Extract the last two digits of the year number
		const yearDigits = parseInt(yearNumber.toString().slice(-2));
		// Compare the years
		return age === yearDigits;
	}

	function compareGender(patientGender, analysisGender) {
		// Get the first character of the analysisGender string and normalize to uppercase
		const firstCharAnalysis = analysisGender.charAt(0).toUpperCase();

		// Check if patientGender is "M" (Male) or "F" (Female)
		if (
			patientGender?.toUpperCase() === 'MALE' ||
			patientGender?.toUpperCase() === 'FEMALE'
		) {
			// Compare the first character of analysisGender with patientGender
			return firstCharAnalysis === patientGender?.charAt(0).toUpperCase();
		} else {
			// Match patientGender with "O" (Other)
			return firstCharAnalysis === 'O';
		}
	}

	// use effects
	useEffect(() => {
		if (id)
			getAnalysis({
				id: id,
				process_type: pathnameIncludesCaseMA ? null : 'rc',
			});

		getConfigurations({});
	}, [id]);

	// use effects
	useEffect(() => {
		if (id)
			getRevision({
				id: id,
			});
	}, [id]);

	useEffect(() => {
		if (getRevisionState.apiState === 'success') {
			if (getRevisionState.data !== null) {
				setRevisionList(getRevisionState.data);
			}
			getRevisionReset();
		}
	}, [getRevisionState]);

	useEffect(() => {
		if (editCaseState.apiState === 'loading') {
			setUpdateCaseLoading(true);
		}
		if (editCaseState.apiState === 'success') {
			if (sendLetterLoading) {
				setSendLetterLoading(false);
				// navigate(-1);
			}
			setSendLetterLoading(false);
			setCurrentActiveCollapse(null);
			setPrevActiveCollapse(null);
			setClinicalSummaryTextLoading(false);
			handleUploadCancel();
			setClinicalDocsLoading(false);
			setShowClinicalTextArea(false);
			setUpdateModalButtonText('Update');
			setUpdateCaseLoading(false);
			// getAnalysis({ id: id });
			if (flag)
				notification.success({
					message: editCaseState.message,
				});
			setModalOpen([false, '']);
			editCaseReset();
			setUpdateModalOpen([false, '']);
			setRevenueCycleStatus('');
			updateForm.setFieldsValue({});
			setRevenueCycleFormData({
				id: id,
				appeal_status: '',
				reimbursed_at: '',
				claim_category: '',
				claim_amount: null,
				status: '',
				denial_reason: '',
				denial_code1: '',
				denial_code2: '',
				revenue_cycle_stage: '',
				is_claim_amount_settled: null,
				claim_amount_updated_at: '',
				appeal_status_updated_at: '',
				revenue_cycle_updates: {
					revenue_cycle_status: '',
					appeal_status: '',
					remarks: '',
					url: [],
				},
			});
			setRevenueCycleStage('');
			updateForm.resetFields();
			setSubmitCase(false);
			setSelectedOption('');
			setFileList([]);
			getRevision({
				id: id,
			});
			if (
				flag &&
				!denialLetterActionPerform &&
				!supportingDocActionPerform
			) {
				getAnalysis({ id: id });
			}
			// navigate(-1);
		}
		setFlag(true);
		if (editCaseState.apiState === 'error') {
			setClinicalSummaryTextLoading(false);
			handleUploadCancel();
			setSendLetterLoading(false);
			setClinicalDocsLoading(false);
			setShowClinicalTextArea(false);
			setUpdateModalButtonText('Update');
			setUpdateCaseLoading(false);
			setSubmitCase(false);
			notification.error({
				message: editCaseState.message
					? editCaseState.message
					: 'Unable to update case status, Please try again',
			});
		}
		editCaseReset();
	}, [editCaseState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'error') {
			setMainDataLoading(false);
		}
		if (getAnalysisState.apiState === 'success') {
			setMainDataLoading(false);
			setShowClinicalTextArea(false);
			if (
				getAnalysisState.data.case_details &&
				getAnalysisState.data.case_details.allocated_to === null
			) {
				notification.error({
					message: 'Case Not Allocated',
					description:
						"Oops! It seems like this case hasn't been allocated to anyone yet. Please allocate it first before proceeding with the analysis.",
				});
			}
			setPatientData(getAnalysisState.data.case_details);

			setPageContent({
				...pageContent,
				originalQuery:
					getAnalysisState.data.coverage_feedback_response[
						'original_query'
					] ?? '-',
				pastHistory:
					getAnalysisState.data.coverage_feedback_response[
						'Past History'
					] ?? [],
				presentComplaint:
					getAnalysisState.data.coverage_feedback_response[
						'Present Complaint'
					] ?? [],
				recommendedProcedure:
					getAnalysisState.data.coverage_feedback_response[
						'Suggested Treatment'
					] ?? [],
				medicalCoverage: [
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Coverage']
						: 'No',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['Rationale']
						: '-',
					getAnalysisState.data.coverage_feedback_response[
						'Medicare Evaluation'
					]
						? getAnalysisState.data.coverage_feedback_response[
								'Medicare Evaluation'
						  ]['reference']
						: null,
				],
				missing_points_for_coverage:
					getAnalysisState.data.coverage_feedback_response
						.missing_points_for_coverage ?? [],
				status: getAnalysisState.data.coverage_feedback_response.status,
			});
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.age
			)
				setMatchDateOfbirth(
					compareDates(
						getAnalysisState.data.case_details.date_of_birth,
						getAnalysisState.data.coverage_feedback_response.age,
					),
				);
			if (
				getAnalysisState.data.coverage_feedback_response &&
				getAnalysisState.data.coverage_feedback_response.gender
			)
				setMatchGender(
					compareGender(
						getAnalysisState.data.case_details.gender,
						getAnalysisState.data.coverage_feedback_response.gender,
					),
				);
			setLastAnalysisData(
				getAnalysisState.data?.coverage_feedback_response,
			);

			setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
			setTimelineData(getAnalysisState.data?.timelineData);
			// Check if the last timeline entry indicates that the case is closed
			const lastTimelineEntry =
				getAnalysisState.data.timelineData?.slice(-1)[0];
			if (
				lastTimelineEntry &&
				(lastTimelineEntry.appeal_status === 'Approved' ||
					lastTimelineEntry.appeal_status === 'Rejected')
			) {
				setCaseClosed(true);
			} else {
				setCaseClosed(false);
			}
			const revenueCycleDocs =
				getAnalysisState.data.case_details
					.revenue_cycle_supporting_documents;

			if (revenueCycleDocs && revenueCycleDocs.length > 0) {
				// Modify fileList to add the prefix to URLs
				const modifiedFileList = revenueCycleDocs.map((file) => ({
					...file,
					url: `${config.s3_url}${file.url}`,
				}));
				setFileList([...modifiedFileList]);
				setSelectedOption('upload');
				setSelectedOption('upload');
			}

			const supportingDocs =
				getAnalysisState.data.case_details.supporting_docs.length > 0 &&
				getAnalysisState.data.case_details.supporting_docs.map(
					(doc) => {
						return doc;
					},
				);
			setDocumentsToDisplay(supportingDocs);

			const clinicalDocs =
				getAnalysisState.data.case_details.clinical_docs.map((doc) => {
					return doc;
				});
			setClinicalDocumentsToDisplay(clinicalDocs);

			const isAnyArrayNotEmpty = Object.keys(pageContent)
				.filter((key) => key !== 'medicalCoverage')
				.some((key) => pageContent[key].length > 0);

			setShouldDisplayCards(isAnyArrayNotEmpty);
		}
	}, [getAnalysisState.apiState]);

	useEffect(() => {
		if (getAnalysisState.apiState === 'success') {
			setShowClinicalTextArea(false);
			const { coverage_feedback_response } = getAnalysisState.data;

			// Check if coverage_feedback_response is empty or if "Medicare Evaluation" is missing or empty
			const isMedicareEvaluationEmpty =
				!coverage_feedback_response?.['Medicare Evaluation'] ||
				Object.keys(coverage_feedback_response['Medicare Evaluation'])
					.length === 0;

			if (
				patientData.clinical_summary &&
				(!coverage_feedback_response ||
					Object.keys(coverage_feedback_response).length === 0 ||
					isMedicareEvaluationEmpty)
			) {
				if (patientData.allocated_to) {
					setLoading(true);
					document
						.getElementById('notification')
						?.classList.add('d-none');
					analyseCoverage({
						clinicalSummary: patientData.clinical_summary,
						case_id: patientData._id,
						state: patientData.state,
						provider: patientData.insurance_company?.name,
						supportingDocsSummary: patientData.summary_docs,
					});
				}
			}
		}
		getAnalysisReset();
	}, [patientData]);
	console.log('loading==============================in case filing', loading);

	useEffect(() => {
		if (showAnalysisData) {
			document.getElementById('getAns')?.classList.add('top-0');
			document.getElementById('getAns')?.classList.remove('top-100');
		}
	}, [showAnalysisData]);

	useEffect(() => {
		if (
			analyseCoverageState.apiState == 'success' &&
			id === analyseCoverageState.data?.case_id
		) {
			setFeedbackId(analyseCoverageState.data.feedback_id);
			setLastAnalysisData(analyseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery:
					analyseCoverageState.data['original_query'] ?? '',
				pastHistory: analyseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					analyseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					analyseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					analyseCoverageState.data['Medicare Evaluation']
						? analyseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					analyseCoverageState.data.missing_points_for_coverage ?? [],
			});
			setAnalysisCompleted('success');
			analyseCoverageReset();
			if (
				analyseCoverageState.data &&
				analyseCoverageState.data.date_of_birth
			)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						analyseCoverageState.data.age,
					),
				);
			if (analyseCoverageState.data && analyseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						analyseCoverageState.data.gender,
					),
				);
			if (
				analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				analyseCoverageState.data.is_fields_missing &&
				analyseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (analyseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						analyseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						analyseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						analyseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
		} else if (analyseCoverageState.apiState === 'error') {
			setShowClinicalTextArea(false);
			setAnalysisCompleted('error');
			setIsMissingFieldsThere(false);
			// setLoading(false);
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			analyseCoverageReset();
		}
	}, [analyseCoverageState]);

	useEffect(() => {
		if (
			reviseCoverageState.apiState == 'success' &&
			id === reviseCoverageState.data?.case_id
		) {
			setShowClinicalTextArea(false);
			setFeedbackId(reviseCoverageState.data.feedback_id);
			setLastAnalysisData(reviseCoverageState.data);
			setPageContent({
				...pageContent,
				originalQuery: reviseCoverageState.data['original_query'] ?? '',
				pastHistory: reviseCoverageState.data['Past History'] ?? [],
				presentComplaint:
					reviseCoverageState.data['Present Complaint'] ?? [],
				recommendedProcedure:
					reviseCoverageState.data['Suggested Treatment'] ?? [],
				medicalCoverage: [
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Coverage'
						  ]
						: 'No',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'Rationale'
						  ]
						: '',
					reviseCoverageState.data['Medicare Evaluation']
						? reviseCoverageState.data['Medicare Evaluation'][
								'reference'
						  ]
						: null,
				],
				missing_points_for_coverage:
					reviseCoverageState.data.missing_points_for_coverage ?? [],
				status: reviseCoverageState.data.status,
			});
			setAnalysisCompleted('success');
			if (reviseCoverageState.data && reviseCoverageState.data.age)
				setMatchDateOfbirth(
					compareDates(
						patientData.date_of_birth,
						reviseCoverageState.data.age,
					),
				);
			if (reviseCoverageState.data && reviseCoverageState.data.gender)
				setMatchGender(
					compareGender(
						patientData.gender,
						reviseCoverageState.data.gender,
					),
				);
			if (
				reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
				reviseCoverageState.data.is_fields_missing &&
				reviseCoverageState.data.hasOwnProperty('missing_fields')
			) {
				if (reviseCoverageState.data.missing_fields.length > 0) {
					setMissingFields(
						reviseCoverageState.data.missing_fields ?? [],
					);
					setMissingFieldInstructions(
						reviseCoverageState.data.missing_field_instructions ??
							[],
					);
					setMissingFieldTitle(
						reviseCoverageState.data.missing_field_title ?? '',
					);
					setMissingFieldsModal(true);
					setIsMissingFieldsThere(true);
				}
			} else {
				setShowAnalysisData(true);
				setIsMissingFieldsThere(false);
			}
			getRevision({
				id: id,
			});
			reviseCoverageReset();
		} else if (reviseCoverageState.apiState == 'error') {
			setShowClinicalTextArea(false);
			setLoading(false);
			setAnalysisCompleted('error');
			notification.error({
				message:
					'Hey, we are facing high traffic. Please check back in few minutes.',
			});
			document.getElementById('notification')?.classList.remove('d-none');
		}
	}, [reviseCoverageState.apiState]);

	useEffect(() => {
		if (sendFeedbackState.apiState === 'success') {
			setCoverageLoading(false);
			setLooksGoodLoading(false);
			setModalOpen([false, '', '']);
			if (sendFeedbackState.data === 'negative')
				setThanksModal([true, 'Thanks for making us Better!']);
			else
				setThanksModal([true, 'Case has been analysed and confirmed!']);
			sendFeedbackReset();
			if (id) getAnalysis({ id: id });
		}
		if (sendFeedbackState.apiState === 'error') {
			setCoverageLoading(false);
			setLooksGoodLoading(false);
		}
	}, [sendFeedbackState]);

	useEffect(() => {
		if (getConfigurationsState.apiState === 'success') {
			if (getConfigurationsState.data.all_configurations.length > 0) {
				getConfigurationsState.data.all_configurations.map((option) => {
					if (option.key === 'denial_reason') {
						const optionsList = option.options.map((opt) => ({
							value: opt.value,
							label: opt.label,
						}));
						setDenialReasonData(optionsList);
					}
				});
			}
		}
	}, [getConfigurationsState]);

	const resetFormFields = () => {
		setRevenueCycleFormData({
			...revenueCycleFormData,
			reimbursed_at: '',
			claim_category: '',
			claim_amount: null,
			is_claim_amount_settled: null,
			claim_amount_updated_at: '',
			appeal_status_updated_at: '',
			revenue_cycle_updates: {
				revenue_cycle_status: '',
				appeal_status: '',
				remarks: '',
				url: [],
			},
			denial_reason: '',
			denial_code1: '',
			denial_code2: '',
		});
		setFileList([]);
		updateForm.resetFields([
			'claim_category',
			'claim_amount',
			'is_claim_amount_settled',
			'claim_amount_updated_at',
			'appeal_status_updated_at',
			'remarks',
			'revenue_cycle_supporting_documents',
			'denial_reason',
			'denial_code1',
			'denial_code2',
			'rejected_category',
		]);
		setSelectedOption('');
	};

	// Function to handle changes in the form fields
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		// Update the state based on the field name
		if (name === 'appeal_status') {
			resetFormFields();
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				appeal_status: value,
				revenue_cycle_stage: revenueCycleStage,
				revenue_cycle_updates: {
					appeal_status: value,
					revenue_cycle_status: revenueCycleStage,
				},
				status: value === 'approved' ? 'successful' : 'rejected',
			}));
		} else if (name === 'claim_category') {
			// Update claim_category and claim_amount based on the selected value
			const claimAmount = value === 'full' ? patientData.case_amount : '';
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				claim_category: value,
				claim_amount: claimAmount,
			}));
			// If claim_category is 'full', disable the claim_amount field
			if (value === 'full') {
				updateForm.setFieldsValue({ claim_amount: claimAmount });
			} else {
				updateForm.setFieldsValue({ claim_amount: '' });
			}
		} else if (name === 'claim_amount') {
			// Update claim_amount
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				claim_amount: value,
			}));
		} else if (name === 'remarks') {
			setFileList([]);
			updateForm.resetFields(['revenue_cycle_supporting_documents']);
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				revenue_cycle_stage: revenueCycleStage,
				revenue_cycle_updates: {
					...prevState.revenue_cycle_updates,
					remarks: value,
				},
			}));
		} else if (name === 'is_claim_amount_settled') {
			// if (value === false) {
			// 	updateForm.setFieldsValue({
			// 		claim_amount: '',
			// 		claim_category: '',
			// 		claim_amount_updated_at: '',
			// 	});
			// 	setRevenueCycleFormData((prevState) => ({
			// 		...prevState,
			// 		claim_amount: '',
			// 		claim_category: '',
			// 		claim_amount_updated_at: '',
			// 	}));
			// }
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				is_claim_amount_settled: value,
			}));
		} else if (name === 'denial_code1') {
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				denial_code1: value,
			}));
		} else if (name === 'denial_code2') {
			setRevenueCycleFormData((prevState) => ({
				...prevState,
				denial_code2: value,
			}));
		}
	};

	// Handle changes in the Select component
	const handleSelect = (value, fieldName) => {
		setRevenueCycleFormData((prevState) => ({
			...prevState,
			[fieldName]: value,
		}));
	};

	const handleDateChange = (date, name) => {
		const formattedDate = date
			? date.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			: null;
		setRevenueCycleFormData((prevState) => ({
			...prevState,
			[name]: formattedDate,
			...(name === 'appeal_status_updated_at' &&
				prevState.appeal_status === 'approved' && {
					claim_amount_updated_at: formattedDate,
				}),
		}));
	};

	const handleChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setFileList(updatedFileList);
		updateForm.validateFields(['revenue_cycle_supporting_documents']);
	};

	const handleSubmit = async () => {
		try {
			// if (revenueCycleFormData.status === 'rejected' && selectedOption === 'revenue_cycle_supporting_documents' && fileList.length === 0) {
			// 	notification.error({
			// 		message: 'Submission Error',
			// 		description: 'Please upload supporting documents before submitting.',
			// 	});
			// 	return;
			// }
			if (fileList.length > 0) {
				setUpdateCaseLoading(true);
				setUpdateModalButtonText('Processing Denial Letter');
				await Promise.all(
					fileList.map(async (fileData) => {
						let get_ext = fileData.originFileObj.name.split('.');
						let ext = get_ext[get_ext.length - 1];
						const res = await axios.get(
							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=case_supporting_documents&file_name=${patientData.patient_last_name}&ext=${ext}&id=${patientData.mrn}${fileData.originFileObj.uid}`,
							{
								headers: {
									'Content-type':
										'application/json; charset=UTF-8',
									Authorization: 'Bearer ' + getUserToken(),
								},
							},
						);

						const result = await axios.put(
							res.data.data,
							fileData.originFileObj,
						);
						if (result.status === 200) {
							const documentPath = `/case_supporting_documents/${patientData.mrn}${fileData.originFileObj.uid}/${patientData.patient_last_name}.${ext}`;
							const newFile = {
								uid: fileData.originFileObj.uid,
								name: fileData.originFileObj.name,
								status: 'done',
								url: documentPath,
							};
							setUpdatedFileList((prevFileList) => [newFile]);
						}
					}),
				).then((res) => {
					setUpdateModalButtonText('Processing Denial Letter');
					setSubmitCase(true);
				});
			} else {
				setUpdateModalButtonText('Updating');
				editCase(revenueCycleFormData);
			}
		} catch (error) {
			setUpdateModalButtonText('Update');
			setUpdateCaseLoading(false);
			setSubmitCase(false);
			// Handle error if any
			console.error('Error occurred:', error);
		}
	};

	useEffect(() => {
		if (submitCase) {
			editCase({
				...revenueCycleFormData,
				revenue_cycle_updates: {
					...revenueCycleFormData.revenue_cycle_updates,
					url: updatedFileList,
					revenue_cycle_status:
						revenueCycleFormData.revenue_cycle_stage,
					remarks: null,
				},
			});
		}
	}, [submitCase]);

	useEffect(() => {}, [pageContent]);

	const handleAddOption = (index) => {
		setNewCheckBoxModal([true, index]);
		setNewOptionText('');
		newOptionForm.setFieldsValue({});
		newOptionForm.resetFields();
	};

	const handleNewCheckBoxModal = () => {
		// Create a new option object
		newOptionForm
			.validateFields()
			.then(() => {
				const newOption = {
					label: newOptionText,
					value: newOptionText,
				};

				if (
					newCheckBoxModal[1] !== null &&
					newCheckBoxModal[1] >= 0 &&
					newCheckBoxModal[1] < missingFields.length
				) {
					const updatedFields = [...missingFields];
					updatedFields[newCheckBoxModal[1]].options.push(newOption);
					setMissingFields(updatedFields);

					// Get the current form values
					const currentValues = missingFieldsForm.getFieldsValue();

					// Add the new option to the appropriate field and mark it as checked
					if (
						!currentValues[updatedFields[newCheckBoxModal[1]].name]
					) {
						currentValues[updatedFields[newCheckBoxModal[1]].name] =
							[];
					}
					currentValues[updatedFields[newCheckBoxModal[1]].name].push(
						newOptionText,
					);

					// Update the form with the new values
					missingFieldsForm.setFieldsValue(currentValues);
				}

				// Close the modal and reset the newOptionText state
				setNewCheckBoxModal([false, null]);
				setNewOptionText('');
				newOptionForm.setFieldsValue({});
				newOptionForm.resetFields();
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	//Clinical Docs Upload
	// const showClinicalModal = () => {
	// setIsClinicalUploadModalOpen(true);
	// };

	// const handleClinicalDocsUpload = async () => {
	// setClinicalDocsLoading(true);
	// try {
	// 	clinicalDocsForm
	// 		.validateFields()
	// 		.then(async () => {
	// 			if (clinicalDocumentFileList.length > 0) {
	// 				await Promise.all(
	// 					clinicalDocumentFileList.map(async (fileData) => {
	// 						let get_ext =
	// 							fileData.originFileObj.name.split('.');
	// 						let ext = get_ext[get_ext.length - 1];
	// 						const res = await axios.get(
	// 							// `${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=original_documents&file_name=denial_letter&ext=pdf&id=denial_letter`,
	// 							`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${patientData.patient_last_name}${fileData.originFileObj.uid}&ext=${ext}&id=${patientData.case_id}`,
	// 							{
	// 								headers: {
	// 									'Content-type':
	// 										'application/json; charset=UTF-8',
	// 									Authorization:
	// 										'Bearer ' + getUserToken(),
	// 								},
	// 							},
	// 						);
	// 						const result = await axios.put(
	// 							res.data.data,
	// 							fileData.originFileObj,
	// 						);
	// 						if (result.status === 200) {
	// 							// const documentPath = `/original_documents/denial_letter/denial_letter.pdf`;
	// 							const documentPath = `/clinical_documents/${patientData.case_id}/${patientData.patient_last_name}${fileData.originFileObj.uid}.${ext}`;
	// 							const newFile = {
	// 								uid: fileData.originFileObj.uid,
	// 								name: fileData.originFileObj.name,
	// 								status: 'done',
	// 								url: documentPath,
	// 							};
	// 							setUpdatedClinicalDocumentFileList(
	// 								(prevFileList) => [{ url: newFile }],
	// 							);
	// 						}
	// 					}),
	// 				).then((res) => {
	// 					setSubmitClinicalDocs(true);
	// 				});
	// 			}
	// 			setSubmitClinicalDocs(true);
	// 		})
	// 		.catch((err) => {
	// 			setClinicalDocsLoading(false);
	// 		});
	// } catch (error) {
	// 	setSubmitClinicalDocs(false);
	// 	// Handle error if any
	// 	notification.success({
	// 		message: 'Could not upload',
	// 	});
	// 	setClinicalDocsLoading(false);
	// 	console.error('Error occurred:', error);
	// }
	// };

	const handleUploadCancel = () => {
		setIsClinicalUploadModalOpen(false);
		setClinicalDocumentFileList([]);
		setUpdatedClinicalDocumentFileList([]);
		setClinicalDocsLoading(false);
		setSubmitClinicalDocs(false);
		clinicalDocsForm.setFieldsValue({ clinical_docs: '' });
	};

	const handleClinicalDocsChange = ({ fileList: newFileList }) => {
		const updatedFileList = newFileList.map((file) => {
			if (file.status === 'error') {
				return { ...file, status: 'done' };
			}
			return file;
		});
		setClinicalDocumentFileList(updatedFileList);
		clinicalDocsForm.validateFields(['clinical_docs']);
	};

	const showClinicalDelete = (title, file) => {
		// XModal.confirm({
		// 	title: `Delete ${title}?`,
		// 	content: (
		// 		<p className="modalPara1">
		// 			Are you sure you want to delete - {title}?
		// 		</p>
		// 	),
		// 	icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
		// 	okText: 'Yes, Confirm',
		// 	okType: 'danger',
		// 	cancelText: 'No, Cancel',
		// 	className: 'confirmModal',
		// 	onOk: async () => {
		// 		// Make an API call to delete the file from S3 and your collection
		// 		try {
		// 			// Extract file name and extension from the file URL
		// 			const urlParts = file.url.split('/');
		// 			const fileName = urlParts[urlParts.length - 1];
		// 			const case_id = urlParts[urlParts.length - 2];
		// 			const [patientName, ext] = fileName.split('.');
		// 			// Make API calls to delete the file from S3 and collection
		// 			await axios.delete(
		// 				`${config.api.base_url}/settings/delete-signed-url?folder_name=clinical_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
		// 				{
		// 					headers: {
		// 						Authorization: 'Bearer ' + getUserToken(),
		// 					},
		// 				},
		// 			);
		// 			// Remove the file from supporting_docs array
		// 			const updatedClinicalDocs =
		// 				clinicalDocumentsToDisplay.filter(
		// 					(item) => item.url.uid === file.uid,
		// 				);
		// 			// Make API call to update the supporting_docs field in your case
		// 			await editCase({
		// 				id: id,
		// 				deleted_clinical_docs: updatedClinicalDocs,
		// 				remove: true,
		// 				singleDelete: true,
		// 			});
		// 			console.log(
		// 				'File deleted successfully from S3 and collection',
		// 			);
		// 		} catch (error) {
		// 			console.error('Error deleting file:', error);
		// 			// Handle error
		// 		}
		// 	},
		// 	onCancel() {
		// 		console.log('Cancel');
		// 	},
		// });
	};

	const getTimeAgoOrDate = (updatedAt) => {
		const now = new Date();
		const updateDate = new Date(updatedAt);

		// Check if the updated date is today
		const isToday = now.toDateString() === updateDate.toDateString();

		if (isToday) {
			// Calculate time difference
			const diffInMs = now - updateDate;
			const seconds = Math.floor(diffInMs / 1000);
			const minutes = Math.floor(seconds / 60);
			const hours = Math.floor(minutes / 60);

			if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
			if (minutes > 0)
				return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
			return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
		} else {
			// If not today, return formatted date
			return updateDate.toLocaleDateString('en-GB'); // Format: DD/MM/YYYY
		}
	};

	useEffect(() => {
		if (submitClinicalDocs) {
			editCase({
				id: id,
				clinical_docs: updatedClinicalDocumentFileList,
				upload_documents: true,
				remove: true,
			});
		}
	}, [submitClinicalDocs]);

	useEffect(() => {
		if (missingFieldsModal && missingFieldsCardRef.current) {
			missingFieldsCardRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [missingFieldsModal]);
	console.log('appealLetterLoading-------------', appealLetterLoading);
	console.log('downloadLoading----------------', downloadLoading);

	return (
		<>
			{appealLetterLoading && (
				<XLottieOverlay>
					<XLottie animationData={AppealLoading} loop={true} />
				</XLottieOverlay>
			)}

			{downloadLoading && (
				<XLottieOverlay>
					<XLottie animationData={DownloadAnimation} loop={true} />
				</XLottieOverlay>
			)}

			{/* {loading && (
				<XLottieOverlay>
					<XLottie animationData={ReloadingAnimation} loop={true} />
				</XLottieOverlay>
			)}  */}

			{loading && (
				<AnalysisLoader
					analysisId={id}
					analysisCompleted={analysisCompleted}
					setAnalysisCompleted={setAnalysisCompleted}
					setLoading={setLoading}
					isMissingFieldsThere={isMissingFieldsThere}
					loading={loading}
					setIsMissingFieldsThere={setIsMissingFieldsThere}
				/>
			)}

			<XContainer fluid>
				<div className="marginTopMobile "></div>
				<XAffix
					id="notification"
					className="d-none zIndex"
					offsetTop={10}
				>
					<div className="marginBottomMobile"></div>
					<div
						className="mb-3"
						style={{
							backgroundColor: 'white',
							borderRadius: '20px',
							zIndex: '1111110',
						}}
					>
						<XNotificationBar style={{ zIndex: '22222' }}>
							Clinical Summary has been changed !! Click to{' '}
							<XButtonReload
								type="button"
								style={{ padding: '18px' }}
								className="refreshBtn"
								onClick={() => {
									setLoading(true);
									closeIcdCptDrawer();
									closeIcdCptDrawer2();
									document
										.getElementById('notification')
										?.classList.add('d-none');

									reviseCoverageReset();
									// Update lastAnalysisData with the latest pageContent values
									const updatedLastAnalysisData = {
										...lastAnalysisData, // Keep all the existing keys and values intact
										'Past History': pageContent.pastHistory, // Update pastHistory
										'Present Complaint':
											pageContent.presentComplaint, // Update presentComplaint
										'Suggested Treatment':
											pageContent.recommendedProcedure, // Update recommendedProcedure
										'Medicare Evaluation': {
											...lastAnalysisData[
												'Medicare Evaluation'
											],
											Coverage:
												pageContent.medicalCoverage[0], // Update medicalCoverage fields
											Rationale:
												pageContent.medicalCoverage[1],
											reference:
												pageContent.medicalCoverage[2],
										},
										original_query:
											pageContent.originalQuery, // Update original_query
										past_history: pageContent.pastHistory, // Update past_history in lastAnalysisData
										present_complaint:
											pageContent.presentComplaint, // Update present_complaint in lastAnalysisData
										status: pageContent.status, // Update status in lastAnalysisData
										missing_points_for_coverage:
											pageContent.missing_points_for_coverage, // Update missing_points_for_coverage
										// Add other updates if necessary, or keep them unchanged
									};
									reviseCoverage({
										case_id: patientData._id,
										feedbackId: feedbackId,
										clinicalHistory:
											pageContent.pastHistory,
										presentComplaint:
											pageContent.presentComplaint,
										recommendedProcedure:
											pageContent.recommendedProcedure,
										medicalCoverage:
											pageContent.medicalCoverage,
										state: patientData.state,
										provider:
											patientData.insurance_company?.name,
										last_analysis_data:
											updatedLastAnalysisData,
										supportingDocsSummary:
											patientData &&
											patientData.summary_docs,
									});
								}}
							>
								Reanalyse it !!
							</XButtonReload>
							<XButtonCloseBar
								type="button"
								className="closeBar mt-3"
							>
								<CloseOutlined
									onClick={() => {
										document
											.getElementById('notification')
											?.classList.add('d-none');
									}}
									style={{ fontSize: '20px' }}
								/>
							</XButtonCloseBar>
						</XNotificationBar>
					</div>
					<div className="marginNone"></div>
				</XAffix>
			</XContainer>
			{props.displayHeader ? (
				<></>
			) : (
				<>
					<Col lg={24} xl={24} xs={0} md={0}>
						<Header
							headerTag="Case Filings"
							breadcrumb="true"
							caseId={patientData.case_id}
						/>
					</Col>
					<Col lg={0} xl={0} xs={24} md={24}>
						<HeaderFile activeNav="casefiling" />
					</Col>
				</>
			)}

			<XContainer fluid>
				{checkModulePermissions(
					'ma-plan-appeals-processing',
				).sub_modules.includes('view-case') ||
				checkModulePermissions('case-filing').sub_modules.includes(
					'view-case',
				) ? (
					<>
						<Row gutter={(0, 30)}>
							{mainDataLoading ? (
								<>
									<Skeleton
										active
										paragraph={{
											rows: 8,
										}}
									/>
								</>
							) : (
								<>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={14}
										xl={16}
									>
										{/* <XCardBackBg className="mb-3">
											<Row>
												<Col xs={20}>
													<XButtonBack
														onClick={() =>
															navigate(-1)
														}
														icon={
															<ArrowLeftOutlined />
														}
														size="large"
													/>

													<XEditTitle>
														View Case
													</XEditTitle>
												</Col>
											</Row>
										</XCardBackBg> */}

										{/* New Data */}
										{/* case patient details */}
										{/* <XCardBackBg className="mb-3">
											<Row
												align={'middle'}
												className="align-items-center"
											>
												<Col xs={24} sm={12} lg={12}>
													<Space>
														<XAvatarName
															size="large"
															gap="4"
														>
															{
																patientData
																	.patient_first_name[0]
															}
															{
																patientData
																	.patient_last_name[0]
															}
														</XAvatarName>
														<MainTitle>
															{
																patientData.patient_name
															}
															<br />
															<span>
																{
																	patientData.mrn
																}
															</span>
														</MainTitle>
													</Space>
												</Col>

												<Col
													xs={24}
													lg={12}
													md={12}
													className="text-end"
												>
													
												</Col>
											</Row>
											<AddressWrap className="mt-4">
												<Row gutter={[15, 15]}>
													<Col
														xs={24}
														sm={12}
														lg={12}
													>
														<div className="heading">
															DOB <br />
															<span>
																{patientData.date_of_birth
																	? dateOfBirth(
																			patientData.date_of_birth,
																	  )
																	: ''}
															</span>
														</div>
													</Col>
													<Col
														xs={24}
														sm={12}
														lg={12}
													>
														<div className="heading">
															Gender <br />
															<span>
																{patientData.gender
																	? toTitleCase(
																			patientData.gender,
																	  )
																	: ''}
																&nbsp;
																{matchGender ? (
																	<> </>
																) : (
																	<Tooltip
																		title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
																		trigger={
																			'hover'
																		}
																	>
																		<WarningOutlined
																			style={{
																				color: 'red',
																			}}
																		/>
																	</Tooltip>
																)}
															</span>
														</div>
													</Col>
													<Col
														xs={24}
														sm={12}
														lg={12}
													>
														<div className="heading">
															Revenue Cycle
															<br />
															<span>
																{patientData.revenue_cycle ===
																	'pre_service' &&
																	'Pre Service'}
																{patientData.revenue_cycle ===
																	'post_claim' &&
																	'Post Claim'}
																{patientData.revenue_cycle ===
																	'part_b_drugs' &&
																	'Part B Drugs'}
															</span>
														</div>
													</Col>
													<Col
														xs={24}
														sm={12}
														lg={12}
													>
														<div className="heading">
															State
															<br />
															<span>
																{
																	patientData.state
																}
															</span>
														</div>
													</Col>
													<Col
														xs={24}
														md={12}
														lg={12}
													>
														<div className="heading">
															Insurance Claim
															Number <br />
															<span>
																{patientData?.insurance_claim_number ??
																	'-'}
															</span>
														</div>
													</Col>
													<Col
														xs={24}
														lg={12}
														md={12}
													>
														<div className="heading">
															Providers Encounter
															Number <br />
															<span>
																{patientData?.providers_encounter_number ??
																	'-'}
															</span>
														</div>
													</Col>
													
													<Col
														xs={24}
														lg={12}
														md={12}
													>
														<div className="heading">
															Date of Service{' '}
															<br />
															
															{patientData
																.date_of_service
																?.single_date
																?.length >
																0 && (
																<>
																	{patientData.date_of_service.single_date.map(
																		(
																			date,
																			index,
																		) => (
																			<span
																				key={
																					index
																				}
																			>
																				{dayjs(
																					date,
																				).format(
																					'MM-DD-YYYY',
																				)}
																				{index <
																					patientData
																						.date_of_service
																						.single_date
																						.length -
																						1 &&
																					', '}
																			</span>
																		),
																	)}
																</>
															)}
															
															{patientData
																.date_of_service
																?.single_date
																?.length > 0 &&
																patientData
																	.date_of_service
																	?.date_range
																	?.length >
																	0 &&
																', '}
															
															{patientData
																.date_of_service
																?.date_range
																?.length >
																0 && (
																<>
																	{patientData.date_of_service.date_range.map(
																		(
																			range,
																			index,
																		) => (
																			<span
																				key={
																					index
																				}
																			>
																				{range[0]
																					? dayjs(
																							range[0],
																					  ).format(
																							'MM-DD-YYYY',
																					  )
																					: ''}
																				{
																					' to '
																				}
																				{range[1]
																					? dayjs(
																							range[1],
																					  ).format(
																							'MM-DD-YYYY',
																					  )
																					: ''}
																				{index <
																					patientData
																						.date_of_service
																						.date_range
																						.length -
																						1 &&
																					', '}
																			</span>
																		),
																	)}
																</>
															)}
															
															{!(
																patientData
																	.date_of_service
																	?.single_date
																	?.length >
																	0 ||
																patientData
																	.date_of_service
																	?.date_range
																	?.length > 0
															) && ' -'}
														</div>
													</Col>
													<Col
														xs={24}
														sm={12}
														lg={12}
													>
														<div className="heading">
															Insurance Company
															<br />
															<span>
																{patientData
																	.insurance_company
																	?.name ??
																	'-'}
															</span>
														</div>
													</Col>
												</Row>
												{patientData.status ===
												'letter_sent' ? (
													<Row className="mt-2">
														<Col xl={18} lg={18}>
															<XTag
																className={
																	patientData.status
																}
															>
																{toTitleCase(
																	patientData.status,
																)}
															</XTag>
														</Col>
													</Row>
												) : (
													''
												)}
											</AddressWrap>
										</XCardBackBg> */}

										{/* called common component start */}
										<CaseOperations
											currentRevision={currentRevision}
											aipa={false}
											previewMode={caseClosed}
											matchGender={matchGender}
											dateOfBirth={dateOfBirth}
											loading={loading}
											patientData={patientData}
											mainDataLoading={mainDataLoading}
											handleClinicalDocsChange={
												handleClinicalDocsChange
											}
											clinicalDocumentFileList={
												clinicalDocumentFileList
											}
											clinicalDocsForm={clinicalDocsForm}
											handleUploadCancel={
												handleUploadCancel
											}
											clinicalDocsLoading={
												clinicalDocsLoading
											}
											setIsClinicalUploadModalOpen={
												setIsClinicalUploadModalOpen
											}
											isClinicalUploadModalOpen={
												isClinicalUploadModalOpen
											}
											setPreviewImage={setPreviewImage}
											previewImage={previewImage}
											setPreviewTitle={setPreviewTitle}
											previewTitle={previewTitle}
											setPdfPreviewOpen={
												setPdfPreviewOpen
											}
											pdfPreviewOpen={pdfPreviewOpen}
											documentsToDisplay={
												documentsToDisplay
											}
											clinicalSummaryTextLoading={
												clinicalSummaryTextLoading
											}
											setClinicalSummaryTextLoading={
												setClinicalSummaryTextLoading
											}
											setShowClinicalTextArea={
												setShowClinicalTextArea
											}
											showClinicalTextArea={
												showClinicalTextArea
											}
											setFormData={setFormData}
											formData={formData}
											id={id}
											editCase={editCase}
											setPageContent={setPageContent}
											pageContent={pageContent}
											clinicalDocumentsToDisplay={
												clinicalDocumentsToDisplay
											}
											feedbackId={feedbackId}
											reviseCoverage={reviseCoverage}
											lastAnalysisData={lastAnalysisData}
											// closeIcdCptDrawer2={
											// 	closeIcdCptDrawer2
											// }
											// closeIcdCptDrawer={
											// 	closeIcdCptDrawer
											// }
											setLoading={setLoading}
											getBase64={getBase64}
											setClinicalDocsLoading={
												setClinicalDocsLoading
											}
											setSubmitClinicalDocs={
												setSubmitClinicalDocs
											}
											setUpdatedClinicalDocumentFileList={
												setUpdatedClinicalDocumentFileList
											}
											form={form}
											setModalTextArea={setModalTextArea}
											setModalOpen={setModalOpen}
											handleCodeClick={handleCodeClick}
											modalOpen={modalOpen}
											sendFeedback={sendFeedback}
											sendFeedbackState={
												sendFeedbackState
											}
											modalTextArea={modalTextArea}
											updatePageContent={
												updatePageContent
											}
											missingFieldsForm={
												missingFieldsForm
											}
											missingFieldsCardRef={
												missingFieldsCardRef
											}
											handleAddOption={handleAddOption}
											missingFields={missingFields}
											missingFieldInstructions={
												missingFieldInstructions
											}
											setMissingFieldsModal={
												setMissingFieldsModal
											}
											setMissingFieldInstructions={
												setMissingFieldInstructions
											}
											setMissingFields={setMissingFields}
											missingFieldTitle={
												missingFieldTitle
											}
											missingFieldsModal={
												missingFieldsModal
											}
											// LooksGoodLoading={LooksGoodLoading}
											// coverageloading={coverageloading}
											// setCoverageLoading={
											// 	setCoverageLoading
											// }
											// setLooksGoodLoading={
											// 	setLooksGoodLoading
											// }
										/>
										{/* called common component end */}

										{/* Clinical Summary */}
										{/* <XCardBackBg>
											
											<Row
												gutter={[15, 15]}
												className="align-items-center"
											>
												<Col xs={24} sm={24} lg={8}>
													<div className="labelHeading">
														Clinical Summary
													</div>
												</Col>
												<Col
													xs={24}
													sm={24}
													lg={16}
													className="text-lg-end"
												>
													<Space className="d-block d-md-inline-flex">
														
														{!showClinicalTextArea && (
															<XButtonConfirm
																icon={
																	<img
																		src="/images/edit-clinical-icon.svg"
																		style={{
																			width: '20px',
																		}}
																		alt="Edit"
																	/>
																}
																className="mb-2 mb-md-0 mb-sm-0"
																type="primary"
																size="large"
																shape="round"
																style={{
																	background:
																		'#fff',
																}}
																onClick={() => {
																	setShowClinicalTextArea(
																		true,
																	);
																	clinicalForm.setFieldsValue(
																		{
																			clinical_summary:
																				patientData.clinical_summary,
																		},
																	);
																}}
															>
																Edit
															</XButtonConfirm>
														)}
														
														{clinicalDocumentsToDisplay &&
														clinicalDocumentsToDisplay.length >
															0 ? (
															<>
																{clinicalDocumentsToDisplay &&
																	clinicalDocumentsToDisplay.length >
																		0 &&
																	clinicalDocumentsToDisplay.map(
																		(
																			ele,
																		) => {
																			return (
																				<>
																					{ele?.url.name
																						.split(
																							'.',
																						)
																						.includes(
																							'pdf',
																						) && (
																						<>
																							<XButtonConfirm
																								type="primary"
																								size="large"
																								shape="round"
																								icon={
																									<img
																										src="/images/pdf-icon.svg"
																										style={{
																											width: '20px',
																										}}
																										alt="Edit"
																									/>
																								}
																								className="btnActive mb-2 mb-md-0 mb-sm-0"
																								onClick={() =>
																									handlePreview(
																										ele.url,
																									)
																								}
																							>
																								Clinical
																								Document
																							</XButtonConfirm>

																							<XButtonConfirm
																								icon={
																									<img
																										src="/images/delete-icon-new.svg"
																										alt="Delete"
																									/>
																								}
																								type="primary"
																								size="large"
																								shape="round"
																								style={{
																									background:
																										'#fff',
																								}}
																								onClick={() => {
																									showClinicalDelete(
																										'Clinical Summary Document',
																										ele.url,
																									);
																								}}
																							>
																								Delete
																							</XButtonConfirm>
																						</>
																					)}
																				</>
																			);
																		},
																	)}
															</>
														) : (
															<>
																<XButtonAdd
																	onClick={() => {
																		showClinicalModal();
																	}}
																	icon={
																		<PlusOutlined />
																	}
																></XButtonAdd>
															</>
														)}
													</Space>
												</Col>
											</Row>
											
											<XCardSummaryEdit className="mt-4 mb-4">
												<Row className="align-items-center">
													{!showClinicalTextArea ? (
														<Col xs={20} lg={24}>
															<XPara1
																style={{
																	textAlign:
																		'justify',
																}}
															>
																<ReadMoreArea
																	expandLabel="Read More"
																	collapseLabel="Read Less"
																	className="readMore"
																>
																	{
																		patientData.clinical_summary
																	}
																</ReadMoreArea>
															</XPara1>
														</Col>
													) : (
														<>
															<Form
																form={
																	clinicalForm
																}
																onFinish={(
																	values,
																) => {
																	setClinicalSummaryTextLoading(
																		true,
																	);
																	editCase({
																		id,
																		...values,
																	});
																}}
																className="w-100"
															>
																<Col
																	xs={24}
																	className="text-end"
																>
																	<XForm.Item
																		name="clinical_summary"
																		className="mb-0"
																		rules={[
																			{
																				required: true,
																				message:
																					'Please enter clinical summary',
																			},
																		]}
																	>
																		<TextArea
																			name="clinical_summary"
																			showCount
																			style={{
																				marginBottom: 24,
																			}}
																			className="p-2"
																			onChange={(
																				e,
																			) => {
																				handleInputChange(
																					e,
																					formData,
																					setFormData,
																				);
																			}}
																			autoSize={{
																				minRows: 5,
																			}}
																			placeholder="Enter Detailed Clinical Summary"
																		/>
																	</XForm.Item>
																</Col>
																<Col
																	xs={24}
																	className="text-end"
																>
																	<Button
																		shape="round"
																		style={{
																			color: '#000',
																			marginRight:
																				'10px',
																		}}
																		onClick={() => {
																			setShowClinicalTextArea(
																				false,
																			);
																		}}
																		className="cancel"
																	>
																		Cancel
																	</Button>
																	<XButtonConfirm
																		loading={
																			clinicalSummaryTextLoading
																		}
																		shape="round"
																		type="primary"
																		style={{
																			background:
																				'#000',
																			color: '#fff',
																		}}
																		htmlType="submit"
																	>
																		Submit
																	</XButtonConfirm>
																</Col>
															</Form>
														</>
													)}
												</Row>
											</XCardSummaryEdit>

											<XCardSupportingDocument>
												<Row className="mb-4">
													<Col xs={24}>
														<h2>
															Supporting Document
														</h2>
													</Col>
												</Row>
												<Row gutter={(15, 15)}>
													{documentsToDisplay.length >
														0 &&
														documentsToDisplay.map(
															(doc, index) => {
																const isPDF =
																	doc.url.name
																		.split(
																			'.',
																		)
																		.pop() ===
																	'pdf';
																return (
																	<Col
																		xs={24}
																		sm={12}
																		xl={8}
																		key={
																			index
																		}
																	>
																		<Tooltip
																			title={
																				doc.document_name
																			}
																			placement="top"
																		>
																			<Space
																				className="uploadDocs"
																				onClick={() =>
																					handlePreview(
																						doc.url,
																					)
																				}
																			>
																				<img
																					src="/images/pdf.svg"
																					alt={
																						doc.document_name
																					}
																				/>
																				<div>
																					{
																						doc.document_name
																					}
																				</div>
																			</Space>
																		</Tooltip>
																	</Col>
																);
															},
														)}
												</Row>
											</XCardSupportingDocument>
										</XCardBackBg> */}

										{/* Clinical Summary Upload */}
										{/* <Modal
											title="Upload Clinical Document"
											open={isClinicalUploadModalOpen}
											onOk={handleClinicalDocsUpload}
											okButtonProps={{
												loading: clinicalDocsLoading,
											}}
											onCancel={() => {
												if (!clinicalDocsLoading) {
													handleUploadCancel();
												}
											}}
										>
											<XForm
												form={clinicalDocsForm}
												layout="vertical"
												autoComplete="off"
											>
												<XForm.Item
													name="clinical_docs"
													rules={[
														{
															required: true,
															validator: (
																_,
																value,
															) => {
																if (
																	clinicalDocumentFileList.length ===
																	0
																) {
																	return Promise.reject(
																		new Error(
																			'Please Select file!',
																		),
																	);
																}
																return Promise.resolve();
															},
														},
													]}
												>
													<Dragger
														fileList={
															clinicalDocumentFileList
														}
														maxCount={1}
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
														// accept=".pdf,.jpeg,.jpg,.png"
														accept=".pdf"
														onChange={
															handleClinicalDocsChange
														}
													>
														<p className="ant-upload-drag-icon">
															<InboxOutlined />
														</p>
														<p className="ant-upload-text">
															Click or drag file
															to this area to
															upload
														</p>
													</Dragger>
												</XForm.Item>
											</XForm>
										</Modal> */}

										{/* Extracted Supporting documents */}
										{/* {!mainDataLoading &&
										patientData &&
										patientData.summary_docs ? (
											<XCardBackBg className="mt-4">
												<Row
													gutter={(15, 15)}
													className="mb-2 align-items-center"
												>
													<Col xs={20} lg={20}>
														<Space align="center">
															<img
																className="iconResp"
																src="/images/extracted-summary.svg"
																alt=""
															/>
															<XTitleHead>
																<span className="color6 d-none d-sm-block">
																	Summary From
																	Uploaded
																	Documents
																</span>
																<span className="color6 d-block d-sm-none text-start">
																	Summary From
																	Uploaded{' '}
																	<br />
																	Documents
																</span>
															</XTitleHead>
														</Space>
													</Col>
												</Row>
												{!mainDataLoading &&
												patientData &&
												patientData.summary_docs ? (
													<XCardSummaryEdit
														style={{
															textAlign:
																'justify',
														}}
													>
														{
															patientData.summary_docs
														}
													</XCardSummaryEdit>
												) : (
													''
												)}
											</XCardBackBg>
										) : (
											''
										)} */}

										{/* Past Clicnical History */}
										{/* <XCardBackBg className="mt-4">
											<Row
												gutter={(15, 15)}
												className="align-items-center"
											>
												<Col xs={20} lg={20}>
													<Space align="center">
														<img
															className="iconResp"
															src="/images/past-icon.svg"
															alt=""
														/>
														<XTitleHead>
															<span className="color1 d-none d-sm-block">
																Past Clinical
																History
															</span>

															<span className="color1 d-block d-sm-none text-start">
																Past Clinical{' '}
																<br /> History
															</span>
														</XTitleHead>
													</Space>
												</Col>
												<Col
													xs={4}
													lg={4}
													className="text-end"
												>
													<XButtonAdd
														icon={<PlusOutlined />}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		'',
																},
															);
															setModalTextArea(
																'',
															);
															setModalOpen([
																true,
																'Add Past Clinical History',
																'',
																null,
																'pastHistory',
															]);
														}}
													></XButtonAdd>
												</Col>
											</Row>
											<XDivider dashed className="mt-3" />
											{pageContent.pastHistory.length > 0
												? pageContent.pastHistory.map(
														(row, index) => {
															const splitPastHistory =
																row.split(
																	'- ICD-10 Code:',
																);

															return (
																<XRecommendedCard
																	bgColor="#ffffff"
																	className="mt-3"
																>
																	<Row className="align-items-center justify-content-between">
																		<Col
																			xs={
																				24
																			}
																			lg={
																				14
																			}
																		>
																			{splitPastHistory.length >
																			1 ? (
																				<XPara1>
																					{
																						splitPastHistory[0]
																					}
																				</XPara1>
																			) : (
																				<XPara1>
																					{
																						row
																					}
																				</XPara1>
																			)}
																		</Col>
																		{splitPastHistory.length >
																			1 && (
																			<Col
																				xs={
																					24
																				}
																				lg={
																					6
																				}
																				className="text-center"
																				style={{
																					borderLeft:
																						'1px solid #57636F26',
																					borderRight:
																						'1px solid #57636F26',
																				}}
																				onClick={() => {
																					handleCodeClick(
																						'ICD-10',
																						{
																							code: splitPastHistory[1],
																							description:
																								splitPastHistory[0],
																							codeTypeText:
																								'pastHistory',
																							buttontext:
																								'Add in Past',
																						},
																					);
																				}}
																				role="button"
																			>
																				<XPara1>
																					<span className="hoverGradient">
																						ICD-10:
																						{
																							splitPastHistory[1]
																						}
																					</span>
																				</XPara1>
																			</Col>
																		)}
																		<Col
																			xs={
																				24
																			}
																			lg={
																				4
																			}
																			className="text-center"
																			style={{
																				padding:
																					'15px',
																				background:
																					'#DFEBF080',
																			}}
																		>
																			<XButtonImg
																				style={{
																					background:
																						'white',
																					boxShadow:
																						'0px 0px 10px 0px #0000001A',
																				}}
																				icon={
																					<img
																						src="/images/edit-icon.svg"
																						alt="Edit"
																					/>
																				}
																				className="me-3"
																				type="text"
																				onClick={() => {
																					form.setFieldsValue(
																						{
																							modal_text_area:
																								row,
																						},
																					);
																					setModalTextArea(
																						row,
																					);
																					setModalOpen(
																						[
																							true,
																							`Edit Past Clinical History`,
																							row,
																							index,
																							'pastHistory',
																						],
																					);
																				}}
																			></XButtonImg>
																			<XButtonImg
																				style={{
																					background:
																						'white',
																					boxShadow:
																						'0px 0px 10px 0px #0000001A',
																				}}
																				icon={
																					<img
																						src="/images/delete-icon-new.svg"
																						alt="Delete"
																					/>
																				}
																				type="text"
																				onClick={() => {
																					showDeleteConfirm(
																						'Past Clinical History',
																						index,
																						row,
																						'pastHistory',
																					);
																				}}
																			></XButtonImg>
																		</Col>
																	</Row>
																</XRecommendedCard>
															);
														},
												  )
												: '-'}
										</XCardBackBg> */}

										{/* Present Clicnical History */}
										{/* <XCardBackBg className="mt-4">
											<Row
												gutter={(15, 15)}
												className="align-items-center"
											>
												<Col xs={20} lg={20}>
													<Space align="center">
														<img
															className="iconResp"
															src="/images/present-icon.svg"
															alt=""
														/>
														<XTitleHead>
															<span className="color2 d-none d-sm-block">
																Present
																Complaint
															</span>

															<span className="color2 d-block d-sm-none text-start">
																Present <br />{' '}
																Complaint
															</span>
														</XTitleHead>
													</Space>
												</Col>
												<Col
													xs={4}
													lg={4}
													className="text-end"
												>
													<XButtonAdd
														icon={<PlusOutlined />}
														onClick={() => {
															form.setFieldsValue(
																{
																	modal_text_area:
																		'',
																},
															);
															setModalTextArea(
																'',
															);
															setModalOpen([
																true,
																'Add Present Complaint',
																'',
																null,
																'presentComplaint',
															]);
														}}
													></XButtonAdd>
												</Col>
											</Row>
											<XDivider dashed className="mt-3" />
											{pageContent.presentComplaint
												.length > 0
												? pageContent.presentComplaint.map(
														(row, index) => {
															const splitPresentComplaint =
																row.split(
																	'- ICD-10 Code:',
																);

															return (
																<XRecommendedCard
																	bgColor="#ffffff"
																	className="mt-3"
																>
																	<Row className="align-items-center justify-content-between">
																		<Col
																			xs={
																				24
																			}
																			lg={
																				14
																			}
																		>
																			{splitPresentComplaint.length >
																			1 ? (
																				<XPara1>
																					{
																						splitPresentComplaint[0]
																					}
																				</XPara1>
																			) : (
																				<XPara1>
																					{
																						row
																					}
																				</XPara1>
																			)}
																		</Col>
																		{splitPresentComplaint.length >
																			1 && (
																			<Col
																				xs={
																					24
																				}
																				lg={
																					6
																				}
																				className="text-center"
																				style={{
																					borderLeft:
																						'1px solid #57636F26',
																					borderRight:
																						'1px solid #57636F26',
																				}}
																				onClick={() => {
																					handleCodeClick(
																						'ICD-10',
																						{
																							code: splitPresentComplaint[1],
																							description:
																								splitPresentComplaint[0],
																							codeTypeText:
																								'presentComplaint',
																							buttontext:
																								'Add in Present',
																						},
																					);
																				}}
																				role="button"
																			>
																				<XPara1>
																					<span className="hoverGradient">
																						ICD-10:
																						{
																							splitPresentComplaint[1]
																						}
																					</span>
																				</XPara1>
																			</Col>
																		)}
																		<Col
																			xs={
																				24
																			}
																			lg={
																				4
																			}
																			className="text-center"
																			style={{
																				padding:
																					'15px',
																				background:
																					'#DFEBF080',
																			}}
																		>
																			<XButtonImg
																				style={{
																					background:
																						'white',
																					boxShadow:
																						'0px 0px 10px 0px #0000001A',
																				}}
																				icon={
																					<img
																						src="/images/edit-icon.svg"
																						alt="Edit"
																					/>
																				}
																				className="me-3"
																				type="text"
																				onClick={() => {
																					form.setFieldsValue(
																						{
																							modal_text_area:
																								row,
																						},
																					);
																					setModalTextArea(
																						row,
																					);
																					setModalOpen(
																						[
																							true,
																							`Edit Present Complaint`,
																							row,
																							index,
																							'presentComplaint',
																						],
																					);
																				}}
																			></XButtonImg>
																			<XButtonImg
																				style={{
																					background:
																						'white',
																					boxShadow:
																						'0px 0px 10px 0px #0000001A',
																				}}
																				icon={
																					<img
																						src="/images/delete-icon-new.svg"
																						alt="Delete"
																					/>
																				}
																				type="text"
																				onClick={() => {
																					showDeleteConfirm(
																						'Present Complaint',
																						index,
																						row,
																						'presentComplaint',
																					);
																				}}
																			></XButtonImg>
																		</Col>
																	</Row>
																</XRecommendedCard>
															);
														},
												  )
												: '-'}
										</XCardBackBg> */}

										{/* Missing Fields and Recommended Procedure */}
										{/* <XCardBackBg
											ref={missingFieldsCardRef}
											className="mt-4"
										>
											<Row
												gutter={(15, 15)}
												className="align-items-center"
											>
												<Col xs={20} lg={20}>
													<Space align="center">
														<img
															className="iconResp"
															src="/images/procedure-icon.svg"
															alt=""
														/>
														<XTitleHead>
															<span className="color3 d-none d-sm-block">
																Denial Procedure
															</span>

															<span className="color3 d-block d-sm-none text-start">
																Denial <br />{' '}
																Procedure
															</span>
														</XTitleHead>
													</Space>
												</Col>
												{!missingFieldsModal && (
													<Col
														xs={4}
														lg={4}
														className="text-end"
													>
														<XButtonAdd
															icon={
																<PlusOutlined />
															}
															onClick={() => {
																form.setFieldsValue(
																	{
																		modal_text_area:
																			'',
																	},
																);
																setModalTextArea(
																	'',
																);
																setModalOpen([
																	true,
																	'Add Denial Procedure',
																	'',
																	null,
																	'recommendedProcedure',
																]);
															}}
														></XButtonAdd>
													</Col>
												)}
											</Row>
											<XDivider dashed className="mt-3" />
											{missingFieldsModal ? (
												<>
													<XModalTitle className="ms-2 mt-4">
														Provide us with the
														below details for
														completing the analysis
													</XModalTitle>
													{missingFieldTitle && (
														<XModalSubtitle className="ms-2 mt-2">
															{missingFieldTitle}
														</XModalSubtitle>
													)}
													<XDivider
														dashed
														className="mt-3"
													/>
													<XForm
														form={missingFieldsForm}
														onFinish={() => {
															missingFieldsForm
																.validateFields()
																.then(
																	(
																		values,
																	) => {
																		setLoading(
																			true,
																		);
																		reviseCoverageReset();
																		reviseCoverage(
																			{
																				case_id:
																					patientData._id,
																				feedbackId:
																					feedbackId,
																				clinicalHistory:
																					pageContent.pastHistory,
																				presentComplaint:
																					pageContent.presentComplaint,
																				recommendedProcedure:
																					pageContent.recommendedProcedure,
																				medicalCoverage:
																					pageContent.medicalCoverage,
																				state: patientData.state,
																				provider:
																					patientData
																						.insurance_company
																						?.name,
																				missing_fields:
																					values,
																				last_analysis_data:
																					lastAnalysisData,
																				supportingDocsSummary:
																					patientData &&
																					patientData.summary_docs,
																			},
																		);

																		missingFieldsForm.resetFields();
																		setMissingFields(
																			[],
																		);
																		setMissingFieldInstructions(
																			[],
																		);
																		setMissingFieldsModal(
																			false,
																		);
																	},
																)
																.catch(
																	(error) => {
																		return;
																	},
																);
														}}
														name="groupForm"
														layout="vertical"
														autoComplete="off"
													>
														<Row
															gutter={(15, 0)}
															className="mt-4"
														>
															{missingFieldInstructions.length >
																0 && (
																<Col xs={24}>
																	<XList
																		size="small"
																		header={
																			<div>
																				Instructions
																			</div>
																		}
																		bordered
																		dataSource={
																			missingFieldInstructions
																		}
																		renderItem={(
																			item,
																			index,
																		) => (
																			<List.Item>
																				<XCircle>
																					{index +
																						1}
																				</XCircle>{' '}
																				{
																					item
																				}
																			</List.Item>
																		)}
																	/>
																	<br />
																</Col>
															)}

															{missingFields.map(
																(
																	item,
																	index,
																) => {
																	if (
																		item.type ==
																		'textarea'
																	) {
																		return (
																			<Col
																				xs={
																					24
																				}
																			>
																				<XForm.Item
																					label={
																						<XLabel>
																							{
																								item.label
																							}
																						</XLabel>
																					}
																					name={
																						item.name
																					}
																					rules={[
																						item.hasOwnProperty(
																							'required',
																						) &&
																							item.required && {
																								required: true,
																								message:
																									item.required_error_message ??
																									'Field is required',
																							},
																						item.hasOwnProperty(
																							'regex',
																						) &&
																							item.regex && {
																								validator:
																									(
																										rule,
																										value,
																									) => {
																										let regex =
																											new RegExp(
																												item.regex.slice(
																													1,
																													-1,
																												),
																											);
																										if (
																											!regex.test(
																												value,
																											)
																										)
																											return Promise.reject(
																												new Error(
																													item.regex_error_message ??
																														'Invalid Data',
																												),
																											);
																										return Promise.resolve();
																									},
																							},
																					]}
																				>
																					<TextArea
																						rows={
																							3
																						}
																						name={
																							item.name
																						}
																						showCount
																						className="textArea"
																						placeholder="Enter"
																					></TextArea>
																				</XForm.Item>
																			</Col>
																		);
																	} else if (
																		item.type ==
																		'input'
																	) {
																		return (
																			<Col
																				xs={
																					24
																				}
																			>
																				<XForm.Item
																					label={
																						<XLabel>
																							{
																								item.label
																							}
																						</XLabel>
																					}
																					name={
																						item.name
																					}
																					rules={[
																						item.hasOwnProperty(
																							'required',
																						) &&
																							item.required && {
																								required: true,
																								message:
																									item.required_error_message ??
																									'Field is required',
																							},
																						item.hasOwnProperty(
																							'regex',
																						) &&
																							item.regex && {
																								validator:
																									(
																										rule,
																										value,
																									) => {
																										let regex =
																											new RegExp(
																												item.regex.slice(
																													1,
																													-1,
																												),
																											);
																										if (
																											!regex.test(
																												value,
																											)
																										)
																											return Promise.reject(
																												new Error(
																													item.regex_error_message ??
																														'Invalid Data',
																												),
																											);
																										return Promise.resolve();
																									},
																							},
																					]}
																				>
																					<Input
																						size="large"
																						name={
																							item.name
																						}
																						placeholder="Enter"
																					></Input>
																				</XForm.Item>
																			</Col>
																		);
																	} else if (
																		item.type ==
																		'checkbox'
																	) {
																		return (
																			<>
																				<Col
																					xs={
																						24
																					}
																					key={
																						index
																					}
																				>
																					<XForm.Item
																						name={
																							item.name
																						}
																						rules={[
																							item.required && {
																								required: true,
																								message:
																									item.required_error_message ??
																									'Field is required',
																							},
																						]}
																					>
																						<XCheckboxGroup
																							name={
																								item.name
																							}
																							style={{
																								display:
																									'flex',
																								flexDirection:
																									'column',
																							}}
																						>
																							<XList
																								className=""
																								size="small"
																								bordered
																								header={
																									<div
																										style={{
																											display:
																												'flex',
																											justifyContent:
																												'space-between',
																											alignItems:
																												'center',
																										}}
																									>
																										<XLabel>
																											{
																												item.label
																											}
																										</XLabel>
																										<XRoundButton
																											shape="circle"
																											icon={
																												<PlusOutlined />
																											}
																											onClick={() =>
																												handleAddOption(
																													index,
																												)
																											}
																										/>
																									</div>
																								}
																								dataSource={
																									item.options
																								}
																								renderItem={(
																									option,
																									idx,
																								) => (
																									<List.Item>
																										<Checkbox
																											value={
																												option.value
																											}
																										>
																											{
																												option.label
																											}
																										</Checkbox>
																									</List.Item>
																								)}
																							/>
																						</XCheckboxGroup>
																					</XForm.Item>
																				</Col>
																			</>
																		);
																	} else if (
																		item.type ==
																		'radio'
																	) {
																		return (
																			<Col
																				xs={
																					24
																				}
																				key={
																					index
																				}
																			>
																				<XForm.Item
																					name={
																						item.name
																					}
																					rules={[
																						item.required && {
																							required: true,
																							message:
																								'Field is required',
																						},
																					]}
																				>
																					<Radio.Group
																						style={{
																							display:
																								'flex',
																							flexDirection:
																								'column',
																						}}
																					>
																						<XList
																							className="normal-header"
																							size="small"
																							bordered
																							header={
																								<div
																									style={{
																										display:
																											'flex',
																										justifyContent:
																											'space-between',
																										alignItems:
																											'center',
																									}}
																								>
																									<XLabel>
																										{
																											item.label
																										}
																									</XLabel>
																								</div>
																							}
																							dataSource={
																								item.options
																							}
																							renderItem={(
																								option,
																								idx,
																							) => (
																								<List.Item>
																									<Radio
																										value={
																											option.value
																										}
																									>
																										{
																											option.label
																										}
																									</Radio>
																								</List.Item>
																							)}
																						/>
																					</Radio.Group>
																				</XForm.Item>
																			</Col>
																		);
																	}
																},
															)}
														</Row>
														<Row className="mt-1">
															<Col
																xs={24}
																className="text-end"
															>
																<XButtonConfirm
																	type="primary"
																	style={{
																		background:
																			'#000',
																		color: '#fff',
																	}}
																	htmlType="submit"
																	className="modalButton"
																	loading={''}
																>
																	Submit
																</XButtonConfirm>
															</Col>
														</Row>
													</XForm>
												</>
											) : pageContent.recommendedProcedure
													.length > 0 ? (
												pageContent.recommendedProcedure.map(
													(row, index) => {
														const splitRecommendedProcedure =
															row.split(
																'- CPT Code:',
															);

														return (
															<>
																<XRecommendedCard
																	bgColor="#f5f6ff"
																	className="mt-3"
																>
																	<Row className="align-items-center justify-content-between">
																		<Col
																			xs={
																				24
																			}
																			lg={
																				14
																			}
																		>
																			{splitRecommendedProcedure.length >
																			1 ? (
																				<XPara1>
																					{
																						splitRecommendedProcedure[0]
																					}
																				</XPara1>
																			) : (
																				<XPara1>
																					{
																						row
																					}
																				</XPara1>
																			)}
																		</Col>
																		{splitRecommendedProcedure.length >
																			1 && (
																			<Col
																				xs={
																					24
																				}
																				lg={
																					6
																				}
																				className="text-center"
																				style={{
																					borderLeft:
																						'1px solid #57636F26',
																					borderRight:
																						'1px solid #57636F26',
																				}}
																				onClick={() => {
																					handleCodeClick(
																						'CPT',
																						{
																							code: splitRecommendedProcedure[1],
																							description:
																								splitRecommendedProcedure[0],
																							codeTypeText:
																								'recommendedProcedure',
																							buttontext:
																								'Add in Denial',
																						},
																					);
																				}}
																				role="button"
																			>
																				<XPara1>
																					<span className="hoverGradient">
																						CPT:
																						{
																							splitRecommendedProcedure[1]
																						}
																					</span>
																				</XPara1>
																			</Col>
																		)}
																		<Col
																			xs={
																				24
																			}
																			lg={
																				4
																			}
																			className="text-center"
																			style={{
																				padding:
																					'15px',
																				background:
																					'#DFEBF080',
																			}}
																		>
																			<XButtonImg
																				style={{
																					background:
																						'white',
																					boxShadow:
																						'0px 0px 10px 0px #0000001A',
																				}}
																				icon={
																					<img
																						src="/images/edit-icon.svg"
																						alt="Edit"
																					/>
																				}
																				className="me-3"
																				type="text"
																				onClick={() => {
																					form.setFieldsValue(
																						{
																							modal_text_area:
																								row,
																						},
																					);
																					setModalTextArea(
																						row,
																					);
																					setModalOpen(
																						[
																							true,
																							`Edit Denial Procedure`,
																							row,
																							index,
																							'recommendedProcedure',
																						],
																					);
																				}}
																			></XButtonImg>
																			<XButtonImg
																				style={{
																					background:
																						'white',
																					boxShadow:
																						'0px 0px 10px 0px #0000001A',
																				}}
																				icon={
																					<img
																						src="/images/delete-icon-new.svg"
																						alt="Delete"
																					/>
																				}
																				type="text"
																				onClick={() => {
																					showDeleteConfirm(
																						'Denial Procedure',
																						index,
																						row,
																						'recommendedProcedure',
																					);
																				}}
																			></XButtonImg>
																		</Col>
																	</Row>
																</XRecommendedCard>
															</>
														);
													},
												)
											) : (
												'-'
											)}
										</XCardBackBg> */}

										{/* Medical Coverage */}
										{/* <XCardBackBg className="mt-4">
											<Row
												gutter={(15, 15)}
												className="align-items-center"
											>
												<Col xs={24}>
													<Space align="center">
														<img
															style={{
																width: '40px',
															}}
															src={
																loading
																	? '/images/no-cross-icon.svg'
																	: missingFieldsModal
																	? '/images/black-cross-icon.svg'
																	: pageContent
																			.medicalCoverage[0] ===
																	  'Yes'
																	? '/images/coverage-icon.svg'
																	: '/images/cross-icon.svg'
															}
															alt=""
														/>
														<XTitleHead>
															<span
																className={
																	loading
																		? 'color6'
																		: missingFieldsModal
																		? 'color6'
																		: pageContent
																				.medicalCoverage[0] ===
																		  'Yes'
																		? 'color4'
																		: 'color5'
																}
															>
																Medicare
																Coverage
															</span>
														</XTitleHead>
													</Space>
												</Col>
											</Row>
											{pageContent.medicalCoverage
												.length > 0 ? (
												<>
													<Row
														gutter={(15, 15)}
														className="mt-3 align-items-center"
													>
														<Col xs={24}>
															<XPara1>
																{missingFieldsModal &&
																	'Action Awaited on the Above Item'}
																{
																	pageContent
																		.medicalCoverage[1]
																}
																{pageContent
																	.medicalCoverage[2] &&
																Object.keys(
																	pageContent
																		.medicalCoverage[2],
																).length > 0 ? (
																	<>
																		<br />
																		<br />
																		<i>
																			Reference:{' '}
																			<a
																				href={
																					pageContent
																						.medicalCoverage[2][
																						'target'
																					]
																				}
																				rel="noreferrer"
																				target="_blank"
																			>
																				{
																					pageContent
																						.medicalCoverage[2][
																						'display_text'
																					]
																				}
																			</a>
																		</i>
																	</>
																) : (
																	''
																)}
															</XPara1>
														</Col>
													</Row>
												</>
											) : (
												''
											)}
										</XCardBackBg> */}

										{/* Missing Coverage */}
										{/* {pageContent.missing_points_for_coverage &&
											pageContent
												.missing_points_for_coverage
												.length > 0 && (
												<>
													<XCardBackBg className="mt-4">
														<Row>
															<Col xs={24}>
																<Space align="center">
																	<img
																		src={
																			'/images/criteria.svg'
																		}
																		alt=""
																	/>
																	<XTitleHead>
																		<span className="color7">
																			Missing
																			Inclusion
																			Criteria
																		</span>
																		&nbsp;
																		<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
																			<Tag
																				style={{
																					border: '1px solid #9B68BF',
																					color: '#9B68BF',
																					fontSize:
																						'11px',
																				}}
																			>
																				Alpha
																			</Tag>
																		</Tooltip>
																	</XTitleHead>
																</Space>
															</Col>
														</Row>
														<Row
															gutter={(15, 15)}
															className="mt-3 align-items-center criteria_sub_list"
														>
															<Col xs={24}>
																<ul className="listCircule">
																	{pageContent.missing_points_for_coverage?.map(
																		(
																			row,
																			index,
																		) => {
																			return (
																				<li>
																					{
																						row
																					}
																				</li>
																			);
																		},
																	)}
																</ul>
															</Col>
														</Row>
													</XCardBackBg>
												</>
											)} */}

										{checkModulePermissions(
											'ma-plan-appeals-processing',
										).sub_modules.includes(
											'update-coverage',
										) ||
										checkModulePermissions(
											'case-filing',
										).sub_modules.includes(
											'update-coverage',
										) ? (
											!caseClosed &&
											!missingFieldsModal && (
												<>
													{/* AIPA buttons */}
													<div className="showMobile">
														<Row
															gutter={(15, 15)}
															className="mt-4"
														>
															<Col xs={12}>
																<XButtonConfirm
																	className="w-100"
																	type="primary"
																	size="large"
																	shape="round"
																	style={{
																		background:
																			'#fff',
																	}}
																	icon={
																		<img
																			className="rotateButton"
																			src="/images/like-icon.svg"
																			alt=""
																		/>
																	}
																	onClick={() => {
																		form.setFieldsValue(
																			{
																				modal_text_area:
																					pageContent.medicalCoverage
																						? pageContent
																								.medicalCoverage[1]
																						: '',
																				medicare_flag:
																					pageContent.medicalCoverage
																						? pageContent
																								.medicalCoverage[0]
																						: '',
																			},
																		);
																		setModalTextArea(
																			pageContent
																				.medicalCoverage[1],
																		);
																		setModalOpen(
																			[
																				true,
																				`Edit Medicare Coverage`,
																				pageContent
																					.medicalCoverage[1],
																				1,
																				'feedbackSummary',
																			],
																		);
																	}}
																>
																	Refine
																</XButtonConfirm>
															</Col>
															<Col xs={12}>
																<XButtonConfirm
																	className="w-100"
																	type="primary"
																	size="large"
																	shape="round"
																	style={{
																		background:
																			'#fff',
																	}}
																	icon={
																		<img
																			src="/images/like-icon.svg"
																			alt=""
																		/>
																	}
																	loading={
																		LooksGoodLoading
																	}
																	onClick={() => {
																		setLooksGoodLoading(
																			true,
																		);
																		sendFeedback(
																			{
																				feedback_id:
																					feedbackId,
																				feedback:
																					'positive',
																				status: 'approved',
																				response:
																					pageContent.medicalCoverage,
																			},
																		);
																	}}
																>
																	Looks Good
																</XButtonConfirm>
															</Col>
															<Col
																xs={24}
																className="mt-4"
															>
																<XButtonConfirm
																	className="btnActive w-100"
																	loading={
																		coverageloading
																	}
																	onClick={() => {
																		setCoverageLoading(
																			true,
																		);
																		sendFeedback(
																			{
																				feedback_id:
																					feedbackId,
																				status: 'approved',
																				response:
																					pageContent.medicalCoverage,
																			},
																		);
																	}}
																	disabled={
																		pageContent.status ===
																		'approved'
																			? true
																			: false
																	}
																	type="primary"
																	shape="round"
																	// icon={
																	// 	<img
																	// 		src="/images/check 1.svg"
																	// 		alt=""
																	// 	/>
																	// }
																	size="large"
																>
																	Confirm
																	Coverage
																</XButtonConfirm>
															</Col>
														</Row>
													</div>
													<div className="showDesktop">
														<Row className="text-end">
															<Col
																xs={24}
																lg={12}
																className="mt-4"
															>
																<Space
																	style={{
																		width: '100%',
																	}}
																>
																	<XButtonConfirm
																		className="mb-2 mb-md-0 mb-sm-0"
																		type="primary"
																		size="large"
																		shape="round"
																		style={{
																			background:
																				'#fff',
																		}}
																		icon={
																			<img
																				className="rotateButton"
																				src="/images/like-icon.svg"
																				alt=""
																			/>
																		}
																		onClick={() => {
																			form.setFieldsValue(
																				{
																					modal_text_area:
																						pageContent.medicalCoverage
																							? pageContent
																									.medicalCoverage[1]
																							: '',
																					medicare_flag:
																						pageContent.medicalCoverage
																							? pageContent
																									.medicalCoverage[0]
																							: '',
																				},
																			);
																			setModalTextArea(
																				pageContent
																					.medicalCoverage[1],
																			);
																			setModalOpen(
																				[
																					true,
																					`Edit Medicare Coverage`,
																					pageContent
																						.medicalCoverage[1],
																					1,
																					'feedbackSummary',
																				],
																			);
																		}}
																	>
																		Refine
																	</XButtonConfirm>
																	<XButtonConfirm
																		className="mb-2 mb-md-0 mb-sm-0"
																		type="primary"
																		size="large"
																		shape="round"
																		style={{
																			background:
																				'#fff',
																		}}
																		icon={
																			<img
																				src="/images/like-icon.svg"
																				alt=""
																			/>
																		}
																		loading={
																			LooksGoodLoading
																		}
																		onClick={() => {
																			setLooksGoodLoading(
																				true,
																			);
																			sendFeedback(
																				{
																					feedback_id:
																						feedbackId,
																					feedback:
																						'positive',
																					status: 'approved',
																					response:
																						pageContent.medicalCoverage,
																				},
																			);
																		}}
																	>
																		Looks
																		Good
																	</XButtonConfirm>
																</Space>
															</Col>
															<Col
																xs={24}
																lg={12}
																className="mt-4 text-md-end"
															>
																<XButtonConfirm
																	loading={
																		coverageloading
																	}
																	onClick={() => {
																		setCoverageLoading(
																			true,
																		);
																		sendFeedback(
																			{
																				feedback_id:
																					feedbackId,
																				status: 'approved',
																				response:
																					pageContent.medicalCoverage,
																			},
																		);
																	}}
																	disabled={
																		pageContent.status ===
																		'approved'
																			? true
																			: false
																	}
																	type="primary"
																	shape="round"
																	// icon={
																	// 	<img
																	// 		src="/images/check 1.svg"
																	// 		alt=""
																	// 	/>
																	// }
																	size="large"
																	className="btnActive"
																>
																	Confirm
																	Coverage
																</XButtonConfirm>
															</Col>
														</Row>
													</div>
												</>
											)
										) : (
											<></>
										)}
									</Col>
									<Col
										xs={24}
										sm={24}
										md={24}
										lg={10}
										xl={8}
										className="mt-4 mt-lg-0"
										// style={{
										// 	zIndex:"9"
										// }}
									>
										{/* offsetTop={affixTop} */}
										<XAffix offsetTop={20}>
											<>
												{/* new code add start */}

												{/* new code add end */}
												{/* Case TImeline and Prepare Letter */}
												<div
													className={`toggle ${
														!togglePrepareLetter
															? 'rotated'
															: ''
													}`}
												>
													<div className="front">
														{togglePrepareLetter && (
															<>
																<XCardsCaseBg>
																	<Row className="align-items-center">
																		<Col
																			xs={
																				24
																			}
																		>
																			<Space align="center">
																				<XButtonBack
																					onClick={() =>
																						setTogglePrepareLetter(
																							false,
																						)
																					}
																					icon={
																						<ArrowLeftOutlined />
																					}
																					size="large"
																				/>
																				<label
																					class="headerTagStyle"
																					style={{
																						fontWeight: 600,
																					}}
																				>
																					Prepare
																					Letter
																				</label>
																			</Space>
																		</Col>
																	</Row>
																	<XDivider
																		dashed="true"
																		className="mt-3"
																	/>
																	<PrepareLetter
																		denialLetterActionPerform={
																			denialLetterActionPerform
																		}
																		setDenialLetterActionPerform={
																			setDenialLetterActionPerform
																		}
																		setSupportingDocActionPerform={
																			setSupportingDocActionPerform
																		}
																		// documentsToDisplay={
																		// 	documentsToDisplay
																		// }
																		setParentDocumentsToDisplay={
																			setDocumentsToDisplay
																		}
																		setParentPatientData={
																			setPatientData
																		}
																		approveModalOpen={
																			approveModalOpen
																		}
																		revenueCycle={
																			patientData.revenue_cycle
																		}
																		setTogglePrepareLetter={
																			setTogglePrepareLetter
																		}
																		setLoading={
																			setAppealLetterLoading
																		}
																		setDownloadLoading={
																			setDownloadLoading
																		}
																		setApproveModalOpen={
																			setApproveModalOpen
																		}
																	/>
																</XCardsCaseBg>
															</>
														)}
													</div>
													<div className="back">
														{!togglePrepareLetter && (
															<XCardBackBg>
																{/* new code */}
																<XTabs
																	defaultActiveKey="1"
																	activeKey={
																		activeKey
																	}
																	centered={
																		true
																	}
																	style={{
																		marginTop:
																			'15px',
																	}}
																	size="large"
																	type="card"
																	onChange={
																		handleTabsChange
																	}
																	items={[
																		{
																			label: 'Case Timeline',
																			key: '1',
																			children:
																				(
																					<>
																						{mainDataLoading ? (
																							<>
																								<Skeleton
																									active
																									paragraph={{
																										rows: 6,
																									}}
																								/>
																							</>
																						) : (
																							<>
																								{timelineData &&
																								timelineData.length >
																									0 ? (
																									<Timeline
																										setCurrentActiveCollapse={
																											setCurrentActiveCollapse
																										}
																										setPrevActiveCollapse={
																											setPrevActiveCollapse
																										}
																										prevActiveCollapse={
																											prevActiveCollapse
																										}
																										currentActiveCollapse={
																											currentActiveCollapse
																										}
																										handlePreviewBeforUploa
																										d={
																											handlePreviewBeforUpload
																										}
																										handleChange={
																											handleChange
																										}
																										handleDownload={
																											handleDownload
																										}
																										selectedOption={
																											selectedOption
																										}
																										filterByLabel={
																											filterByLabel
																										}
																										denialReasonData={
																											denialReasonData
																										}
																										handleSelect={
																											handleSelect
																										}
																										handleDateChange={
																											handleDateChange
																										}
																										disableFutureDates={
																											disableFutureDates
																										}
																										updateModalOpen={
																											updateModalOpen
																										}
																										updateForm={
																											updateForm
																										}
																										handleSubmit={
																											handleSubmit
																										}
																										patientData={
																											patientData
																										}
																										handleInputChange={
																											handleInputChange
																										}
																										setApproveModalOpen={
																											setApproveModalOpen
																										}
																										revenueCycleStatus={
																											revenueCycleStatus
																										}
																										setRevenueCycleStage={
																											setRevenueCycleStage
																										}
																										revenueCycleStage={
																											revenueCycleStage
																										}
																										status={
																											formData?.status
																										}
																										setUpdateModalOpen={
																											setUpdateModalOpen
																										}
																										setRevenueCycleStatus={
																											setRevenueCycleStatus
																										}
																										revenueCycleFormData={
																											revenueCycleFormData
																										}
																										setRevenueCycleFormData={
																											setRevenueCycleFormData
																										}
																										timelineData={
																											timelineData
																										}
																										togglePrepareLetter={
																											togglePrepareLetter
																										}
																										setTogglePrepareLetter={
																											setTogglePrepareLetter
																										}
																										fileList={
																											fileList
																										}
																										setFileList={
																											setFileList
																										}
																										setUpdatedFileList={
																											setUpdatedFileList
																										}
																										updatedFileList={
																											updatedFileList
																										}
																										id={
																											id
																										}
																										setSelectedOption={
																											setSelectedOption
																										}
																										medicareCoverage={
																											pageContent
																												.medicalCoverage[0]
																										}
																										case_status={
																											patientData.status
																										}
																										setFlag={
																											setFlag
																										}
																										setDownloadLoading={
																											setDownloadLoading
																										}
																										updateModalButtonText={
																											updateModalButtonText
																										}
																										updateCaseLoading={
																											updateCaseLoading
																										}
																									/>
																								) : (
																									<>

																									</>
																								)}
																							</>
																						)}
																					</>
																				),
																		},
																		{
																			label: 'Revision History',
																			key: '2',
																			children:
																				(
																					<>
																						<div className="scrollAfix">
																							{revisionList?.length >
																							0 ? (
																								<RevisionTimeline
																									items={revisionList?.flatMap(
																										(
																											revision,
																											i,
																										) =>
																											revision.changes.map(
																												(
																													change,
																													index,
																												) => ({
																													dot: (
																														<RevisionHistoryNum
																															style={{
																																backgroundColor:
																																	currentRevision?._id &&
																																	currentRevision?._id.toString() ===
																																		change._id.toString()
																																		? '#007299'
																																		: '',
																															}}
																														>
																															<span
																																style={{
																																	color:
																																		currentRevision?._id &&
																																		currentRevision?._id.toString() ===
																																			change._id.toString()
																																			? ''
																																			: '#007299',
																																}}
																																class="RevisionNum"
																															>
																																{/* v5.0 */}

																																v
																																{
																																	revision.revisionNumber
																																}

																																.
																																{revision
																																	.changes
																																	.length -
																																	index}
																															</span>
																														</RevisionHistoryNum>
																													),
																													children:
																														(
																															<RevisionHistoryCommentHeading>
																																<RevisionHisTitle>
																																	Edited{' '}
																																	{
																																		change.name
																																	}
																																</RevisionHisTitle>
																																<RevisionTimeContainer>
																																	<RevisionTime class="-hour-ago">
																																		{getTimeAgoOrDate(
																																			revision.updatedAt,
																																		)}
																																	</RevisionTime>
																																	{currentRevision?._id &&
																																	currentRevision?._id.toString() ===
																																		change._id.toString() ? (
																																		<CurrentRevision
																																			role="button"
																																			onClick={() =>
																																				setCurrentRevision()
																																			}
																																		>
																																			Current
																																		</CurrentRevision>
																																	) : (
																																		<EyeIcon
																																			onClick={() =>
																																				setCurrentRevision(
																																					change,
																																				)
																																			}
																																		>
																																			<img
																																				src="/images/revision-eye.svg"
																																				alt=""
																																			/>
																																		</EyeIcon>
																																	)}
																																</RevisionTimeContainer>
																															</RevisionHistoryCommentHeading>
																														),
																												}),
																											),
																									)}
																									// {
																									// 	dot: (
																									// 		<RevisionHistoryNum>
																									// 			<span class="RevisionNum">
																									// 				v5.0
																									// 			</span>
																									// 		</RevisionHistoryNum>
																									// 	),
																									// 	children:
																									// 		(
																									// 			<RevisionHistoryCommentHeading>
																									// 				<RevisionHisTitle>
																									// 					Edited
																									// 					Clinical
																									// 					Summary
																									// 				</RevisionHisTitle>
																									// 				<RevisionTimeContainer>
																									// 					<RevisionTime class="-hour-ago">
																									// 						1
																									// 						hour
																									// 						ago
																									// 					</RevisionTime>
																									// 					{/* <RevisionDot></RevisionDot> */}
																									// 					<CurrentRevision>
																									// 						Current
																									// 					</CurrentRevision>
																									// 				</RevisionTimeContainer>
																									// 			</RevisionHistoryCommentHeading>
																									// 		),
																									// },
																								/>
																							) : (
																								<div
																									style={{
																										textAlign:
																											'center',
																										padding:
																											'20px',
																										color: '#555',
																									}}
																								>
																									No
																									revision
																									history
																									available
																								</div>
																							)}
																						</div>
																					</>
																				),
																		},
																	]}
																/>
																{/* new code */}
																{/* old code */}
																{/* <Row className="mb-2 align-items-center">
																	<Col
																		xs={24}
																	>
																		<CaseTimelineTitle>
																			Case
																			Timeline
																		</CaseTimelineTitle>
																	</Col>
																</Row> */}

																{/* {mainDataLoading ? (
																	<>
																		<Skeleton
																			active
																			paragraph={{
																				rows: 6,
																			}}
																		/>
																	</>
																) : (
																	<>
																		{timelineData &&
																		timelineData.length >
																			0 ? (
																			<Timeline
																				editCase={
																					editCase
																				}
																				sendLetterLoading={
																					sendLetterLoading
																				}
																				setSendLetterLoading={
																					setSendLetterLoading
																				}
																				setCurrentActiveCollapse={
																					setCurrentActiveCollapse
																				}
																				setPrevActiveCollapse={
																					setPrevActiveCollapse
																				}
																				prevActiveCollapse={
																					prevActiveCollapse
																				}
																				currentActiveCollapse={
																					currentActiveCollapse
																				}
																				handlePreviewBeforUploa
																				d={
																					handlePreviewBeforUpload
																				}
																				handleChange={
																					handleChange
																				}
																				handleDownload={
																					handleDownload
																				}
																				selectedOption={
																					selectedOption
																				}
																				filterByLabel={
																					filterByLabel
																				}
																				denialReasonData={
																					denialReasonData
																				}
																				handleSelect={
																					handleSelect
																				}
																				handleDateChange={
																					handleDateChange
																				}
																				disableFutureDates={
																					disableFutureDates
																				}
																				updateModalOpen={
																					updateModalOpen
																				}
																				updateForm={
																					updateForm
																				}
																				handleSubmit={
																					handleSubmit
																				}
																				patientData={
																					patientData
																				}
																				handleInputChange={
																					handleInputChange
																				}
																				setApproveModalOpen={
																					setApproveModalOpen
																				}
																				revenueCycleStatus={
																					revenueCycleStatus
																				}
																				setRevenueCycleStage={
																					setRevenueCycleStage
																				}
																				revenueCycleStage={
																					revenueCycleStage
																				}
																				status={
																					formData?.status
																				}
																				setUpdateModalOpen={
																					setUpdateModalOpen
																				}
																				setRevenueCycleStatus={
																					setRevenueCycleStatus
																				}
																				revenueCycleFormData={
																					revenueCycleFormData
																				}
																				setRevenueCycleFormData={
																					setRevenueCycleFormData
																				}
																				timelineData={
																					timelineData
																				}
																				togglePrepareLetter={
																					togglePrepareLetter
																				}
																				setTogglePrepareLetter={
																					setTogglePrepareLetter
																				}
																				fileList={
																					fileList
																				}
																				setFileList={
																					setFileList
																				}
																				setUpdatedFileList={
																					setUpdatedFileList
																				}
																				updatedFileList={
																					updatedFileList
																				}
																				id={
																					id
																				}
																				setSelectedOption={
																					setSelectedOption
																				}
																				medicareCoverage={
																					pageContent
																						.medicalCoverage[0]
																				}
																				case_status={
																					patientData.status
																				}
																				setFlag={
																					setFlag
																				}
																				setDownloadLoading={
																					setDownloadLoading
																				}
																				updateModalButtonText={
																					updateModalButtonText
																				}
																				updateCaseLoading={
																					updateCaseLoading
																				}
																			/>
																		) : (
																			<>

																			</>
																		)}
																	</>
																)} */}
															</XCardBackBg>
														)}
													</div>
												</div>
											</>
											{/* )} */}
										</XAffix>
									</Col>
								</>
							)}
						</Row>

						{/* //Todo Commented the Chatbox in the Demo Branch */}
						<ChatBoxWrap>
							{chatOpen && <ChatBox case_id={id} />}
							{patientData.status == 'allocated' ? (
								<></>
							) : pageContent.medicalCoverage[0] == 'Yes' ? (
								<ChatButton
									type="text"
									onClick={() => {
										setChatOpen(!chatOpen);
									}}
								>
									{chatOpen ? (
										<>
											<span className="mobileClose">
												<img
													src="/images/chat-mob-close.svg"
													alt="Close chat Icon"
												/>
											</span>
											<span className="hideMobileClose">
												<img
													src="/images/chat-cross-icon.svg"
													alt="Close chat Icon"
													width="60px"
													height="60px"
												/>
											</span>
										</>
									) : (
										<img
											src="/images/chat-icon.svg"
											alt="Open chat Icon"
											width="60px"
											height="60px"
										/>
									)}
								</ChatButton>
							) : (
								<></>
							)}
						</ChatBoxWrap>
					</>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>

			{/* //todo Drawer of the Coding Tools Start */}
			<XAddCodeSticky type="primary" onClick={showIcdCptDrawer}>
				Coding Tools
			</XAddCodeSticky>

			<DrawerCodingTools
				// previewMode={false}
				previewMode={caseClosed}
				fixed={false}
				icdform={icdform}
				cptform={cptform}
				activeCode={activeCode}
				setActiveCode={setActiveCode}
				closeIcdCptDrawer={closeIcdCptDrawer}
				openIcdCptDrawer={openIcdCptDrawer}
				handleCodeClick={handleCodeClick}
				setModalOpen={setModalOpen}
				setModalTextArea={setModalTextArea}
				closeIcdCptDrawer2={closeIcdCptDrawer2}
				openIcdCptDrawer2={openIcdCptDrawer2}
				modalOpen={modalOpen}
				updatePageContent={updatePageContent}
				modalTextArea={modalTextArea}
				setIcdCodes={setIcdCodes}
				icdCodes={icdCodes}
				searchResults={searchResults}
				setSearchResults={setSearchResults}
				icdLoading={icdLoading}
				setIcdLoading={setIcdLoading}
				triggeredBySearch={triggeredBySearch}
				setTriggeredBySearch={setTriggeredBySearch}
				triggeredByEnter={triggeredByEnter}
				setTriggeredByEnter={setTriggeredByEnter}
				loadMore={loadMore}
				setLoadMore={setLoadMore}
				icdSearchLoading={icdSearchLoading}
				setIcdSearchLoading={setIcdSearchLoading}
				cptCodes={cptCodes}
				setCptCodes={setCptCodes}
				cptLoading={cptLoading}
				setCptLoading={setCptLoading}
				cptSearchResults={cptSearchResults}
				setCptSearchResults={setCptSearchResults}
				cptTriggeredBySearch={cptTriggeredBySearch}
				setCptTriggeredBySearch={setCptTriggeredBySearch}
				cptTriggeredByEnter={cptTriggeredByEnter}
				setCptTriggeredByEnter={setCptTriggeredByEnter}
				cptLoadMore={cptLoadMore}
				setCptLoadMore={setCptLoadMore}
				cptSearchLoading={cptSearchLoading}
				setCptSearchLoading={setCptSearchLoading}
				icdFilters={icdFilters}
				setIcdFilters={setIcdFilters}
				cptFilters={cptFilters}
				setCptFilters={setCptFilters}
			/>

			{/* <XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				centered
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									status: 'pending_for_analysis',
									response: [coverage, modalTextArea],
								});
							} else {
								// Format the text according to the selected code type (ICD-10 or CPT)
								const formattedText = `${
									res.modal_text_area
								} - ${
									modalOpen[4] === 'recommendedProcedure'
										? 'CPT Code'
										: 'ICD-10 Code'
								}: ${res.code}`;
								// Save the formatted text in setModalTextArea
								setModalTextArea(formattedText);

								// Optionally update page content
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									formattedText,
								);

								// updatePageContent(
								// 	modalOpen[4],
								// 	modalOpen[3],
								// 	modalTextArea,
								// );
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				okButtonProps={{
					loading: sendFeedbackState.apiState === 'loading',
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<>
									<XForm.Item
										name="medicare_flag"
										label="Coverage"
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
									>
										<Select
											showSearch
											placeholder="Select a person"
											optionFilterProp="children"
											onChange={(e) => {
												setCoverage(e);
											}}
											options={[
												{
													value: 'Yes',
													label: 'YES',
												},
												{
													value: 'No',
													label: 'NO',
												},
											]}
										/>
									</XForm.Item>

									<XForm.Item
										name="modal_text_area"
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
									>
										<TextArea
											name="modal_text_area"
											showCount
											style={{
												height: 120,
												marginBottom: 24,
											}}
											className="textArea mt-2"
											placeholder="Enter"
											onChange={(e) =>
												setModalTextArea(e.target.value)
											}
										>
										</TextArea>
									</XForm.Item>
								</>
							)}
							{modalOpen[4] !== 'feedbackSummary' && (
								<>
									<XForm.Item
										name="modal_text_area"
										label="Description"
										rules={[
											{
												required: true,
												message:
													'Description is required',
											},
										]}
									>
										<TextArea
											name="modal_text_area"
											showCount
											style={{
												height: 100,
												// marginBottom: 24,
											}}
											className="textArea mt-2"
											placeholder="Enter description"
										/>
									</XForm.Item>
									<XForm.Item
										name="code"
										label={`${
											modalOpen[4] ===
											'recommendedProcedure'
												? 'CPT'
												: 'ICD-10'
										} Code`}
										rules={[
											{
												required: true,
												message: 'Code is required',
											},
										]}
									>
										<Input
											className="textArea mt-2"
											placeholder={`Enter ${
												modalOpen[4] ===
												'recommendedProcedure'
													? 'CPT'
													: 'ICD-10'
											} Code`}
										/>
									</XForm.Item>
								</>
							)}
						</Col>
					</Row>
				</XForm>
			</XModal> */}

			<XModal
				centered
				open={''}
				onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row gutter={(15, 0)} className="mt-4">
					<Col xs={24} lg={{ span: 12, offset: 6 }}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1 className="mt-5 mb-4">
							11{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>

			{/* Update Revenue Modal */}
			<XModal
				title={`Update Case Status`}
				centered
				open={updateModalOpen[0]}
				onCancel={() => {
					if (!updateCaseLoading) {
						setUpdateModalOpen([false, '']);
						setRevenueCycleStatus('');
						updateForm.setFieldsValue({});
						setRevenueCycleFormData({
							id: id,
							reimbursed_at: '',
							appeal_status: '',
							claim_category: '',
							claim_amount: null,
							status: '',
							revenue_cycle_stage: '',
							is_claim_amount_settled: null,
							claim_amount_updated_at: '',
							appeal_status_updated_at: '',
							revenue_cycle_updates: {
								revenue_cycle_status: '',
								appeal_status: '',
								remarks: '',
								url: [],
							},
							denial_reason: '',
							denial_code1: '',
							denial_code2: '',
						});
						setRevenueCycleStage('');
						setFileList([]);
						setUpdatedFileList([]);
						updateForm.resetFields();
						setSelectedOption('');
					}
				}}
				className="modalButton"
				okText="Update Status"
				footer={null}
			>
				<XForm
					form={updateForm}
					name="updateRevenue"
					layout="vertical"
					autoComplete="off"
					onFinish={handleSubmit}
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							<XForm.Item
								name="appeal_status"
								label="Revenue Status"
								rules={[
									{
										required: true,
										message:
											'Please select revenue status!',
									},
								]}
							>
								<XRadio.Group
									onChange={(e) => {
										handleInputChange(
											e,
											revenueCycleFormData,
											setRevenueCycleFormData,
										);
										if (e.target.value === 'approved') {
											setRevenueCycleStatus('approved');
										} else if (
											e.target.value === 'rejected'
										) {
											setRevenueCycleStatus('rejected');
										} else {
											setRevenueCycleStatus('');
										}
									}}
									name="appeal_status"
								>
									<XRadio value={'approved'}>Approved</XRadio>
									<XRadio value={'rejected'}>Rejected</XRadio>
								</XRadio.Group>
							</XForm.Item>
						</Col>
						{revenueCycleStatus === 'rejected' && (
							<>
								<Col xs={24}>
									<Col xs={24}>
										<XForm.Item
											label="Select Rejected Date"
											name="appeal_status_updated_at"
											rules={[
												{
													required: true,
													message:
														'Please select Date!',
												},
											]}
										>
											<DatePicker
												size="large"
												name="appeal_status_updated_at"
												className="w-100"
												placeholder="MM/DD/YYYY" // Add placeholder
												format="MM/DD/YYYY" // Set display format
												onChange={(date) =>
													handleDateChange(
														date,
														'appeal_status_updated_at',
													)
												}
												disabledDate={
													disableFutureDates
												}
											/>
										</XForm.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<XForm.Item
											name="denial_reason"
											label={'Denial Type'}
											rules={[
												{
													required: true,
													message:
														'Please select Denial Type!',
												},
											]}
										>
											<Select
												size="large"
												placeholder="Select Denial Type"
												name="denial_reason"
												filterOption={filterByLabel}
												options={denialReasonData}
												onSelect={(value) =>
													handleSelect(
														value,
														'denial_reason',
													)
												}
											/>
										</XForm.Item>
									</Col>
									<Row gutter={[10]}>
										<Col xs={24} sm={12} md={12} lg={12}>
											<XForm.Item
												name="denial_code1"
												label="Denial Code 1"
											>
												<Input
													size="large"
													placeholder="Enter CARC"
													name="denial_code1"
													onChange={handleInputChange}
												/>
											</XForm.Item>
										</Col>
										<Col xs={24} sm={12} md={12} lg={12}>
											<XForm.Item
												name="denial_code2"
												label="Denial Code 2"
											>
												<Input
													size="large"
													placeholder="Enter RARC"
													name="denial_code2"
													onChange={handleInputChange}
												/>
											</XForm.Item>
										</Col>
									</Row>
									<Card>
										<Row>
											<Col xs={24}>
												<XForm.Item
													className="mb-0"
													label="Denial Reason"
													name="rejected_category"
													rules={[
														{
															required: true,
															message:
																'Please select option!',
														},
													]}
												>
													<XRadio.Group
														onChange={(e) => {
															handleInputChange(
																e,
																revenueCycleFormData,
																setRevenueCycleFormData,
															);
															updateForm.setFieldsValue(
																{
																	remarks: '',
																	revenue_cycle_supporting_documents:
																		'',
																},
															);
															setFileList([]);
															setRevenueCycleFormData(
																(
																	prevState,
																) => ({
																	...prevState,
																	revenue_cycle_updates:
																		{
																			...prevState.revenue_cycle_updates,
																			remarks:
																				'',
																			url: [],
																		},
																}),
															);
															if (
																e.target
																	.value ===
																'revenue_cycle_supporting_text'
															) {
																setSelectedOption(
																	'revenue_cycle_supporting_text',
																);
															} else if (
																e.target
																	.value ===
																'revenue_cycle_supporting_documents'
															) {
																setSelectedOption(
																	'revenue_cycle_supporting_documents',
																);
															}
														}}
														name="rejected_category"
													>
														<XRadio
															className="pb-2"
															value={
																'revenue_cycle_supporting_text'
															}
														>
															Enter Reason
														</XRadio>
														<XRadio
															className="pb-2"
															value={
																'revenue_cycle_supporting_documents'
															}
														>
															Upload Denial Letter
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>
										</Row>
									</Card>
								</Col>
								{selectedOption ===
									'revenue_cycle_supporting_text' && (
									<Col xs={24} className="text-end mt-4">
										<XForm.Item
											name="remarks"
											label="Enter Reason"
											rules={[
												{
													required: true,
													message:
														'Please enter reason',
												},
											]}
										>
											<TextArea
												name="remarks"
												showCount
												style={{
													height: 120,
													marginBottom: 24,
												}}
												onChange={(e) =>
													handleInputChange(
														e,
														revenueCycleFormData,
														setRevenueCycleFormData,
													)
												}
												placeholder="Enter Reason"
											/>
										</XForm.Item>
									</Col>
								)}
								{selectedOption ===
									'revenue_cycle_supporting_documents' && (
									<>
										<Col xs={24} className="mt-4">
											<XForm.Item
												name="revenue_cycle_supporting_documents"
												label="Upload Denial Letter"
												rules={[
													{
														required: true,
														validator: (
															_,
															value,
														) => {
															if (
																fileList.length ===
																0
															) {
																return Promise.reject(
																	new Error(
																		'Please upload denial letter!',
																	),
																);
															}
															return Promise.resolve();
														},
													},
												]}
											>
												<Card>
													<Upload
														action={null}
														style={{
															padding: 0,
															margin: 0,
														}}
														showUploadList={{
															showPreviewIcon: false,
														}}
														className="supporting_files timeline-upload"
														listType="picture-card"
														fileList={fileList}
														onPreview={
															handlePreviewBeforUpload
														}
														multiple={false}
														maxCount={1}
														onChange={handleChange}
														accept=".pdf"
														beforeUpload={(
															file,
														) => {
															const isJPEG =
																file.type ===
																'image/jpeg';
															const isJPG =
																file.type ===
																'image/jpg';
															const isPNG =
																file.type ===
																'image/png';
															const isGIF =
																file.type ===
																'image/gif';
															const isVideo =
																file.type.startsWith(
																	'video/',
																);
															const isAudio =
																file.type.startsWith(
																	'audio/',
																);

															const isLt10M =
																file.size /
																	1024 /
																	1024 <
																10;

															if (!isLt10M) {
																notification.error(
																	{
																		message:
																			'File Size Exceeded',
																		description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
																	},
																);
															}
															if (
																isGIF ||
																isVideo ||
																isAudio ||
																isJPEG ||
																isJPG ||
																isPNG
															) {
																notification.error(
																	{
																		message:
																			'File Type Error',
																		description: `GIF, video, image and audio files are not allowed.`,
																	},
																);
															}

															return (
																isLt10M &&
																!isGIF &&
																!isVideo &&
																!isAudio &&
																!isJPEG &&
																!isJPG &&
																!isPNG
															);
														}}
														onDownload={
															handleDownload
														}
													>
														{fileList.length >= 1
															? null
															: uploadButton}
													</Upload>
												</Card>
											</XForm.Item>
										</Col>
									</>
								)}
							</>
						)}
						{revenueCycleStatus === 'approved' && (
							<>
								<Col xs={24}>
									<Col xs={24}>
										<XForm.Item
											label="Select Approval Date"
											name="appeal_status_updated_at"
											rules={[
												{
													required: true,
													message:
														'Please select Date!',
												},
											]}
										>
											<DatePicker
												size="large"
												name="appeal_status_updated_at"
												className="w-100"
												placeholder="MM/DD/YYYY" // Add placeholder
												format="MM/DD/YYYY" // Set display format
												onChange={(date) =>
													handleDateChange(
														date,
														'appeal_status_updated_at',
													)
												}
												disabledDate={
													disableFutureDates
												}
											/>
										</XForm.Item>
									</Col>
									<Card>
										<Row>
											<Col xs={24}>
												<XForm.Item
													label="Payment Received"
													name="is_claim_amount_settled"
													className="mb-3"
													rules={[
														{
															required: true,
															message:
																'Please select!',
														},
													]}
												>
													<XRadio.Group
														onChange={(e) => {
															handleInputChange(
																e,
																revenueCycleFormData,
																setRevenueCycleFormData,
															);
														}}
														name="is_claim_amount_settled"
													>
														<XRadio value={true}>
															Yes
														</XRadio>
														<XRadio value={false}>
															No
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>
											{revenueCycleFormData.is_claim_amount_settled ===
												true && (
												<>
													<Col xs={24}>
														<XForm.Item
															label="Payment Date"
															name="reimbursed_at"
															rules={[
																{
																	required: true,
																	message:
																		'Please select Reimbursed At!',
																},
															]}
														>
															<DatePicker
																name="reimbursed_at"
																size="large"
																className="w-100"
																placeholder="MM/DD/YYYY" // Add placeholder
																format="MM/DD/YYYY" // Set display format
																onChange={(
																	date,
																) =>
																	handleDateChange(
																		date,
																		'reimbursed_at',
																	)
																}
																disabledDate={
																	disableFutureDates
																}
															/>
														</XForm.Item>
													</Col>
												</>
											)}
											<Col xs={24}>
												<XForm.Item
													label="Payment Type"
													name="claim_category"
													rules={[
														{
															required: true,
															message:
																'Please select Claim Type!',
														},
													]}
												>
													<XRadio.Group
														onChange={(e) => {
															handleInputChange(
																e,
																revenueCycleFormData,
																setRevenueCycleFormData,
															);
														}}
														name="claim_category"
													>
														<XRadio value={'full'}>
															Full
														</XRadio>
														<XRadio
															value={'partial'}
														>
															Partial
														</XRadio>
													</XRadio.Group>
												</XForm.Item>
											</Col>
											<Row gutter={[10]}>
												<Col xs={24}>
													<XForm.Item
														name="claim_amount"
														label="Claim Amount"
														rules={[
															{
																required: true,
																message:
																	'Please Enter Claim Amount!',
															},
														]}
													>
														<InputNumber
															addonBefore={'$'}
															size="large"
															min={0}
															placeholder="Enter Claim Amount"
															name="claim_amount"
															style={{
																width: '100%',
															}}
															// disabled={
															// 	revenueCycleFormData.claim_category ===
															// 	'full'
															// }
															onChange={(e) =>
																setRevenueCycleFormData(
																	(prev) => ({
																		...prev,
																		claim_amount:
																			e,
																	}),
																)
															}
														/>
													</XForm.Item>
												</Col>
											</Row>
										</Row>
									</Card>
								</Col>
								<Col xs={24} className="text-end mt-4">
									<XForm.Item
										name="remarks"
										label="Enter Remarks"
									>
										<TextArea
											name="remarks"
											showCount
											maxLength={100}
											style={{
												height: 120,
												marginBottom: 24,
											}}
											onChange={(e) =>
												handleInputChange(
													e,
													revenueCycleFormData,
													setRevenueCycleFormData,
												)
											}
											placeholder="Enter remarks"
										/>
									</XForm.Item>
								</Col>
							</>
						)}
					</Row>

					<Row className="mt-4">
						<Col xs={24} className="text-end">
							<XButtonConfirm
								type="primary"
								style={{
									background: '#000',
									color: '#fff',
								}}
								htmlType="submit"
								className="modalButton"
								loading={updateCaseLoading}
							>
								{updateModalButtonText}
							</XButtonConfirm>
						</Col>
					</Row>
				</XForm>
			</XModal>

			{/* uplodoaded Files Previews */}
			{/* requested by */}
			{/* <Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				{previewImage ? (
					<img
						alt="example"
						style={{
							width: '100%',
						}}
						src={previewImage}
					/>
				) : null}
			</Modal> */}
			{/* requested by */}
			{/* Modal for uplodoaded Files preview */}
			{/* <Modal
				open={pdfPreviewOpen}
				title="Preview"
				footer={null}
				onCancel={() => setPdfPreviewOpen(false)}
				width={pdfWidth} // Set the width of the modal dynamically
				// Other modal props...
			>
				<div>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
						<div style={{ height: '750px' }}>
							<Viewer fileUrl={previewImage} />
						</div>
					</Worker>
				</div>
			</Modal> */}

			{/* Modal for Missing Fields */}
			<XModal
				title={
					<>
						{/* <EditOutlined /> */}
						<XModalTitle className="ms-2">
							Additional Information Required
						</XModalTitle>
						{missingFieldTitle && (
							<XModalSubtitle className="ms-2 mt-2">
								{missingFieldTitle}
							</XModalSubtitle>
						)}
						<XDivider dashed className="mt-3" />
					</>
				}
				centered
				open={''}
				onOk={() => {
					missingFieldsForm
						.validateFields()
						.then((values) => {
							setLoading(true);
							reviseCoverageReset();
							reviseCoverage({
								case_id: patientData._id,
								feedbackId: feedbackId,
								clinicalHistory: pageContent.pastHistory,
								presentComplaint: pageContent.presentComplaint,
								recommendedProcedure:
									pageContent.recommendedProcedure,
								medicalCoverage: pageContent.medicalCoverage,
								state: patientData.state,
								provider: patientData.insurance_company?.name,
								missing_fields: values,
								last_analysis_data: lastAnalysisData,
								supportingDocsSummary:
									patientData && patientData.summary_docs,
							});

							missingFieldsForm.resetFields();
							setMissingFields([]);
							setMissingFieldInstructions([]);
							setMissingFieldsModal(false);
						})
						.catch((error) => {
							return;
						});
				}}
				onCancel={() => {
					missingFieldsForm.resetFields();
					setMissingFields([]);
					setMissingFieldInstructions([]);
					setMissingFieldsModal(false);
				}}
				className="modalButton"
				width={900}
				okText="Submit"
				style={{
					padding: '40px',
					borderRadius: '5px !important',
					'ant-modal-content': {
						padding: '30px !important',
						borderRadius: '5px !important',
					},
				}}
			>
				<XForm
					form={missingFieldsForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						{missingFieldInstructions.length > 0 && (
							<Col xs={24}>
								<XList
									size="small"
									header={<div>Instructions</div>}
									bordered
									dataSource={missingFieldInstructions}
									renderItem={(item, index) => (
										<List.Item>
											<XCircle>{index + 1}</XCircle>{' '}
											{item}
										</List.Item>
									)}
								/>
								<br />
							</Col>
						)}

						{missingFields.map((item, index) => {
							if (item.type == 'textarea') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<TextArea
												rows={3}
												name={item.name}
												showCount
												className="textArea"
												placeholder="Enter"
											></TextArea>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'input') {
								return (
									<Col xs={24}>
										<XForm.Item
											label={
												<XLabel>{item.label}</XLabel>
											}
											name={item.name}
											rules={[
												item.hasOwnProperty(
													'required',
												) &&
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												item.hasOwnProperty('regex') &&
													item.regex && {
														validator: (
															rule,
															value,
														) => {
															let regex =
																new RegExp(
																	item.regex.slice(
																		1,
																		-1,
																	),
																);
															if (
																!regex.test(
																	value,
																)
															)
																return Promise.reject(
																	new Error(
																		item.regex_error_message ??
																			'Invalid Data',
																	),
																);
															return Promise.resolve();
														},
													},
											]}
										>
											<Input
												size="large"
												name={item.name}
												placeholder="Enter"
											></Input>
										</XForm.Item>
									</Col>
								);
							} else if (item.type == 'checkbox') {
								return (
									<>
										<Col xs={24} key={index}>
											<XForm.Item
												name={item.name}
												rules={[
													item.required && {
														required: true,
														message:
															item.required_error_message ??
															'Field is required',
													},
												]}
											>
												<XCheckboxGroup
													name={item.name}
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													<XList
														className="normal-header"
														size="small"
														bordered
														header={
															<div
																style={{
																	display:
																		'flex',
																	justifyContent:
																		'space-between',
																	alignItems:
																		'center',
																}}
															>
																<XLabel>
																	{item.label}
																</XLabel>
																<XRoundButton
																	shape="circle"
																	icon={
																		<PlusOutlined />
																	}
																	onClick={() =>
																		handleAddOption(
																			index,
																		)
																	}
																/>
															</div>
														}
														dataSource={
															item.options
														}
														renderItem={(
															option,
															idx,
														) => (
															<List.Item>
																<Checkbox
																	value={
																		option.value
																	}
																>
																	{
																		option.label
																	}
																</Checkbox>
															</List.Item>
														)}
													/>
												</XCheckboxGroup>
											</XForm.Item>
										</Col>
									</>
								);
							} else if (item.type == 'radio') {
								return (
									<Col xs={24} key={index}>
										<XForm.Item
											name={item.name}
											rules={[
												item.required && {
													required: true,
													message:
														'Field is required',
												},
											]}
										>
											<Radio.Group
												style={{
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<XList
													className="normal-header"
													size="small"
													bordered
													header={
														<div
															style={{
																display: 'flex',
																justifyContent:
																	'space-between',
																alignItems:
																	'center',
															}}
														>
															<XLabel>
																{item.label}
															</XLabel>
														</div>
													}
													dataSource={item.options}
													renderItem={(
														option,
														idx,
													) => (
														<List.Item>
															<Radio
																value={
																	option.value
																}
															>
																{option.label}
															</Radio>
														</List.Item>
													)}
												/>
											</Radio.Group>
										</XForm.Item>
									</Col>
								);
							}
						})}
					</Row>
				</XForm>
			</XModal>

			{/* Modal for adding new option */}
			<XModal
				title="Add New Option"
				open={newCheckBoxModal[0]}
				onOk={handleNewCheckBoxModal}
				okText="Submit"
				onCancel={() => {
					setNewCheckBoxModal([false, null]);
					setNewOptionText('');
					newOptionForm.setFieldsValue({});
					newOptionForm.resetFields();
				}}
			>
				<XForm
					form={newOptionForm}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name={'new-option'}
						rules={[
							{
								required: true,
								message: 'Please input option',
							},
						]}
					>
						<Input
							size="large"
							className="mt-3 mb-3"
							value={newOptionText}
							onChange={(e) => setNewOptionText(e.target.value)}
							placeholder="Enter new option text"
						/>
					</XForm.Item>
				</XForm>
			</XModal>

			<XModal
				centered
				open={thanksModal[0]}
				closable={false} // Hide the close icon
				// onOk={() => setThanksModal([false, ''])}
				// onCancel={() => {
				// 	setThanksModal([false, '']);
				// }}
				className="modalButton"
				okText="Submit"
				footer={null}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
					gutter={(15, 0)}
					className="mt-2"
				>
					<Col xs={24} lg={18}>
						<XLottieMargin>
							<XLottie animationData={Thanks} loop={true} />
						</XLottieMargin>

						<XPara1
							style={{ textAlign: 'center' }}
							className="mt-0 mb-4 "
						>
							{thanksModal[1]}
						</XPara1>
					</Col>
				</Row>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	editCaseState: state.editCase,
	addCaseState: state.addCase,
	analyseCoverageState: state.analyseCoverage,
	reviseCoverageState: state.reviseCoverage,
	sendFeedbackState: state.sendFeedback,
	getAnalysisState: state.getAnalysis,
	getAppealLetterState: state.getAppealLetter,
	saveAppealLetterState: state.saveAppealLetter,
	generateAppealLetterState: state.generateAppealLetter,
	getConfigurationsState: state.getConfigurations,
	getRevisionState: state.getRevision,
});

const mapDispatchToProps = (dispatch) => ({
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	addCase: (data) => dispatch(addCaseApi(data)),
	addCaseReset: () => dispatch(addCaseReset()),
	sendFeedback: (params) => dispatch(sendFeedbackApi(params)),
	sendFeedbackReset: () => dispatch(sendFeedbackReset()),
	analyseCoverage: (params) => dispatch(analyseCoverageApi(params)),
	analyseCoverageReset: () => dispatch(analyseCoverageReset()),
	reviseCoverage: (params) => dispatch(reviseCoverageApi(params)),
	reviseCoverageReset: () => dispatch(reviseCoverageReset()),
	getAnalysis: (params) => dispatch(getAnalysisApi(params)),
	getAnalysisReset: (params) => dispatch(getAnalysisReset(params)),
	getAppealLetter: (params) => dispatch(getAppealLetterApi(params)),
	saveAppealLetter: (params) => dispatch(saveAppealLetterApi(params)),
	generateAppealLetter: (params) => dispatch(generateAppealLetterApi(params)),
	getConfigurations: (params) => dispatch(getConfigurationsApi(params)),
	getRevision: (params) => dispatch(getRevisionApi(params)),
	getRevisionReset: (params) => dispatch(getRevisionReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseForm);
