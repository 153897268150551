import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Button, Select, Radio, Tag } from 'antd';
import { Flex, Input, Space, notification, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
	XTable,
	XModal,
	XForm,
	XSwitch,
	XButton2,
	X2Select,
	XSearch,
	XSelect,
} from '../../styles/GlobalStyle';
import { XButtonImg } from './ManageStyle';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
// Actions
import { getRolesApi, getRolesReset } from '../../actions/getRolesAction';
import { getModulesApi, getModulesReset } from '../../actions/getModulesAction';
import {
	getOrganizationRoleByIdApi,
	getOrganizationRoleByIdReset,
} from '../../actions/getOrganizationRoleByIdAction';
import {
	addOrganizationRoleApi,
	addOrganizationRoleReset,
} from '../../actions/addOrganizationRoleAction';
import {
	editOrganizationRoleApi,
	editOrganizationRoleReset,
} from '../../actions/editOrganizationRoleAction';
import {
	deleteOrganizationRoleApi,
	deleteOrganizationRoleReset,
} from '../../actions/deleteOrganizationRoleAction';
// utils
import {
	filterByLabel,
	handleInputChange,
	handleSelectChange,
} from '../../utils/formFunctions';
import DataTable from '../../components/DataTable/DataTable';
import { checkModulePermissions, urlPagination } from '../../utils/helper';
import { getOrganizationsApi } from '../../actions/getOrganizationsAction';
import { dateFilters } from '../../utils/constants';
import {
	getRolesDropdownApi,
	getRolesDropdownReset,
} from '../../actions/getRolesDropdownAction';
import {
	getRoleByIdApi,
	getRoleByIdReset,
} from '../../actions/getRoleByIdAction';
import {
	SearchButton,
	XInputText,
} from '../AnalyseCoverage/AnalyseCoverageStyle';

const RoleManagement = (props) => {
	const {
		getRoles,
		getRolesReset,
		getRolesState,
		getModules,
		getModulesReset,
		getModulesState,
		getOrganizationRoleByIdState,
		addOrganizationRoleState,
		deleteOrganizationRoleState,
		editOrganizationRoleState,
		getOrganizationRoleById,
		addOrganizationRole,
		deleteOrganizationRole,
		editOrganizationRole,
		getOrganizationRoleByIdReset,
		addOrganizationRoleReset,
		deleteOrganizationRoleReset,
		editOrganizationRoleReset,
		getOrganizations,
		getOrganizationsState,
		getRolesDropdownState,
		getRolesDropdown,
		getRolesDropdownReset,
		getRoleById,
		getRoleByIdReset,
		getRoleByIdState,
	} = props;

	const defaultFilters = {
		search: '',
		page: 1,
		role: '',
		created_on: '',
		limit: 10,
	};
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [modalOpen, setModalOpen] = useState([false, '']);
	const [tableData, setTableData] = useState([]);
	const [moduleList, setModuleList] = useState([]);
	const [editRoleActive, setEditRoleActive] = useState(false);
	const [totalRecords, setTotalRecords] = useState('');
	const [currentPermissions, setCurrentPermissions] = useState([]);
	const [displayOrganization, setDisplayOrganization] = useState(false);
	const [renderEdit, setRenderEdit] = useState(true);

	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions('role-management').sub_modules.includes(
			'list-roles',
		) && getRoles({ type: 'super-admin', ...updatedFilters });
	}, [window.location.search]);

	useEffect(() => {
		if (getRolesState.apiState === 'success') {
			if (getRolesState.data.all_roles.length > 0) {
				const updatedTableData = [];
				setTotalRecords(getRolesState.data.total_count);
				getRolesState.data.all_roles.map((item, index) => {
					updatedTableData.push({
						key: item._id,
						_id: item._id,
						role: item.display_name,
						organization: item.organization_name || '-',
						modules:
							item.permissions.map((mod) => (
								<Tag style={{ margin: '2px' }}>
									{mod.module.module_name}
								</Tag>
							)) || '-',
						display_name: item.display_name,
						createdOn: (
							<div className="showDate">
								{moment(item.createdAt).format('DD-MM-YYYY')}
							</div>
						),
						action: (
							<div className="text-center">
								<Row>
									<Col xs={{ span: 6, offset: 6 }}>
										{checkModulePermissions(
											'role-management',
										).sub_modules.includes(
											'update-roles',
										) ? (
											<XButtonImg
												onClick={() => {
													handleEdit(item);
												}}
												type="text"
											>
												<img
													src="./images/pencil.svg"
													alt=""
												/>
											</XButtonImg>
										) : (
											<></>
										)}
									</Col>
								</Row>
							</div>
						),
					});
				});
				setTableData(updatedTableData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getRolesState]);

	useEffect(() => {
		if (getModulesState.apiState === 'success') {
			if (getModulesState.data.length > 0) {
				let module_list = [];

				getModulesState.data.map((item) => {
					let sub_modules = [];

					item.sub_modules.map((itm) => {
						let sub_mod = {
							id: itm._id,
							name: itm.sub_module_name,
							included: false,
						};

						if (editOrganizationRole) {
							let subModuleCheck = currentPermissions?.filter(
								(mod) => {
									if (item._id === mod.module[0]._id) {
										if (
											mod.sub_modules?.includes(itm._id)
										) {
											return mod;
										}
									}
								},
							);
							if (subModuleCheck.length > 0) {
								sub_mod.included = true;
							}
						}
						sub_modules.push(sub_mod);
					});

					module_list.push({
						id: item._id,
						name: item.module_name,
						sub_modules,
					});
				});

				setModuleList(module_list);
			}
			// else {
			// 	notification.error({
			// 		message: 'No Modules defined for Super admin',
			// 	});
			// }
			getModulesReset();
		}
	}, [getModulesState]);

	useEffect(() => {
		if (addOrganizationRoleState.apiState === 'success') {
			setFormData({});
			addOrganizationRoleReset();
			setModuleList([]);
			getRoles({ type: 'super-admin' });
			setCurrentPermissions([]);
			notification.success({
				message: addOrganizationRoleState.message,
			});
			form.resetFields();
			setModalOpen([false, '']);
		}
		if (addOrganizationRoleState.apiState === 'error') {
			notification.error({
				message: addOrganizationRoleState.message,
			});
			addOrganizationRoleReset();
		}
	}, [addOrganizationRoleState]);

	useEffect(() => {
		if (editOrganizationRoleState.apiState === 'success') {
			setFormData({});
			editOrganizationRoleReset();
			setModuleList([]);
			getRoles({ type: 'super-admin' });
			setCurrentPermissions([]);
			notification.success({
				message: editOrganizationRoleState.message,
			});
			form.resetFields();
			setModalOpen([false, '']);
			setEditRoleActive(false);
		}
		if (editOrganizationRoleState.apiState === 'error') {
			notification.error({
				message: editOrganizationRoleState.message,
			});
			editOrganizationRoleReset();
			setEditRoleActive(false);
		}
	}, [editOrganizationRoleState]);

	useEffect(() => {
		if (deleteOrganizationRoleState.apiState === 'success') {
			deleteOrganizationRoleReset();
			setEditRoleActive(false);
			notification.success({
				message: deleteOrganizationRoleState.message,
			});
			getRoles({ type: 'super-admin' });
		}
	}, [deleteOrganizationRoleState]);

	useEffect(() => {
		if (getOrganizationRoleByIdState.apiState === 'success') {
			setCurrentPermissions(
				getOrganizationRoleByIdState.data.permissions,
			);
			setFormData({
				id: getOrganizationRoleByIdState.data._id,
				role_name: getOrganizationRoleByIdState.data.role.display_name,
				organization_name: getOrganizationRoleByIdState.data
					.organization
					? getOrganizationRoleByIdState.data?.organization.name
					: null,
				role_id: getOrganizationRoleByIdState.data.role._id,
				organization_id: getOrganizationRoleByIdState.data.organization
					? getOrganizationRoleByIdState.data?.organization._id
					: null,
			});
			form.setFieldsValue({
				id: getOrganizationRoleByIdState.data._id,
				role_name: getOrganizationRoleByIdState.data.role.display_name,
				organization_name: getOrganizationRoleByIdState.data
					.organization
					? getOrganizationRoleByIdState.data?.organization.name
					: null,
				role_id: getOrganizationRoleByIdState.data.role._id,
				organization_id: getOrganizationRoleByIdState.data.organization
					? getOrganizationRoleByIdState.data?.organization._id
					: null,
			});
			getOrganizationRoleByIdReset();
		}
	}, [getOrganizationRoleByIdState]);

	useEffect(() => {
		if (getRoleByIdState.apiState === 'success') {
			setCurrentPermissions(getRoleByIdState.data.permissions);
			setFormData({
				...formData,
				role_id: getRoleByIdState.data._id,
				role_name: getRoleByIdState.data.display_name,
			});

			getRoleByIdReset();
		}
	}, [getRoleByIdState]);

	// Functions
	const showDeleteConfirm = (item) => {
		setModalOpen(false);
		XModal.confirm({
			title: 'Delete Role?',
			content: (
				<p className="modalPara1">
					Are you sure you want to delete this role?
				</p>
			),
			icon: <img className="me-3" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deleteOrganizationRole({
					id: item.id,
					role_id: item.role_id,
					organization_id: item.organization_id,
				});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const subModuleSelected = (checked, moduleKey, subModuleKey) => {
		const updatedModuleList = [...moduleList];
		updatedModuleList[moduleKey] = {
			...updatedModuleList[moduleKey],
			sub_modules: [...updatedModuleList[moduleKey].sub_modules],
		};
		updatedModuleList[moduleKey].sub_modules[subModuleKey] = {
			...updatedModuleList[moduleKey].sub_modules[subModuleKey],
			included: checked,
		};
		setModuleList(updatedModuleList);
	};

	const moduleSelected = (value, moduleKey) => {
		const updatedModuleList = moduleList.map((module, index) => {
			if (index === moduleKey) {
				const updatedSubModules = module.sub_modules.map(
					(subModule) => {
						return { ...subModule, included: value };
					},
				);
				return { ...module, sub_modules: updatedSubModules };
			}
			return module;
		});

		setModuleList(updatedModuleList);
	};

	const isAnySubModuleUnchecked = (moduleKey) => {
		const selectedModule = moduleList[moduleKey];
		const uncheckedSubModule = selectedModule.sub_modules.find(
			(subModule) => !subModule.included,
		);
		return !!uncheckedSubModule;
	};

	const updateRoleDetails = (id) => {
		form.validateFields()
			.then(() => {
				const allIncludedFalse = moduleList.every((module) =>
					module.sub_modules.every(
						(subModule) => !subModule.included,
					),
				);
				if (allIncludedFalse) {
					form.setFields([
						{
							name: 'permissions',
							errors: [
								'Please give access to atleast one module',
							],
						},
					]);
					return;
				} else {
					editOrganizationRole({
						id: formData.id,
						role: formData.role_name,
						permissions: moduleList,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const saveRole = () => {
		form.validateFields()
			.then(() => {
				const allIncludedFalse = moduleList.every((module) =>
					module.sub_modules.every(
						(subModule) => !subModule.included,
					),
				);
				if (allIncludedFalse) {
					form.setFields([
						{
							name: 'permissions',
							errors: [
								'Please give access to atleast one module',
							],
						},
					]);
					return;
				} else {
					addOrganizationRole({
						role_id: formData.role_id,
						organization_id: formData.organization,
						permissions: moduleList,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const onSelectChange = (newSelectedRowKeys) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		setDisplayOrganization,
	};

	const handleEdit = (item) => {
		console.log(item);
		setEditRoleActive(true);
		setRenderEdit(false);
		getOrganizationRoleById({ id: item._id });
		getModules({
			type: 'super-admin',
		});
		setFormData({
			_id: item._id,
			role: item.display_name,
			organization_name: item.organization_name,
			role_id: item.role_id,
			organization_id: item.organization_id,
		});
		// setModalOpen([true, `Edit ${item.display_name}`]);
	};

	//Edit Modal display only after the state has changed so that the data get loaded completly
	useEffect(() => {
		if (editRoleActive)
			setModalOpen([
				true,
				`Edit ${formData.role} for ${formData.organization_name}`,
			]);
	}, [editRoleActive]);

	useEffect(() => {
		if (localStorage.getItem('role') === 'super-admin') {
			setDisplayOrganization(true);
		}
		getRolesDropdown();
		getOrganizations();
	}, []);

	const columns = [
		{
			title: 'Role',
			dataIndex: 'role',
			width: 200,
		},
		{
			title: 'Organization',
			dataIndex: 'organization',
		},
		{
			title: 'Modules',
			dataIndex: 'modules',
			width: 700,
		},
		{
			title: 'Created on',
			dataIndex: 'createdOn',
		},
		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
			width: '10%',
		},
	];

	return (
		<>
			<Row gutter={(15, 15)}>
				<Col
					style={{
						display: 'flex',
						alignItems: 'center',
						paddingBottom: '20px',
					}}
					xs={24}
					md={6}
					lg={3}
					xl={3}
					xxl={3}
					className="mt-lg-2"
				>
					<p className="tableHeader">Total {totalRecords} Roles</p>
				</Col>
				<Col
					xs={24}
					md={14}
					lg={{ span: 6 }}
					xl={{ span: 6 }}
					xxl={{ span: 6 }}
					className="mb-4 mb-lg-0"
				>
					{displayOrganization ? (
						<Space>
							<XInputText
								allowClear
								style={{ borderRadius: '25px' }}
								// size="large"
								placeholder="Search By Role Name"
								defaultValue={
									filters.role_name ? filters.role_name : null
								}
								onChange={(v) => {
									if (v.target.value === '') {
										urlPagination(filters, 'role_name', '');
										setFilters({
											...filters,
											role_name: '',
										});
									} else {
										setFilters({
											...filters,
											role_name: v.target.value,
										});
									}
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										const searchValue = filters.role_name
											? filters.role_name
											: '';
										if (searchValue) {
											urlPagination(
												filters,
												'role_name',
												searchValue,
											);
											setFilters({
												...filters,
												role_name: searchValue,
											});
										}
									}
								}}
							/>
							<SearchButton
								style={{
									borderRadius: '20px',
									color: '#007299',
								}}
								onClick={() => {
									// Trigger search when the button is clicked
									const searchValue = filters.role_name
										? filters.role_name
										: '';
									if (searchValue) {
										urlPagination(
											filters,
											'role_name',
											searchValue,
										);
										setFilters({
											...filters,
											role_name: searchValue,
										});
									}
								}}
								className="px-4"
								size="large"
							>
								Search
							</SearchButton>
						</Space>
					) : (
						// <XForm.Item>
						// 	<XSearch
						// 		placeholder="Search By Role Name"
						// 		allowClear
						// 		enterButton="Search"
						// 		size="large"
						// 		defaultValue={
						// 			filters.role_name ? filters.role_name : null
						// 		}
						// 		onChange={(v) => {
						// 			if (v.target.value === '') {
						// 				urlPagination(filters, 'role_name', '');
						// 				setFilters({
						// 					...filters,
						// 					role_name: '',
						// 				});
						// 			}
						// 		}}
						// 		onSearch={(v) => {
						// 			urlPagination(filters, 'role_name', v);
						// 			setFilters({
						// 				...filters,
						// 				role_name: v,
						// 			});
						// 		}}
						// 	/>
						// </XForm.Item>
						<></>
					)}
				</Col>
				<Col
					xs={24}
					md={12}
					lg={{ span: 4, offset: 7 }}
					xl={{ span: 4, offset: 7 }}
					xxl={{ span: 4, offset: 7 }}
				>
					{displayOrganization ? (
						<XForm.Item name="organization">
							<X2Select
								size="large"
								placeholder="Select Organization"
								name="organization"
								showSearch={true}
								className="removeBg organization-role"
								// onFocus={() => {
								// 	getOrganizations();
								// }}
								onSearch={(search) => {
									if (search.length >= 3) {
										getOrganizations({
											search,
										});
									} else if (search.length === 0) {
										getOrganizations();
									}
								}}
								allowClear
								options={
									getOrganizationsState.apiState ===
										'success' &&
									getOrganizationsState.data.list?.length >
										0 &&
									getOrganizationsState.data.list.map(
										(itm) => {
											return {
												label: itm.name,
												value: itm._id,
											};
										},
									)
								}
								onSelect={(value, options) => {
									setFilters({
										...filters,
										organization: value,
									});

									urlPagination(
										filters,
										'organization',
										value,
									);
								}}
								onClear={() => {
									setFilters({
										...filters,
										organization: '',
									});
									urlPagination(filters, 'organization', '');
								}}
							></X2Select>
						</XForm.Item>
					) : (
						<></>
					)}
				</Col>
				<Col
					xs={24}
					md={12}
					lg={{ span: 4 }}
					xl={{ span: 4 }}
					xxl={{ span: 4 }}
				>
					{' '}
					{displayOrganization ? (
						checkModulePermissions(
							'role-management',
						).sub_modules.includes('create-roles') ? (
							<XButton2
								className="text-right"
								onClick={() => {
									getModules({ type: 'super-admin' });
									setModalOpen([true, 'Select Role']);
									setRenderEdit(true);
								}}
								icon={<PlusOutlined />}
							>
								New Role
							</XButton2>
						) : (
							<></>
						)
					) : (
						<></>
					)}
				</Col>
			</Row>
			<DataTable
				columns={columns}
				tableData={tableData}
				state={getRolesState.apiState}
				totalRecords={totalRecords}
				setFilters={setFilters}
				filters={filters}
				page={'role-management'}
				handleEdit={handleEdit}
				showDeleteConfirm={showDeleteConfirm}
			/>
			<XModal
				title={modalOpen[1]}
				centered
				open={modalOpen[0]}
				onCancel={() => {
					form.resetFields();
					setFormData({});
					setCurrentPermissions([]);
					setModalOpen([false, '']);
					setEditRoleActive(false);
				}}
				cancelButtonProps={{ hidden: 'none' }}
				okButtonProps={{ hidden: 'none' }}
				className="modalButtonBoth"
				okText="Submit"
				zIndex={1100}
				width={850}
			>
				<XForm
					form={form}
					name="roleForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 15)} className="mt-4">
						{renderEdit ? (
							<>
								<Col xs={24} lg={12}>
									<XForm.Item
										name="role_id"
										label="Select Role"
										rules={[
											{
												required: true,
												message: 'Please select Role!',
											},
										]}
									>
										<XSelect
											size="large"
											placeholder="Select Role"
											className="removeBg"
											// onFocus={() => getRolesDropdown()}
											onSelect={(value, option) => {
												handleSelectChange(
													'role_id',
													value,
													formData,
													setFormData,
												);
												getRoleById({ id: value });
												getModules({
													type: 'super-admin',
												});
											}}
											filterOption={filterByLabel}
											showSearch
											options={
												getRolesDropdownState.apiState ===
													'success' &&
												getRolesDropdownState.data
													.all_roles.length > 0 &&
												getRolesDropdownState.data.all_roles.map(
													(ele) => {
														return {
															key: ele._id,
															value: ele._id,
															label: ele.display_name,
														};
													},
												)
											}
										></XSelect>
									</XForm.Item>
								</Col>
								<Col xs={24} lg={12}>
									<XForm.Item
										name="organization"
										label={'Organization'}
										rules={[
											{
												required: true,
												message:
													'Please enter Region name',
											},
										]}
									>
										<XSelect
											size="large"
											placeholder="Select Organization"
											name="organization"
											showSearch={true}
											className="removeBg"
											onSearch={(search) => {
												if (search.length >= 3) {
													getOrganizations({
														search,
													});
												} else if (
													search.length === 0
												) {
													getOrganizations();
												}
											}}
											// onFocus={() => {
											// 	getOrganizations();
											// }}
											filterOption={filterByLabel}
											options={
												getOrganizationsState.apiState ===
													'success' &&
												getOrganizationsState.data.list
													?.length > 0 &&
												getOrganizationsState.data.list.map(
													(itm) => {
														return {
															label: itm.name,
															value: itm._id,
														};
													},
												)
											}
											onSelect={(value, options) => {
												handleSelectChange(
													'organization',
													value,
													formData,
													setFormData,
												);
											}}
										></XSelect>
									</XForm.Item>
								</Col>
							</>
						) : (
							<></>
						)}
					</Row>
					<Row>
						<Col xs={24}>
							<XForm.Item name="permissions">
								<div className="customLabel mb-2">
									Give Access
								</div>
							</XForm.Item>
						</Col>
					</Row>
					<div
						className="customScroll"
						style={{
							overflowX: 'hidden',
							overflowY: 'scroll',
							maxHeight: 300,
						}}
					>
						{moduleList.length > 0 &&
							moduleList.map((item, module_key) => (
								<>
									<Row>
										<Col xs={24}>
											<div className="customHeader">
												<Flex
													justify="space-between"
													align="flex-start"
												>
													<span>{item.name}</span>
													<span>
														<XSwitch
															defaultChecked
															size="small"
															onChange={(value) =>
																moduleSelected(
																	value,
																	module_key,
																)
															}
															checked={
																!isAnySubModuleUnchecked(
																	module_key,
																)
															}
														/>
													</span>
												</Flex>
											</div>
										</Col>
									</Row>
									<Row gutter={(15, 20)} className="mt-4">
										<Col
											xs={24}
											sm={24}
											md={24}
											className="my-3"
										>
											<Row>
												{item.sub_modules.length > 0 &&
													item.sub_modules.map(
														(
															itm,
															sub_module_key,
														) => (
															<>
																<Col
																	xs={18}
																	lg={3}
																	md={4}
																	className="mb-4"
																>
																	<span>
																		{
																			itm.name
																		}
																	</span>
																</Col>
																<Col
																	xs={6}
																	lg={3}
																	md={4}
																	className="mb-4"
																>
																	<XSwitch
																		size="small"
																		checked={
																			itm.included ===
																			true
																		}
																		onChange={(
																			checked,
																		) =>
																			subModuleSelected(
																				checked,
																				module_key,
																				sub_module_key,
																			)
																		}
																	/>
																</Col>
															</>
														),
													)}
											</Row>
										</Col>
									</Row>
								</>
							))}
					</div>
					<Row
						className="mt-4"
						gutter={[15, 15]}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Col xs={12} md={6} lg={5}>
							{checkModulePermissions(
								'role-management',
							).sub_modules.includes('delete-roles') ? (
								<Button
									onClick={() => {
										showDeleteConfirm(formData);
									}}
									type="text"
									className="modalButton"
									style={{
										background: '#fff',
										color: '#007299',
									}}
								>
									Delete
								</Button>
							) : (
								<></>
							)}
						</Col>
						<Col xs={12} md={6} lg={5}>
							{checkModulePermissions(
								'role-management',
							).sub_modules.includes('delete-roles') ? (
								<Button
									onClick={() =>
										editRoleActive
											? updateRoleDetails()
											: saveRole()
									}
									type="text"
									className="modalButton"
									style={{
										background: '#007299',
										color: '#fff',
									}}
								>
									Save
								</Button>
							) : (
								<></>
							)}
						</Col>
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getRolesState: state.getRoles,
	getModulesState: state.getModules,
	getOrganizationRoleByIdState: state.getOrganizationRoleById,
	addOrganizationRoleState: state.addOrganizationRole,
	deleteOrganizationRoleState: state.deleteOrganizationRole,
	editOrganizationRoleState: state.editOrganizationRole,
	getOrganizationsState: state.getOrganizations,
	getRolesDropdownState: state.getRolesDropdown,
	getRoleByIdState: state.getRoleById,
});

const mapDispatchToProps = (dispatch) => ({
	getRoles: (params) => dispatch(getRolesApi(params)),
	getRolesReset: () => dispatch(getRolesReset()),
	getModules: (params) => dispatch(getModulesApi(params)),
	getModulesReset: () => dispatch(getModulesReset()),
	getOrganizationRoleById: (params) =>
		dispatch(getOrganizationRoleByIdApi(params)),
	deleteOrganizationRole: (params) =>
		dispatch(deleteOrganizationRoleApi(params)),
	editOrganizationRole: (params) => dispatch(editOrganizationRoleApi(params)),
	addOrganizationRole: (params) => dispatch(addOrganizationRoleApi(params)),
	getOrganizationRoleByIdReset: () =>
		dispatch(getOrganizationRoleByIdReset()),
	editOrganizationRoleReset: () => dispatch(editOrganizationRoleReset()),
	deleteOrganizationRoleReset: () => dispatch(deleteOrganizationRoleReset()),
	addOrganizationRoleReset: () => dispatch(addOrganizationRoleReset()),
	getRoleById: (params) => dispatch(getRoleByIdApi(params)),
	getRoleByIdReset: () => dispatch(getRoleByIdReset()),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
	getRolesDropdown: (params) => dispatch(getRolesDropdownApi(params)),
	getRolesDropdownReset: () => dispatch(getRolesDropdownReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleManagement);
